/* eslint-disable no-unused-vars */
import React from "react";

import MenuButton from "../MenuButton/MenuButton";
import { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";

import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";

const SPTAttendence = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const [myBranches, setMyBranches] = useState([]);

  const [trainerName, setTrainerName] = useState([]);
  const [classesNames, setClassesNames] = useState([]);
  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");

  const getUsers = async (page, limit, values) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);

    try {
      const responce = await UserServices.getGxClasses(
        newLimit,
        pageNo,
        values
      );

      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];
        await res.map((value) => {
          results.push({
            id: value.id,
            branch_name: value.branch_name,
            branch_id: value.branch_id,

            trainer_name: value.trainer_name,
            trainer_id: value.trainer_id,
            package_name: value.name,

            order_details: value.order_details,
            time_slot: value.time_slot,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.per_page);
        setTotalResults(responce.data.total_record);
        setTotalPages(responce.data.total_pages);
        setCurrentPage(responce.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);

      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  const getClassesList = async (br_Id, tr_id) => {
    try {
      const responce = await UserServices.registrationPkgs(br_Id, "4", tr_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setClassesNames([{ name: "Select Package ", id: "" }, ...results]);
    } catch (err) {
      console.log(err);

      setClassesNames([{ name: "No Package Found", id: "" }]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit, values);
    branches();
    gymTrainer(branch_id);

    getClassesList(values.branch_id, values.trainer_id);
  }, []);
  const addGxAttendence = async (newVal) => {
    try {
      setLoading(true);
      const response = await UserServices.addAttendanceForTrainer(newVal);
      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit, values);
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === 400) {
        toast.error(
          "Email delivery failed. The recipient's email is invalid.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } else if (err?.response?.status === 403) {
        toast.error("This record is Already Exists!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("SPT Attendance.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);

    // return (
    //   <>
    //     {slice.length ? (
    //       slice.map((el) =>
    //         el.time_slot.length ? (
    //           <>
    //             <div
    //               className="bg-black d-flex mt-3"
    //               style={{ padding: "18px 10px" }}
    //             >
    //               <div className="text-white fw-bold ">
    //                 Trainer Name:{" "}
    //                 <span style={{ fontWeight: "100" }}>
    //                   {el.trainer_name}{" "}
    //                 </span>
    //               </div>
    //               <div className="text-white fw-bold ms-auto">
    //                 Package Name:{" "}
    //                 <span style={{ fontWeight: "100" }}>
    //                   {el.package_name}{" "}
    //                 </span>
    //               </div>
    //             </div>
    //             <table
    //               key={el.id}
    //               className="table table-striped table-borderless table-style-1"
    //               id="report-POS"
    //               data-caption={el.package_name + " Attendance"}
    //             >
    //               <thead>
    //                 <tr>
    //                   <th scope="col" className="no_sort">
    //                     Client Name
    //                   </th>

    //                   <th scope="col" className="no_sort">
    //                     Time Slot
    //                   </th>
    //                   <th scope="col" className="no_sort">
    //                     Mark Attendance
    //                   </th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 {el.order_details.length ? (
    //                   el.order_details.map((inl, index) => (
    //                     <tr key={index}>
    //                       <td>{inl.client_name}</td>

    //                       <td>
    //                         {el.time_slot.length
    //                           ? el.time_slot[0].start_time +
    //                             " To " +
    //                             el.time_slot[0].end_time
    //                           : null}
    //                       </td>
    //                       <td>
    //                         <button
    //                           className="attendence-btn pre"
    //                           onClick={() => {
    //                             addGxAttendence({
    //                               branch_id: el.branch_id,
    //                               trainer_id: el.trainer_id,
    //                               order_id: inl.id,
    //                               clientId: inl.client_id,
    //                               trainer_status: "Present",
    //                               client_status: "Present",
    //                               clientName: inl.client_name,
    //                               clientEmail: inl.email,
    //                               date: values.date,
    //                               trainer_schedule_id: el.time_slot[0].id,
    //                               type: "SPT  ",
    //                               package_id: el.id,
    //                             });
    //                           }}
    //                         >
    //                           P
    //                         </button>
    //                         <button
    //                           type="button"
    //                           className="attendence-btn ms-2"
    //                           onClick={() => {
    //                             addGxAttendence({
    //                               branch_id: el.branch_id,
    //                               trainer_id: el.trainer_id,
    //                               order_id: inl.id,
    //                               clientId: inl.client_id,
    //                               trainer_status: "Present",
    //                               client_status: "Absent",
    //                               clientName: inl.client_name,
    //                               clientEmail: inl.email,
    //                               date: values.date,
    //                               trainer_schedule_id: el.time_slot[0].id,
    //                               type: "SPT",
    //                               package_id: el.id,
    //                             });
    //                           }}
    //                         >
    //                           A
    //                         </button>
    //                       </td>
    //                     </tr>
    //                   ))
    //                 ) : (
    //                   <tr>
    //                     <td colSpan="6" className="text-center text-capitalize">
    //                       No Client found
    //                     </td>
    //                   </tr>
    //                 )}
    //               </tbody>
    //             </table>
    //           </>
    //         ) : null
    //       )
    //     ) : (
    //       <div className="text-danger font-22 mt-2 text-center bg-danger-subtle p-1">
    //         No Record Found
    //       </div>
    //     )}
    //     {totalResults > limit && totalPages > 1 ? (
    //       <Pagination
    //         activePage={currentPage}
    //         itemsCountPerPage={parseInt(limit)}
    //         totalItemsCount={totalResults}
    //         onChange={(e) => {
    //           getUsers(e, limit, values);
    //         }}
    //         pageRangeDisplayed={8}
    //         itemClass="page-item"
    //         linkClass="page-link"
    //         firstPageText="First Page"
    //         lastPageText="Last Page"
    //       />
    //     ) : (
    //       ""
    //     )}
    //   </>
    // );
    return (
      <>
        {slice.length ? (
          slice.map((el) =>
            el.time_slot.length ? (
              <>
                <div
                  className="bg-black d-flex mt-3"
                  style={{ padding: "18px 10px" }}
                >
                  <div className="text-white fw-bold ">
                    Trainer Name:{" "}
                    <span style={{ fontWeight: "100" }}>
                      {el.trainer_name}{" "}
                    </span>
                  </div>
                  <div className="text-white fw-bold ms-auto">
                    Slot Name:{" "}
                    <span style={{ fontWeight: "100" }}>
                      {el.package_name}{" "}
                    </span>
                  </div>
                </div>
                <table
                  key={el.id}
                  className="table table-striped table-borderless table-style-1"
                  id="report-POS"
                  data-caption={el.package_name + " Attendance"}
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Client Name
                      </th>

                      <th scope="col" className="no_sort">
                        Time Slot
                      </th>
                      <th scope="col" className="no_sort">
                        Mark Attendance
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {el.order_details.length ? (
                      el.order_details.map((inl, index) => (
                        <tr key={index}>
                          <td>{inl.client_name}</td>
                          <td>
                            {el.time_slot.length
                              ? el.time_slot[0].start_time +
                                " To " +
                                el.time_slot[0].end_time
                              : null}
                          </td>
                          <td>
                            {inl.gx_attendance && inl.gx_attendance.length ? (
                              inl.gx_attendance.map((attendance, idx) => (
                                <span key={idx}>
                                  {attendance.client_status === "Present" ? (
                                    <>
                                      <button
                                        className="attendence-btn pre"
                                        disabled
                                      >
                                        P
                                      </button>
                                      <button
                                        type="button"
                                        className="attendence-btn ms-2"
                                        disabled
                                        style={{ backgroundColor: "lightgrey" }}
                                      >
                                        A
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="attendence-btn pre"
                                        disabled
                                        style={{ backgroundColor: "lightgrey" }}
                                      >
                                        P
                                      </button>
                                      <button
                                        type="button"
                                        className="attendence-btn ms-2"
                                        disabled
                                      >
                                        A
                                      </button>
                                    </>
                                  )}
                                </span>
                              ))
                            ) : (
                              <>
                                <button
                                  className="attendence-btn pre"
                                  onClick={() => {
                                    addGxAttendence({
                                      branch_id: el.branch_id,
                                      trainer_id: el.trainer_id,
                                      order_id: inl.id,
                                      clientId: inl.client_id,
                                      trainer_status: "Present",
                                      client_status: "Present",
                                      clientName: inl.client_name,
                                      clientEmail: inl.email,
                                      date: values.date,
                                      trainer_schedule_id: el.time_slot[0].id,
                                      type: "SPT",
                                      package_id: el.id,
                                    });
                                  }}
                                  style={{
                                    backgroundColor: "lightgrey",
                                    color: "black",
                                    border: "1px solid gray",
                                    transition:
                                      "background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease",
                                  }}
                                  onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = "green";
                                    e.target.style.color = "white";
                                    e.target.style.borderColor = "white";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.backgroundColor =
                                      "lightgrey";
                                    e.target.style.color = "black";
                                    e.target.style.borderColor = "gray";
                                  }}
                                >
                                  P
                                </button>
                                <button
                                  className="attendence-btn ms-2"
                                  onClick={() => {
                                    addGxAttendence({
                                      branch_id: el.branch_id,
                                      trainer_id: el.trainer_id,
                                      order_id: inl.id,
                                      clientId: inl.client_id,
                                      trainer_status: "Present",
                                      client_status: "Absent",
                                      clientName: inl.client_name,
                                      clientEmail: inl.email,
                                      date: values.date,
                                      trainer_schedule_id: el.time_slot[0].id,
                                      type: "SPT",
                                      package_id: el.id,
                                    });
                                  }}
                                  style={{
                                    backgroundColor: "lightgrey",
                                    color: "black",
                                    border: "1px solid gray",
                                    transition:
                                      "background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease",
                                  }}
                                  onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = "#e8002b";
                                    e.target.style.color = "white";
                                    e.target.style.borderColor = "white";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.backgroundColor =
                                      "lightgrey";
                                    e.target.style.color = "black";
                                    e.target.style.borderColor = "gray";
                                  }}
                                >
                                  A
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center text-capitalize">
                          No Client found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            ) : null
          )
        ) : (
          <div className="text-danger font-22 mt-2 text-center bg-danger-subtle p-1">
            No Record Found
          </div>
        )}
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit, values);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const initialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    class_id: "",
    catagrey: "4",
    date: eDate,
  };
  const { values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: "",
    onSubmit: async (values) => {
      getUsers("1", limit, values);
    },
  });

  return (
    <>
      <div className="wrapper">
        {" "}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">SPT Attendance</h5>
              </div>
              <div className="card-body">
                <form
                  className="row g-3 trainee-add mb-2"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Branch Name</label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            gymTrainer(e.target.value);
                            getClassesList(e.target.value, values.trainer_id);
                            values.trainer_id = "";
                            values.class_id = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Trainer</label>

                      {trainerName ? (
                        <select
                          className="form-select"
                          name="trainer_id"
                          value={values.trainer_id}
                          onChange={(e) => {
                            handleChange(e);
                            getClassesList(values.branch_id, e.target.value);
                            values.class_id = "";
                          }}
                          onBlur={handleBlur}
                          disabled={role === "9" && UId ? true : false}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Package </label>

                      {classesNames ? (
                        <select
                          className="form-select"
                          name="class_id"
                          value={values.class_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {classesNames.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-1">
                    <button
                      type="submit"
                      className="btn btn-primary mx-md-2 file-download mt-md-4 w-100"
                    >
                      Search
                    </button>
                  </div>
                  {/* <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Attendance Date</label>

                      <input
                        className="form-control"
                        type="date"
                        name="date"
                        disabled={role === "9" ? true : false}
                        value={values.date || ""}
                        max={eDate}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-1 col-lg-1 ms-auto">
                    <div className="input-group mt-md-4">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getUsers("1", e.target.value, values)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className=" col-md-1 text-end">
                    <button
                      className="btn btn-primary file-download mt-md-4 w-100"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                      type="button"
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </form>
                <div className="table-responsive">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default SPTAttendence;

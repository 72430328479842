import React, { useContext, useEffect, useState } from "react";
import { salaryFilter } from "../schemas";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import CurrencyFormat from "react-currency-format";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import { NoteState } from "../../context/notes/NoteState";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function SatffProfileSalary() {
  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;
  const { id } = useParams();
  // Get the current date
  const currentDate = new Date();

  currentDate.setDate(1);

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  const formattedMonth = month < 10 ? `0${month}` : month;
 
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [designtion, setDesigntion] = useState("");

  const getSalary = async (e) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        const responce = await UserServices.getLatestStaffSalary(
          branch_id,
          values,
          limit,
          "!"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              department: values.department,
              medical: parseInt(values.medical),
              reward: parseInt(values.reward),
              joining: values.joining,
              advance_total: parseInt(values.advance),
              fine_total: parseInt(values.fine),
              designation: values.designation,
              salary: parseInt(values.salary),
              detections: parseInt(values.detections),
              cafe: parseInt(values.cafe),

              commission: parseInt(values.commission?.commission),
              commission_per: parseInt(values.commission?.commission_per),
              gx_commission: parseInt(values.commission?.gx_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              small_pt_commission: parseInt(
                values.commission?.small_pt_commission
              ),
              loans: parseInt(values.loan),
            });
            setDesigntion(values.designation)

            return null;
          });

          setMyData([...results]);
          setLimit(responce.data.pagination.per_page);
          setTotalResults(responce.data.pagination.total_record);
          setTotalPages(responce.data.pagination.total_pages);
          setCurrentPage(responce.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getSalary();
    console.log(formattedMonth);
  }, []);

  const initialValues = {
    sdate: formattedMonth,
    edate: year,
    trainer_id: role === "9" ? UId : id,
  };
  //.......................................................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: salaryFilter,
      onSubmit: (values) => {
        getSalary();
      },
    });

  const changePagination = (e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getLatestStaffSalary(
          branch_id,
          values,
          limit,
          pageNo
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              department: values.department,
              medical: parseInt(values.medical),
              reward: parseInt(values.reward),
              joining: values.joining,
              advance_total: parseInt(values.advance),
              fine_total: parseInt(values.fine),
              designation: values.designation,
              salary: parseInt(values.salary),
              detections: parseInt(values.detections),
              cafe: parseInt(values.cafe),

              commission: parseInt(values.commission?.commission),
              commission_per: parseInt(values.commission?.commission_per),
              gx_commission: parseInt(values.commission?.gx_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              small_pt_commission: parseInt(
                values.commission?.small_pt_commission
              ),
              loans: parseInt(values.loan),
            });
            return null;
          });

          setMyData([...results]);
          setLimit(responce.data.pagination.per_page);
          setTotalResults(responce.data.pagination.total_record);
          setTotalPages(responce.data.pagination.total_pages);
          setCurrentPage(responce.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");

          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getLatestStaffSalary(
          branch_id,
          values,
          newLimit,
          "!"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              department: values.department,
              medical: parseInt(values.medical),
              reward: parseInt(values.reward),
              joining: values.joining,
              advance_total: parseInt(values.advance),
              fine_total: parseInt(values.fine),
              designation: values.designation,
              salary: parseInt(values.salary),
              detections: parseInt(values.detections),
              cafe: parseInt(values.cafe),

              commission: parseInt(values.commission?.commission),
              commission_per: parseInt(values.commission?.commission_per),
              gx_commission: parseInt(values.commission?.gx_commission),
              pt_commission: parseInt(values.commission?.pt_commission),
              small_pt_commission: parseInt(
                values.commission?.small_pt_commission
              ),
              loans: parseInt(values.loan),
            });
            return null;
          });

          setMyData([...results]);
          setLimit(responce.data.pagination.per_page);
          setTotalResults(responce.data.pagination.total_record);
          setTotalPages(responce.data.pagination.total_pages);
          setCurrentPage(responce.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");

          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Staff_Members_Salary.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    let totalNetSalary = 0;
    let totalSalary = 0;

    const calculateFirstMonthSalary = (joiningDate, totalSalary) => {
      const joiningDateObj = new Date(joiningDate);

      if (
        joiningDateObj.getFullYear() === parseInt(values.edate) &&
        joiningDateObj.getMonth() + 1 === parseInt(values.sdate)
      ) {
        const remainingDays =
          new Date(
            joiningDateObj.getFullYear(),
            joiningDateObj.getMonth() + 1,
            0
          ).getDate() -
          joiningDateObj.getDate() +
          1;
        const dailySalary =
          totalSalary /
          new Date(
            joiningDateObj.getFullYear(),
            joiningDateObj.getMonth() + 1,
            0
          ).getDate();

        const firstMonthSalary = dailySalary * remainingDays;

        return firstMonthSalary.toFixed(0);
      } else if (parseInt(values.edate) > joiningDateObj.getFullYear()) {
        return totalSalary.toFixed(0);
      } else if (
        joiningDateObj.getFullYear() === parseInt(values.edate) &&
        parseInt(values.sdate) >= joiningDateObj.getMonth() + 1
      ) {
        return totalSalary;
      } else {
        return 0;
      }
    };

    return loading ? (
      <SkeletonTheme height="40px">
        <Skeleton count={5} />
      </SkeletonTheme>
    ) : (
      <>
        <div className="table-responsive mt-3">
          <table
            className="table table-striped table-borderless table-style-1"
            id="report-POS"
            data-caption="Staff Members Salary"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("department")}
                  className={getClassNamesFor("department")}
                >
                  Department
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("name")}
                  className={getClassNamesFor("name")}
                >
                  Staff Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("designation")}
                  className={getClassNamesFor("designation")}
                >
                  Designation
                </th>

                <th
                  scope="col"
                  onClick={() => requestSort("salary")}
                  className={getClassNamesFor("salary")}
                >
                  Salary
                </th>
                {designtion === "Trainer" ? (
                  <>
                    <th
                      scope="col"
                      onClick={() => requestSort("commission_per")}
                      className={getClassNamesFor("commission_per")}
                    >
                      PT %
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("pt_commission")}
                      className={getClassNamesFor("pt_commission")}
                    >
                      PT Commission
                    </th>

                    <th
                      scope="col"
                      onClick={() => requestSort("gx_commission")}
                      className={getClassNamesFor("gx_commission")}
                    >
                      Studio Commission
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("small_pt_commission")}
                      className={getClassNamesFor("small_pt_commission")}
                    >
                      SPT Commission
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("commission")}
                      className={getClassNamesFor("commission")}
                    >
                      Total Commission
                    </th>
                  </>
                ) : null}

                <th
                  scope="col"
                  onClick={() => requestSort("reward")}
                  className={getClassNamesFor("reward")}
                >
                  Reward
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("advance_total")}
                  className={getClassNamesFor("advance_total")}
                >
                  Advance
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("fine_total")}
                  className={getClassNamesFor("fine_total")}
                >
                  Fine
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("loans")}
                  className={getClassNamesFor("loans")}
                >
                  Monthly Installment
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("cafe")}
                  className={getClassNamesFor("cafe")}
                >
                  Cafe
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("detections")}
                  className={getClassNamesFor("detections")}
                >
                  Deduction
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("medical")}
                  className={getClassNamesFor("medical")}
                >
                  Medical
                </th>
                <th scope="col" className="no_sort">
                  Net Salary
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                <>
                  {items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * limit + index + 1}
                      </td>
                      <td>{el.department}</td>

                      <td>
                        <Link to={"/staff-profile/" + el.id}>{el.name}</Link>
                      </td>
                      <td>{el.designation}</td>
                      <td>
                        <span className="d-none">
                          {
                            (totalSalary =
                              totalSalary +
                              parseInt(
                                calculateFirstMonthSalary(el.joining, el.salary)
                              ))
                          }
                        </span>
                        <CurrencyFormat
                          value={calculateFirstMonthSalary(
                            el.joining,
                            el.salary
                          )}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      {designtion === "Trainer" ? (
                        <>
                          <td>{el.commission_per + "%"}</td>
                          <td>
                            <CurrencyFormat
                              value={el.pt_commission}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.gx_commission}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.small_pt_commission}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>

                          <td>
                            <CurrencyFormat
                              value={el.commission}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </>
                      ) : null}
                      <td>
                        <CurrencyFormat
                          value={el.reward}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>

                      <td>
                        <CurrencyFormat
                          value={el.advance_total}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.fine_total}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.loans}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.cafe}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.detections}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.medical}
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <span className="d-none">
                          {
                            (totalNetSalary =
                              totalNetSalary +
                              (parseInt(
                                calculateFirstMonthSalary(el.joining, el.salary)
                              ) +
                                el.commission +
                                el.reward -
                                el.advance_total -
                                el.fine_total -
                                el.cafe -
                                el.detections -
                                el.loans -
                                el.medical))
                          }
                        </span>
                        <CurrencyFormat
                          value={
                            parseInt(
                              calculateFirstMonthSalary(el.joining, el.salary)
                            ) +
                            el.commission +
                            el.reward -
                            el.advance_total -
                            el.fine_total -
                            el.cafe -
                            el.detections -
                            el.loans -
                            el.medical
                          }
                          displayType={"text"}
                          thousandSpacing={"2s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="4"></td>

                    <td colSpan="1" className="expenses-num">
                      Total Salary :{" "}
                      <CurrencyFormat
                        value={totalSalary}
                        displayType={"text"}
                        thousandSpacing={"2s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td colSpan="1"></td>
                    {designtion === "Trainer" ? (
                      <>
                        <td colSpan="1" className="expenses-num">
                          Total PT Commission :{" "}
                          <CurrencyFormat
                            value={items.reduce(
                              (a, v) => (a = a + parseInt(v.pt_commission)),
                              0
                            )}
                            displayType={"text"}
                            thousandSpacing={"2s"}
                            thousandSeparator={true}
                            prefix={"Rs "}
                            suffix={"/-"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </td>
                        <td colSpan="4"></td>
                      </>
                    ) : null}

                    <td colSpan="1" className="expenses-num">
                      Total Advance :{" "}
                      <CurrencyFormat
                        value={items.reduce(
                          (a, v) => (a = a + parseInt(v.advance_total)),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"2s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td colSpan="1" className="expenses-num">
                      Total Fine :{" "}
                      <CurrencyFormat
                        value={items.reduce(
                          (a, v) => (a = a + parseInt(v.fine_total)),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"2s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td colSpan="1" className="expenses-num">
                      Total Monthly Installment :{" "}
                      <CurrencyFormat
                        value={items.reduce(
                          (a, v) => (a = a + parseInt(v.loans)),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"2s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td colSpan="1"></td>
                    <td colSpan="1" className="expenses-num">
                      Total Net Salary :{" "}
                      <CurrencyFormat
                        // value={totalNetSalary}
                        value={Math.ceil(totalNetSalary)}
                        displayType={"text"}
                        thousandSpacing={"2s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      <div className="card-body">
        <h5 className="mb-0 text-danger mt-2">View Salary</h5>
        <form className="row g-3 trainee-add mt-3" onSubmit={handleSubmit}>
          <div className="col-md-2">
            <div className="form-group custom-group">
              <label className="form-label">Select Month</label>
              <select
                className="form-select"
                name="sdate"
                value={values.sdate}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>

              {errors.sdate && touched.sdate ? (
                <p className="text-danger">{errors.sdate}</p>
              ) : null}
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group custom-group">
              <label className="form-label">Enter Year</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter End date"
                name="edate"
                value={values.edate}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              {errors.edate && touched.edate ? (
                <p className="text-danger">{errors.edate}</p>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-1">
            <button
              type="submit"
              className="btn btn-primary d-inline-block mt-4"
            >
              Generate
            </button>
          </div>
          {totalResults > limit ? (
            <div className="col-md-1 col-lg-1 mt-5 ms-auto">
              <div className="input-group">
                <select
                  className="form-select"
                  value={limit}
                  onChange={(e) => changeLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="250">250</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
          ) : null}
          <div className="ms-auto col-md-1 text-end mt-5">
            <button
              className="btn btn-primary mx-2 file-download"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
          </div>
        </form>
        <Table data={myData} rowsPerPage={limit} />
      </div>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { useFormik } from "formik";
import moment from "moment";

export default function NeewSPTBookings() {
    const [loading, setLoading] = useState(false);
    const [myData, setMyData] = useState([]);
    const [name, setName] = useState([]);
    const [limit, setLimit] = useState("25");
    const [currentPage, setCurrentPage] = useState(1);
  
    const [totalResults, setTotalResults] = useState("0");
    const [totalPages, setTotalPages] = useState("1");
  
    const [myBranches, setMyBranches] = useState([]);
  
    const [type, setType] = useState("GX");
  
    const { state } = useContext(NoteState);
    const { branch_id, role, UId } = state;
    const [trainerName, setTrainerName] = useState([]);
    const [classesNames, setClassesNames] = useState([]);
  
    const getUsers = async (page, limit, values) => {
      setLoading(true);
      setCurrentPage(page);
      let pageNo = page;
  
      let newLimit = limit;
      setLimit(newLimit);
      setType(values.type);
      let e = document.getElementById("answerInput");
      var input = e,
        list = input.getAttribute("list"),
        options = document.querySelectorAll("#" + list + " option"),
        hiddenInput = document.getElementById(
          input.getAttribute("id") + "-hidden"
        ),
        inputValue = input.value;
  
      // hiddenInput.value = inputValue;
  
      for (var i = 0; i < options.length; i++) {
        var option = options[i];
  
        if (option.innerText === inputValue) {
          hiddenInput.value = await option.getAttribute("data-value");
          break;
        } else {
          hiddenInput.value = "";
        }
      }
  
      let client_id = await document.getElementById("answerInput-hidden").value;
      try {
        const responce = await UserServices.getNewGXBookings(
          newLimit,
          pageNo,
          values,
          client_id
        );
  
        let myDataarr;
        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];
          await res.map((value) => {
            results.push({
              branch_name: value.branch_name,
              client_id: value.client_id,
              client_name: value.client_name,
              trainer_name: value.get_package_name?.trainer_name,
              order_detail: value.order_detail,
              package: value.get_package_name,
  
              start_date: value.start_date,
              end_date: value.end_date,
  
              id: value.id,
            });
            return null;
          });
  
          setMyData([...results]);
          setLimit(responce.data.pagination.per_page);
          setTotalResults(responce.data.pagination.total_record);
          setTotalPages(responce.data.pagination.total_pages);
          setCurrentPage(responce.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
  
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      }
    };
  
    const branches = async () => {
      try {
        const responce = await UserServices.branchName();
        const res = responce.data.data;
        const results = [];
  
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
      }
    };
    const gymTrainer = async (br_Id) => {
      // var br_Id = e.target.value;
      try {
        const responce = await UserServices.TrainerName(br_Id, 1);
  
        const res = responce.data.data;
        const results = [];
  
        res.map((value) => {
          results.push({
            id: value.id,
            first_name: value.first_name,
            last_name: value.last_name,
          });
          return null;
        });
        setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
        setTrainerName([]);
      }
    };
  
    const getClassesList = async (br_Id, tr_id) => {
      try {
        const responce = await UserServices.registrationPkgs(br_Id, "15", tr_id);
  
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setClassesNames([{ name: "Select Slot ", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
  
        setClassesNames([{ name: "No Slot Found", id: "" }]);
      }
    };
    const nameClients = async (bid) => {
      try {
        const responce = await UserServices.getClientsName(bid);
  
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.first_name + " " + value.last_name,
          });
          return null;
        });
        setName([...results]);
      } catch (err) {
        console.log(err);
        setName([]);
      }
    };
      /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit, values);
    branches();
    gymTrainer(branch_id);
    getClassesList(values.branch_id, values.trainer_id);
    nameClients(values.trainer_id);
  }, []);

  //..........................................................................................................

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("New-GX-Bookings.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1 align-middle"
          id="report-POS"
          data-caption="New GX Bookings"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer_name")}
                className={getClassNamesFor("trainer_name")}
              >
                Trainer Name
              </th>
              <th scope="col" className="no_sort">
                Slot Name
              </th>
              <th scope="col" className="no_sort">
                Total Sessions
              </th>
              <th scope="col" className="no_sort">
                Start Date
              </th>

              <th scope="col" className="no_sort">
                End Date
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>

                  <td>{el.client_name}</td>
                  <td>{el.trainer_name}</td>

                  <td>{el.package?.name}</td>
                  <td>{el.package?.session_count}</td>
                  <td>{moment(el.start_date).format("DD-MM-YYYY")}</td>
                  <td>{moment(el.end_date).format("DD-MM-YYYY")}</td>

                  <td>
                    <Link
                      className="updt-btn inactive-btn"
                      to={`/preassessment/${el.client_id}`}
                    >
                      <i class="bi bi-plus text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Add Assessments
                      </span>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit, values);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const initialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    type: "SPT",
    category: "4",
    class_id: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: "",
      onSubmit: async (values, action) => {
        getUsers("1", limit, values);
      },
    });

  return (
     <>
      <div className="wrapper">
        {" "}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">New {values.type} Bookings</h5>
              </div>
              <div className="card-body">
                <form
                  className="row g-3 trainee-add mb-2"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Branch Name</label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            gymTrainer(e.target.value);
                            getClassesList(e.target.value, values.trainer_id);
                            nameClients(e.target.value);
                            values.trainer_id = "";
                            values.class_id = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Client Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Client Name"
                        name="client_name"
                        list="member_id"
                        id="answerInput"
                      />
                    </div>
                    <input
                      type="hidden"
                      name="answer"
                      id="answerInput-hidden"
                    />
                    <datalist id="member_id">
                      {name.map((el, index) => {
                        return (
                          <option
                            key={index}
                            data-value={el.id}
                            value={el.name}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                    </datalist>
                  </div>

                  <div className="col-12 col-lg-2">
                    <div className="form-group custom-group">
                      Available Trainers
                      {trainerName ? (
                        <select
                          className="form-select"
                          name="trainer_id"
                          value={values.trainer_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            getClassesList(values.branch_id, e.target.value);
                            values.class_id = "";
                          }}
                          disabled={role === "9" && UId ? true : false}
                          onBlur={handleBlur}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.trainer_id && touched.trainer_id ? (
                        <p className="text-danger">{errors.trainer_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Slots</label>

                      {classesNames ? (
                        <select
                          className="form-select"
                          name="class_id"
                          value={values.class_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {classesNames.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-1">
                    <button
                      type="submit"
                      className="btn btn-primary mx-md-2 file-download mt-md-4 w-100"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-md-1 col-lg-1 ms-auto">
                    <div className="input-group mt-md-4">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getUsers("1", e.target.value, values)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className=" col-md-1 text-end">
                    <button
                      className="btn btn-primary file-download mt-md-4 w-100"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                      type="button"
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </form>
                <div className="table-responsive">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}

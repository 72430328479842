/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { clientReport } from "../schemas";
import Swal from "sweetalert2";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import CafeNav from "../Includes/Navbar/CafeNav";
import CafeSidebar from "../Includes/Sidebar/CafeSidebar";
import CafeMenu from "./CafeMenu";
import $ from "jquery";
import jsPDF from "jspdf";

export default function CafeSales() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [DAteSeted, setDAteSeted] = useState(false);
  const [radioOption, setRadioOption] = useState("Detail");
  const [show, setShow] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [exportLink, setExportLink] = useState("");
  const [summaryExport, setSummaryExport] = useState("");
  // Print Slip
  const [data, setData] = useState([]);
  const [order_date, setorder_date] = useState("");
  const [branch, setBranch] = useState("");

  const setDates = async (param) => {
    setDAteSeted(true);

    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);

        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };
  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        setMyData([]);
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/orders/export-transaction?branch_id=" +
            branch_id +
            "&start_date=" +
            values.sdate +
            "&end_date=" +
            values.edate +
            "&category=10"
        );
        const responce = await UserServices.getCafeTransactionReport(
          branch_id,
          values
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];
          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            value.data.map((value) => {
              results.push({
                name: value.client_name,
                note: value.note,
                client_id: value.client_id,
                branch_name: value.branch_name,
                total_received: parseInt(value.total_received),
                payment_method: value.payment_history,
                items: value.items,
                net_price: value.net_price,
                price: value.price,
                date: value.date,
                sold_by: value.sold_by,
                order_id: value.id,
                tax: value.tax,
                discount: value.discount,
              });
              return null;
            });
          });
          setMyData([...results]);
          setShow(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setExportLink();
        if (err.response.status === 401) {
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setMyData([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const summaryReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        setSummaryData([]);
        setSummaryExport(
          process.env.REACT_APP_API_Link +
            "/export-transaction-summary?branch_id=" +
            branch_id +
            "&start_date=" +
            values.sdate +
            "&end_date=" +
            values.edate +
            "&category=10"
        );
        const responce = await UserServices.getCafeSummaryReport(
          branch_id,
          values
        );
        let myDataarr;
        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              order_count: value.order_count,
              order_date: value.order_date,
              total_discount: value.total_discount,
              total_net_price: value.total_net_price,
              total_price: value.total_price,
              total_tax: value.total_tax,
            });
            return null;
          });

          setSummaryData([...results]);
          setShow(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setSummaryExport();
        if (err.response.status === 401) {
          setSummaryData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setSummaryData([]);
          setLoading(false);
        } else {
          setSummaryData([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
    searchVal: "",
  };
  const showInstalDetails = (id) => {
    $("#" + id).fadeToggle();
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("cafe_sales.pdf");
  };
  const [limit, setLimit] = useState("25");

  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, limit);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0 text-uppercase">Filtered Result</h5>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3 text-end">
              <a
                href={exportLink}
                rel="noreferrer"
                // target="_blank"
                className="btn btn-primary mx-2 file-download float-end mb-3"
              >
                <i className="bi bi-download"></i> Excel
              </a>
              <button
                className="btn btn-primary mx-2 file-download float-end mb-3"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>

              {/* Button trigger modal */}
              {items.length ? (
                <button
                  type="button"
                  className="btn btn-primary mx-2 file-download float-end mb-3"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  <i className="bi bi-printer"></i> Print Slip
                </button>
              ) : null}
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div className="card">
            <div className="card-body">
              <div className="col-md-1 ms-auto">
                <select
                  className="form-select mb-3"
                  onChange={(e) => {
                    setLimit(e.target.value);
                  }}
                  value={limit}
                >
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="1500">1500</option>
                  <option value={myData.length}>All</option>
                </select>
              </div>
              <div className="table-responsive ">
                <table
                  className="table  table-borderless table-style-1  table-striped"
                  data-caption="Cafe Sales"
                  id="report-POS"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("name")}
                        className={getClassNamesFor("name")}
                      >
                        Client
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("order_id")}
                        className={getClassNamesFor("order_id")}
                      >
                        Order ID
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("date")}
                        className={getClassNamesFor("date ")}
                      >
                        Sale Date
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("price")}
                        className={getClassNamesFor("price")}
                      >
                        Price
                      </th>
                      <th scope="col" className="no_sort">
                        Discount
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("tax")}
                        className={getClassNamesFor("tax")}
                      >
                        GST
                      </th>
                      <th scope="col" className="no_sort">
                        Net Price
                      </th>
                      <th scope="col" className="no_sort">
                        Received
                      </th>
                      <th scope="col" className="no_sort">
                        Pending
                      </th>
                      <th scope="col" className="no_sort">
                        Payment Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <>
                          <tr key={index}>
                            <td className="d-flex">
                              {page === 1
                                ? index + 1
                                : (page - 1) * limit + index + 1}{" "}
                              {el.items.length ? (
                                <div
                                  className="ms-2"
                                  onClick={() => {
                                    showInstalDetails(el.order_id);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="bi bi-chevron-down fw-bold"></i>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {el.note && el.note !== "N/A"
                                ? el.note.split(",")[0]
                                : el.name}
                            </td>
                            <td>{el.order_id}</td>
                            <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                            <td>
                              <CurrencyFormat
                                value={el.price}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.discount}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.tax}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.net_price}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.payment_method.reduce(
                                  (a, v) => (a = a + v.received),
                                  0
                                )}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.payment_method.reduce(
                                  (a, v) => (a = a + v.pending),
                                  0
                                )}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>

                            <td>
                              {el.payment_method.map((pyel, index) => (
                                <span key={index}>
                                  {el.payment_method[index - 1]
                                    ?.payment_type !== pyel.payment_type ? (
                                    <>
                                      {el.payment_method.length > 1 && index > 0
                                        ? ", "
                                        : ""}
                                      {pyel.payment_type}{" "}
                                    </>
                                  ) : null}
                                </span>
                              ))}
                            </td>
                          </tr>
                          {el.items.length ? (
                            <tr
                              className="instalmnt-info table table-striped" 
                              id={el.order_id}
                            >
                              <td colSpan="10">
                                <tr>
                                  <th
                                    style={{
                                      width: "20%",
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                    }}
                                  >
                                    Item Name
                                  </th>
                                  <th
                                    style={{
                                      width: "20%",
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                    }}
                                  >
                                    Price
                                  </th>
                                  <th
                                    style={{
                                      width: "24%",
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                    }}
                                  >
                                    Discount
                                  </th>
                                  <th
                                    style={{
                                      width: "30%",
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                    }}
                                  >
                                    GST
                                  </th>
                                  <th
                                    style={{
                                      background: "rgb(252 239 239);",
                                      color: "#000",
                                      width: "30%",
                                    }}
                                  >
                                    Net Price
                                  </th>
                                </tr>
                                {el.items.map((inl, index) => (
                                  <tr key={index}>
                                    <td>{inl.package_name}</td>

                                    <td>
                                      <CurrencyFormat
                                        value={inl.price}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={inl.discount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={inl.tax}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                    <td>
                                      <CurrencyFormat
                                        value={inl.net_price}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        thousandSpacing={"3"}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </td>
                            </tr>
                          ) : null}
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center text-capitalize">
                          No record found
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="4"></td>
                      <td colSpan="1" className="expenses-num">
                        Total Price :{" "}
                        <CurrencyFormat
                          value={myData.reduce((a, v) => (a = a + v.price), 0)}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td colSpan="1" className="expenses-num">
                        Total Discount :{" "}
                        <CurrencyFormat
                          value={myData.reduce(
                            (a, v) => (a = a + v.discount),
                            0
                          )}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td colSpan="1" className="expenses-num">
                        Total GST :{" "}
                        <CurrencyFormat
                          value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td colSpan="1" className="expenses-num">
                        Total Net Price :{" "}
                        <CurrencyFormat
                          value={myData.reduce(
                            (a, v) => (a = a + v.net_price),
                            0
                          )}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td colSpan="1" className="expenses-num">
                        Total Received :{" "}
                        <CurrencyFormat
                          value={myData.reduce(
                            (a, v) =>
                              (a =
                                a +
                                v.payment_method?.reduce(
                                  (a, v) => (a = a + v.received),
                                  0
                                )),
                            0
                          )}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td colSpan="1" className="expenses-num">
                        Total Pending :{" "}
                        <CurrencyFormat
                          value={myData.reduce(
                            (a, v) =>
                              (a =
                                a +
                                v.payment_method?.reduce(
                                  (a, v) => (a = a + v.pending),
                                  0
                                )),
                            0
                          )}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                {data.length && data.length > limit ? (
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={data.length}
                    onChange={(e) => {
                      setPage(e);
                    }}
                    pageRangeDisplayed={8}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First Page"
                    lastPageText="Last Page"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="grand-total-cart bg-white card">
          <div>
            <div className="cart-entry">
              Total Price{" "}
              <CurrencyFormat
                value={myData.reduce((a, v) => (a = a + v.price), 0)}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
              <div className="clearfix"></div>
            </div>
          </div>
          <div>
            <div className="cart-entry">
              Total Discount{" "}
              <CurrencyFormat
                value={myData.reduce((a, v) => (a = a + v.discount), 0)}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
              <div className="clearfix"></div>
            </div>
          </div>
          <div>
            <div className="cart-entry">
              Total GST{" "}
              <CurrencyFormat
                value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
              <div className="clearfix"></div>
            </div>
          </div>
          <div>
            <div className="cart-entry">
              Total Net Price{" "}
              <CurrencyFormat
                value={myData.reduce((a, v) => (a = a + v.net_price), 0)}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
              <div className="clearfix"></div>
            </div>
          </div>
          <div>
            <div className="cart-entry">
              Total Received{" "}
              <CurrencyFormat
                value={myData.reduce(
                  (a, v) =>
                    (a =
                      a +
                      v.payment_method?.reduce(
                        (a, v) => (a = a + v.received),
                        0
                      )),
                  0
                )}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
              <div className="clearfix"></div>
            </div>
          </div>
          <div>
            <div className="cart-entry">
              Total Pending{" "}
              <CurrencyFormat
                value={myData.reduce(
                  (a, v) =>
                    (a =
                      a +
                      v.payment_method?.reduce(
                        (a, v) => (a = a + v.pending),
                        0
                      )),
                  0
                )}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
              <div className="clearfix"></div>
            </div>
          </div>
          <div></div>
        </div>
      </>
    );
  };
  const SummaryTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0 text-uppercase">Filtered Result</h5>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3 text-end">
              <a
                href={summaryExport}
                rel="noreferrer"
                // target="_blank"
                className="btn btn-primary mx-2 file-download float-end mb-3"
              >
                <i className="bi bi-download"></i> Excel
              </a>
              <button
                className="btn btn-primary mx-2 file-download float-end mb-3"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive ">
                <table
                  className="table  table-borderless table-style-1"
                  data-caption="Cafe Sales"
                  id="report-POS"
                >
                  <thead>
                    <tr>
                      {/* <th scope="col" className="no_sort">
                        Sr#
                      </th> */}
                      <th
                        scope="col"
                        onClick={() => requestSort("order_count")}
                        className={getClassNamesFor("order_count")}
                      >
                        Order Count
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("order_date")}
                        className={getClassNamesFor("order_date")}
                      >
                        Order Date
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("total_price")}
                        className={getClassNamesFor("total_price")}
                      >
                        Total Price
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("total_discount")}
                        className={getClassNamesFor("total_discount")}
                      >
                        Total Discount
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("total_tax")}
                        className={getClassNamesFor("total_tax")}
                      >
                        Total GST
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("total_net_price")}
                        className={getClassNamesFor("total_net_price")}
                      >
                        Total Net Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <>
                          <tr key={index}>
                            <td>{el.order_count} Transactions</td>
                            <td>
                              {moment(el.order_date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.total_price}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.total_discount}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.total_tax}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.total_net_price}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center text-capitalize">
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  // Print slip

  const getRecord = async (values) => {
    try {
      const response = await UserServices.getCafeInvoiceRecord(
        branch_id,
        values
      );

      if (response.status === 200) {
        const res = response.data.data;
        setorder_date(res[0].date);
        setBranch(res[0].data[0].branch_name);
        const results = [];
        console.log(res[0].data);
        res.map((value) => {
          value.data.map((value) => {
            results.push({
              id: value?.id,
              net_price: value?.net_price,
              received: value?.payment_history.length
                ? value?.payment_history[0]?.received
                : 0,
              pending: value?.payment_history.length
                ? value?.payment_history[0]?.pending
                : 0,
              payment_type: value?.payment_history[0]?.payment_type,
            });
          });
        });

        await setData([...results]);
      } else if (response.status === 404) {
        // Handle 404 response by setting values to empty
        setorder_date("");
        setBranch("");
        await setData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downloadImage = async (blob, fileName) => {
    const div = window.document.createElement("div");
    div.style = "display:none; text-align:center;";

    const myImage = window.document.createElement("img");
    myImage.src = blob;
    myImage.style = "display:block; margin:0 auto;";

    // eslint-disable-next-line no-unused-vars
    var printMe = await div.appendChild(myImage);
    setLoading(false);
    var print_area = window.open();
    if (print_area && print_area.document) {
      print_area.document.write(div.innerHTML);
      print_area.document.close();
      print_area.focus();
      print_area.print();
      print_area.close();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to open print window",
      });
    }
  };
  const ItemSold = ({ data }) => {
    return (
      <>
        {data.length ? (
          <>
            {data.map(
              (el, index) =>
                el.payment_type === "Cash" && (
                  <tr key={index}>
                    <td valign="middle">{el.id}</td>
                    <td valign="middle">{el.payment_type}</td>
                    <td valign="middle">
                      <CurrencyFormat
                        value={el.received}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </td>
                  </tr>
                )
            )}

            {data.map(
              (el, index) =>
                el.payment_type === "Credit Card" && (
                  <tr key={index}>
                    <td valign="middle">{el.id}</td>
                    <td valign="middle">{el.payment_type}</td>
                    <td valign="middle">
                      <CurrencyFormat
                        value={el.received}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </td>
                  </tr>
                )
            )}
            {data.map((el, index) => (
              <>
                {el.payment_type === "Online" && (
                  <tr key={index}>
                    <td valign="middle">{el.id}</td>
                    <td valign="middle">{el.payment_type}</td>
                    <td valign="middle">
                      <CurrencyFormat
                        value={el.received}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandSpacing={"3"}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <>{value}</>}
                      />
                    </td>
                  </tr>
                )}
              </>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan="11" className="text-center text-capitalize">
              No record found
            </td>
          </tr>
        )}
      </>
    );
  };
  const calculateTotalCashReceived = () => {
    const totalCashReceived = data.reduce((total, el) => {
      if (el.payment_type === "Cash") {
        return total + el.received;
      }
      return total;
    }, 0);

    return totalCashReceived;
  };
  const calculateTotalCreditReceived = () => {
    const totalCreditReceived = data.reduce((total, el) => {
      if (el.payment_type === "Credit Card") {
        return total + el.received;
      }
      return total;
    }, 0);

    return totalCreditReceived;
  };
  const calculateTotalOnlineReceived = () => {
    const totalOnlineReceived = data.reduce((total, el) => {
      if (el.payment_type === "Online") {
        return total + el.received;
      }
      return total;
    }, 0);

    return totalOnlineReceived;
  };

  const printSlip = async (el, imageFileName) => {
    setLoading(true);

    // Increase the resolution of the canvas by setting a higher scale option
    const canvas = await html2canvas(el, { scale: 3 });

    // Convert to a higher resolution JPEG image format with increased quality
    const image = canvas.toDataURL("image/jpeg", 0.95); // You can adjust the quality (0.0 to 1.0) as needed

    downloadImage(image, imageFileName);
  };
  const generatePdfSlip = async (el) => {
    setLoading(true);
    const canvas = await html2canvas(el, {
      scale: 5,
      allowTaint: true,
      useCORS: true,
    });
    const image = canvas.toDataURL("image/png", 1.0);
    const invoiceWidth = 150; // in mm
    const invoiceHeight = 297; // in mm
    const pdf = new jsPDF("p", "mm", [invoiceWidth, invoiceHeight]);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const scaleX = pdfWidth / canvasWidth;
    const scaleY = pdfHeight / canvasHeight;
    const scale = Math.min(scaleX, scaleY);
    const canvasScaledWidth = canvasWidth * scale;
    const canvasScaledHeight = canvasHeight * scale;
    const pdfX = (pdfWidth - canvasScaledWidth) / 2;
    const pdfY = (pdfHeight - canvasScaledHeight) / 2;
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(
      imgData,
      "PNG",
      pdfX,
      pdfY,
      canvasScaledWidth,
      canvasScaledHeight
    );
    const pdfData = pdf.output("blob");
    const pdfURL = URL.createObjectURL(pdfData);
    setLoading(false);
    window.open(pdfURL, "_blank");
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        if (radioOption === "Summary") {
          summaryReport(values);
        } else {
          generateReport(values);
          getRecord(values);
        }
      },
    });

  return (
    <div className="wrapper">
      <CafeNav />
      <CafeSidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <CafeMenu />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Cafe Sales</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="link-hirarcy">
                  <span className="text-danger">Reports</span> » Cafe Sales
                </div>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <h5>Quick Dates</h5>
                </div>
                <div className="row quick multi-button custom-multi-button">
                  <div className="col-md-4">
                    <label className="form-label d-block">Last</label>
                    <input
                      type="button"
                      check-val="lastYear"
                      onClick={() => {
                        setDates("lastYear");
                      }}
                      className="generate_report"
                      defaultValue="Year"
                    />
                    <input
                      type="button"
                      check-val="lastQuarter"
                      onClick={() => {
                        setDates("lastQuarter");
                      }}
                      className="generate_report"
                      defaultValue="Quarter"
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="lastMonth"
                      onClick={() => {
                        setDates("lastMonth");
                      }}
                      className="generate_report"
                    />
                    <input
                      type="button"
                      defaultValue="Yesterday"
                      check-val="lastDay"
                      className="generate_report"
                      onClick={() => {
                        setDates("lastDay");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">To-Date</label>
                    <input
                      type="button"
                      defaultValue="Year"
                      check-val="toDateYear"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateYear");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Quarter"
                      check-val="toDateQuarter"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateQuarter");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="toDateMonth"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateMonth");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Today"
                      check-val="toDateToday"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateToday");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">Previous</label>
                    <input
                      type="button"
                      defaultValue="365 Days"
                      check-val="previous365"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous365");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="90 Days"
                      check-val="previous90"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous90");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="30 Days"
                      check-val="previous30"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous30");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="9 Days"
                      check-val="previous9"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous9");
                      }}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <h5>Options</h5>
                </div>
                <div className="col-md-6 col-lg-3 ">
                  <div className="custom-raio">
                    {/* <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                          }}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Detail"
                          defaultChecked={true}
                          onChange={() => {
                            setRadioOption("Detail");
                          }}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul> */}
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                          }}
                          checked={radioOption === "Summary"}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Detail"
                          onChange={() => {
                            setRadioOption("Detail");
                          }}
                          checked={radioOption === "Detail"}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12"></div>
                {/* {values.package_type !== "3" ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label fw-bold">
                        Revenue Category
                      </label>
                      {revenueCategory.length ? (
                        <select
                          className="form-select"
                          name="revenueCat"
                          value={values.revenueCat}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {revenueCategory.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          name="revenueCat"
                          value={values.revenueCat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">All</option>
                        </select>
                      )}
                    </div>
                  </div>
                ) : null} */}
                <div className="clearfix"></div>
                <div className="col-md-4 col-lg-5"></div>
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5"></div>
              </form>
            </div>
          </div>
          {/* {show ? <Table data={myData}  /> : null} */}
          {radioOption === "Detail" && show ? <Table data={myData} /> : null}
          {radioOption === "Summary" && show ? (
            <SummaryTable data={summaryData} />
          ) : null}
        </div>
      </main>
      {radioOption === "Detail" && show ? (
        <div>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Print Invoice
                    <button
                      className="btn btn-danger d-inline-block ms-2  me-2 btn-sm"
                      onClick={() => {
                        printSlip(
                          document.getElementById("invoice-POS"),
                          "slip"
                        );
                      }}
                    >
                      <i className="bi bi-printer-fill"></i>
                    </button>
                    Generate PDF
                    <button
                      className="btn btn-danger d-inline-block ms-2 btn-sm"
                      onClick={() => {
                        generatePdfSlip(document.getElementById("invoice-POS"));
                      }}
                    >
                      <i className="bi bi-file-earmark-pdf-fill"></i>
                    </button>
                  </h5>
                  <button
                    type="button"
                    className="btn-close me-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="bi bi-x-lg fs-5"></i>
                  </button>
                </div>

                <div className="modal-body">
                  {" "}
                  <div className="card-body">
                    <div id="printableArea">
                      <div
                        style={{
                          boxShadow: "0 0 1in -0.25in rgba(0, 0, 0, 0.5)",
                          width: "90mm",
                          background: "#FFF",
                          margin: "0px auto",
                        }}
                      >
                        <div
                          id="invoice-POS"
                          style={{
                            padding: "2mm",
                            margin: "20px auto",
                            width: "90mm",
                            background: "#FFF",
                          }}
                        >
                          <center id="top">
                            <div className="info">
                              <img
                                src="/assets/images/logo.png"
                                alt=""
                                style={{
                                  margin: "0px auto",
                                  marginTop: "10px",
                                  width: "80%",
                                }}
                              />
                            </div>
                          </center>

                          <div id="mid2">
                            <div className="info" style={{ marginTop: "15px" }}>
                              <span style={{ display: "block" }}>
                                Branch:
                                <span style={{ float: "right" }}>{branch}</span>
                              </span>
                              <span style={{ display: "block" }}>
                                From:
                                <span style={{ float: "right" }}>
                                  {moment(values.sdate).format("DD-MM-YYYY")}
                                </span>
                              </span>
                              <span style={{ display: "block" }}>
                                To:
                                <span style={{ float: "right" }}>
                                  {moment(values.edate).format("DD-MM-YYYY")}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div id="bot">
                            <div id="table" style={{ marginTop: "3px" }}>
                              <table
                                className="table"
                                style={{ marginBottom: "0px" }}
                              >
                                <tbody>
                                  <tr>
                                    <th width="30%">
                                      <b style={{ color: "black" }}>
                                        Receipt #
                                      </b>
                                    </th>

                                    <th width="40%">
                                      <b style={{ color: "black" }}>
                                        Payments Type
                                      </b>
                                    </th>

                                    <th width="30%">
                                      <b style={{ color: "black" }}>Received</b>
                                    </th>
                                  </tr>
                                  <ItemSold data={data} />
                                  {data.length ? (
                                    <>
                                      <tr className="tabletitle">
                                        <th
                                          align="center"
                                          colspan="2"
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Total Cash Received
                                          </span>
                                        </th>

                                        <th
                                          align="right"
                                          style={{
                                            fontSize: "14px",

                                            fontWeight: "400",
                                          }}
                                          valign="middle"
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              fontWeight: "600",
                                            }}
                                          >
                                            <CurrencyFormat
                                              value={calculateTotalCashReceived()}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandSpacing={"3"}
                                              prefix={"Rs "}
                                              suffix={"/-"}
                                              renderText={(value) => (
                                                <>{value}</>
                                              )}
                                            />
                                          </span>
                                        </th>
                                      </tr>
                                      <tr className="tabletitle">
                                        <th
                                          align="center"
                                          colspan="2"
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Total CC Received
                                          </span>
                                        </th>

                                        {data.length && (
                                          <th
                                            align="right"
                                            style={{
                                              fontSize: "14px",

                                              fontWeight: "400",
                                            }}
                                            valign="middle"
                                          >
                                            <span
                                              style={{
                                                color: "black",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <CurrencyFormat
                                                value={calculateTotalCreditReceived()}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                thousandSpacing={"3"}
                                                prefix={"Rs "}
                                                suffix={"/-"}
                                                renderText={(value) => (
                                                  <>{value}</>
                                                )}
                                              />
                                            </span>
                                          </th>
                                        )}
                                      </tr>
                                      <tr className="tabletitle">
                                        <th
                                          align="center"
                                          colspan="2"
                                          style={{
                                            color: "black",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Total Online Received
                                          </span>
                                        </th>

                                        <th
                                          align="right"
                                          style={{
                                            fontSize: "14px",

                                            fontWeight: "400",
                                          }}
                                          valign="middle"
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              fontWeight: "600",
                                            }}
                                          >
                                            <CurrencyFormat
                                              value={calculateTotalOnlineReceived()}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandSpacing={"3"}
                                              prefix={"Rs "}
                                              suffix={"/-"}
                                              renderText={(value) => (
                                                <>{value}</>
                                              )}
                                            />
                                          </span>
                                        </th>
                                      </tr>
                                    </>
                                  ) : null}
                                  <tr className="tabletitle">
                                    <th
                                      align="center"
                                      colspan="2"
                                      style={{
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Total GST
                                      </span>
                                    </th>

                                    <th
                                      align="right"
                                      style={{
                                        fontSize: "14px",

                                        fontWeight: "400",
                                      }}
                                      valign="middle"
                                    >
                                      <span
                                        style={{
                                          color: "black",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <CurrencyFormat
                                          value={myData.reduce(
                                            (a, v) => (a = a + v.tax),
                                            0
                                          )}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandSpacing={"3"}
                                          prefix={"Rs "}
                                          suffix={"/-"}
                                          renderText={(value) => <>{value}</>}
                                        />
                                      </span>
                                    </th>
                                  </tr>
                                  <tr className="tabletitle">
                                    <th
                                      align="center"
                                      colspan="2"
                                      style={{
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <b style={{ color: "black" }}>
                                        Total Net Price
                                      </b>
                                    </th>

                                    <th
                                      align="right"
                                      style={{
                                        fontSize: "14px",

                                        fontWeight: "400",
                                      }}
                                      valign="middle"
                                    >
                                      {data.length ? (
                                        <b style={{ color: "black" }}>
                                          <CurrencyFormat
                                            value={data.reduce(
                                              (a, v) => (a = a + v.net_price),
                                              0
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandSpacing={"3"}
                                            prefix={"Rs "}
                                            suffix={"/-"}
                                            renderText={(value) => <>{value}</>}
                                          />
                                        </b>
                                      ) : (
                                        "0"
                                      )}
                                    </th>
                                  </tr>
                                  <tr className="tabletitle">
                                    <th
                                      align="center"
                                      colspan="2"
                                      style={{
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <b style={{ color: "black" }}>
                                        Total Received
                                      </b>
                                    </th>

                                    <th
                                      align="right"
                                      style={{
                                        fontSize: "14px",

                                        fontWeight: "400",
                                      }}
                                      valign="middle"
                                    >
                                      {data.length ? (
                                        <b style={{ color: "black" }}>
                                          <CurrencyFormat
                                            value={data.reduce(
                                              (a, v) => (a = a + v.received),
                                              0
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandSpacing={"3"}
                                            prefix={"Rs "}
                                            suffix={"/-"}
                                            renderText={(value) => <>{value}</>}
                                          />
                                        </b>
                                      ) : (
                                        "0"
                                      )}
                                    </th>
                                  </tr>
                                  <tr className="tabletitle">
                                    <th
                                      align="center"
                                      colspan="2"
                                      style={{
                                        color: "black",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <b style={{ color: "black" }}>
                                        Total Pending
                                      </b>
                                    </th>

                                    <th
                                      align="right"
                                      style={{
                                        fontSize: "14px",

                                        fontWeight: "400",
                                      }}
                                      valign="middle"
                                    >
                                      {data.length ? (
                                        <b style={{ color: "black" }}>
                                          <CurrencyFormat
                                            value={data.reduce(
                                              (a, v) => (a = a + v.pending),
                                              0
                                            )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandSpacing={"3"}
                                            prefix={"Rs "}
                                            suffix={"/-"}
                                            renderText={(value) => <>{value}</>}
                                          />
                                        </b>
                                      ) : (
                                        "0"
                                      )}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <img
                              src="/assets/images/divider.png"
                              alt=""
                              style={{
                                margin: "10px auto",
                                width: "90%",
                                display: "block",
                                opacity: ".6",
                              }}
                            />
                            <div id="legalcopy">
                              <p
                                style={{
                                  textAlign: "center",
                                  marginBottom: "5px",
                                  fontSize: "12px",
                                  lineHeight: "16px",
                                  fontWeight: "400",
                                  color: "#525252",
                                }}
                              >
                                Developed By: Spark Solutionz
                                <br />
                                (+92) 321-604-4363
                              </p>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary px-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

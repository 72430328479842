/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { useFormik } from "formik";
import moment from "moment";
import { Tooltip } from "react-tooltip";

const SPTAppointments = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const [myBranches, setMyBranches] = useState([]);
  const [trainerName, setTrainerName] = useState([]);

  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;

  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // Adjust when current day is Sunday

  const startOfWeek = new Date(currentDate.setDate(diff));
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week is 6 days after the start

  const getUsers = async (page, limit, values) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);

    try {
      const responce = await UserServices.getSptAppointments(
        newLimit,
        pageNo,
        values
      );
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];
        await res.map((value) => {
          results.push({
            branch_name: value.branch_name,
            time_slots: value.time_slots,
            name: value.name,
            trainer_id: value.trainer_id,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.pagination.per_page);
        setTotalResults(responce.data.pagination.total_record);
        setTotalPages(responce.data.pagination.total_pages);
        setCurrentPage(responce.data.pagination.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);

      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit, values);
    branches();
    gymTrainer(branch_id);
  }, []);

  //..........................................................................................................

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("SPT-Appointments.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);

    return slice.length ? (
      <>
        {slice.map((el, index) => (
          <div className="card-body p-0 pt-2" key={index}>
            <div className="bg-black d-flex" style={{ padding: "18px" }}>
              <div className="text-white fw-bold">
                Branch Name:{" "}
                <span style={{ fontWeight: "100" }}> {el.branch_name}</span>
              </div>
              <div className="text-white fw-bold ms-4">
                Trainer Name:{" "}
                <span style={{ fontWeight: "100" }}> {el.name}</span>
              </div>
            </div>
            <div className="table-responsive">
              <table
                className="table table-striped table-borderless table-style-1"
                data-caption={el.name}
              >
                {el.time_slots.length ? (
                  <>
                    <thead>
                      <tr>
                        <th scope="col" className="no_sort ">
                          Time
                        </th>
                        <th scope="col" className="no_sort ">
                          Monday
                        </th>
                        <th scope="col" className="no_sort ">
                          Tuesday
                        </th>
                        <th scope="col" className="no_sort ">
                          Wednesday
                        </th>
                        <th scope="col" className="no_sort ">
                          Thursday
                        </th>
                        <th scope="col" className="no_sort ">
                          Friday
                        </th>
                        <th scope="col" className="no_sort ">
                          Saturday
                        </th>
                        <th scope="col" className="no_sort ">
                          Sunday
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {el.time_slots.map((apt, index) => (
                        <tr key={index}>
                          <td>{apt.time}</td>
                          <td>
                            {apt.schedule?.Monday?.client_name ? (
                              <>
                                <Link
                                  data-tooltip-id={`Monday${apt.schedule?.schedule_id}`}
                                >
                                  {apt.schedule?.Monday?.client_name}
                                </Link>

                                <Tooltip
                                  clickable
                                  className="detail-for-pkg"
                                  classNameArrow="detail-for-pkg-arrow"
                                  id={`Monday${apt.schedule?.schedule_id}`}
                                  html={`<ol class="ps-2 m-0"> <li><strong>Package Name:</strong> ${
                                    apt.schedule?.Monday?.package_name
                                  } </li>
                                <li>  <strong>Package Date:</strong> ${moment(
                                  apt.schedule?.Monday?.package_start_date
                                ).format(
                                  "DD-MM-YYYY"
                                )} <strong>To</strong> ${moment(
                                    apt.schedule?.Monday?.package_end_date
                                  ).format("DD-MM-YYYY")}</li>
                                <li> <strong>PT Package:</strong> ${
                                  apt.schedule?.Monday?.session_count
                                } </li>
                                </ol> `}
                                />
                              </>
                            ) : (
                              "Free"
                            )}
                          </td>
                          <td>
                            {apt.schedule?.Tuesday?.client_name ? (
                              <>
                                <Link
                                  data-tooltip-id={`Tuesday${apt.schedule?.schedule_id}`}
                                >
                                  {apt.schedule?.Tuesday?.client_name}
                                </Link>

                                <Tooltip
                                  clickable
                                  className="detail-for-pkg"
                                  classNameArrow="detail-for-pkg-arrow"
                                  id={`Tuesday${apt.schedule?.schedule_id}`}
                                  html={`<ol class="ps-2 m-0"> <li><strong>Package Name:</strong> ${
                                    apt.schedule?.Tuesday?.package_name
                                  } </li>
                                <li>  <strong>Package Date:</strong> ${moment(
                                  apt.schedule?.Tuesday?.package_start_date
                                ).format(
                                  "DD-MM-YYYY"
                                )} <strong>To</strong> ${moment(
                                    apt.schedule?.Tuesday?.package_end_date
                                  ).format("DD-MM-YYYY")}</li>
                              <li> <strong>PT Package:</strong> ${
                                apt.schedule?.Tuesday?.session_count
                              } </li>
                              </ol> `}
                                />
                              </>
                            ) : (
                              "Free"
                            )}
                          </td>
                          <td>
                            {" "}
                            {apt.schedule?.Wednesday?.client_name ? (
                              <>
                                <Link
                                  data-tooltip-id={`Wednesday${apt.schedule?.schedule_id}`}
                                >
                                  {apt.schedule?.Wednesday?.client_name}
                                </Link>

                                <Tooltip
                                  clickable
                                  className="detail-for-pkg"
                                  classNameArrow="detail-for-pkg-arrow"
                                  id={`Wednesday${apt.schedule?.schedule_id}`}
                                  html={`<ol class="ps-2 m-0"> <li><strong>Package Name:</strong> ${
                                    apt.schedule?.Wednesday?.package_name
                                  } </li>
                                <li>  <strong>Package Date:</strong> ${moment(
                                  apt.schedule?.Wednesday?.package_start_date
                                ).format(
                                  "DD-MM-YYYY"
                                )} <strong>To</strong> ${moment(
                                    apt.schedule?.Wednesday?.package_end_date
                                  ).format("DD-MM-YYYY")}</li>
                              <li> <strong>PT Package:</strong> ${
                                apt.schedule?.Wednesday?.session_count
                              } </li>
                              </ol> `}
                                />
                              </>
                            ) : (
                              "Free"
                            )}
                          </td>
                          <td>
                            {" "}
                            {apt.schedule?.Thursday?.client_name ? (
                              <>
                                <Link
                                  data-tooltip-id={`Thursday${apt.schedule?.schedule_id}`}
                                >
                                  {apt.schedule?.Thursday?.client_name}
                                </Link>

                                <Tooltip
                                  clickable
                                  className="detail-for-pkg"
                                  classNameArrow="detail-for-pkg-arrow"
                                  id={`Thursday${apt.schedule?.schedule_id}`}
                                  html={`<ol class="ps-2 m-0"> <li><strong>Package Name:</strong> ${
                                    apt.schedule?.Thursday?.package_name
                                  } </li>
                                <li>  <strong>Package Date:</strong> ${moment(
                                  apt.schedule?.Thursday?.package_start_date
                                ).format(
                                  "DD-MM-YYYY"
                                )} <strong>To</strong> ${moment(
                                    apt.schedule?.Thursday?.package_end_date
                                  ).format("DD-MM-YYYY")}</li>
                              <li> <strong>PT Package:</strong> ${
                                apt.schedule?.Thursday?.session_count
                              } </li>
                              </ol> `}
                                />
                              </>
                            ) : (
                              "Free"
                            )}
                          </td>
                          <td>
                            {" "}
                            {apt.schedule?.Friday?.client_name ? (
                              <>
                                <Link
                                  data-tooltip-id={`Friday${apt.schedule?.schedule_id}`}
                                >
                                  {apt.schedule?.Friday?.client_name}
                                </Link>

                                <Tooltip
                                  clickable
                                  className="detail-for-pkg"
                                  classNameArrow="detail-for-pkg-arrow"
                                  id={`Friday${apt.schedule?.schedule_id}`}
                                  html={`<ol class="ps-2 m-0"> <li><strong>Package Name:</strong> ${
                                    apt.schedule?.Friday?.package_name
                                  } </li>
                                <li>  <strong>Package Date:</strong> ${moment(
                                  apt.schedule?.Friday?.package_start_date
                                ).format(
                                  "DD-MM-YYYY"
                                )} <strong>To</strong> ${moment(
                                    apt.schedule?.Friday?.package_end_date
                                  ).format("DD-MM-YYYY")}</li>
                              <li> <strong>PT Package:</strong> ${
                                apt.schedule?.Friday?.session_count
                              } </li>
                              </ol> `}
                                />
                              </>
                            ) : (
                              "Free"
                            )}
                          </td>
                          <td>
                            {" "}
                            {apt.schedule?.Saturday?.client_name ? (
                              <>
                                <Link
                                  data-tooltip-id={`Saturday${apt.schedule?.schedule_id}`}
                                >
                                  {apt.schedule?.Saturday?.client_name}
                                </Link>

                                <Tooltip
                                  clickable
                                  className="detail-for-pkg"
                                  classNameArrow="detail-for-pkg-arrow"
                                  id={`Saturday${apt.schedule?.schedule_id}`}
                                  html={`<ol class="ps-2 m-0"> <li><strong>Package Name:</strong> ${
                                    apt.schedule?.Saturday?.package_name
                                  } </li>
                                <li>  <strong>Package Date:</strong> ${moment(
                                  apt.schedule?.Saturday?.package_start_date
                                ).format(
                                  "DD-MM-YYYY"
                                )} <strong>To</strong> ${moment(
                                    apt.schedule?.Saturday?.package_end_date
                                  ).format("DD-MM-YYYY")}</li>
                              <li> <strong>PT Package:</strong> ${
                                apt.schedule?.Saturday?.session_count
                              } </li>
                              </ol> `}
                                />
                              </>
                            ) : (
                              "Free"
                            )}
                          </td>
                          <td>
                            {" "}
                            {apt.schedule?.Sunday?.client_name ? (
                              <>
                                <Link
                                  data-tooltip-id={`Sunday${apt.schedule?.schedule_id}`}
                                >
                                  {apt.schedule?.Sunday?.client_name}
                                </Link>

                                <Tooltip
                                  clickable
                                  className="detail-for-pkg"
                                  classNameArrow="detail-for-pkg-arrow"
                                  id={`Sunday${apt.schedule?.schedule_id}`}
                                  html={`<ol class="ps-2 m-0"> <li><strong>Package Name:</strong> ${
                                    apt.schedule?.Sunday?.package_name
                                  } </li>
                                <li>  <strong>Package Date:</strong> ${moment(
                                  apt.schedule?.Sunday?.package_start_date
                                ).format(
                                  "DD-MM-YYYY"
                                )} <strong>To</strong> ${moment(
                                    apt.schedule?.Sunday?.package_end_date
                                  ).format("DD-MM-YYYY")}</li>
                              <li> <strong>PT Package:</strong> ${
                                apt.schedule?.Sunday?.session_count
                              } </li>
                              </ol> `}
                                />
                              </>
                            ) : (
                              "Free"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        <h5 className="text-danger text-center">
                          No Appointments Found
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        ))}
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {}}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    ) : (
      <div className="text-danger font-22 mt-2 text-center bg-danger-subtle p-1">
        No Record Found
      </div>
    );
  };

  const initialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    sDate: startOfWeek.toISOString().split("T")[0],
    eDate: endOfWeek.toISOString().split("T")[0],
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: "",

      onSubmit: async (values, action) => {
        getUsers("1", limit, values);
      },
    });

  return (
    <>
      <div className="wrapper">
        {" "}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">SPT Appointments</h5>
              </div>
              <div className="card-body">
                <form
                  className="row g-3 trainee-add mb-2"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Branch Name</label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            gymTrainer(e.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-lg-2">
                    <div className="form-group custom-group">
                      Available Trainers
                      {trainerName ? (
                        <select
                          className="form-select"
                          name="trainer_id"
                          value={values.trainer_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          disabled={role === "9" && UId ? true : false}
                          onBlur={handleBlur}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.trainer_id && touched.trainer_id ? (
                        <p className="text-danger">{errors.trainer_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-lg-2">
                    <div className="form-group custom-group">
                      Start Date
                      <input
                        type="date"
                        className="form-control"
                        name="sDate"
                        value={values.sDate || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.sDate && touched.sDate ? (
                        <p className="text-danger">{errors.sDate}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-lg-2">
                    <div className="form-group custom-group">
                      End Date
                      <input
                        type="date"
                        className="form-control"
                        name="eDate"
                        value={values.eDate || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.eDate && touched.eDate ? (
                        <p className="text-danger">{errors.eDate}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-1">
                    <button
                      type="submit"
                      className="btn btn-primary mx-md-2 file-download mt-md-4 w-100"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-md-1 col-lg-1 ms-auto">
                    <div className="input-group mt-md-4">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getUsers("1", e.target.value, values)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className=" col-md-1 text-end">
                    <button
                      className="btn btn-primary file-download mt-md-4 w-100"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                      type="button"
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </form>

                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default SPTAppointments;

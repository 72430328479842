import React, { useContext, useState, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import moment from "moment";
import { useFormik } from "formik";

export default function TrainerDairy() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [myBranches, setMyBranches] = useState([]);
  const [trainerName, setTrainerName] = useState([]);

  const getRecord = async (lim, page, bId, TrId, status) => {
    try {
      setLoading(true);
      const response = await UserServices.getTrainingDairy(
        bId,
        lim,
        page,
        TrId,
        status
      );

      let myDataarr = response?.data?.data?.data;
      const results = [];

      myDataarr.map((value) => {
        return results.push({
          Trainer_name: value.Trainer_name,
          id: value.id,
          name: value.name,
          start: value.start_time,
          end: value.end_time,
          assigned_time_slots: value.assigned_time_slots,
        });
      });
      setMyData([...results]);
      setTotalResults(response?.data?.totalRecord);
      setTotalPages(response?.data?.totalPages);
      setCurrentPage(response?.data?.data?.current_page);
      setLimit(response?.data?.data?.per_page);
      setLoading(false);
    } catch (err) {
      console.log(err);

      setMyData([]);
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage("1");
      setLoading(false);
    }
  };

  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const convertTime = (input) => {
    const [hours, minutes] = input.split(":").map(Number);
    let formattedHours = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";

    formattedHours =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  useEffect(() => {
    branches();

    gymTrainer(branch_id);

    getRecord(
      limit,
      currentPage,
      addPkgValues.branch_id,
      addPkgValues.trainer_id,
      addPkgValues.status
    );
  }, []);

  const TableOne = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    let act = 0;
    let exp = 0;
    let totl = 0;
    let empt = 0;
    return slice.length ? (
      <>
        {slice.map((el, index) => (
          <div className="card-body p-0 pt-2" key={index}>
            <span className="d-none">{(act = 0)}</span>
            <span className="d-none">{(exp = 0)}</span>
            <span className="d-none">{(totl = 0)}</span>
            <span className="d-none">{(empt = 0)}</span>
            <div
              className="bg-black row"
              style={{ padding: "20px 0px", margin: "0" }}
            >
              <div className="text-white col-6 col-md-8 fw-bold">
                Trainer Name:{" "}
                <span style={{ fontWeight: "100" }}> {el.Trainer_name}</span>
              </div>
              <div className="text-white col-6 col-md-4 fw-bold text-end">
                Duty Hours:{" "}
                {/* <span style={{ fontWeight: "100" }}>
                  {el.start ? convertTime(el.start) : "00:00"} -{" "}
                  {el.end ? convertTime(el.end) : "00:00"}
                </span> */}
                <span style={{ fontWeight: "100" }}>
                  {el.start === "N/A" || el.end === "N/A"
                    ? "N/A"
                    : `${el.start ? convertTime(el.start) : "00:00"} - ${
                        el.end ? convertTime(el.end) : "00:00"
                      }`}
                </span>
              </div>
            </div>

            <div className="table-container-dairy">
              <div className="headings">
                <div className="headings-dairy time-dairy">Time</div>
                <div className="headings-dairy client-dairy">Client Name</div>
                <div className="headings-dairy">PT Package</div>
                <div className="headings-dairy bg-body-tertiary">Paid Date</div>
                <div className="headings-dairy">Renewal Date</div>
                <div className="headings-dairy bg-body-tertiary">Status</div>
              </div>
              <div className="data-dairy">
                {el.assigned_time_slots.map((apt) =>
                  apt.appointments.length ? (
                    apt.appointments.map((apo, index) => (
                      <div key={index} className="data-row">
                        <div className="data-cell time-dairy">
                          {apo.start_time + " - " + apo.end_time}
                        </div>
                        <div className="data-cell client-dairy">
                          {apo.client_name}
                        </div>
                        <div className="data-cell">
                          {apo.session_count * apo.quantity}
                        </div>
                        <div className="data-cell bg-body-tertiary">
                          {moment(apo.sale_date).format("DD-MM-YYYY")}
                        </div>
                        <div className="data-cell">
                          {moment(apo.expiry_date).format("DD-MM-YYYY")}
                        </div>
                        <div
                          className={`data-cell bg-body-tertiary  ${
                            apo.status === "1" ? "text-success" : "text-danger"
                          }`}
                        >
                          {
                            apo.status === "1" ? "Active" : "Expired"
                            // <>
                            //   <Link
                            //     to={`/assign-slot/${el.id}/${apt.time_slot_id}/${apt.id}`}
                            //     data-tooltip-id={apt.id}
                            //     data-tooltip-content="Assign Slot"
                            //   >
                            //     Expired
                            //   </Link>
                            //   <Tooltip
                            //     id={apt.id}
                            //     place="top"
                            //     className="note-tooltip"
                            //     clickable
                            //   />
                            // </>
                            // ) : (
                            //   <>
                            //     <Link
                            //       to={`/assign-slot/${el.id}/${apt.time_slot_id}/${apt.id}`}
                            //       data-tooltip-id={apt.time_slot_id}
                            //       className="text-black"
                            //       data-tooltip-content="Assign Slot"
                            //     >
                            //       Empty
                            //     </Link>
                            //     <Tooltip
                            //       id={apt.time_slot_id}
                            //       place="top"
                            //       className="note-tooltip"
                            //       clickable
                            //     />
                            //   </>
                            // )
                          }

                          <span className="d-none">
                            {apo.status === "1"
                              ? (act = act + 1)
                              : (exp = exp + 1)}

                            {(totl = totl + 1)}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="data-row" key={apt.id}>
                      <div className="data-cell time-dairy">
                        {apt.start_time + " - " + apt.end_time}
                      </div>
                      <div className="data-cell client-dairy">Not Assigned</div>
                      <div className="data-cell">N/A</div>
                      <div className="data-cell bg-body-tertiary">N/A</div>
                      <div className="data-cell">N/A</div>
                      <div className={`data-cell bg-body-tertiary `}>
                        Empty
                        {/* <Link
                          to={`/assign-slot/${el.id}/${apt.time_slot_id}/${apt.id}`}
                          data-tooltip-id={apt.time_slot_id}
                          className="text-black"
                          data-tooltip-content="Assign Slot"
                        >
                          Empty
                        </Link>
                        <Tooltip
                          id={apt.time_slot_id}
                          place="top"
                          className="note-tooltip"
                          clickable
                        /> */}
                        <span className="d-none">
                          {(totl = totl + 1)}
                          {(empt = empt + 1)}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="grey-for-bg first"></div>
              <div className="grey-for-bg"></div>
            </div>

            <div className="slot-detail">
              <div className="show-slots">
                <span className="fw-bold">Total Slots:</span> {totl}
              </div>
              <div className="show-slots">
                <span className="fw-bold">Filled Slots:</span> {act}
              </div>
              <div className="show-slots">
                <span className="fw-bold">Vacant:</span> {empt}
              </div>
              <div className="show-slots">
                <span className="fw-bold">Expired:</span> {exp}
              </div>
              <div className="show-slots">
                <span className="fw-bold">Active:</span> {act}
              </div>
            </div>
          </div>
        ))}
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getRecord(
                limit,
                e,
                addPkgValues.branch_id,
                addPkgValues.trainer_id,
                addPkgValues.status
              );
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    ) : (
      ""
    );
  };

  const addPkginitialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    status: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: "",
    onSubmit: (addPkgValues) => {
      getRecord(
        limit,
        "1",
        addPkgValues.branch_id,
        addPkgValues.trainer_id,
        addPkgValues.status
      );
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Trainer Diary</h5>
            </div>
            <div className="card-body">
              <form
                className="row g-3"
                onSubmit={(e) => {
                  addPkgHandleSubmit(e);
                }}
              >
                <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Branch Name</label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={addPkgValues.branch_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);

                          gymTrainer(e.target.value);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    Available Trainers
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={addPkgValues.trainer_id || ""}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-1">
                  <button
                    type="submit"
                    className="btn btn-primary  rounded-0 w-100 mt-md-4"
                  >
                    Search
                  </button>
                </div>
                <div className="col-12 col-lg-1 ms-auto">
                  <div className="form-group custom-group">
                    <label className="form-label">Show</label>

                    <select
                      className="form-select "
                      value={limit}
                      onChange={(e) =>
                        getRecord(
                          e.target.value,
                          currentPage,
                          addPkgValues.branch_id,
                          addPkgValues.trainer_id,
                          addPkgValues.status
                        )
                      }
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </form>
              <TableOne data={myData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

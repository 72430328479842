import React, { useState, useEffect, useContext } from "react";
import UserServices from "../../UserServices/UserServices";
import MenuButton from "../MenuButton/MenuButton";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import moment from "moment";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

const ViewFreezing = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  // for inactive table
  const [inactiveLimit, setInactiveLimit] = useState("25");
  const [inactiveTotalResults, setInactiveTotalResults] = useState("0");
  const [inactiveTotalPages, setInactiveTotalPages] = useState("1");
  const [inactiveData, setInactiveData] = useState([]);
  const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);

  const getFee = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getActiveFee(
        branch_id,
        "1",
        newLimit,
        pageNo
      );
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            start_date: values.start_date,
            end_date: values.end_date,
            duration: values.duration,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getInactiveFee = async (page, limit) => {
    setLoading(true);
    setInactiveCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setInactiveLimit(newLimit);
    try {
      const responce = await UserServices.getActiveFee(
        branch_id,
        "0",
        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            start_date: values.start_date,
            end_date: values.end_date,
            duration: values.duration,
          });
          return null;
        });

        setInactiveData([...results]);
        setInactiveLimit(responce.data.data.per_page);
        setInactiveTotalResults(responce.data.totalRecord);
        setInactiveTotalPages(responce.data.totalPages);
        setInactiveCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setInactiveTotalResults("0");
        setInactiveTotalPages("1");
        setInactiveData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getFee(currentPage, limit);
    getInactiveFee(inactiveCurrentPage, inactiveLimit);
  }, []);

  const makeInActive = async (freez_Id) => {
    try {
      setLoading(true);

      const responce = await UserServices.inactiveFee(freez_Id);

      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getFee(currentPage, limit);
        getInactiveFee(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveFee = (freez_Id) => {
    makeInActive(freez_Id);
  };
  const deleteRecord = async (fee_Id) => {
    try {
      const responce = await UserServices.deleteFee(fee_Id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getInactiveFee(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFee = (fee_Id) => {
    deleteRecord(fee_Id);
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Freezing.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Freezing"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
              <th
                onClick={() => requestSort("start_date")}
                className={getClassNamesFor("start_date")}
              >
                Start Date
              </th>

              <th
                scope="col"
                onClick={() => requestSort("end_date")}
                className={getClassNamesFor("end_date")}
              >
                End Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("duration")}
                className={getClassNamesFor("duration")}
              >
                Duration
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  <td>{moment(el.start_date).format("DD-MM-YYYY")}</td>
                  <td>{moment(el.end_date).format("DD-MM-YYYY")}</td>
                  <td>{el.duration} Days</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => inactiveFee(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Inactive
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getFee(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const InactiveTable = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
              <th
                onClick={() => requestSort("start_date")}
                className={getClassNamesFor("start_date")}
              >
                Start Date
              </th>

              <th
                scope="col"
                onClick={() => requestSort("end_date")}
                className={getClassNamesFor("end_date")}
              >
                End Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("duration")}
                className={getClassNamesFor("duration")}
              >
                Duration
              </th>

              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {inactiveCurrentPage === 1
                      ? index + 1
                      : (inactiveCurrentPage - 1) * inactiveLimit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  {/* <td>{el.start_date}</td> */}
                  <td>{moment(el.start_date).format("DD-MM-YYYY")}</td>
                  <td>{moment(el.end_date).format("DD-MM-YYYY")}</td>
                  {/* <td>{el.end_date}</td> */}
                  <td>{el.duration} Days</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => delFee(el.id)}
                      className="inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {inactiveTotalResults > inactiveLimit && inactiveTotalPages > 1 ? (
          <Pagination
            activePage={inactiveCurrentPage}
            itemsCountPerPage={parseInt(inactiveLimit)}
            totalItemsCount={inactiveTotalResults}
            onChange={(e) => {
              getInactiveFee(e, inactiveLimit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">View Freezing</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-1 col-lg-1 mb-2 ms-2 ms-auto ">
                  <select
                    className="form-select"
                    onChange={(e) => getFee("1", e.target.value)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <div className=" col-md-1 text-end">
                  <button
                    className="btn btn-primary mx-2 file-download"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                </div>
              </div>
              <div className="table-responsive mt-3">
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Inactive Freezing</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-lg-6 ms-3"></div>
                {/* {totalResults > limit ? ( */}
                <div className="col-md-1 col-lg-1 mb-2 ms-auto me-3">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={inactiveLimit}
                      onChange={(e) => getInactiveFee("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="table-responsive ">
                <InactiveTable
                  data={inactiveData}
                  rowsPerPage={inactiveLimit}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ViewFreezing;

import React, { useContext, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { clienthome } from "../schemas";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import UserServices from "../../UserServices/UserServices";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { MaskField, useMask } from "react-mask-field/dist";
import { NoteState } from "../../context/notes/NoteState";
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import { CanvasPreview } from "../AddClients/CanvasPreview";
import { UseDebounceEffect } from "../AddClients/UseDebounceEffect";
import $ from "jquery";

const ClientHome = () => {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState("");
  const [cnicError, setCnicError] = useState("");
  const [loading, setLoading] = useState(false);
  const [fname, setFname] = useState("");
  const [balance, setBalance] = useState("");
  const [lname, setLname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [clubId, setClubId] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");
  const [cnic, setCnic] = useState("");
  const [uid, setuid] = useState("0");
  const [branchId, setBranchId] = useState("");
  const [prfoImg, setPrfoImg] = useState("");
  const [picture, setPicture] = useState("");
  const [PkgsList, setPkgsList] = useState([]);
  const [file, setFile] = useState("");
  const [Identification_number, setIdentification_number] = useState("");
  const [identification_type, setIdentification_type] = useState("");
  const { id } = useParams();
  const formData = new FormData();

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });

  const videoConstraints = {
    width: 450,
    height: 400,
    facingMode: "user",
  };

  let active_packages = [];

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getClientProfileInfo(id);

        if (responce.status === 200) {
          let data = responce.data.data;
          await data.map((value) => {
            setFname(value.first_name);
            setBalance(value.available_balance);
            setLname(value.last_name);
            setPhone(value.phone);
            setemail(value.email);
            setDate(value.birthday);
            setCountry(value.country);
            setAddress(value.address);
            setCity(value.city);
            setClubId(value.club_id);
            setGender(value.gender);
            setZip(value.postal_code);
            setCnic(value.cnic);
            setuid(value.uid);
            setBranchId(value.branch_id);
            setPrfoImg(value.image);
            setIdentification_type(value.identification_type);
            setIdentification_number(value.identification_number);
            active_packages.push(value.active_packages);
          });

          setPkgsList(...active_packages);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.message === "Network Error") {
          setLoading(false);
        } else if (err.response.status === 401) {
          setLoading(false);

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No Record Found!",
            confirmButtonColor: "#e72e2e",
            // confirmButtonText: "LogIn Again!",
          });
          navigate("/dashboard");
        } else {
          setLoading(false);
        }
      }
    };

    getUsers();
  }, [id]);

  const verifyPhone = async (e) => {
    var check_Phone = e.target.value;
    const phNum = check_Phone
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");
    try {
      const response = await UserServices.checkPhone(phNum, id);
      if (response.status === 200) {
        setPhoneError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setPhoneError("phone number already exists");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };

  const verifyCnic = async (e) => {
    var check_Cnic = e.target.value;
    try {
      const response = await UserServices.checkCnic(check_Cnic, id);
      if (response.status === 200) {
        setCnicError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setCnicError("CNIC already exists");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };

  const UpdateProfile = async (values) => {
    if (phoneError === "" && cnicError === "") {
      if (file !== "" && picture === "") {
        formData.append("file", file);

        formData.append("image_upload_from", "webcam");
      } else if (picture !== "") {
        formData.append("file", picture);
        formData.append("image_upload_from", "webcam");
      }
      formData.append("identification_type", values.uniques_entry_by);

      if (values.uniques_entry_by === "Cnic") {
        formData.append("identification_number", values.cnic);
      } else if (values.uniques_entry_by === "Passport Number") {
        formData.append("identification_number", values.passport);
      } else if (values.uniques_entry_by === "Driving License Number") {
        formData.append("identification_number", values.license);
      }

      const phNum = values.phone
        .replace(/-/g, "")
        .replace(/[\])}[{(]/g, "")
        .replace(/ /g, "");

      formData.append("first_name", values.name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("phone", phNum);
      formData.append("address", values.address);
      formData.append("city", values.city);
      formData.append("club_id", values.club_id);
      formData.append("country", values.country);
      formData.append("postal_code", values.postal_code);
      formData.append("gender", values.gender);
      formData.append("birthday", values.birthday);

      setLoading(true);

      try {
        const response = await UserServices.UpdateClientProfile(formData, id);
        if (response.status === 204) {
          setLoading(false);

          toast.success("Profile information updated successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };

  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
  });
  const [imgSrc, setImgSrc] = useState("");

  const [completedCrop, setCompletedCrop] = useState("");
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  // const hiddenAnchorRef = useRef(null);
  // const blobUrlRef = useRef("");
  const [croppedImage, setCroppedImage] = useState(null);

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: 450,
          height: 450,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  UseDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        CanvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  function onImageLoad() {
    setCrop(centerAspectCrop(450, 450, 1));
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    // const ctx = offscreen.getContext("2d");

    const canvas = document.createElement("canvas");
    canvas.width = previewCanvas.width;
    canvas.height = previewCanvas.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    setFile(canvas.toDataURL("image/jpeg"));
    var imageView = document.getElementById("output");
    imageView.src = canvas.toDataURL("image/jpeg");

    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    // const blob = await offscreen.convertToBlob({
    //   type: "image/png",
    // });

    // if (blobUrlRef.current) {
    //   URL.revokeObjectURL(blobUrlRef.current);
    // }
    // blobUrlRef.current = URL.createObjectURL(blob);

    // if (hiddenAnchorRef.current) {
    //   hiddenAnchorRef.current.href = blobUrlRef.current;
    //   hiddenAnchorRef.current.click();
    // }
  }
  var clickEvent = $.Event("click");

  const handleUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Reset crop
      setCompletedCrop(null); // Clear completed crop
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      $("#cropBox-btn").trigger(clickEvent);
    }
  };

  const webcamRef = React.useRef(null);
  // const capture = () => {
  //   setPicture("");
  //   const pictureSrc = webcamRef.current.getScreenshot();
  //   setPicture(pictureSrc);
  //   var image = document.getElementById("output");
  //   image.src = pictureSrc;
  // };

  const capture = () => {
    setPicture("");
    const pictureSrc = webcamRef.current.getScreenshot();
    setImgSrc("");
    setCompletedCrop(null);
    if (pictureSrc) {
      setCrop(undefined); // Reset crop
      setImgSrc(pictureSrc);
      $("#cropBox-btn").trigger(clickEvent);
    } else {
      toast.error("Unproccessable content!", { position: "top-center" });
    }
  };
  useEffect(() => {
    const modalElement = document.querySelector("#cropBox");

    const handleModalClose = () => {
      setImgSrc(""); // Clear image source
      setCrop(undefined); // Reset crop
      setCompletedCrop(null); // Clear completed crop
    };

    // Add event listener for Bootstrap's modal hide event
    modalElement.addEventListener("hide.bs.modal", handleModalClose);

    return () => {
      // Clean up the event listener on component unmount
      modalElement.removeEventListener("hide.bs.modal", handleModalClose);
    };
  }, []);
  const PkgOpt = ({ data }) => {
    return (
      <>
        {data.length !== 0 ? (
          <Link to={"/sell-package/" + id + "/renew"} className="dropdown-item">
            Renew Package
          </Link>
        ) : (
          <Link to={"/sell-package/" + id + "/new"} className="dropdown-item">
            Sell Package
          </Link>
        )}
      </>
    );
  };

  const initialValues = {
    name: fname,
    last_name: lname,
    cnic: Identification_number,
    passport: Identification_number,
    license: Identification_number,
    email: email,
    phone: phone,
    address: address,
    city: city,
    club_id: clubId,
    country: country,
    postal_code: zip,
    gender: gender,
    birthday: date,
    uniques_entry_by: identification_type,
  };

  //validation........................................................................................
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clienthome,
      onSubmit: (values) => {
        UpdateProfile(values);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20 text-capitalize">
                Client: {values.name + " " + values.last_name}
                <button
                  className="btn btn-primary d-inline-block btn-sm float-end"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="bi bi-tools" /> Tools
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to={"/preAssessment/" + id}>
                      Add Assessment
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to={"/client-assessment/" + id}
                    >
                      View Assessments
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={"/client-purchases/" + id}
                      className="dropdown-item"
                    >
                      View Purchases
                    </Link>
                  </li>
                  <li>
                    <PkgOpt data={PkgsList} />
                  </li>
                  <li>
                    <Link
                      to={"/account-details/" + id}
                      className="dropdown-item"
                    >
                      Account Details
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/client-attendance/" + id}
                      className="dropdown-item"
                    >
                      View Attendance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/new-card/" + id + "/1"}
                      className="dropdown-item"
                    >
                      Assign Card
                    </Link>
                  </li>
                </ul>
              </h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="add-in-sch d-flex">
                  <div className="col-9">
                    <label className="form-label fw-bold m-0">
                      Membership No:<span>{uid}</span>
                    </label>
                  </div>
                  <div className="col-3 ">
                    <label className="form-label fw-bold d-flex ms-5">
                      Available Balance:{" "}
                      <span className="text-success">
                        <CurrencyFormat
                          value={balance}
                          displayType={"text"}
                          thousandSeparator={true}
                          thousandSpacing={"2s"}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="mb-0">Client Image</h5>
                </div>
                <div className="col-12">
                  <div className="clint-pic-upload">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#cropBox"
                          id="cropBox-btn"
                          type="button"
                          className="d-none"
                        ></button>
                        <input
                          type="file"
                          id="imageUpload"
                          onClick={(e) => {
                            e.target.value = null;
                            setFile("");
                            setPicture("");
                            setCompletedCrop("");
                          }}
                          onChange={handleUpload}
                          accept=".png, .jpg, .jpeg"
                        />

                        <button
                          className="btn btn-primary "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-camera-fill" />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              Capture with webcam
                            </button>
                          </li>
                          <li>
                            <label
                              className="dropdown-item"
                              htmlFor="imageUpload"
                            >
                              Upload Image
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="avatar-preview">
                        <div
                          id="imagePreview"
                          style={
                            file === "" && picture === ""
                              ? prfoImg === ""
                                ? {
                                    backgroundImage:
                                      'url("/assets/images/placeholder2.png")',
                                    overflow: "hidden",
                                  }
                                : {
                                    backgroundImage:
                                      'url("/assets/images/placeholder2.png")',
                                    overflow: "hidden",
                                    backgroundImage: 'url("' + prfoImg + '")',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }
                              : {
                                  backgroundImage: "none",
                                  overflow: "hidden",
                                }
                          }
                        >
                          <img
                            id="output"
                            style={
                              file === "" && picture === ""
                                ? {
                                    display: "none",
                                  }
                                : {
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <h5 className="mb-0">Client Name</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter First Name"
                      name="name"
                      value={values.name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.name && touched.name ? (
                    <p className="text-danger">{errors.name}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Last Name"
                      name="last_name"
                      value={values.last_name || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.last_name && touched.last_name ? (
                    <p className="text-danger">{errors.last_name}</p>
                  ) : null}
                </div>
                <hr />
                <div className="col-12 mt-0">
                  <h5 className="mb-0">Client Contact Details</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.email && touched.email ? (
                    <p className="text-danger">{errors.email}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="(e.g 92xxxxxxxxxx"
                      name="phone"
                      value={values.phone}
                      onChange={(e) => {
                        handleChange(e);
                        verifyPhone(e);
                      }}
                      onBlur={handleBlur}
                      ref={phone_number}
                    />
                    {phoneError !== "" ? (
                      <p className="text-danger">{phoneError}</p>
                    ) : null}

                    {errors.phone && touched.phone ? (
                      <p className="text-danger">{errors.phone}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Select Identification Type{" "}
                      {/* {values.uniques_entry_by === "Cnic"
                        ? "CNIC"
                        : values.uniques_entry_by} */}
                    </label>
                    <select
                      className="form-select"
                      name="uniques_entry_by"
                      value={values.uniques_entry_by}
                      onChange={(e) => {
                        handleChange(e);
                        values.cnic = "";
                        values.passport = "";
                        values.license = "";
                        setIdentification_number("");
                        setIdentification_type(e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="Cnic">CNIC</option>
                      <option value="Passport Number">Passport Number</option>
                      <option value="Driving License Number">
                        Driving License Number
                      </option>
                    </select>
                    {errors.uniques_entry_by && touched.uniques_entry_by ? (
                      <p className="text-danger">{errors.uniques_entry_by}</p>
                    ) : null}
                  </div>
                </div>

                {values.uniques_entry_by === "Cnic" ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">CNIC</label>

                      <MaskField
                        className="form-control cnic-mask"
                        placeholder="xxxxx-xxxxxxx-x"
                        name="cnic"
                        value={values.cnic}
                        onChange={(e) => {
                          handleChange(e);
                          verifyCnic(e);
                        }}
                        onBlur={handleBlur}
                        mask="_____-_______-_-"
                        replacement={{ _: /\d/ }}
                      />

                      {cnicError ? (
                        <div className="errorMsg text-danger">{cnicError}</div>
                      ) : null}
                      {errors.cnic && touched.cnic ? (
                        <p className="text-danger">{errors.cnic}</p>
                      ) : null}
                    </div>
                  </div>
                ) : values.uniques_entry_by === "Passport Number" ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Passport Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Passport Number"
                        name="passport"
                        value={values.passport}
                        onChange={(e) => {
                          handleChange(e);
                          verifyCnic(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {cnicError ? (
                        <div className="errorMsg text-danger">
                          Passport number already exists
                        </div>
                      ) : null}

                      {errors.passport && touched.passport ? (
                        <p className="text-danger">{errors.passport}</p>
                      ) : null}
                    </div>
                  </div>
                ) : values.uniques_entry_by === "Driving License Number" ? (
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Driving License Number{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <MaskField
                        className="form-control"
                        placeholder="xx-xx-xxxx"
                        name="license"
                        value={values.license}
                        onChange={(e) => {
                          handleChange(e);
                          verifyCnic(e);
                        }}
                        onBlur={handleBlur}
                        mask="__-__-____-"
                        replacement={{ _: /^[A-Za-z0-9]*$/ }}
                      />
                      {cnicError ? (
                        <div className="errorMsg text-danger">
                          Driving license number already exists
                        </div>
                      ) : null}
                      {errors.license && touched.license ? (
                        <p className="text-danger">{errors.license}</p>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      CNIC <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="xxxxx-xxxxxxx-x"
                      name="cnic"
                      value={values.cnic}
                      onChange={(e) => {
                        handleChange(e);
                        verifyCnic(e);
                      }}
                      onBlur={handleBlur}
                      ref={cnic_format}
                    />
                    {cnicError !== "" ? (
                      <p className="text-danger">{cnicError}</p>
                    ) : null}
                    {errors.cnic && touched.cnic ? (
                      <p className="text-danger">{errors.cnic}</p>
                    ) : null}
                  </div>
                </div> */}
                <hr />
                <div className="col-12 mt-0">
                  <h5 className="mb-0">Client Address Details</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.address && touched.address ? (
                    <p className="text-danger">{errors.address}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Club ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Club ID"
                      name="club_id"
                      value={values.club_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.club_id && touched.club_id ? (
                      <p className="text-danger">{errors.club_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.city && touched.city ? (
                    <p className="text-danger">{errors.city}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Country</label>
                    {/* <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    /> */}
                    <select
                      className="form-select"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select Country</option>
                      <option value="Pakistan">Pakistan</option>
                    </select>
                  </div>
                  {errors.country && touched.country ? (
                    <p className="text-danger">{errors.country}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Postal Code</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Postal Code"
                      name="postal_code"
                      value={values.postal_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.postal_code && touched.postal_code ? (
                    <p className="text-danger">{errors.postal_code}</p>
                  ) : null}
                </div>
                <hr />
                <div className="col-12 mt-0">
                  <h5 className="mb-0">Client Other Details</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Gender</label>
                    <select
                      className="form-select"
                      autoComplete="off"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Others</option>
                    </select>
                  </div>
                  {errors.gender && touched.gender ? (
                    <p className="text-danger">{errors.gender}</p>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Birthday</label>
                    <input
                      type="date"
                      className="form-control "
                      placeholder="Enter Birthday"
                      name="birthday"
                      value={values.birthday}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.birthday && touched.birthday ? (
                    <p className="text-danger">{errors.birthday}</p>
                  ) : null}
                </div>
                <div className="clearfix" />
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 "
                  >
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Capture Image
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {picture === "" ? (
                  <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    width={450}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className="webcam"
                  />
                ) : (
                  <img src={picture} alt="" />
                )}
              </div>
              <div>
                {picture !== "" ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPicture("");
                      }}
                      className="btn btn-danger custom-butn3 mt-2"
                    >
                      Retake
                    </button>
                    <button
                      className="btn btn-danger custom-butn3 mt-2 ms-2"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                    className="btn btn-danger custom-butn3 "
                  >
                    Capture
                  </button>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="cropBox"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="cropBoxLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="cropBoxLabel">
                Upload Image
              </h1>
              <button
                type="button"
                className="modal-close-btn1"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="modal-body">
              {!!imgSrc && (
                <ReactCrop
                  key={imgSrc} // Ensure re-render on image change
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  aspect={1}
                  maxWidth={450}
                  maxHeight={450}
                  onComplete={(c) => setCompletedCrop(c)}
                >
                  <img
                    alt="Crop me"
                    src={imgSrc}
                    ref={imgRef}
                    minHeight={450}
                    minWidth={450}
                    style={{
                      transform: `scale(1) rotate(0deg)`,
                      minHeight: "450px",
                      minWidth: "450px",
                      objectFit: "cover",
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              {!!completedCrop && (
                <>
                  <div>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            {!completedCrop ? null : (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={onDownloadCropClick}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default ClientHome;

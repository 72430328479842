import React, { useContext, useRef, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { assignTimeSlot } from "../schemas";
import { useEffect } from "react";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import $ from "jquery";
import jsPDF from "jspdf";

export default function AssignTimeSlots() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [trainerName, setTrainerName] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState([]);

  // update

  var clickEvent = $.Event("click");

  const closeButtonRef = useRef(null);
  const getPkgs = async (id) => {
    setLoading(true);
    try {
      await setSelectedTimeSlot([]);
      const responce = await UserServices.getAssignedSlotsById(id);

      if (responce.status === 200) {
        let data = responce.data.data.data;
        const res = data;
        const results = [];

        res.map((values) => {
          results.push(values.time_slot_id);
          return null;
        });

        await setSelectedTimeSlot([...results]);

        data.map((values) => {
          updateSetValues({
            id: values.id,
            branch_id: values.branch_id,
            user_id: values.user_id,
          });
        });

        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setSelectedTimeSlot([]);
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getAssignedSlots(
        branch_id,
        "1",
        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            assigned_time_slots: values.assigned_time_slots,
            trainer: values.Trainer_name,
            branch_name: values.name,
          });
          return null;
        });

        setMyData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  const getTimeSolts = async () => {
    try {
      const responce = await UserServices.timeSlotGet(
        branch_id,
        "1",
        "999999999"
      );

      const res = responce.data.data.data;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          branch_name: values.branch_name,
          end_time: values.end_time,
          start_time: values.start_time,
        });
        return null;
      });
      setTimeSlot([...results]);
    } catch (err) {
      console.log(err);
      setTimeSlot([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);
    branches();
    getTimeSolts();
    if (branch_id) {
      gymTrainer(branch_id);
    } else {
    }
  }, []);
  const makeDelete = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.deleteSlots(package_Id);
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
        // getInactivePkg(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };
  const deletePackage = (package_Id) => {
    makeDelete(package_Id);
  };
  const timeSlotsAssign = async (addPkgValues, action) => {
    var selectedTime = $(
      ".add-time-slot-time .form-check input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedTime.length) {
      toast.error("Please select time slot", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        setLoading(true);
        const response = await UserServices.assignSlots(
          addPkgValues,
          selectedTime
        );

        if (response.status === 201) {
          setLoading(false);
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          $(".add-time-slot-time .form-check input:checkbox").prop(
            "checked",
            false
          );
          getPkg(currentPage, limit);
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
        } else if (err.response.status === 500) {
          // toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 400) {
          // toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    }
  };

  const addPkginitialValues = {
    branch_id: branch_id,
    user_id: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: assignTimeSlot,
    onSubmit: (addPkgValues, action) => {
      timeSlotsAssign(addPkgValues, action);
    },
  });

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("assign-time-slot.pdf");
  };

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1 align-middle"
          data-caption="Assign Time Slots"
          id="report-POS"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_id")}
                className={getClassNamesFor("branch_id")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer")}
                className={getClassNamesFor("trainer")}
              >
                Trainer Name
              </th>
              <th scope="col" className="no_sort">
                Assigned Time Slots
              </th>

              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>
                  <td>{el.trainer}</td>

                  <td>
                    {el.assigned_time_slots?.length ? (
                      <div className="time-allocated">
                        {el.assigned_time_slots.map((val, index) => (
                          <>
                            {el.assigned_time_slots?.length > 1 && index > 0 ? (
                              <strong> | </strong>
                            ) : null}
                            <span key={index} className="timeSelected-span">
                              <span>
                                {val.start_time} <strong>To</strong>{" "}
                                {val.end_time}
                              </span>
                            </span>
                          </>
                        ))}
                      </div>
                    ) : null}
                  </td>

                  <td>
                    <button
                      onClick={() => {
                        getPkgs(el.id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => deletePackage(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const UpdateExpnc = async (updateValues, action) => {
    var selectedTime = $(
      ".update-time-slot-time .form-check input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedTime.length) {
      toast.error("Please select time slot", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setLoading(true);

      try {
        const response = await UserServices.updateAssignedSlot(
          updateValues,
          selectedTime
        );

        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        action.resetForm();
        closeButtonRef.current.click();

        getPkg(currentPage, limit);
      } catch (err) {
        if (err?.response?.status === 409) {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else if (err?.response?.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };
  const updateinitialValues = {
    id: "",
    branch_id: "",
    user_id: "",
  };

  //.........................................................................
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
    setValues: updateSetValues,
  } = useFormik({
    initialValues: updateinitialValues,
    validationSchema: assignTimeSlot,
    onSubmit: (updateValues, action) => {
      UpdateExpnc(updateValues, action);
    },
  });

  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Assign Time Slots</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form
                  className="row g-3"
                  onSubmit={(e) => {
                    addPkgHandleSubmit(e);
                  }}
                >
                  <div className="col-12 col-lg-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={addPkgValues.branch_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            gymTrainer(e.target.value);
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                        <p className="text-danger">{addPkgErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group custom-group">
                      Available Trainers<span className="text-danger">*</span>
                      {trainerName ? (
                        <select
                          className="form-select"
                          name="user_id"
                          value={addPkgValues.user_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.user_id && addPkgTouched.user_id ? (
                        <p className="text-danger">{addPkgErrors.user_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-12">
                    <div className="form-group custom-group add-time-slot-time">
                      Available Time Slots<span className="text-danger">*</span>
                      {timeSlot ? (
                        <div className="row">
                          {timeSlot.map((el, index) => {
                            return (
                              <div className="col-lg-2 mt-2" key={index}>
                                <div className="form-check">
                                  <label>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={el.id}
                                    />
                                    {el.start_time} <strong>To</strong>{" "}
                                    {el.end_time}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 col-lg-2  ms-auto">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getPkg("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                    <button
                      className="btn btn-primary file-download"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-4">
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </main>
      </div>

      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Expense
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
                onClick={() => {
                  updateSetValues({
                    id: "",
                    branch_id: "",
                    user_id: "",
                  });
                }}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={updateHandleSubmit}>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={true}
                        value={updateValues.branch_id || ""}
                        onChange={(e) => {
                          updateHandleChange(e);
                          gymTrainer(e.target.value);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.branch_id && updateTouched.branch_id ? (
                      <p className="text-danger">{updateErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Available Trainers<span className="text-danger">*</span>
                    </label>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="user_id"
                        disabled={true}
                        value={updateValues.user_id || ""}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.user_id && updateTouched.user_id ? (
                      <p className="text-danger">{updateErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="form-group custom-group update-time-slot-time">
                    <label className="form-label">
                      Available Time Slots<span className="text-danger">*</span>
                    </label>

                    {timeSlot && updateValues.user_id !== "" ? (
                      <div className="row">
                        {timeSlot.map((el, index) => {
                          return (
                            <div className="col-lg-2 mt-2" key={index}>
                              <div className="form-check">
                                <label>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={el.id}
                                    defaultChecked={
                                      selectedTimeSlot?.includes(
                                        parseInt(el.id)
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                  {el.start_time} {el.end_time}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}

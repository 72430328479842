import React, { useContext, useRef, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { assignTimeSlotToClient } from "../schemas";
import { useEffect } from "react";
import $ from "jquery";
import { useNavigate, useParams } from "react-router";

export default function AssignTimeToClient() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const { trainer_id, client_id } = useParams();
  const navigate = useNavigate();

  const [trainerName, setTrainerName] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const [name, setName] = useState([]);

  const [daysError, setDaysError] = useState(false);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };
  const getTimeSolts = async (trId) => {
    try {
      const responce = await UserServices.trainerTimeList(trId);

      const res = responce.data.data;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          time: values.start_time + " To " + values.end_time,
        });
        return null;
      });
      setTimeSlot([{ time: "Select Time Slot", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTimeSlot([{ time: "No Time Slot Found", id: "" }]);
    }
  };

  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsName(branch_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };

  const clientPkg = async (branchId, trId) => {
    if (client_id !== "") {
      try {
        const responce = await UserServices.getClientsForTimeAssign(
          branchId,
          trId,
          client_id,
          trainer_id === "0" ? "16" : "2"
        );

        addFieldValue(
          "package_name",
          responce?.data?.data?.data[0]?.package_name
        );
        addFieldValue("order_id", responce?.data?.data?.data[0]?.order_id);
        addFieldValue("start", responce?.data?.data?.data[0]?.start_date);
        addFieldValue("duration", responce?.data?.data?.data[0]?.duration);
        addFieldValue("end", responce?.data?.data?.data[0]?.end_date);

        // const startDate = new Date(responce?.data?.data?.data[0]?.start_date);
        // const endDate = new Date(
        //   startDate.getTime() +
        //     responce?.data?.data?.data[0]?.duration * 24 * 60 * 60 * 1000
        // );

        // const year = endDate.getFullYear();
        // const month = endDate.getMonth() + 1;
        // const day = endDate.getDate();

        // const formattedEndDate = `${year}-${month < 10 ? "0" : ""}${month}-${
        //   day < 10 ? "0" : ""
        // }${day}`;
        // addFieldValue("end", formattedEndDate);
      } catch (err) {
        console.log(err);
        setName([]);
      }
    } else {
      addFieldValue("package_name", "");
      addFieldValue("order_id", "");
      addFieldValue("start", "");
      addFieldValue("end", "");
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    gymTrainer(branch_id);
    nameClients(branch_id);
    clientPkg(branch_id, trainer_id);
    if (trainer_id !== "0") {
      getTimeSolts(trainer_id);
    }
  }, []);

  // const handleStartChange = (value) => {
  //   const startDate = new Date(value);
  //   const endDate = new Date(
  //     startDate.getTime() + addPkgValues.duration * 24 * 60 * 60 * 1000
  //   );

  //   const year = endDate.getFullYear();
  //   const month = endDate.getMonth() + 1;
  //   const day = endDate.getDate();

  //   const formattedEndDate = `${year}-${month < 10 ? "0" : ""}${month}-${
  //     day < 10 ? "0" : ""
  //   }${day}`;
  //   addFieldValue("end", formattedEndDate);
  // };

  const checkAvaliability = async (time_id, sDate) => {
    if (time_id !== "" && sDate !== "") {
      try {
        setLoading(true);
        const response = await UserServices.getTrainerAvaliability(
          time_id,
          sDate
        );

        if (response.status === 200) {
          setLoading(false);
          // handleStartChange(sDate);
        }
      } catch (err) {
        setLoading(false);
        toast.error("Time slot unavailable", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        addFieldValue("time_id", "");
        addFieldValue("start", "");
        addFieldValue("end", "");
      }
    } else if (sDate !== "") {
      // handleStartChange(sDate);
    }
  };

  const timeSlotsAssign = async (addPkgValues, action) => {
    setDaysError(false);

    try {
      setLoading(true);
      const response = await UserServices.assignSlotToClient(
        addPkgValues,
        trainer_id === "0" ? "Befit" : "PT"
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        if (trainer_id === "0") {
          navigate("/new-befit-bookings");
        } else navigate("/new-pt-bookings");
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };

  const addPkginitialValues = {
    branch_id: branch_id,
    trainer_id: trainer_id === "0" ? "" : trainer_id,
    time_id: "",
    user_id: client_id,
    order_id: "",
    package_name: "",
    start: "",
    end: "",
    duration: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    setFieldValue: addFieldValue,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: assignTimeSlotToClient,
    onSubmit: (addPkgValues, action) => {
      timeSlotsAssign(addPkgValues, action);
    },
  });

  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Assign Time Slots</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form
                  className="row g-3"
                  onSubmit={(e) => {
                    addPkgHandleSubmit(e);
                  }}
                >
                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={addPkgValues.branch_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            // changeCategory(e);
                            addPkgValues.package_name = "";
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                        <p className="text-danger">{addPkgErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <label className="form-label">
                      Client Name<span className="text-danger">*</span>
                    </label>
                    {name ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={addPkgValues.user_id || ""}
                        disabled={true}
                      >
                        {name.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}

                    {addPkgErrors.user_id && addPkgTouched.user_id ? (
                      <p className="text-danger">{addPkgErrors.user_id}</p>
                    ) : null}
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group">
                      Available Trainers<span className="text-danger">*</span>
                      {trainerName ? (
                        <select
                          className="form-select"
                          name="trainer_id"
                          value={addPkgValues.trainer_id || ""}
                          disabled={trainer_id === "0" ? false : true}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            getTimeSolts(e.target.value);
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.trainer_id && addPkgTouched.trainer_id ? (
                        <p className="text-danger">{addPkgErrors.trainer_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="form-group custom-group add-time-slot-time">
                      Available Time Slots<span className="text-danger">*</span>
                      {timeSlot ? (
                        <select
                          className="form-select"
                          name="time_id"
                          value={addPkgValues.time_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);
                            checkAvaliability(
                              e.target.value,
                              addPkgValues.start
                            );
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {timeSlot.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.time}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.time_id && addPkgTouched.time_id ? (
                        <p className="text-danger">{addPkgErrors.time_id}</p>
                      ) : null}
                    </div>
                  </div>

                  {addPkgValues.user_id !== "" ? (
                    <>
                      <div className="col-12 col-lg-4">
                        <div className="form-group custom-group add-time-slot-time">
                          Client Packge<span className="text-danger">*</span>
                          <input
                            type="text"
                            className="form-control"
                            name="package_name"
                            value={addPkgValues.package_name || ""}
                            onChange={(e) => {
                              addPkgHandleChange(e);
                            }}
                            onBlur={addPkgHandleBlur}
                            disabled={true}
                          />
                          {addPkgErrors.order_id && addPkgTouched.order_id ? (
                            <p className="text-danger">
                              {addPkgErrors.order_id}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group custom-group add-time-slot-time">
                          Start Date <span className="text-danger">*</span>
                          <input
                            type="date"
                            className="form-control"
                            name="start"
                            value={addPkgValues.start || ""}
                            disabled={true}
                            onChange={(e) => {
                              addPkgHandleChange(e);
                              checkAvaliability(
                                addPkgValues.time_id,
                                e.target.value
                              );
                            }}
                            onBlur={addPkgHandleBlur}
                          />
                          {addPkgErrors.start && addPkgTouched.start ? (
                            <p className="text-danger">{addPkgErrors.start}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-lg-4">
                        <div className="form-group custom-group add-time-slot-time">
                          End Date <span className="text-danger">*</span>
                          <input
                            type="date"
                            className="form-control"
                            name="end"
                            value={addPkgValues.end || ""}
                            onChange={(e) => {
                              addPkgHandleChange(e);
                            }}
                            onBlur={addPkgHandleBlur}
                            disabled={true}
                          />
                          {addPkgErrors.end && addPkgTouched.end ? (
                            <p className="text-danger">{addPkgErrors.end}</p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Assign
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </main>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { addLeaveQuota } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import $ from "jquery";

export default function LeaveQuota() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [names, setNames] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  var clickEvent = $.Event("click");
  const [selectedBranches, setSelectedBranches] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [packageId, setPackageId] = useState("");
  const [selectedLeaves, setSelectedLeaves] = useState("");
  const [clientType, setClientType] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const closeButtonRef = useRef(null);

  const getPkgs = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getLeaveQuotaInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;
        // eslint-disable-next-line array-callback-return
        data.map((values) => {
          setSelectedBranches(values.branch_id);
          setSelectedUser(values.user_info.id);
          setSelectedType(values.leave_type);
          setPackageId(values.id);
          setSelectedLeaves(values.number_of_leaves);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const staffNames = async () => {
    try {
      const responce = await UserServices.staffName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ name: "Select Name", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getLeaveQuota(
        branch_id,
        newLimit,
        pageNo, 
        selectedOption,
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            leave_type: values.leave_type,
            number_of_leaves: values.number_of_leaves,
            user_info: values.user_info,
            branch_info: values.branch_info,
            status: values.status,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const changeStaffType = (e) => {
    setSelectedOption(e);
    let staffType = e;
    setClientType(staffType);
    const getStaffData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getLeaveQuota(
          branch_id,
          limit,
          "1",
          staffType,
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((values) => {
            results.push({
              id: values.id,
              leave_type: values.leave_type,
              number_of_leaves: values.number_of_leaves,
              user_info: values.user_info,
              branch_info: values.branch_info,
              status: values.status,
            });
            return null;
          });
  
          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getStaffData();
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    staffNames();
    getPkg(currentPage, limit);
  }, []);
  const makeActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.leaveQuotaActions(package_Id, "1");
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activePackage = (package_Id) => {
    makeActive(package_Id);
  };
  const makeInActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.leaveQuotaActions(package_Id, "0");
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactivePackage = (package_Id) => {
    makeInActive(package_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.leaveQuotaActions(id, "2");
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  const checkLeave = async (addPkgValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.checkQuotaLeave(addPkgValues);

      if (response.status === 200) {
        setLoading(false);
        addLeave(addPkgValues, action);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 409) {
        toast.error("Already Exist!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const addLeave = async (addPkgValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addQuotaLeave(addPkgValues);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const addPkginitialValues = {
    branch_id: branch_id,
    leave_type: "",
    user_id: "",
    number_of_leaves: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: addLeaveQuota,
    onSubmit: (addPkgValues, action) => {
      checkLeave(addPkgValues, action);
    },
  });
  const Table = ({ data }) => {
    const { items} = useSortableData(data);


    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Leave Quota"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Branch Name
              </th>
              <th scope="col" className="no_sort">
                Staff
              </th>
              <th scope="col" className="no_sort">
                Leave Type
              </th>
              <th scope="col" className="no_sort">
                No. of Leaves
              </th>
              <th scope="col" className="no_sort">
                Status
              </th>{" "}
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{el.branch_info.name}</td>
                  <td>
                    <Link to={"/staff-profile/" + el.user_info.id}>
                      {el.user_info.name}
                    </Link>
                  </td>
                  <td>{el.leave_type}</td>
                  <td>{el.number_of_leaves}</td>
                  <td>{el.status === "0" ? "Inactive" : "Active"}</td>
                  <td>
                    {el.status === "0" ? (
                      <button
                        onClick={() => {
                          getPkgs(el.id);
                        }}
                        className="updt-btn inactive-btn"
                        disabled={true}
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          getPkgs(el.id);
                        }}
                        className="updt-btn inactive-btn"
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </button>
                    )}
                    {el.status === "0" ? (
                      <button
                        type="button"
                        onClick={() => activePackage(el.id)}
                        className="ms-2 active-btn"
                      >
                        <i className="bi bi-check-circle text-success"></i>{" "}
                        <span className="text-success inative-text ms-1">
                          Active
                        </span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => inactivePackage(el.id)}
                        className="ms-2 inactive-btn"
                      >
                        <i className="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Inactive
                        </span>
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 && (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              setCurrentPage(e);
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        )}
      </>
    );
  };
  const updationCheck = async (values) => {
    try {
      setLoading(true);
      const response = await UserServices.checkQuotaLeaveUpdate(
        values,
        packageId
      );

      if (response.status === 200) {
        setLoading(false);
        UpdatePackage(values);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 409) {
        toast.error("Already Exist!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const UpdatePackage = async (values, action) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdateLeaveQuota(values, packageId);
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getPkg(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const initialValues = {
    branch_id: selectedBranches,
    user_id: selectedUser,
    leave_type: selectedType,
    number_of_leaves: selectedLeaves,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: addLeaveQuota,
      onSubmit: (values) => {
        updationCheck(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Leave Quota</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  addPkgHandleSubmit(e);
                }}
              >
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={addPkgValues.branch_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                      <p className="text-danger">{addPkgErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                     Staff Name <span className="text-danger">*</span>
                    </label>
                    {names ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={addPkgValues.user_id}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.user_id && addPkgTouched.user_id ? (
                      <p className="text-danger">{addPkgErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Leave Type<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="leave_type"
                      value={addPkgValues.leave_type || ""}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    >
                      <option value="">Select Leave Type</option>
                      <option value="Medical">Medical</option>
                      <option value="Casual">Casual</option>
                      <option value="Annual">Annual</option>
                    </select>
                    {addPkgErrors.leave_type && addPkgTouched.leave_type ? (
                      <p className="text-danger">{addPkgErrors.leave_type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      No. Of Leaves<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter No. Of Leaves"
                      name="number_of_leaves"
                      min={0}
                      value={addPkgValues.number_of_leaves}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.number_of_leaves &&
                    addPkgTouched.number_of_leaves ? (
                      <p className="text-danger">
                        {addPkgErrors.number_of_leaves}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="card">
            <div className="card-body">
              <div className="row">
              <div className="col-12 col-md-2 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label"> Select Staff</label>
                    {names ? (
                      <select
                        className="form-select"
                        onChange={(e) => changeStaffType(e.target.value)}
                        value={selectedOption}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-1 col-lg-1 mt-3 mb-3 ms-auto me-3">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getPkg("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Leave Quota
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={values.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectedBranches(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    {names ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={values.user_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.user_id && touched.user_id ? (
                      <p className="text-danger">{errors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Leave Type<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="leave_type"
                      value={values.leave_type || ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Leave Type</option>
                      <option value="Medical">Medical</option>
                      <option value="Casual">Casual</option>
                      <option value="Annual">Annual</option>
                    </select>
                    {errors.leave_type && touched.leave_type ? (
                      <p className="text-danger">{errors.leave_type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      No. Of Leaves<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter No. Of Leaves"
                      name="number_of_leaves"
                      min={0}
                      value={values.number_of_leaves}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.number_of_leaves && touched.number_of_leaves ? (
                      <p className="text-danger">{errors.number_of_leaves}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

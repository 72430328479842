import React, { useContext, useEffect, useState, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addStaffPromotion } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from "moment";
import { toast } from "react-toastify";
import $ from "jquery";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

export default function StaffPromotion() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [names, setNames] = useState([]);
  const [salary, setSalary] = useState("");
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [selectedDepartmentName, setSelectedDepartmentName] = useState("");
  const [selectedDesignationId, setSelectedDesignationId] = useState("");
  const [selectedDesignationName, setSelectedDesignationName] = useState("");
  const [myDepartment, setMyDepartment] = useState([]);
  const [myDesignation, setMyDesignation] = useState([]);
  const [staffCatagry, setStaffCatagry] = useState([]);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const staffNames = async () => {
    // setCurrentPage();
    try {
      const responce = await UserServices.staffName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ name: "Select Names", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const department = async () => {
    try {
      const responce = await UserServices.departmentName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDepartment([{ name: "Select Department", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setMyDepartment([]);
    }
  };
  const designation = async () => {
    try {
      const responce = await UserServices.designationName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDesignation([{ name: "Select Designation", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setMyDesignation([]);
    }
  };
  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const response = await UserServices.getActivePromotions(
        branch_id,
        newLimit,
        pageNo
      );
      let myDataarr;

      if (response.status === 200) {
        myDataarr = response.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch: values.branch,
            employee_id: values.employee_id,
            employee: values.employee,
            pervs_depart: values.pervs_depart,
            new_depart: values.new_depart,
            pervs_designation: values.pervs_designation,
            new_designation: values.new_designation,
            previous_salary: values.previous_salary,
            new_salary: values.new_salary,
            status: values.status,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(response.data.pagination.per_page);
          setTotalResults(response.data.pagination.total_record);
          setTotalPages(response.data.pagination.total_pages);
          setCurrentPage(response.data.pagination.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    staffNames();
    department();
    designation();
    getPkg(currentPage, limit);
  }, []);
  const getUsers = async (user_id) => {
    try {
      const responce = await UserServices.getStaffProfileInfo(user_id);
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((value) => {
          setSalary(value.salary);
          setSelectedDepartmentId(value.department_id);
          setSelectedDepartmentName(value.department);
          setSelectedDesignationName(value.designation);
          setSelectedDesignationId(value.designation_id);
          return null;
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Network Error") {
      } else if (err.response.status === 404) {
        setLoading(false);
        // navigate("/dashboard");
      } else {
        setLoading(false);
      }
    }
  };
  const staffCatagryGet = async (e) => {
    if (e.target.value === "1" || e.target.value === "2") {
      try {
        const responce = await UserServices.staffcatagries(branch_id);
        const res = responce.data.data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });

        setStaffCatagry([...results]);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const makeInActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.promotionsActions(package_Id, "0");
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactivePackage = (package_Id) => {
    makeInActive(package_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.promotionsActions(id, "2");
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  const makeActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.promotionsActions(package_Id, "1");
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activePackage = (package_Id) => {
    makeActive(package_Id);
  };
  const addPromotion = async (addPkgValues, action, catType) => {
    try {
      setLoading(true);
      const response = await UserServices.addStaffPromotion(
        addPkgValues,
        selectedDepartmentId,
        selectedDesignationId,
        salary,
        catType
      );

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Internal Server Error!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 400) {
        toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const addPkginitialValues = {
    branch_id: branch_id,
    user_id: "",
    previous_department: selectedDepartmentId,
    previous_designation: selectedDesignationId,
    new_department: "",
    new_designation: "",
    previous_salary: salary,
    new_salary: "",
    date: eDate,
    type: "",
    commission: "",
    promotion_type: "",
    details: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: addStaffPromotion,
    onSubmit: (addPkgValues, action) => {
      var selectedCat = $("input:checkbox:checked")
        .map(function () {
          return this.value;
        })
        .get()
        .join(",");
      addPromotion(addPkgValues, action, selectedCat);
    },
  });
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("Staff_Promotions.pdf");
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Active Promotions"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("employee")}
                className={getClassNamesFor("employee")}
              >
                Employee
              </th>
              <th
                scope="col"
                onClick={() => requestSort("pervs_depart")}
                className={getClassNamesFor("pervs_depart")}
              >
                Previous Department
              </th>
              <th
                scope="col"
                onClick={() => requestSort("new_depart")}
                className={getClassNamesFor("new_depart")}
              >
                New Department
              </th>
              <th
                scope="col"
                onClick={() => requestSort("pervs_designation")}
                className={getClassNamesFor("pervs_designation")}
              >
                Previous Designation
              </th>
              <th
                scope="col"
                onClick={() => requestSort("new_designation")}
                className={getClassNamesFor("new_designation")}
              >
                New Designation
              </th>
              <th
                scope="col"
                onClick={() => requestSort("previous_salary")}
                className={getClassNamesFor("previous_salary")}
              >
                Previous salary
              </th>
              <th
                scope="col"
                onClick={() => requestSort("new_salary")}
                className={getClassNamesFor("new_salary")}
              >
                New Salary
              </th>
              <th scope="col" className="no_sort">
                Status
              </th>{" "}
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{currentPage === 1
                              ? index + 1
                              : (currentPage - 1) * limit + index + 1}</td>
                  <td>{el.branch}</td>
                  <td>
                    <Link to={"/staff-profile/" + el.employee_id}>
                      {el.employee}
                    </Link>
                  </td>
                  <td>{el.pervs_depart ? el.pervs_depart : "N/A"}</td>
                  <td>{el.new_depart ? el.new_depart : "N/A"}</td>
                  <td>{el.pervs_designation ? el.pervs_designation : "N/A"}</td>
                  <td>{el.new_designation ? el.new_designation : "N/A"}</td>
                  <td>
                    <CurrencyFormat
                      value={el.previous_salary}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                  <td>
                    {" "}
                    <CurrencyFormat
                      value={el.new_salary}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3"}
                    />
                  </td>
                  <td>{el.status === "0" ? "Inactive" : "Active"}</td>
                  <td>
                    {/* {el.status === "0" ? (
                      <button
                        onClick={() => {
                          getPkgs(el.id);
                        }}
                        className="updt-btn inactive-btn"
                        disabled={true}
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          getPkgs(el.id);
                        }}
                        className="updt-btn inactive-btn"
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </button>
                    )} */}
                    {el.status === "0" ? (
                      <button
                        type="button"
                        onClick={() => activePackage(el.id)}
                        className="ms-2 active-btn"
                      >
                        <i className="bi bi-check-circle text-success"></i>{" "}
                        <span className="text-success inative-text ms-1">
                          Active
                        </span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => inactivePackage(el.id)}
                        className="ms-2 inactive-btn"
                      >
                        <i className="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Inactive
                        </span>
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 && (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              setCurrentPage(e);
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        )}
      </>
    );
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Add Staff Promotion</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  addPkgHandleSubmit(e);
                }}
              >
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={addPkgValues.branch_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                      <p className="text-danger">{addPkgErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    {names.length === 0 ? (
                      <SkeletonTheme height="40px">
                        <Skeleton count={1} />
                      </SkeletonTheme>
                    ) : (
                      <select
                        className="form-select"
                        name="user_id"
                        value={addPkgValues.user_id}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                          getUsers(e.target.value);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                    {addPkgErrors.user_id && addPkgTouched.user_id ? (
                      <p className="text-danger">{addPkgErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Promotion Type<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="promotion_type"
                      value={addPkgValues.promotion_type}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    >
                      <option value="">Select Promotion Type</option>
                      <option value="Department">Department</option>
                      <option value="Position">Position</option>
                      <option value="Salary">Salary</option>
                      <option value="All">All</option>
                    </select>
                    {addPkgErrors.promotion_type &&
                    addPkgTouched.promotion_type ? (
                      <p className="text-danger">
                        {addPkgErrors.promotion_type}
                      </p>
                    ) : null}
                  </div>
                </div>
                {addPkgValues.promotion_type === "Department" ||
                addPkgValues.promotion_type === "All" ? (
                  <>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Previous Department
                        </label>
                        <div className="form-group custom-group">
                          <input
                            type="text"
                            className="form-control"
                            name="previous_department"
                            autoComplete="off"
                            value={selectedDepartmentName}
                            onChange={async (e) => {
                              addPkgHandleChange(e);
                            }}
                            onBlur={addPkgHandleBlur}
                            disabled={true}
                          />
                        </div>
                        {addPkgErrors.previous_department &&
                        addPkgTouched.previous_department ? (
                          <p className="text-danger">
                            {addPkgErrors.previous_department}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Promoted Department
                        </label>
                        {myDepartment.length ? (
                          <select
                            className="form-select"
                            name="new_department"
                            value={addPkgValues.new_department}
                            onChange={addPkgHandleChange}
                            onBlur={addPkgHandleBlur}
                          >
                            {myDepartment.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            autoComplete="off"
                            name="new_department"
                            value={addPkgValues.new_department}
                            onChange={addPkgHandleChange}
                            onBlur={addPkgHandleBlur}
                          >
                            <option value="">Select Department</option>
                          </select>
                        )}
                        {addPkgErrors.new_department &&
                        addPkgTouched.new_department ? (
                          <p className="text-danger">
                            {addPkgErrors.new_department}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                {addPkgValues.promotion_type === "Position" ||
                addPkgValues.promotion_type === "All" ? (
                  <>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Previous Designation
                        </label>
                        <div className="form-group custom-group">
                          <input
                            type="text"
                            className="form-control"
                            name="previous_designation"
                            autoComplete="off"
                            value={selectedDesignationName}
                            onChange={async (e) => {
                              addPkgHandleChange(e);
                            }}
                            onBlur={addPkgHandleBlur}
                            disabled={true}
                          />
                        </div>
                        {addPkgErrors.previous_designation &&
                        addPkgTouched.previous_designation ? (
                          <p className="text-danger">
                            {addPkgErrors.previous_designation}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Promoted Designation
                        </label>
                        {myDesignation.length ? (
                          <select
                            className="form-select"
                            name="new_designation"
                            value={addPkgValues.new_designation}
                            onChange={(e) => {
                              addPkgHandleChange(e);
                              staffCatagryGet(e);
                            }}
                            onBlur={addPkgHandleBlur}
                          >
                            {myDesignation.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            name="new_designation"
                            value={addPkgValues.new_designation}
                            onChange={addPkgHandleChange}
                            onBlur={addPkgHandleBlur}
                          >
                            <option value="">Select Designation</option>
                          </select>
                        )}
                        {addPkgErrors.new_designation &&
                        addPkgTouched.new_designation ? (
                          <p className="text-danger">
                            {addPkgErrors.new_designation}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                {addPkgValues.promotion_type === "Salary" ||
                addPkgValues.promotion_type === "All" ? (
                  <>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Current Salary</label>
                        <div className="form-group custom-group">
                          <input
                            type="text"
                            className="form-control"
                            name="previous_salary"
                            autoComplete="off"
                            value={salary}
                            onChange={async (e) => {
                              addPkgHandleChange(e);
                            }}
                            onBlur={addPkgHandleBlur}
                            disabled={true}
                          />
                        </div>
                        {addPkgErrors.previous_salary &&
                        addPkgTouched.previous_salary ? (
                          <p className="text-danger">
                            {addPkgErrors.previous_salary}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Revised Salary</label>
                        <div className="form-group custom-group">
                          <input
                            type="number"
                            className="form-control"
                            name="new_salary"
                            autoComplete="off"
                            value={addPkgValues.new_salary}
                            onChange={async (e) => {
                              addPkgHandleChange(e);
                            }}
                            onBlur={addPkgHandleBlur}
                          />
                        </div>
                        {addPkgErrors.new_salary && addPkgTouched.new_salary ? (
                          <p className="text-danger">
                            {addPkgErrors.new_salary}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Details</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" Enter Details"
                          autoComplete="off"
                          name="details"
                          value={addPkgValues.details}
                          onChange={addPkgHandleChange}
                          onBlur={addPkgHandleBlur}
                        />
                        {addPkgErrors.details && addPkgTouched.details ? (
                          <p className="text-danger">{addPkgErrors.details}</p>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                {addPkgValues.new_designation === "1" ||
                addPkgValues.new_designation === "2" ? (
                  <>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Commission</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Commission "
                          autoComplete="off"
                          name="commission"
                          value={addPkgValues.commission}
                          onChange={addPkgHandleChange}
                          onBlur={addPkgHandleBlur}
                        />
                        {addPkgErrors.commission && addPkgTouched.commission ? (
                          <p className="text-danger">
                            {addPkgErrors.commission}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {staffCatagry.length ? (
                      <div className="col-md-6 col-lg-4 ps-lg-5 ">
                        <label className="form-label">Package Category</label>
                        <div className="custom-raio">
                          <ul className="cat-type">
                            {staffCatagry.map((el, index) => (
                              <li key={index}>
                                <input
                                  type="checkbox"
                                  id={el.id}
                                  value={el.id}
                                  className="option[17]"
                                  defaultChecked={true}
                                />
                                <label htmlFor={el.id}>{el.name}</label>
                                <div className="check" />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={addPkgValues.date}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />
                  </div>
                  {addPkgErrors.date && addPkgTouched.date ? (
                    <p className="text-danger">{addPkgErrors.date}</p>
                  ) : null}
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <button
            className="btn btn-primary mx-2 file-download float-end mb-3"
            onClick={() => {
              generatePdf(document.getElementById("report-POS"));
            }}
          >
            <i className="bi bi-file-earmark-pdf"></i> PDF
          </button>
          <div className="clearfix"></div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Active Promotions</h5>
            </div>
            <div className="card-body">
              <div className="row">
                {/* <div className="col-md-2 col-lg-2 ms-2 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Search By Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      onChange={(e) => {
                        addPkgHandleChange(e);
                        // searchPkg(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    />
                  </div>
                </div> */}
                <div className="col-md-1 col-lg-1 mt-2 ms-auto me-3 mb-3">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getPkg("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

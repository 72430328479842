import axios from "axios";
import authHeader from "./auth-header";
import authHeaderImage from "./auth-header-image";
const API_URL = process.env.REACT_APP_API_Link;

const Login = (values) => {
  return axios.post(API_URL + "/auth/login", {
    email: values.email,
    password: values.password,
  });
};

const addStaff = (fileUp) => {
  return axios.post(API_URL + "/auth/register", fileUp, {
    headers: authHeaderImage(),
  });
};

const getactiveUsers = (
  bId,
  status,
  limitNo,
  pageNo,
  selectedOption,
  selectedDepartment
) => {
  return axios.get(
    API_URL + "/auth/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        department_id: selectedDepartment,
        gender: selectedOption,
      },
      headers: authHeader(),
    }
  );
};
const staffNameSearch = (srch, bId, status, limit, pageNo) => {
  return axios.get(API_URL + "/auth/get?name=" + srch, {
    params: {
      branch_id: bId,
      status: status,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getStaffDataByType = (status, limitNo, pageNo, staffType, bId) => {
  return axios.get(API_URL + "/auth/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
      gender: staffType,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const getStaffDataByDepartment = (status, limitNo, pageNo, department, bId) => {
  return axios.get(API_URL + "/auth/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
      department_id: department,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};

const deleteStaff = (user_id) => {
  return axios.put(
    API_URL + "/auth/delete/" + user_id,
    {},
    { headers: authHeader() }
  );
};
const inactiveStaff = (client_id) => {
  return axios.put(
    API_URL + "/auth/inactive/" + client_id,
    {},
    { headers: authHeader() }
  );
};
const activeStaff = (client_Id) => {
  return axios.put(
    API_URL + "/auth/active/" + client_Id,
    {},
    { headers: authHeader() }
  );
};
const departmentName = () => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      type: "Department",
    },
    headers: authHeader(),
  });
};
const paymentType = (value) => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      branch_id: value,
      type: "PaymentMethod",
    },
    headers: authHeader(),
  });
};
const expenseType = (value) => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      branch_id: value,
      type: "Expense",
    },
    headers: authHeader(),
  });
};
const searchExpenseType = () => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      branch_id: "1",
      type: "Expense",
    },
    headers: authHeader(),
  });
};

const designationName = () => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      type: "Designations",
    },
    headers: authHeader(),
  });
};
const branchName = () => {
  return axios.get(API_URL + "/branches/branches-name-list", {
    params: {
      status: "1",
    },
    headers: authHeader(),
  });
};

const categoriesName = () => {
  return axios.get(API_URL + "/category-types", {
    headers: authHeader(),
  });
};

const paymentNames = () => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      type: "PaymentMethod",
    },
    headers: authHeader(),
  });
};

const membershipType = (bId, catagry) => {
  return axios.get(API_URL + "/packages/get-Registration-Category", {
    params: {
      branch_id: bId,
      status: "1",
      category_id: catagry,
    },
    headers: authHeader(),
  });
};

const dynamicRegNo = (bId) => {
  return axios.get(API_URL + "/clients/registration-number/" + bId, {
    // params: {
    //   branch_id: bId,
    //   status: "1",
    //   category_id: catagry,
    // },
    headers: authHeader(),
  });
};

const membershipFee = (mem_type) => {
  return axios.get(API_URL + "/membership-types/price/" + mem_type, {
    // params: {
    //   branch_id: bId,
    //   status: "1",
    // },
    headers: authHeader(),
  });
};
const pkgFee = (pkg_type, sdate, qty) => {
  return axios.get(API_URL + "/packages/fetch-package-info", {
    params: {
      id: pkg_type,
      startDate: sdate,
      quantity: qty,
    },
    headers: authHeader(),
  });
};

const cafePkgFee = (pkg_type) => {
  return axios.get(API_URL + "/packages/fetch-package-info", {
    params: {
      id: pkg_type,
    },
    headers: authHeader(),
  });
};
const validityDates = (validity, sdate) => {
  return axios.get(API_URL + "/packages/fetch-package-info", {
    params: {
      validity: validity,
      startDate: sdate,
    },
    headers: authHeader(),
  });
};

const gymPkgList = (bId, catagry, mem_type) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      branch_id: bId,
      category: catagry,
      membership_id: mem_type,
      status: "1",
    },
    headers: authHeader(),
  });
};
const cafePkgList = (bId, catagry, mem_type) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      branch_id: bId,
      category: catagry,
      cafe_category_id: mem_type,
      status: "1",
    },
    headers: authHeader(),
  });
};
const timeSlot = (bId, pt_pkg) => {
  return axios.get(API_URL + "/packages/small-group-pt-packages/" + pt_pkg, {
    // params: {
    //   branch_id: bId,
    //   pt_group: pt_group,
    // },
    headers: authHeader(),
  });
};
const ptGroupPkgs = (bId, catagry, grpID) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      branch_id: bId,
      category: catagry,
      group: grpID,
      status: "1",
    },
    headers: authHeader(),
  });
};

const registrationPkgs = (bId, catagry, tr_id) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      branch_id: bId,
      category: catagry,
      status: "1",
      user_id: tr_id ? tr_id : "",
    },
    headers: authHeader(),
  });
};

const trainerPkgTypesList = (bId, user_id, catagry, trainer_cat) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      branch_id: bId,
      user_id: user_id,
      category: catagry,
      membership_id: trainer_cat,
      status: "1",
    },
    headers: authHeader(),
  });
};

const trainerPkgList = (tr_id) => {
  return axios.get(API_URL + "/training-categories/get", {
    params: {
      user_id: tr_id,
      status: "1",
    },
    headers: authHeader(),
  });
};
const trainerTimeList = (tr_id) => {
  return axios.get(
    API_URL + "/fitness/time-slot-assignment/trainer-availability/" + tr_id,
    {
      headers: authHeader(),
    }
  );
};

const staffcatagries = (bId) => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      branch_id: bId,
      type: "TrainerPackageType",
    },
    headers: authHeader(),
  });
};

const checkEmail = (check_Email) => {
  return axios.get(API_URL + "/auth/unique-email/" + check_Email, {
    headers: authHeader(),
  });
};

const checkEmailwId = (email, id) => {
  return axios.get(`${API_URL}/auth/unique-email/${email}/${id}`, {
    headers: authHeader(),
  });
};

const checkPhone = (check_Phone, id) => {
  return axios.get(
    API_URL + "/clients/unique-phone/" + check_Phone + "/" + id,
    {
      headers: authHeader(),
    }
  );
};
const checkStaffPhone = (check_Phone, id) => {
  return axios.get(API_URL + "/auth/unique-phone/" + check_Phone + "/" + id, {
    headers: authHeader(),
  });
};
const checkAuthPhone = (check_Phone) => {
  return axios.get(API_URL + "/auth/unique-phone/" + check_Phone, {
    headers: authHeader(),
  });
};
const checkCard = (check_Card) => {
  return axios.get(API_URL + "/cards/unique-card/" + check_Card, {
    headers: authHeader(),
  });
};
const checkCnic = (check_Cnic, id) => {
  return axios.get(API_URL + "/clients/unique-cnic/" + check_Cnic + "/" + id, {
    headers: authHeader(),
  });
};
const checkStaffCnic = (check_Cnic, id) => {
  return axios.get(API_URL + "/clients/unique-cnic/" + check_Cnic + "/" + id, {
    headers: authHeader(),
  });
};

const addCheckPhone = (check_Phone) => {
  return axios.get(API_URL + "/clients/unique-phone/" + check_Phone, {
    headers: authHeader(),
  });
};

const addCheckCnic = (check_Cnic) => {
  return axios.get(API_URL + "/clients/unique-cnic/" + check_Cnic, {
    headers: authHeader(),
  });
};
const addStaffCheckCnic = (check_Cnic) => {
  return axios.get(API_URL + "/auth/unique-cnic/" + check_Cnic, {
    headers: authHeader(),
  });
};

const addClients = (fileUp) => {
  return axios.post(API_URL + "/clients/add", fileUp, {
    headers: authHeaderImage(),
  });
};
const getClients = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/clients/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getCafeClient = (bId, status) => {
  return axios.get(API_URL + "/clients/get", {
    params: {
      branch_id: bId,
      status: status,
      type: "Cafe",
    },
    headers: authHeader(),
  });
};
const getCafeClientDeposit = (bId) => {
  return axios.get(API_URL + "/client-balance/get", {
    params: {
      branch_id: bId,
      limit: 99999999999999999,
    },
    headers: authHeader(),
  });
};
const getclientCatagriesList = (id) => {
  return axios.get(API_URL + "/cart/list-package-categories/" + id, {
    headers: authHeader(),
  });
};

const getClientsCart = (id) => {
  return axios.get(API_URL + "/cart/get", {
    params: {
      client_id: id,
    },
    headers: authHeader(),
  });
};

const getTaxPercent = (bId, type, status) => {
  return axios.get(API_URL + "/finance/setting/get-current-charges", {
    params: {
      type: type,
      branch_id: bId,
      status: status,
    },
    headers: authHeader(),
  });
};

const getCafeCart = (bId) => {
  return axios.get(API_URL + "/cart/get", {
    params: {
      branch_id: bId,
      category: "10",
    },
    headers: authHeader(),
  });
};

const updateCafeCart = (id, qty, price) => {
  return axios.put(
    API_URL + "/cart/cart-update/" + id,
    {
      quantity: qty,
      price: price * qty,
    },
    { headers: authHeader() }
  );
};

const getClientsPurchases = (Uid, limit, pageNo, startDate, endDate) => {
  return axios.get(API_URL + "/orders/get", {
    params: {
      client_id: Uid,
      limit: limit,
      page: pageNo,
      start_date: startDate,
      end_date: endDate,
    },
    headers: authHeader(),
  });
};
const getClientsPurchasesinfo = (id) => {
  return axios.get(API_URL + "/orders/get/" + id, {
    // params: {
    //   client_id: Uid,
    //   limit: limit,
    //   page: pageNo,
    //   start_date: startDate,
    //   end_date: endDate,
    // },
    headers: authHeader(),
  });
};
const getClientsPurchasesForInvoice = (Or_Id) => {
  return axios.get(API_URL + "/orders/get/" + Or_Id, {
    params: {},
    headers: authHeader(),
  });
};

const getPaymentsDataForInvoice = (Or_Id) => {
  return axios.get(API_URL + "/payments-history/get/" + Or_Id, {
    params: {},
    headers: authHeader(),
  });
};

const getClientsAccountsForInvoicePurchases = (Or_Id, catagry) => {
  return axios.get(API_URL + "/orders-detail/get", {
    params: {
      order_id: Or_Id,
      category: catagry ? catagry : "",
    },
    headers: authHeader(),
  });
};

const getClientsAccountsForInvoice = (Or_Id) => {
  return axios.get(API_URL + "/orders-detail/get/" + Or_Id, {
    params: {
      // order_id: Or_Id,
    },
    headers: authHeader(),
  });
};

const getBranchesForInvoice = (br_id) => {
  return axios.get(API_URL + "/branches/get/" + br_id, {
    headers: authHeader(),
  });
};

const getClientsAccounts = (
  Uid,
  status,
  //  startDate, endDate,
  limit,
  pageNo
) => {
  return axios.get(API_URL + "/orders-detail/get", {
    params: {
      client_id: Uid,
      status: status,
      // start_date: startDate,
      // end_date: endDate,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};

const paymentPendAdd = (id, bId, values) => {
  return axios.post(
    API_URL + "/payments-history/add",
    {
      branch_id: bId,
      order_id: id,
      payment_method_id: values.pay_met,
      pending: values.payment_pend,
      received: values.payment_rec,
      receiving_date: values.recieving_date,
      cheque_number: values.cheque_no,
      payment_type: "later",
    },
    { headers: authHeader() }
  );
};

const orderDateUpdate = (id, values) => {
  return axios.put(
    API_URL + "/orders-detail/update/" + id,
    {
      start_date: values.sDate,
      end_date: values.eDate,
      // sale_date: values.sale_date,
    },
    { headers: authHeader() }
  );
};

const getClientsPayments = (Uid, limit, pageNo, startDate, endDate) => {
  return axios.get(API_URL + "/payments-history/get", {
    params: {
      client_id: Uid,
      limit: limit,
      page: pageNo,
      start_date: startDate,
      end_date: endDate,
    },
    headers: authHeader(),
  });
};

const getOrderDates = (id) => {
  return axios.get(API_URL + "/orders-detail/package-dates/" + id, {
    params: {},
    headers: authHeader(),
  });
};

const getOrderPayments = (id) => {
  return axios.get(API_URL + "/payments-history/last-transaction/" + id, {
    params: {},
    headers: authHeader(),
  });
};

const getClientDataByType = (status, limitNo, pageNo, clinetType, bId) => {
  return axios.get(API_URL + "/clients/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
      gender: clinetType,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};

const deleteClient = (user_id) => {
  return axios.put(
    API_URL + "/clients/delete/" + user_id,
    {},
    { headers: authHeader() }
  );
};

const deleteClientCartItem = (user_id) => {
  return axios.delete(API_URL + "/cart/delete/" + user_id, {
    headers: authHeader(),
  });
};

const cartDestroy = (user_id) => {
  return axios.delete(
    API_URL + "/cart/destroy/" + user_id,

    { headers: authHeader() }
  );
};

// /clients/delete/4

const getinactiveClients = (bId) => {
  return axios.get(API_URL + "/clients/get", {
    params: {
      branch_id: bId,
      status: "0",
    },
    headers: authHeader(),
  });
};
const inactiveClients = (user_id) => {
  return axios.put(
    API_URL + "/clients/inactive/" + user_id,
    {},
    { headers: authHeader() }
  );
};
const activeClients = (user_id) => {
  return axios.put(
    API_URL + "/clients/active/" + user_id,
    {},
    { headers: authHeader() }
  );
};
const clientCount = (bId) => {
  return axios.get(API_URL + "/clients/count", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};

const staffCount = (bId) => {
  return axios.get(API_URL + "/auth/count", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const packageCount = (bId) => {
  return axios.get(API_URL + "/packages/count", {
    params: {
      branch_id: bId,
      category: "1",
    },
    headers: authHeader(),
  });
};
const sellpackageCount = (bId) => {
  return axios.get(API_URL + "/orders-detail/count", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const expensesCount = (bId) => {
  return axios.get(API_URL + "/expense/count", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const getClientProfileInfo = (id) => {
  return axios.get(API_URL + "/clients/get/" + id, {
    headers: authHeader(),
  });
};
const UpdateClientProfile = (fileUp, id) => {
  return axios.post(API_URL + "/clients/update/" + id, fileUp, {
    headers: authHeaderImage(),
  });
};

const getStartEndDates = (param) => {
  return axios.get(API_URL + "/get-dates/" + param, {
    headers: authHeader(),
  });
};

const gymPkgSell = (bId, cId, eDate, priceFul, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      start_date: values.sdate,
      end_date: eDate,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.gymPkgType,
      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};
const physioPkgSell = (bId, cId, eDate, priceFul, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      start_date: values.sdate,
      end_date: eDate,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.memType,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};
const cafePkgSell = (bId, cId, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      price: values.price,
      discount: 0,
      quantity: values.quantity,
      net_price: values.price,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.cafeProductType,
    },
    {
      headers: authHeader(),
    }
  );
};
const trainerPkgSell = (bId, cId, eDate, priceFul, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      start_date: values.sdate,
      end_date: eDate,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.pkgType,
      quantity: values.qty,

      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};

const registerPkgSell = (bId, cId, priceFul, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.memType,
      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
      // start_date: values.start_date,
      // end_date: values.end_date
    },
    {
      headers: authHeader(),
    }
  );
};
const depositPkgSell = (bId, cId, priceFul, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      price: priceFul,
      discount_type: "amount",
      discount: "0",
      net_price: priceFul,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.memType,
      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      // discount_approve_by: values.approveBy,
      // reference_by: values.referencedBy,
      // start_date: values.start_date,
      // end_date: values.end_date
    },
    {
      headers: authHeader(),
    }
  );
};
const genralPkgSell = (bId, cId, priceFul, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.memType,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
      // start_date: values.start_date,
      // end_date: values.end_date
    },
    {
      headers: authHeader(),
    }
  );
};
const CFTpkgSell = (bId, cId, eDate, priceFul, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      start_date: values.sdate,
      end_date: eDate,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: values.memType,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
      // start_date: values.start_date,
      // end_date: values.end_date
    },
    {
      headers: authHeader(),
    }
  );
};
const sessionPkgSell = (
  bId,
  cId,
  priceFul,
  eDate,
  duration,
  catgry,
  values
) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      price: priceFul,
      start_date: values.sdate,
      end_date: eDate,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      duration: duration,
      package_id: values.memType,
      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};

const checkOutOrder = (
  bId,
  cId,
  total_price,
  total_discount,
  net_total,
  values,
  type,
  tax
) => {
  return axios.post(
    API_URL + "/orders/add",
    {
      branch_id: bId,
      client_id: cId,
      price: total_price,
      discount: total_discount,
      net_price: net_total,
      payment_method_id: values.pay_met + "," + values.split_pay,
      payment_received: values.payment_rec + "," + values.payment_split,
      pending_payment: values.payment_pend + "," + values.payment_split_pend,
      cheque_number: values.cheque_no + "," + values.split_cheque_no,
      sale_type: type,
      tax: tax,
    },
    {
      headers: authHeader(),
    }
  );
};

const guestPassSell = (bId, cId, priceFul, eDate, catgry, values, PkgId) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      start_date: values.sdate,
      end_date: eDate,
      package_id: PkgId,

      // validilty: values.Validilty,
      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};

const smallGroupSell = (bId, cId, eDate, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,

      discount_type: values.discount_type,
      discount: values.discount_amount,
      price: values.total_fee,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      start_date: values.sdate,
      end_date: eDate,
      package_id: values.time_slot,
      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};

const NutritionistPkgSell = (bId, cId, priceFul, eDate, catgry, values) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      price: priceFul,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      start_date: values.sdate,
      end_date: eDate,
      package_id: values.pkgType,
      // payment_method_id: values.pay_met + "," + values.split_pay,
      // payment_received: values.payment_rec + "," + values.payment_split,
      // pending_payment: values.payment_pend + "," + values.payment_split_pend,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};

const getClientReport = (bId, values, limit, pageNo) => {
  return axios.get(API_URL + "/clients/get", {
    params: {
      status: values.status,
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.report,
      membership_type_id: values.memType,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};

const getDetailSaleReport = (bId, values) => {
  return axios.get(API_URL + "/orders-detail/detail-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.report,
      package_id: values.revenueCat,
      category: values.package_type,
    },
    headers: authHeader(),
  });
};
const getCafeSaleReport = (bId, values, ctgry) => {
  return axios.get(API_URL + "/orders-detail/detail-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      // gender: values.report,
      package_id: values.revenueCat,
      category: ctgry,
    },
    headers: authHeader(),
  });
};
const getSummerySaleReport = (bId, values) => {
  return axios.get(API_URL + "/orders-detail/summery-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.report,
      package_id: values.revenueCat,
      category: values.package_type,
    },
    headers: authHeader(),
  });
};
const getSummeryCafeSaleReport = (bId, values, ctgry) => {
  return axios.get(API_URL + "/orders-detail/summery-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      // gender: values.report,
      package_id: values.revenueCat,
      category: ctgry,
    },
    headers: authHeader(),
  });
};
const getSummerySaleReportAll = (bId, values) => {
  return axios.get(API_URL + "/orders/summery-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      payment_method_id: values.pay_met,
      category: values.package_type,
    },
    headers: authHeader(),
  });
};

const getSummerySaleReportAllNew = (bId, values) => {
  return axios.get(API_URL + "/summary", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      payment_method_id: values.pay_met,
      category: values.package_type,
    },
    headers: authHeader(),
  });
};

const getDetailSaleReportAll = (bId, values) => {
  return axios.get(API_URL + "/orders/detail-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      payment_method_id: values.pay_met,
      category: values.package_type,
    },
    headers: authHeader(),
  });
};

const getDetailSaleReportAllNew = (bId, values) => {
  return axios.get(API_URL + "/detail", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      payment_method_id: values.pay_met,
      category: values.package_type,
    },
    headers: authHeader(),
  });
};
const getDetailSaleReportBootcamp = (bId, values) => {
  return axios.get(API_URL + "/session-detail-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      payment_method_id: values.pay_met,
      category: values.package_type,
    },
    headers: authHeader(),
  });
};

const getDetailedTransactionReport = (bId, values, client_id) => {
  return axios.get(API_URL + "/orders/transaction-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      client_id: client_id,
    },
    headers: authHeader(),
  });
};
const getTransactionReport = (bId, values) => {
  return axios.get(API_URL + "/transaction-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
    },
    headers: authHeader(),
  });
};

const getAssessmentDetailsById = (id) => {
  return axios.get(API_URL + "/pre-assessment/get", {
    params: {
      client_id: id,
    },
    headers: authHeader(),
  });
};

const getMeasurementsById = (id) => {
  return axios.get(API_URL + "/post-assessment/get/" + id, {
    // params: {
    //   client_id: id,
    // },
    headers: authHeader(),
  });
};

const RevenueCategoryNames = (id, branch_id) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      category: id,
      status: "1",
      branch_id: branch_id,
    },
    headers: authHeader(),
  });
};
const RevenueCafeCategoryNames = (id, branch_id) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      category: id,
      status: "1",
      branch_id: branch_id,
    },
    headers: authHeader(),
  });
};
const clientPreAssessment = (
  values,
  goals,
  cId,
  heartCond,
  cheastPain,
  lightHeated,
  injuries,
  medicalSupervision,
  pregnant,
  takingMedicine,
  smokingHabit,
  healthClubMember,
  havePersonalTrainer,
  exerciseDays,
  pastPain,
  currentMedicine,
  triedPlan,
  gymMember,
  followingDiet,
  currentSupliments,
  trainingType
) => {
  const convertTime = (input) => {
    const [hours, minutes] = input.split(":").map(Number);
    let formattedHours = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";

    formattedHours =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };
  return axios.post(
    API_URL + "/pre-assessment/add",
    {
      client_id: cId,
      branch_id: values.branch_id,
      other_reason: values.reason,
      pregnant: pregnant,
      medical_supervision: medicalSupervision,
      exercise_days: exerciseDays,
      smoking_habit: smokingHabit,
      health_club_member: healthClubMember,
      have_personal_trainer: havePersonalTrainer,
      fitness_goals: goals,
      taking_medicine: takingMedicine,
      doctor_recommended_activity: heartCond,
      chest_pain_during_activity: cheastPain,
      balance_issues: lightHeated,
      bone_joint_problem: injuries,
      chest_pain_past_month: pastPain,
      medication_prescribed: currentMedicine,
      tried_diet_plan: triedPlan,
      gym_member: gymMember,
      following_diet: followingDiet,
      have_training: trainingType,
      medication_supplement_use: currentSupliments,
      breakfast: values.specBreakfast,
      breakfast_time: convertTime(values.timeBreakfast),
      lunch: values.specLunch,
      lunch_time: convertTime(values.timeLunch),
      dinner: values.specDinner,
      dinner_time: convertTime(values.timeDinner),
      snack: values.specSnack,
      snack_time: convertTime(values.timeSnack),
      munching: values.specMunching,
      munching_time: convertTime(values.timeMunching),
      water_intake: values.waterIntake,
      favorite_foods: values.foodPrefer,
      allergic_foods: values.foodAllergies,
      disliked_foods: values.foodNotPrefer,
    },
    {
      headers: authHeader(),
    }
  );
};

const addPostAssessment = (values, id) => {
  return axios.post(
    API_URL + "/post-assessment/add",
    {
      client_id: id,
      weight: values.Weight,
      date: values.date,
      branch_id: values.branch_id,
      height: values.Height,
      age: values.Age,
      chest: values.Chest,
      arm: values.Arm,
      waist: values.Waist,
      gluts: values.Glutes,
      thigh: values.Thigh,
      body_mass_index: values.BMI,
      fat: values.Fat,
      rhr: values.Rhr,
      mhr: values.Mhr,
      whr: values.Whr,
      blood_pressure: values.Bp,
      v_fat: values.Vfat,
      max_push_ups: values.max_push_ups,
      max_push_ups_one_min: values.max_push_ups_one_min,
      one_rm_squats: values.one_rm_squat,
      one_rm_bench_press: values.one_rm_bench_press,
      fit_and_reach: values.fit_and_reach,
    },
    {
      headers: authHeader(),
    }
  );
};

const staffName = (bId) => {
  return axios.get(API_URL + "/auth/get-name", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const filteredStaffName = (bId, id) => {
  return axios.get(API_URL + "/auth/get-name", {
    params: {
      branch_id: bId,
      user_id: id,
    },
    headers: authHeader(),
  });
};
const getProfInfo = (id) => {
  return axios.get(API_URL + "/auth/get/" + id, {
    headers: authHeader(),
  });
};
const getStaffProfileInfo = (id) => {
  return axios.get(API_URL + "/auth/get/" + id, {
    headers: authHeader(),
  });
};
const UpdateStaffProfile = (fileUp, id) => {
  return axios.post(API_URL + "/auth/update/" + id, fileUp, {
    headers: authHeaderImage(),
  });
};
const updateProfInfo = (values, id, bId) => {
  return axios.post(
    API_URL + "/auth/update/" + id,
    {
      first_name: values.name,
      last_name: values.last_name,
      email: values.email,
      branch_id: bId,
      // password: values.password,
    },
    {
      headers: authHeader(),
    }
  );
};

const addStaffFines = (bId, values, Fine) => {
  return axios.post(
    API_URL + "/users-finance/add",

    {
      branch_id: bId,
      occurrence_date: values.Occurrence_date,
      amount: values.amount,
      reason: values.reason,
      user_id: values.user_id,
      return_month: values.return_date,
      category: Fine,
    },
    {
      headers: authHeader(),
    }
  );
};
const addStaffAdvances = (bId, values, Advance) => {
  return axios.post(
    API_URL + "/users-finance/add",

    {
      branch_id: bId,
      occurrence_date: values.occurrence_date,
      amount: values.amount,
      transaction_type: values.transaction_type,
      payment_type_id: values.payment_type_id,
      reason: values.reason,
      user_id: values.user_id,
      category: Advance,
      cheque_no: values.cheque_no,
      return_month: values.return_month,
      bank_account_id: values.bank_account_id,
    },
    {
      headers: authHeader(),
    }
  );
};
const addStaffLoan = (bId, values) => {
  return axios.post(
    API_URL + "/staff-loans/add",

    {
      branch_id: bId,
      staff_id: values.staff_id,
      amount: values.amount,
      transaction_type: values.transaction_type,
      payment_type_id: values.payment_type_id,
      term: values.term,
      date: values.date,
      return_start_date: values.return_start_date,
      reason: values.reason,
      cheque_no: values.cheque_no,
      bank_account_id: values.bank_account_id,
    },
    {
      headers: authHeader(),
    }
  );
};

const overAllClientSearch = (bid, inputText) => {
  return axios.get(API_URL + "/clients/search-clients", {
    params: {
      branch_id: bid,
      name: inputText,
    },
    headers: authHeader(),
  });
};
const getClientName = (bid, id) => {
  return axios.get(API_URL + "/clients/search-clients/" + id, {
    params: {
      branch_id: bid,
    },
    headers: authHeader(),
  });
};

const clientSearch = (values, bid, status, limit, pageNo) => {
  let searchBy;
  if (values.bySearch === "1") {
    searchBy = "id";
  } else if (values.bySearch === "2") {
    searchBy = "membership_no";
  } else if (values.bySearch === "3") {
    searchBy = "name";
  }
  return axios.get(
    API_URL + "/clients/search-clients?" + searchBy + "=" + values.searchVal,
    {
      params: {
        branch_id: bid,
        status: status,
        limit: limit,
        page: pageNo,
      },
      headers: authHeader(),
    }
  );
};
const staffSearch = (values, bid, status, limit, pageNo) => {
  let search;
  if (values.bySearch === "1") {
    search = "id";
  } else if (values.bySearch === "2") {
    search = "membership_no";
  } else if (values.bySearch === "3") {
    search = "staff_name";
  }
  console.log(search);
  return axios.get(
    API_URL + "/auth/search?" + search + "=" + values.searchVal,
    {
      params: {
        branch_id: bid,
        status: status,
        limit: limit,
        page: pageNo,
      },
      headers: authHeader(),
    }
  );
};
const getClientsName = (bid) => {
  return axios.get(API_URL + "/clients/client-name", {
    params: {
      branch_id: bid,
    },
    headers: authHeader(),
  });
};
const getStaffName = (bid) => {
  return axios.get(API_URL + "/auth/fetch-name-list/" + bid, {
    params: {
      // branch_id: bid,
    },
    headers: authHeader(),
  });
};
const getClientsNameById = (bid, cid) => {
  return axios.get(API_URL + "/clients/client-name", {
    params: {
      branch_id: bid,
      id: cid,
    },
    headers: authHeader(),
  });
};

const getAssessedClient = (cid) => {
  return axios.get(API_URL + "/pre-assessment/exists/" + cid, {
    headers: authHeader(),
  });
};

const getClientActiveGymPkg = (cid) => {
  return axios.get(API_URL + "/orders-detail/have-active-gym-package/" + cid, {
    headers: authHeader(),
  });
};

const getClientActiveRegistration = (cid) => {
  return axios.get(API_URL + "/orders-detail/client_is_registered/" + cid, {
    headers: authHeader(),
  });
};

const getStaffFines = (bId, status, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/users-finance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
      category: "Fine",
    },
    headers: authHeader(),
  });
};
const getProfileStaffFines = (
  id,
  bId,
  status,
  limitNo,
  pageNo,
  values,
  ctgry
) => {
  return axios.get(API_URL + "/users-finance/get?page=" + pageNo, {
    params: {
      user_id: id,
      branch_id: bId,
      status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
      category: ctgry,
    },
    headers: authHeader(),
  });
};

const getStaffFinesDataByYear = (bId, status, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/users-finance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
      category: "Fine",
    },
    headers: authHeader(),
  });
};
const getStaffProfileFinesDataByYear = (
  user_id,
  bId,
  status,
  limitNo,
  pageNo,
  values,
  ctgry
) => {
  return axios.get(API_URL + "/users-finance/get?page=" + pageNo, {
    params: {
      user_id: user_id,
      branch_id: bId,
      status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
      category: ctgry,
    },
    headers: authHeader(),
  });
};
const getStaffAdvanceDataByYear = (bId, status, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/users-finance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
      category: "Advance",
    },
    headers: authHeader(),
  });
};
const getStaffLoanDataByYear = (bId, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/staff-loans/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      // status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
      // category: "Loan",
    },
    headers: authHeader(),
  });
};
const getStaffAdvanceDataByTime = (bId, status, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/users-finance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
      category: "Advance",
    },
    headers: authHeader(),
  });
};
const getStaffFinesDataByTime = (
  bId,
  // status,
  limitNo,
  pageNo,
  sdate,
  edate
) => {
  return axios.get(API_URL + "/users-finance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      // status: status,
      limit: limitNo,
      start_date: sdate,
      end_date: edate,
      category: "Fine",
    },
    headers: authHeader(),
  });
};
const getStaffLoanDataByTime = (
  bId,
  // status,
  limitNo,
  pageNo,
  sdate,
  edate
) => {
  return axios.get(API_URL + "/staff-loans/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      // status: status,
      limit: limitNo,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const getStaffAdvances = (bId, values) => {
  return axios.get(API_URL + "/users-finance/get", {
    params: {
      branch_id: bId,
      category: "Advance",
      start_date: values.start_date,
      end_date: values.end_date,
    },
    headers: authHeader(),
  });
};
const getStaffLoans = (bId, status, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/staff-loans/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
    },
    headers: authHeader(),
  });
};
const getStaffLoansInfo = (id) => {
  return axios.get(API_URL + "/staff-loans/get/" + id, {
    headers: authHeader(),
  });
};
const getStaffFinesInfo = (id) => {
  return axios.get(API_URL + "/users-finance/get/" + id, {
    headers: authHeader(),
  });
};
const UpdateStaffFines = (values, id, Fine) => {
  return axios.put(
    API_URL + "/users-finance/update/" + id,
    {
      occurrence_date: values.Occurrence_date,
      return_month: values.return_date,
      amount: values.amount,
      reason: values.reason,
      user_id: values.user_id,
      category: Fine,
      branch_id: values.branch_id,
    },

    { headers: authHeader() }
  );
};
const UpdateStaffAdvances = (values, id, Advance) => {
  return axios.put(
    API_URL + "/users-finance/update/" + id,
    {
      occurrence_date: values.occurrence_date,
      amount: values.amount,
      reason: values.reason,
      user_id: values.user_id,
      transaction_type: values.transaction_type,
      payment_type_id: values.payment_type_id,
      return_month: values.return_month,
      category: Advance,
      branch_id: values.branch_id,
    },

    { headers: authHeader() }
  );
};
const UpdateStaffLoan = (bId, values, id) => {
  return axios.put(
    API_URL + "/staff-loans/update/" + id,
    {
      branch_id: bId,
      date: values.date,
      staff_id: values.staff_id,
      term: values.term,
      amount: values.amount,
      reason: values.reason,
      return_start_date: values.return_start_date,
      transaction_type: values.transaction_type,
      payment_type_id: values.payment_type_id,
    },

    { headers: authHeader() }
  );
};
const deleteStaffFines = (stafffine_id) => {
  return axios.put(
    API_URL + "/users-finance/delete/" + stafffine_id,
    {},
    { headers: authHeader() }
  );
};
const deleteStaffAdvance = (stafffine_id) => {
  return axios.put(
    API_URL + "/staff-loans/delete/" + stafffine_id,
    {},
    { headers: authHeader() }
  );
};
const addPackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      duration: values.duration,
      price: values.price,
      membership_id: values.membership_id,
      category: "1",
    },
    { headers: authHeader() }
  );
};

const getStaffSalary = (br_id, values, limit, pageNo) => {
  return axios.get(API_URL + "/auth/salaries", {
    params: {
      branch_id: br_id,
      start_date: values.edate + "-" + values.sdate + "-01",
      end_date: values.edate + "-" + values.sdate + "-31",
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getLatestStaffSalary = (br_id, values, limit, pageNo) => {
  return axios.get(API_URL + "/salary", {
    params: {
      branch_id: br_id,
      start_date: values.edate + "-" + parseInt(values.sdate - 1) + "-26",
      end_date: values.edate + "-" + values.sdate + "-25",
      limit: limit,
      page: pageNo,
      user_id: values.trainer_id,
    },
    headers: authHeader(),
  });
};
// const getLatestStaffCommision = (br_id, values, limit, pageNo) => {
//   return axios.get(API_URL + "/commissions", {
//     params: {
//       branch_id: br_id,
//       start_date: values.edate + "-" + parseInt(values.sdate - 1) + "-26",
//       end_date: values.edate + "-" + values.sdate + "-25",
//       limit: limit,
//       page: pageNo,
//       user_id: values.trainer_id,
//     },
//     headers: authHeader(),
//   });
// };
const getLatestStaffCommision = (br_id, values, limit, pageNo) => {
  return axios.get(API_URL + "/commissions", {
    params: {
      branch_id: br_id,
      start_date: values.startdate,
      end_date: values.enddate,
      limit: limit,
      page: pageNo,
      user_id: values.trainer_id,
    },
    headers: authHeader(),
  });
};

const getStaffProfileSalary = (id, br_id, values, limit, pageNo) => {
  return axios.get(API_URL + "/auth/salaries", {
    params: {
      user_id: id,
      branch_id: br_id,
      start_date: values.edate + "-" + values.sdate + "-01",
      end_date: values.edate + "-" + values.sdate + "-31",
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getTaxy = (br_id, type) => {
  return axios.get(API_URL + "/finance/setting/get-current-charges", {
    params: {
      branch_id: br_id,
      type: type,
    },
    headers: authHeader(),
  });
};

const getSales = (br_id, values, limit, pageNo) => {
  return axios.get(API_URL + "/orders/get", {
    params: {
      branch_id: br_id,
      start_date: values.edate + "-" + values.sdate + "-01",
      end_date: values.edate + "-" + values.sdate + "-31",
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};

const getPackages = () => {
  return axios.get(API_URL + "/packages/get", {
    headers: authHeader(),
  });
};
const getPackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    headers: authHeader(),
  });
};
const MemPkgSearch = (search_name, bId, status, limit, pageNo, category) => {
  return axios.get(API_URL + "/packages/get?name=" + search_name, {
    params: {
      branch_id: bId,
      status: status,
      limit: limit,
      page: pageNo,
      category: category,
    },
    headers: authHeader(),
  });
};
const checkPackageName = (id, check_name, bId, category) => {
  return axios.get(API_URL + "/packages/unique-name/" + check_name, {
    params: {
      id: id,
      branch_id: bId,
      category: category,
    },
    headers: authHeader(),
  });
};
const checkAddPackageName = (check_name, bId, category) => {
  return axios.get(API_URL + "/packages/unique-name/" + check_name, {
    params: {
      branch_id: bId,
      category: category,
    },
    headers: authHeader(),
  });
};
const MembershipType = () => {
  return axios.get(API_URL + "/packages/get-Registration-Category", {
    params: {
      category_id: 6,
    },
    headers: authHeader(),
  });
};
const TrainerCategory = (value) => {
  return axios.get(API_URL + "/related_things/get-names-list", {
    params: {
      branch_id: value,
      type: "TrainerCategory",
    },
    headers: authHeader(),
  });
};
const TrainingCategory = (value) => {
  return axios.get(API_URL + "/training-categories/get", {
    params: {
      user_id: value,
    },
    headers: authHeader(),
  });
};
const MembershipCategory = (value) => {
  return axios.get(API_URL + "/packages/get-Registration-Category", {
    params: {
      branch_id: value,
      category: "6",
    },
    headers: authHeader(),
  });
};
const TrainerName = (value, is_gx = "") => {
  return axios.get(API_URL + "/auth/get-name", {
    params: {
      designation_id: "1",
      branch_id: value,
      is_gx_trainer: is_gx,
    },
    headers: authHeader(),
  });
};
const NutritionistName = (value) => {
  return axios.get(API_URL + "/auth/get-name", {
    params: {
      designation_id: "2",
      branch_id: value,
    },
    headers: authHeader(),
  });
};
const getactivePackage = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        value: "1",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getactiveNutritionPackage = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        value: "5",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getNutritionPackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "5",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getMembershipPackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "6",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getPhysioPackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "14",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getCafeDepositInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "13",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getLesMillsInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "12",
      key: "category",
    },
    headers: authHeader(),
  });
};

const getCafeInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "10",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getGenralPackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "9",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getGymPackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "1",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getCFTpackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "11",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getTrainerPackagesInfo = (id) => {
  return axios.get(API_URL + "/packages/get/" + id, {
    params: {
      value: "2",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getPtPackageInfo = (id) => {
  return axios.get(
    API_URL + "/packages/get/" + id,

    {
      params: {
        value: "4",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getSessionPackageInfo = (id) => {
  return axios.get(
    API_URL + "/packages/get/" + id,

    {
      params: {
        value: "7",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getactiveSessionPackage = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        value: "7",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getactiveTrainerPackage = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        value: "2",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getactivePtPackage = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        value: "4",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getactiveMembershipPackage = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/packages/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      value: "6",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getactivePhysioPackage = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/packages/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      value: "14",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getCafeDeposit = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/packages/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      value: "13",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getactiveLesMils = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/packages/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      value: "12",
      key: "category",
    },
    headers: authHeader(),
  });
};

const getactiveCFTpackage = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/packages/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
      value: "11",
      key: "category",
    },
    headers: authHeader(),
  });
};
const getActiveCafe = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        value: "10",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};

const getActiveCafeProducts = (bId) => {
  return axios.get(
    API_URL + "/packages/get",

    {
      params: {
        branch_id: bId,
        status: "1",
        limit: "999",
        value: "10",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getActiveCafeByCatagryId = (bId, id) => {
  return axios.get(
    API_URL + "/packages/get",

    {
      params: {
        branch_id: bId,
        status: "1",
        value: "10",
        key: "category",
        cafe_category_id: id,
        limit: "9999",
      },
      headers: authHeader(),
    }
  );
};

const getCafeCtgry = (bId, ctgry) => {
  return axios.get(
    API_URL + "/packages/get",

    {
      params: {
        branch_id: bId,
        cafe_category_id: ctgry,
        status: "1",
        value: "10",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getactiveGenralPkg = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
        value: "9",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const inactivePackage = (package_Id) => {
  return axios.put(
    API_URL + "/packages/inactive/" + package_Id,
    {},
    { headers: authHeader() }
  );
};
const activePackage = (package_Id) => {
  return axios.put(
    API_URL + "/packages/active/" + package_Id,
    {},
    { headers: authHeader() }
  );
};
const deletePackages = (id) => {
  return axios.put(
    API_URL + "/packages/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const UpdatePackages = (values, id) => {
  return axios.put(
    API_URL + "/packages/update/" + id,
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      duration: values.duration,
      price: values.price,
      membership_id: values.membership_id,
      category: values.category,
      select_training: values.select_training,
      user_id: values.user_id,
      session_count: values.session_count,
    },

    { headers: authHeader() }
  );
};
const UpdateLesMills = (values, id) => {
  return axios.put(
    API_URL + "/packages/update/" + id,
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      duration: values.duration,
      price: values.price,

      category: "12",
    },

    { headers: authHeader() }
  );
};
const UpdateClientPurchaseDate = (values, id) => {
  return axios.post(
    API_URL + "/approval/add/" + id,
    {
      date: values.date,
    },

    { headers: authHeader() }
  );
};
const DeleteClientPurchase = (id) => {
  return axios.post(
    API_URL + "/approval/add/" + id,
    {
      deleteRecode: "deleteRecode",
    },

    { headers: authHeader() }
  );
};
const UpdateClientPurchaseMethod = (values, id) => {
  return axios.post(
    API_URL + "/approval/add/" + id,
    {
      payment_method_id: values.payment_type,
    },

    { headers: authHeader() }
  );
};
const addBranches = (values) => {
  return axios.post(
    API_URL + "/branches/add",
    {
      name: values.name,
      code: values.code,
      city: values.city,
      country: values.country,
      address: values.street,
      phone: values.phone,
      ntn: values.ntn,
      strn: values.strn,
    },
    { headers: authHeader() }
  );
};
const checkBranchCode = (check_Code) => {
  return axios.get(API_URL + "/branches/unique-branch/" + check_Code, {
    headers: authHeader(),
  });
};
const getBranches = () => {
  return axios.get(API_URL + "/branches/get", {
    headers: authHeader(),
  });
};
const getBranchesFiltration = (status, limitNo, pageNo) => {
  return axios.get(API_URL + "/branches/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getActiveBranches = () => {
  return axios.get(API_URL + "/branches/get", {
    params: {
      status: "1",
    },
    headers: authHeader(),
  });
};

const deleteBrnch = (brnch_Id) => {
  return axios.put(
    API_URL + "/branches/delete/" + brnch_Id,
    {},
    { headers: authHeader() }
  );
};
const getBrnchInfo = (id) => {
  return axios.get(API_URL + "/branches/get/" + id, {
    headers: authHeader(),
  });
};
const UpdateBrnch = (values, id) => {
  return axios.put(
    API_URL + "/branches/update/" + id,
    {
      name: values.name,
      code: values.code,
      phone: values.phone,
      city: values.city,
      address: values.street,
      ntn: values.ntn,
      strn: values.strn,
    },

    { headers: authHeader() }
  );
};
const UpdateUserRoll = (values, id) => {
  return axios.put(
    API_URL + "/auth/update-role/" + id,
    {
      name: values.name,
      email: values.email,
      role: values.role,
      role_status: values.role_status,
    },

    { headers: authHeader() }
  );
};
const getExpences = (bId, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/expense/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
    },
    headers: authHeader(),
  });
};
const getExpenseDataByTime = (
  status,
  limitNo,
  pageNo,
  start_date,
  end_date
) => {
  return axios.get(API_URL + "/expense/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
      start_date: start_date,
      end_date: end_date,
    },
    headers: authHeader(),
  });
};
const getExpenseDataByYear = (status, limitNo, bId, values) => {
  return axios.get(API_URL + "/expense/get", {
    params: {
      status: status,
      limit: limitNo,
      branch_id: bId,
      start_date: values.startdate,
      end_date: values.enddate,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
    },
    headers: authHeader(),
  });
};
const getExpencesInfo = (id) => {
  return axios.get(API_URL + "/expense/get/" + id, {
    headers: authHeader(),
  });
};
// const getExpencesFiltration = (status, limitNo, pageNo, bId, sdate) => {
//   return axios.get(API_URL + "/expense/get?page=" + pageNo, {
//     params: {
//       status: status,
//       limit: limitNo,
//       branch_id: bId,
//       start_date: sdate,
//       end_date: sdate,
//     },
//     headers: authHeader(),
//   });
// };
const getExpncFiltration = (status, limitNo, pageNo, categoryType, bId) => {
  return axios.get(API_URL + "/expense/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
      category_id: categoryType,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const getAsetFiltration = (status, limitNo, pageNo, bId, values) => {
  return axios.get(API_URL + "/finance/asset/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
      branch_id: bId,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      start_date: values.startdate,
      end_date: values.enddate,
    },
    headers: authHeader(),
  });
};
const getLaibilitiesFiltration = (status, limitNo, pageNo, bId, values) => {
  return axios.get(API_URL + "/finance/liabilities/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
      branch_id: bId,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      start_date: values.startdate,
      end_date: values.enddate,
    },
    headers: authHeader(),
  });
};
const getCurrentYearExpences = (bId) => {
  return axios.get(API_URL + "/expense/fetch-by-date/year/" + bId, {
    params: {
      //  branch_id:bId
    },
    headers: authHeader(),
  });
};
const getCurrentTodayExpences = (bId) => {
  return axios.get(API_URL + "/expense/fetch-by-date/today/" + bId, {
    params: {
      //  branch_id: bId
    },
    headers: authHeader(),
  });
};
const getCurrentMonthExpences = (bId) => {
  return axios.get(API_URL + "/expense/fetch-by-date/month/" + bId, {
    params: {
      //  branch_id: bId
    },
    headers: authHeader(),
  });
};
const addExpenses = (values, liability) => {
  return axios.post(
    API_URL + "/expense/add",

    {
      branch_id: values.branch_id,
      occurrence_date: values.occurrence_date,
      amount: values.amount,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      transaction_type: values.transaction_type,
      payment_type_id: values.payment_type_id,
      bank_account_id: values.bank_account_id,
      cheque_number: values.cheque_number,
      description: values.description,
      is_liability: liability,
    },
    {
      headers: authHeader(),
    }
  );
};

const expenseAddMultiple = (values) => {
  return axios.post(API_URL + "/expense/store", values, {
    headers: authHeader(),
  });
};

const UpdateExp = (values, id, liability) => {
  return axios.put(
    API_URL + "/expense/update/" + id,
    {
      branch_id: values.branch_id,
      occurrence_date: values.occurrence_date,
      amount: values.amount,
      cheque_number: values.cheque_number,
      description: values.description,
      payment_type_id: values.payment_type_id,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      transaction_type: values.transaction_type,
      bank_account_id: values.bank_account_id,
      is_liability: liability,
    },

    { headers: authHeader() }
  );
};
const deleteExpenses = (Expnse_Id) => {
  return axios.put(
    API_URL + "/expense/delete/" + Expnse_Id,
    {},
    { headers: authHeader() }
  );
};
const addNutritionPackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      membership_id: values.membership_id,
      package_name: values.package_name,
      duration: values.duration,
      price: values.price,
      category: "5",
      session_count: values.session_count,
    },
    { headers: authHeader() }
  );
};
const addSessionPackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      duration: values.duration,
      price: values.price,
      session_count: values.session,
      category: "7",
    },
    { headers: authHeader() }
  );
};
const addTrainerPackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      membership_id: values.membership_id,
      package_name: values.package_name,
      duration: values.duration,
      price: values.price,
      category: "2",
      session_count: values.session_count,
    },
    { headers: authHeader() }
  );
};
const addMembershipPackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      price: values.price,
      category: "6",
      duration: "0",
    },
    { headers: authHeader() }
  );
};
const addPhysioPackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      price: values.price,
      category: "14",
      duration: values.duration,
      session_count: values.session_count,
    },
    { headers: authHeader() }
  );
};
const addCafeDeposit = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      price: values.price,
      category: "13",
      duration: "0",
    },
    { headers: authHeader() }
  );
};
const addLesMillsPackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      price: values.price,
      duration: values.duration,
      category: "12",
    },
    { headers: authHeader() }
  );
};

const addCFTpackages = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      price: values.price,
      duration: values.duration,
      category: "11",
    },
    { headers: authHeader() }
  );
};
const addCafePackages = (values) => {
  return axios.post(API_URL + "/packages/add", values, {
    headers: authHeaderImage(),
  });
};

const updateCafePackages = (values, id) => {
  return axios.post(API_URL + "/packages/update-cafe/" + id, values, {
    headers: authHeaderImage(),
  });
};

const CafeCategory = (bId) => {
  return axios.get(API_URL + "/finance/categories/fetch-categories-names", {
    params: {
      branch_id: bId,
      type: "Cafe",
    },
    headers: authHeader(),
  });
};

const addGenralPkg = (values) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      package_name: values.package_name,
      price: values.price,
      category: "9",
      duration: "0",
    },
    { headers: authHeader() }
  );
};
const addPtGroupPackages = (values, sTime, eTime) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      membership_id: values.membership_id,
      package_name: values.package_name,
      duration: values.duration,
      price: values.price,
      pt_group: values.pt_group,
      start_time: sTime,
      end_time: eTime,
      session_count: values.session_count,
      category: "4",
    },
    { headers: authHeader() }
  );
};

const UpdateGfxPackages = (values, id) => {
  return axios.put(
    API_URL + "/packages/update/" + id,
    {
      branch_id: values.branch_id,
      duration: values.duration,
      price: values.price,
      status: "1",
      category: "15",
    },

    { headers: authHeader() }
  );
};

const getGfxPackages = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,

        limit: limitNo,
        value: "15",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};
const getGfxPackageInfo = (id) => {
  return axios.get(
    API_URL + "/packages/get/" + id,

    {
      params: {
        value: "15",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};

const getClientInfo = (id) => {
  return axios.get(API_URL + "/clients/search-clients/" + id, {
    headers: authHeader(),
  });
};
const getStaffInfo = (id) => {
  return axios.get(API_URL + "/auth/search/" + id, {
    headers: authHeader(),
  });
};
const addClientCard = (values, id, type, branch_id) => {
  return axios.post(
    API_URL + "/cards/add",

    {
      number: values.number,
      description: values.description,
      member_id: id,
      member_type: type,
      branch_id: branch_id,
    },
    { headers: authHeader() }
  );
};

const getVisitorCards = (id, bId) => {
  return axios.get(API_URL + "/cards/get", {
    params: {
      type: "3",
      key: "member_id",
      value: id,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const getVisitorCardsData = (status, limitNo, pageNo) => {
  return axios.get(API_URL + "/cards/get?page=" + pageNo, {
    params: {
      type: "3",
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const inactiveCard = (card_Id) => {
  return axios.put(
    API_URL + "/cards/inactive/" + card_Id,
    {},
    { headers: authHeader() }
  );
};
const activeCard = (card_Id) => {
  return axios.put(
    API_URL + "/cards/active/" + card_Id,
    {},
    { headers: authHeader() }
  );
};
const getCards = (id, type, bId, limitNo, pageNo) => {
  return axios.get(API_URL + "/cards/get?page=" + pageNo, {
    params: {
      member_type: type,
      value: id,
      branch_id: bId,
      limit: limitNo,
      key: "member_id",
    },
    headers: authHeader(),
  });
};
const getCardsData = (status, limitNo, pageNo) => {
  return axios.get(API_URL + "/cards/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getClientsCards = () => {
  return axios.get(API_URL + "/cards/get", {
    params: {
      type: "1",
      key: "branch_id",
      value: "1",
    },
    headers: authHeader(),
  });
};
const getAllCards = (bId, typ, limitNo, pageNo) => {
  return axios.get(API_URL + "/cards/show?page=" + pageNo, {
    params: {
      branch_id: bId,
      type: typ,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const searchAllCards = (srch, typ, bId) => {
  return axios.get(API_URL + "/cards/show", {
    params: {
      card_number: srch,
      type: typ,
      branch_id: bId,
      //  type: typ,
    },
    headers: authHeader(),
  });
};
const searchStaffCards = (srch, bId) => {
  return axios.get(API_URL + "/cards/show", {
    params: {
      card_number: srch,
      branch_id: bId,
      type: "2",
    },
    headers: authHeader(),
  });
};
const deleteCards = (id) => {
  return axios.put(
    API_URL + "/cards/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const getGymCardByType = (bId, limitNo, pageNo, clientType) => {
  return axios.get(API_URL + "/cards/show?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      type: clientType,
    },
    headers: authHeader(),
  });
};
//   return axios.get(API_URL + "/branches/get?page=" + pageNo, {
//     params: {
//       status: status,
//       limit: limitNo,
//     },
//     headers: authHeader(),
//   });
// };
const getClientsCardsData = (limitNo, pageNo, branch_id) => {
  return axios.get(API_URL + "/cards/get?page=" + pageNo, {
    params: {
      limit: limitNo,
      type: "1",
      key: "branch_id",
      value: branch_id,
      // status: status,
    },
    headers: authHeader(),
  });
};
const getAllCardsData = (branch_id, limitNo, pageNo, type) => {
  return axios.get(API_URL + "/cards/show?page=" + pageNo, {
    params: {
      limit: limitNo,
      branch_id: branch_id,
      type: type,
    },
    headers: authHeader(),
  });
};
const getStaffCards = (branch_id) => {
  return axios.get(API_URL + "/cards/get", {
    params: {
      type: "2",
      key: "branch_id",
      value: branch_id,
    },
    headers: authHeader(),
  });
};
const getStaffCardsData = (limitNo, pageNo, branch_id) => {
  return axios.get(API_URL + "/cards/get?page=" + pageNo, {
    params: {
      type: "2",
      // status: status,
      limit: limitNo,
      key: "branch_id",
      value: branch_id,
    },
    headers: authHeader(),
  });
};
const getStfCrd = (bId) => {
  return axios.get(API_URL + "/cards/show", {
    params: {
      type: "2",
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const getStfCrdData = (limitNo, pageNo, branch_id) => {
  return axios.get(API_URL + "/cards/show?page=" + pageNo, {
    params: {
      type: "2",
      // status: status,
      limit: limitNo,
      key: "branch_id",
      value: branch_id,
    },
    headers: authHeader(),
  });
};
const getGymPackages = (bId, ctgry, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/orders-detail/expired-packages-history?page=" + pageNo,
    {
      params: {
        branch_id: bId,
        category: ctgry,
        limit: limitNo,
      },
      headers: authHeader(),
    }
  );
};
const getGymPackagesByTime = (bId, ctgry, limitNo, pageNo, packageTime) => {
  return axios.get(
    API_URL + "/orders-detail/expired-packages-history?page=" + pageNo,
    {
      params: {
        branch_id: bId,
        category: ctgry,
        limit: limitNo,
        duration: packageTime,
      },
      headers: authHeader(),
    }
  );
};

const getFinesYearExport = (status, limitNo, pageNo, values) => {
  return axios.get(
    API_URL + "/users-finance/ExportUsersFinance?page=" + pageNo,
    {
      params: {
        status: status,
        limit: limitNo,
        start_date: values.startdate,
        end_date: values.enddate,
        category: "Fine",
      },
      headers: authHeader(),
    }
  );
};
const getStaffAttendence = (bId, values, staff_id, limit, pageNo) => {
  return axios.get(API_URL + "/attendance/get", {
    params: {
      category: "2",
      type: "Staff",
      branch_id: bId,
      member_id: staff_id,
      start_time: values.start_time,
      end_time: values.end_time,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      // membership_type_id: values.memType,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getEmployeeAttendence = (bId, values, staff_id, limit, pageNo) => {
  return axios.get(API_URL + "/attendance/index", {
    params: {
      category: "2",
      type: "Staff",
      branch_id: bId,
      member_id: staff_id,
      start_time: values.start_time,
      end_time: values.end_time,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      // membership_type_id: values.memType,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getFilterEmployeeAttendence = (
  bId,
  staff_id,
  values,
  val,
  attendanceStatus,
  limit,
  pageNo
) => {
  return axios.get(API_URL + "/attendance/index", {
    params: {
      category: "2",
      type: "Staff",
      branch_id: bId,
      member_id: staff_id,
      start_date: values.sdate,
      end_date: values.edate,
      department_id: val,
      attendance_status: attendanceStatus,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getLeaveApplication = (bId, values, limit, pageNo) => {
  return axios.get(API_URL + "/hr/leave-application/index", {
    params: {
      branch_id: bId,
      user_id: values.trainer_id,
      leave_status: values.attendance_status,
      application_status: values.request_status,
      start_date: values.sdate,
      end_date: values.edate,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getProfileLeaveApplication = (bId, id, limit, pageNo) => {
  return axios.get(API_URL + "/hr/leave-application/index", {
    params: {
      branch_id: bId,
      user_id: id,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getStaffAttendenceSummary = (bId, values, staff_id, limit, pageNo) => {
  return axios.get(API_URL + "/attendance/showSummery", {
    params: {
      category: "2",
      type: "Staff",
      branch_id: bId,
      member_id: staff_id,
      start_time: values.start_time,
      end_time: values.end_time,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      // membership_type_id: values.memType,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getClientAttendence = (
  type,
  ctgory,
  bId,
  values,
  client_id,
  limit,
  pageNo
) => {
  return axios.get(API_URL + "/attendance/get", {
    params: {
      type: type,
      category: ctgory,
      branch_id: bId,
      start_time: values.start_time,
      member_id: client_id,
      end_time: values.end_time,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const getAttendenceSummary = (type, ctgory, bId, values, client_id) => {
  return axios.get(API_URL + "/attendance/showSummery", {
    params: {
      type: type,
      category: ctgory,
      branch_id: bId,
      start_time: values.start_time,
      member_id: client_id,
      end_time: values.end_time,
      start_date: values.sdate,
      end_date: values.edate,
      gender: values.gender,
    },
    headers: authHeader(),
  });
};
const checkClientName = (check_client) => {
  return axios.get(API_URL + "/freezing/eligibility/" + check_client, {
    // params: {
    //   // branch_id: bId,
    // },
    headers: authHeader(),
  });
};

const freezingPkgSell = (
  values,
  client_id,
  branch_id,
  price,
  pkgId,
  catgry,
  endDate
) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      duration: values.duration,
      start_date: values.start_date,
      note: values.salesnotes,
      client_id: client_id,
      order_id: values.order_id,
      branch_id: branch_id,
      price: price,
      package_id: pkgId,
      category: catgry,
      end_date: endDate,
      freeze_status: "Frozen",
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      sale_date: values.salesdate,
    },
    {
      headers: authHeader(),
    }
  );
};
const getActiveFee = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/freezing/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};

const inactiveFee = (freez_Id) => {
  return axios.put(
    API_URL + "/freezing/inactive/" + freez_Id,
    {},
    { headers: authHeader() }
  );
};
const getInactiveFee = (bId) => {
  return axios.get(API_URL + "/freezing/get", {
    params: {
      branch_id: bId,
      status: "0",
    },
    headers: authHeader(),
  });
};
const deleteFee = (fee_Id) => {
  return axios.put(
    API_URL + "/freezing/delete/" + fee_Id,
    {},
    { headers: authHeader() }
  );
};
const getFeeInfo = (id) => {
  return axios.get(API_URL + "/freezing/get/" + id, {
    headers: authHeader(),
  });
};
const addManagerResources = (values) => {
  return axios.post(
    API_URL + "/related_things/add",
    {
      name: values.name,
      type: values.type,
      description: values.description,
      department_id: values.department_id,
    },
    { headers: authHeader() }
  );
};
const getActiveResources = (bId, limitNo, pageNo, resTime) => {
  return axios.get(API_URL + "/related_things/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      type: resTime,
    },
    headers: authHeader(),
  });
};
const getActiveFilterResources = (bId, limitNo, pageNo, department, resTime) => {
  return axios.get(API_URL + "/related_things/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      department_id: department,
      type: resTime,
    },
    headers: authHeader(),
  });
};
const inactiveResource = (resource_Id) => {
  return axios.put(
    API_URL + "/related_things/inactive/" + resource_Id,
    {},
    { headers: authHeader() }
  );
};
const activeResource = (resource_Id) => {
  return axios.put(
    API_URL + "/related_things/active/" + resource_Id,
    {},
    { headers: authHeader() }
  );
};
const deleteRescource = (resource_Id) => {
  return axios.put(
    API_URL + "/related_things/delete/" + resource_Id,
    {},
    { headers: authHeader() }
  );
};
const getResourceInfo = (id) => {
  return axios.get(API_URL + "/related_things/get/" + id, {
    headers: authHeader(),
  });
};
const updateResource = (values, id) => {
  return axios.put(
    API_URL + "/related_things/update/" + id,
    {
      name: values.name,
      type: values.type,
      department_id: values.department_id,
      description: values.description,
    },

    { headers: authHeader() }
  );
};
const addTowls = (values) => {
  return axios.post(
    API_URL + "/accessories/add",
    {
      branch_id: values.branch_id,
      name: values.name,
      size: values.size,
      quantity: values.quantity,
      date: values.date,
    },
    { headers: authHeader() }
  );
};
const getTowls = (bId, limitNo, pageNo) => {
  return axios.get(API_URL + "/accessories/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getTowelsFiltration = (status, limitNo, pageNo) => {
  return axios.get(API_URL + "/accessories/get?page=" + pageNo, {
    params: {
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const deleteTowels = (towel_Id) => {
  return axios.put(
    API_URL + "/accessories/delete/" + towel_Id,
    {},
    { headers: authHeader() }
  );
};
const getTowelsInfo = (id) => {
  return axios.get(API_URL + "/accessories/get/" + id, {
    headers: authHeader(),
  });
};
const UpdateTowels = (values, id) => {
  return axios.put(
    API_URL + "/accessories/update/" + id,
    {
      branch_id: values.branch_id,
      name: values.name,
      size: values.size,
      quantity: values.quantity,
      date: values.date,
    },

    { headers: authHeader() }
  );
};
const getAttendance = (id) => {
  return axios.get(API_URL + "/attendance/get", {
    params: {
      member_id: id,
      category: "1",
    },
    headers: authHeader(),
  });
};
const getAttendanceInfo = (id, limitNo, pageNo) => {
  return axios.get(API_URL + "/attendance/get?page=" + pageNo, {
    params: {
      member_id: id,
      category: "1",
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getSingleStaffAttendence = (id) => {
  return axios.get(API_URL + "/attendance/get", {
    params: {
      member_id: id,
      category: "2",
      type: "Staff",
    },
    headers: authHeader(),
  });
};
const getStaffAttendanceInfo = (id, limitNo, pageNo) => {
  return axios.get(API_URL + "/attendance/get?page=" + pageNo, {
    params: {
      member_id: id,
      limit: limitNo,
      category: "2",
      type: "Staff",
    },
    headers: authHeader(),
  });
};
const getTrainerNutritionistName = (bId) => {
  return axios.get(API_URL + "/auth/fetch-name-list/" + bId, {
    params: {
      // branch_id: bid,
      category: "mix",
    },
    headers: authHeader(),
  });
};
const getOrderName = (tId, cat = "") => {
  return axios.get(API_URL + "/orders-detail/fetch-trainer-packages/" + tId, {
    params: {
      category: cat,
      // trainer_id: value,
    },
    headers: authHeader(),
  });
};
const getClientFilterName = (tId, cat = "15") => {
  return axios.get(API_URL + "/orders-detail/fetch-trainer-packages/" + tId, {
    params: {
      category: cat,
      // trainer_id: value,
    },
    headers: authHeader(),
  });
};

const getActiveAttendanceForTrainer = (
  bId,
  status,
  limitNo,
  pageNo,
  type = ""
) => {
  return axios.get(API_URL + "/fitness/session-attendance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      type: type,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};

const getAttendanceByTypeTrainer = (
  bId,
  limitNo,
  pageNo,
  packageTime,
  type = ""
) => {
  return axios.get(API_URL + "/fitness/session-attendance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      trainer_id: packageTime,
      type: type,
    },
    headers: authHeader(),
  });
};
const getAttendanceByPackageTrainer = (
  bId,
  limitNo,
  pageNo,
  packageName,
  type = ""
) => {
  return axios.get(API_URL + "/fitness/session-attendance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      order_id: packageName,
      type: type,
    },
    headers: authHeader(),
  });
};

const addAttendanceForTrainer = (values) => {
  return axios.post(
    API_URL + "/fitness/session-attendance/add",
    {
      branch_id: values.branch_id,
      trainer_id: values.trainer_id,
      order_id: values.order_id,
      client_id: values.clientId,
      staff_status: values.trainer_status,
      client_status: values.client_status,
      client_name: values.clientName,
      email: values.clientEmail,
      date: values.date,
      trainer_schedule_id: values.trainer_schedule_id,
      type: values.type ? values.type : "",
      package_id: values.package_id ? values.package_id : "",
    },
    { headers: authHeader() }
  );
};

const checkSessiuonAttendanceForTrainer = (values) => {
  return axios.get(API_URL + "/fitness/session-attendance/exists", {
    params: {
      trainer_id: values.trainer_id,
      order_id: values.order_id,
      date: values.date,
    },
    headers: authHeader(),
  });
};

const checkCompleteAttendanceForTrainer = (order_Id) => {
  return axios.get(
    API_URL +
      "/fitness/session-attendance/is-attendance-is-complete/" +
      order_Id,
    {
      headers: authHeader(),
    }
  );
};
const checkScheduleForTrainerPkg = (order_Id, trainer_id) => {
  return axios.get(
    API_URL +
      "/fitness/session-attendance/get-trainer-schedule/" +
      order_Id +
      "/" +
      trainer_id,
    {
      headers: authHeader(),
    }
  );
};

const checkAttendance = (id, values) => {
  return axios.get(API_URL + "/fitness/session-attendance/exists/" + id, {
    params: {
      trainer_id: values.trainer_id,
      order_id: values.order_id,
      date: values.date,
    },
    headers: authHeader(),
  });
};

const inactiveAttendance = (attend_Id) => {
  return axios.put(
    API_URL + "/fitness/session-attendance/actions/" + attend_Id + "/0",
    {},
    { headers: authHeader() }
  );
};
const activeAttendance = (attend_Id) => {
  return axios.put(
    API_URL + "/fitness/session-attendance/actions/" + attend_Id + "/1",
    {},
    { headers: authHeader() }
  );
};
const deleteAttendance = (attend_Id) => {
  return axios.put(
    API_URL + "/fitness/session-attendance/actions/" + attend_Id + "/2",
    {},
    { headers: authHeader() }
  );
};
const getSessionAttendanceInfo = (bId, id) => {
  return axios.get(API_URL + "/fitness/session-attendance/get/" + id, {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const UpdateAttendance = (values, id) => {
  return axios.post(
    API_URL + "/fitness/session-attendance/update/" + id,
    {
      branch_id: values.branch_id,
      trainer_id: values.trainer_id,
      order_id: values.order_id,
      client_id: values.clientId,
      staff_status: values.trainer_status,
      client_status: values.client_status,
      client_name: values.clientName,
      email: values.clientEmail,
      date: values.date,
    },

    { headers: authHeader() }
  );
};
const getPrindCards = (branch_id, id) => {
  return axios.get(API_URL + "/clients/show/" + id, {
    // params: {
    //   branch_id: branch_id,
    //   status: "1",
    // },

    headers: authHeader(),
  });
};
const getPrintStaffCards = (branch_id, id) => {
  return axios.get(API_URL + "/auth/get/" + id, {
    params: {
      branch_id: branch_id,
      status: "1",
    },
    headers: authHeader(),
  });
};
const checkName = (check_Name) => {
  return axios.get(API_URL + "/related_things/check-Name/" + check_Name, {
    headers: authHeader(),
  });
};
const updateCheckName = (check_Name, id) => {
  return axios.get(API_URL + "/related_things/check-Name/" + check_Name + "/" +  id ,{
    headers: authHeader(),
  });
};
const checkAccountBlance = (client_Id) => {
  return axios.get(API_URL + "/payments-history/client-balance/" + client_Id, {
    headers: authHeader(),
  });
};
const uploadFile = (formData) => {
  return axios.post(API_URL + "/clients/importclients", formData, {
    headers: authHeaderImage(),
  });
};
const getUserRoles = () => {
  return axios.get(API_URL + "/auth/user-role", {
    params: {
      // branch_id: branch_id,
    },
    headers: authHeader(),
  });
};
const getUserRolesData = (limitNo, pageNo) => {
  return axios.get(API_URL + "/auth/user-role?page=" + pageNo, {
    params: {
      limit: limitNo,
      // branch_id: branch_id,
    },
    headers: authHeader(),
  });
};

const getRoleInfo = (id) => {
  return axios.get(API_URL + "/auth/user-role/" + id, {
    headers: authHeader(),
  });
};
const freezingPkgName = (bId) => {
  return axios.get(API_URL + "/packages/get", {
    params: {
      branch_id: bId,
      category: "8",
    },
    headers: authHeader(),
  });
};
const addCatagory = (values) => {
  return axios.post(
    API_URL + "/finance/categories/add",
    {
      branch_id: values.branch_id,
      name: values.name,
      type: values.type,
    },
    { headers: authHeader() }
  );
};
const addCafeCatagory = (values) => {
  return axios.post(
    API_URL + "/finance/categories/add",
    {
      branch_id: values.branch_id,
      name: values.name,
      type: "Cafe",
    },
    { headers: authHeader() }
  );
};
const addSubCatagory = (values) => {
  return axios.post(
    API_URL + "/finance/sub-categories/add",
    {
      branch_id: values.branch_id,
      name: values.name,
      category_id: values.category_id,
    },
    { headers: authHeader() }
  );
};
const checkCategoriesName = (check_name, bId) => {
  return axios.get(API_URL + "/finance/categories/is-category-exist", {
    params: {
      name: check_name,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const updateCategoriesName = (id, check_name, bId) => {
  return axios.get(API_URL + "/finance/categories/is-category-exist/" + id, {
    params: {
      name: check_name,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const activeCategories = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/finance/categories/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
      },
      headers: authHeader(),
    }
  );
};
const cafeCategory = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/finance/categories/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
        type: "Cafe",
      },
      headers: authHeader(),
    }
  );
};
const getCategoryDataByType = (limitNo, pageNo, typ, bId) => {
  return axios.get(API_URL + "/finance/categories/get?page=" + pageNo, {
    params: {
      limit: limitNo,
      type: typ,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const categorySearch = (search_name, bId, status, limit, pageNo) => {
  return axios.get(API_URL + "/finance/categories/get", {
    params: {
      name: search_name,
      branch_id: bId,
      status: status,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const activeSubCategories = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/finance/sub-categories/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
      },
      headers: authHeader(),
    }
  );
};
const subCategorySearch = (search_name, bId, status, limit, pageNo, type) => {
  return axios.get(API_URL + "/finance/sub-categories/get", {
    params: {
      name: search_name,
      branch_id: bId,
      status: status,
      limit: limit,
      page: pageNo,
      type: type,
    },
    headers: authHeader(),
  });
};
const deleteCategory = (id) => {
  return axios.put(
    API_URL + "/finance/categories/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const deleteSubCategory = (id) => {
  return axios.put(
    API_URL + "/finance/sub-categories/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const getCategoriesInfo = (id) => {
  return axios.get(API_URL + "/finance/categories/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const getSubCategoriesInfo = (id) => {
  return axios.get(API_URL + "/finance/sub-categories/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const UpdateCategories = (values, id) => {
  return axios.put(
    API_URL + "/finance/categories/update/" + id,
    {
      branch_id: values.branch_id,
      name: values.name,
      type: values.type,
    },

    { headers: authHeader() }
  );
};
const UpdateCafeCategory = (values, id) => {
  return axios.put(
    API_URL + "/finance/categories/update/" + id,
    {
      branch_id: values.branch_id,
      name: values.name,
      type: "Cafe",
    },

    { headers: authHeader() }
  );
};
const UpdateSubCategories = (values, id) => {
  return axios.put(
    API_URL + "/finance/sub-categories/update/" + id,
    {
      branch_id: values.branch_id,
      name: values.name,
      category_id: values.category_id,
    },

    { headers: authHeader() }
  );
};

const activeExercises = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/fitness/exercise/index?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
      },
      headers: authHeader(),
    }
  );
};
const getExerciseInfo = (id) => {
  return axios.get(API_URL + "/fitness/exercise/show/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const addExercise = (values) => {
  return axios.post(
    API_URL + "/fitness/exercise/store",
    {
      branch_id: values.branch_id,
      name: values.name,
      category_id: values.type,
      description: values.description,
      sub_category_id: values.category_id,
    },
    { headers: authHeader() }
  );
};

const UpdateExercise = (values, id) => {
  return axios.put(
    API_URL + "/fitness/exercise/update/" + id,
    {
      branch_id: values.branch_id,
      name: values.name,
      category_id: values.type,
      description: values.description,
      sub_category_id: values.category_id,
    },

    { headers: authHeader() }
  );
};
const deleteExercise = (id) => {
  return axios.put(
    API_URL + `/fitness/exercise/actions/${id}/2`,
    {},
    { headers: authHeader() }
  );
};

const CategoriesName = (bId) => {
  return axios.get(API_URL + "/finance/categories/get", {
    params: {
      status: "1",
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const CategoriesDropdoenName = (bId, typ) => {
  return axios.get(API_URL + "/finance/categories/fetch-categories-names", {
    params: {
      status: "1",
      branch_id: bId,
      type: typ,
    },
    headers: authHeader(),
  });
};

const SubCategoriesName = (ctgry, bId) => {
  return axios.get(API_URL + "/finance/sub-categories/get", {
    params: {
      category_id: ctgry,
      branch_id: bId,
      status: "1",
    },
    headers: authHeader(),
  });
};
const SubCategoriesDropdoenName = (ctgry, bId) => {
  return axios.get(
    API_URL + "/finance/sub-categories/fetch-subcategories-names",
    {
      params: {
        category_id: ctgry,
        branch_id: bId,
        status: "1",
      },
      headers: authHeader(),
    }
  );
};
const exercisesDropdoenName = (ctgry) => {
  return axios.get(API_URL + "/fitness/exercise/listing", {
    params: {
      sub_category_id: ctgry,
      status: "1",
    },
    headers: authHeader(),
  });
};
const checkSubCategoriesName = (check_name, ctgry_id, bId) => {
  return axios.get(API_URL + "/finance/sub-categories/is-sub-category-exist", {
    params: {
      name: check_name,
      category_id: ctgry_id,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const officeCashFlowAdd = (values, credit) => {
  return axios.post(
    API_URL + "/finance/office-cash-flow/add",
    {
      branch_id: values.branch_id,
      amount: values.amount,
      resource: values.resource,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id: values.bank_account_id,
      is_petty_cash: credit,
      creditor: values.creditor,
    },
    { headers: authHeader() }
  );
};
const bankCashFlowAdd = (values) => {
  return axios.post(
    API_URL + "/finance/bank-ledger/add",
    {
      branch_id: values.branch_id,
      amount: values.amount,
      resource: values.resource,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id: values.bank_account_id,
    },
    { headers: authHeader() }
  );
};
const keeneCashFlowAdd = (values) => {
  return axios.post(
    API_URL + "/finance/keene-ledger/add",
    {
      branch_id: values.branch_id,
      amount: values.amount,
      description: values.description,
      type: values.type,
      date: values.date,
    },
    { headers: authHeader() }
  );
};
const PettyCashAdd = (values) => {
  return axios.post(
    API_URL + "/finance/petty-cash-ledger/add",
    {
      branch_id: values.branch_id,
      amount: values.amount,
      transaction_type: values.transaction_type,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id: values.bank_account_id,
    },
    { headers: authHeader() }
  );
};
const GthirteenCashAdd = (values) => {
  return axios.post(
    API_URL + "/finance/g-thirteen/add",
    {
      branch_id: values.branch_id,
      amount: values.amount,
      transaction_type: values.transaction_type,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id: values.bank_account_id,
    },
    { headers: authHeader() }
  );
};
const getOfficeCashFlow = (bId, values) => {
  return axios.get(
    API_URL + "/finance/office-cash-flow/get",

    {
      params: {
        branch_id: bId,
        start_date: values.sdate,
        end_date: values.edate,
        status: "1",
        limit: "9999999999999999999999999999999999",
      },
      headers: authHeader(),
    }
  );
};
const getBankCashFlow = (bId, values) => {
  return axios.get(
    API_URL + "/finance/bank-ledger/get",

    {
      params: {
        branch_id: bId,
        start_date: values.sdate,
        end_date: values.edate,
        status: "1",
        limit: "9999999999999999999999999999999999",
      },
      headers: authHeader(),
    }
  );
};
const getPettyCashFlow = (bId, values) => {
  return axios.get(
    API_URL + "/finance/petty-cash-ledger/get",

    {
      params: {
        branch_id: bId,
        start_date: values.sdate,
        end_date: values.edate,
        status: "1",
        limit: "9999999999999999999999999999999999",
        transaction_type: values.transaction_type,
      },
      headers: authHeader(),
    }
  );
};
const getGthirteenCashFlow = (bId, values) => {
  return axios.get(
    API_URL + "/finance/g-thirteen/get",

    {
      params: {
        branch_id: bId,
        start_date: values.sdate,
        end_date: values.edate,
        status: "1",
        limit: "9999999999999999999999999999999999",
        transaction_type: values.transaction_type,
      },
      headers: authHeader(),
    }
  );
};
const getKneeLedger = (bId, values) => {
  return axios.get(
    API_URL + "/finance/keene-ledger/get",

    {
      params: {
        branch_id: bId,
        start_date: values.sdate,
        end_date: values.edate,
        limit: "9999999999999999999999999999999999",
      },
      headers: authHeader(),
    }
  );
};
const bankCashSearch = (srchVal, bId) => {
  return axios.get(
    API_URL + "/finance/bank-ledger/get",

    {
      params: {
        search: srchVal,
        branch_id: bId,
        status: "1",
        limit: "9999999999999999999999999999999999",
      },
      headers: authHeader(),
    }
  );
};
const deleteOfficeCashFlow = (id) => {
  return axios.put(
    API_URL + "/finance/office-cash-flow/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const deleteBankCashFlow = (id) => {
  return axios.put(
    API_URL + "/finance/bank-ledger/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const deletePettyCashFlow = (id) => {
  return axios.put(
    API_URL + "/finance/petty-cash-ledger/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const deleteKneeCashFlow = (id) => {
  return axios.put(
    API_URL + "/finance/keene-ledger/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const deleteGthirteenCashFlow = (id) => {
  return axios.put(
    API_URL + "/finance/g-thirteen/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const getCashFlowInfo = (id) => {
  return axios.get(API_URL + "/finance/office-cash-flow/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const getBankCashFlowInfo = (id) => {
  return axios.get(API_URL + "/finance/bank-ledger/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const getPettyCashFlowInfo = (id) => {
  return axios.get(API_URL + "/finance/petty-cash-ledger/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const getGthirteenCashFlowInfo = (id) => {
  return axios.get(API_URL + "/finance/g-thirteen/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const keeneCashFlowInfo = (id) => {
  return axios.get(API_URL + "/finance/keene-ledger/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const officeCashUpdate = (values, id, credit) => {
  return axios.put(
    API_URL + "/finance/office-cash-flow/update/" + id,
    {
      branch_id: values.branch_id,
      amount: values.amount,
      resource: values.resource,
      description: values.description,
      type: values.type,
      date: values.date,
      creditor: values.creditor,
      is_petty_cash: credit,
    },

    { headers: authHeader() }
  );
};
const bankCashUpdate = (values, id) => {
  return axios.put(
    API_URL + "/finance/bank-ledger/update/" + id,
    {
      branch_id: values.branch_id,
      amount: values.amount,
      resource: values.resource,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id: values.bank_account_id,
    },

    { headers: authHeader() }
  );
};
const pettyCashUpdate = (values, id) => {
  return axios.put(
    API_URL + "/finance/g-thirteen/update/" + id,
    {
      branch_id: values.branch_id,
      amount: values.amount,
      transaction_type: values.transaction_type,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id: values.bank_account_id,
    },

    { headers: authHeader() }
  );
};
const keeneCashUpdate = (values, id) => {
  return axios.put(
    API_URL + "/finance/keene-ledger/update/" + id,
    {
      branch_id: values.branch_id,
      amount: values.amount,
      description: values.description,
      type: values.type,
      date: values.date,
    },

    { headers: authHeader() }
  );
};
const laibilitiesAdd = (values) => {
  return axios.post(
    API_URL + "/finance/liabilities/add",
    {
      branch_id: values.branch_id,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      creditor_name: values.creditor_name,
      creditor_contact: values.creditor_contact,
      amount_owned: values.amount_owned,
      description: values.description,
      maturity_date: values.maturity_date,
    },
    { headers: authHeader() }
  );
};
const laibilitieLedgersAdd = (values) => {
  return axios.post(
    API_URL + "/finance/liability-ledger/add",
    {
      branch_id: values.branch_id,
      amount: values.amount,
      transaction_type: values.resource,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id: values.bank_account_id,
    },
    { headers: authHeader() }
  );
};
const laibilitieLedgersUpdate = (values, id) => {
  return axios.put(
    API_URL + "/finance/liability-ledger/update/" + id,
    {
      branch_id: values.branch_id,
      amount: values.amount,
      transaction_type: values.resource,
      description: values.description,
      type: values.type,
      date: values.date,
      bank_account_id:
        values.resource !== "Bank Account" ? null : values.bank_account_id,
    },
    { headers: authHeader() }
  );
};
const laibilitiesAddMultiple = (values) => {
  return axios.post(API_URL + "/finance/liabilities/add-multiple", values, {
    headers: authHeader(),
  });
};
const laibilitiesPay = (values) => {
  return axios.post(
    API_URL + "/finance/liability-installments/add",
    {
      branch_id: values.branch_id,
      liability_id: values.liability_id,
      resource: values.resource,
      amount: values.amount,
      date: values.date,
      bank_account_id: values.bank_account_id,
      cheque_no: values.cheque_no,
    },
    { headers: authHeader() }
  );
};
const loanPay = (values) => {
  return axios.post(
    API_URL + "/loan-installments/add",
    {
      branch_id: values.branch_id,
      loan_id: values.loan_id,
      amount: values.amount,
      date: values.date,
    },
    { headers: authHeader() }
  );
};
const liabilitiesGet = (bId, limitNo, pageNo, values) => {
  return axios.get(API_URL + "/finance/liabilities/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      start_date: values.startdate,
      end_date: values.enddate,
    },
    headers: authHeader(),
  });
};
const liabilitiesSearch = (bId, srchVal) => {
  return axios.get(API_URL + "/finance/liabilities/get", {
    params: {
      branch_id: bId,
      search: srchVal,
      limit: "99999999999999999999",
    },
    headers: authHeader(),
  });
};
const getLiabilitiesFiltration = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/finance/liabilities/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const inactiveLiabilities = (record_Id) => {
  return axios.put(
    API_URL + "/finance/liabilities/inactive/" + record_Id,
    {},
    { headers: authHeader() }
  );
};
const liabilitiesUpdate = (values, id) => {
  return axios.put(
    API_URL + "/finance/liabilities/update/" + id,
    {
      branch_id: values.branch_id,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      creditor_name: values.creditor_name,
      creditor_contact: values.creditor_contact,
      amount_owned: values.amount_owned,
      description: values.description,
      maturity_date: values.maturity_date,
    },

    { headers: authHeader() }
  );
};
const getInactiveLiabilities = (bId) => {
  return axios.get(
    API_URL + "/finance/liabilities/get",

    {
      params: {
        branch_id: bId,
        status: "0",
      },
      headers: authHeader(),
    }
  );
};
const deleteLiabilities = (id) => {
  return axios.put(
    API_URL + "/finance/liabilities/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const deleteInstallmets = (id) => {
  return axios.put(
    API_URL + "/finance/liability-installments/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const deleteLoanInstallmets = (id) => {
  return axios.put(
    API_URL + "/loan-installments/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const getLaibilitiesInfo = (id) => {
  return axios.get(API_URL + "/finance/liabilities/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const assetsAdd = (values) => {
  return axios.post(
    API_URL + "/finance/asset/add",
    {
      branch_id: values.branch_id,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      name: values.name,
      purchase_cost: values.purchase_cost,
      current_value: values.current_value,
      acquisition_date: values.acquisition_date,
      description: values.description,
      quantity: values.quantity,
      total_cost: values.total_cost,
      vendor_name: values.vendor_name,
      vendor_contact: values.vendor_contact,
    },
    { headers: authHeader() }
  );
};
const assetsGet = (bId, limitNo, pageNo, values) => {
  return axios.get(
    API_URL + "/finance/asset/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
        start_date: values.startdate,
        end_date: values.enddate,
        status: "1",
      },
      headers: authHeader(),
    }
  );
};
const getAssetsFiltration = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/finance/asset/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getAssetsInfo = (id) => {
  return axios.get(API_URL + "/finance/asset/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const assetsUpdate = (values, id) => {
  return axios.put(
    API_URL + "/finance/asset/update/" + id,
    {
      branch_id: values.branch_id,
      category_id: values.category_id,
      description: values.description,
      name: values.name,
      purchase_cost: values.purchase_cost,
      current_value: values.current_value,
      acquisition_date: values.acquisition_date,
      quantity: values.quantity,
      total_cost: values.total_cost,
      vendor_name: values.vendor_name,
      vendor_contact: values.vendor_contact,
      sub_category_id: values.sub_category_id,
    },

    { headers: authHeader() }
  );
};
const inactivAssets = (bId) => {
  return axios.get(
    API_URL + "/finance/asset/get",

    {
      params: {
        branch_id: bId,
        status: "0",
      },
      headers: authHeader(),
    }
  );
};
const deleteAssets = (id) => {
  return axios.put(
    API_URL + "/finance/asset/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const activeAssets = (record_Id) => {
  return axios.put(
    API_URL + "/finance/asset/active/" + record_Id,
    {},
    { headers: authHeader() }
  );
};
const balanceBank = (bId, sdate, edate) => {
  return axios.get(API_URL + "/finance/transactions/get-bank-balance-detail", {
    params: {
      branch_id: bId,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const balanceOffice = (bId, sdate, edate) => {
  return axios.get(API_URL + "/finance/office-cash-flow/office-cash-balance", {
    params: {
      branch_id: bId,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const balanceSales = (bId, sdate, edate) => {
  return axios.get(API_URL + "/finance/transactions/sales-counter-balance", {
    params: {
      branch_id: bId,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const creditBalanceBank = (bId, sdate, edate) => {
  return axios.get(API_URL + "/finance/transactions/get-last-credit-bank", {
    params: {
      branch_id: bId,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const creditBalanceOffice = (bId, sdate, edate) => {
  return axios.get(
    API_URL + "/finance/office-cash-flow/get-transaction-by-type",
    {
      params: {
        branch_id: bId,
        start_date: sdate,
        end_date: edate,
        type: "Credit",
      },
      headers: authHeader(),
    }
  );
};
const creditBalanceSales = (bId, sdate, edate) => {
  return axios.get(API_URL + "/finance/transactions/get-last-credit-sales", {
    params: {
      branch_id: bId,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const debitBalanceBank = (bId, sdate, edate) => {
  return axios.get(API_URL + "/finance/transactions/get-last-debit-bank", {
    params: {
      branch_id: bId,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const debitBalanceSales = (bId, sdate, edate) => {
  return axios.get(API_URL + "/finance/transactions/get-last-debit-sales", {
    params: {
      branch_id: bId,
      start_date: sdate,
      end_date: edate,
    },
    headers: authHeader(),
  });
};
const debitBalanceOffice = (bId, sdate, edate) => {
  return axios.get(
    API_URL + "/finance/office-cash-flow/get-transaction-by-type",
    {
      params: {
        branch_id: bId,
        start_date: sdate,
        end_date: edate,
        type: "Debit",
      },
      headers: authHeader(),
    }
  );
};

const monthSaleExpenses = (bid, values) => {
  return axios.get(
    API_URL + "/finance/transactions/get-sales-and-expense-by-category",
    {
      params: {
        branch_id: bid,
        start_date: values.sdate,
        end_date: values.edate,
      },
      headers: authHeader(),
    }
  );
};
const monthSaleExpensesForDashboard = (bid, sdate, edate) => {
  return axios.get(
    API_URL + "/finance/transactions/get-sales-and-expense-by-category",
    {
      params: {
        branch_id: bid,
        start_date: sdate,
        end_date: edate,
      },
      headers: authHeader(),
    }
  );
};
const getDaliySalesCount = (bId, sDate, eDate) => {
  return axios.get(
    API_URL + "/finance/transactions/get-sales-by-service-category",
    {
      params: {
        status: "1",
        branch_id: bId,
        start_date: sDate,
        end_date: eDate,
      },
      headers: authHeader(),
    }
  );
};
const dailySaleExpenses = (bid, sDate, eDate) => {
  return axios.get(
    API_URL + "/finance/transactions/get-sales-and-expense-by-category",
    {
      params: {
        branch_id: bid,
        start_date: sDate,
        end_date: eDate,
      },
      headers: authHeader(),
    }
  );
};
const paymentMethodDetails = (bId, sdate, edate) => {
  return axios.get(
    API_URL + "/finance/transactions/fetch-sales-sum-by-payment-method",
    {
      params: {
        branch_id: bId,
        start_date: sdate,
        end_date: edate,
      },
      headers: authHeader(),
    }
  );
};
const categorySale = (bid, sdate, edate) => {
  return axios.get(
    API_URL + "/finance/transactions/fetch-sales-by-category-and-payment",
    {
      params: {
        branch_id: bid,
        start_date: sdate,
        end_date: edate,
      },
      headers: authHeader(),
    }
  );
};
const getDailyClosingExpense = (bId, values) => {
  return axios.get(
    API_URL + "/finance/transactions/fetch-expense-detail",

    {
      params: {
        branch_id: bId,
        start_date: values.sdate,
        end_date: values.edate,
      },
      headers: authHeader(),
    }
  );
};
const addTax = (values) => {
  return axios.post(
    API_URL + "/finance/setting/add",
    {
      branch_id: values.branch_id,
      type: values.type,
      fee: values.fee,
      date: values.date,
    },
    { headers: authHeader() }
  );
};
const getTax = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/finance/setting/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
      },
      headers: authHeader(),
    }
  );
};
const inactiveSettings = (tax_Id) => {
  return axios.put(
    API_URL + "/finance/setting/inactive/" + tax_Id,
    {},
    { headers: authHeader() }
  );
};
const activeSettings = (tax_Id) => {
  return axios.put(
    API_URL + "/finance/setting/active/" + tax_Id,
    {},
    { headers: authHeader() }
  );
};
const deleteSettings = (id) => {
  return axios.put(
    API_URL + "/finance/setting/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const getSettingInfo = (id) => {
  return axios.get(API_URL + "/finance/setting/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const UpdateSetting = (values, id) => {
  return axios.put(
    API_URL + "/finance/setting/update/" + id,
    {
      branch_id: values.branch_id,
      fee: values.fee,
      type: values.type,
      date: values.date,
    },

    { headers: authHeader() }
  );
};
const getSettingsFiltration = (limitNo, pageNo, bId, values) => {
  return axios.get(API_URL + "/finance/setting/get?page=" + pageNo, {
    params: {
      limit: limitNo,
      branch_id: bId,
      category_id: values.category_id,
      date: values.date,
      type: values.type,
    },
    headers: authHeader(),
  });
};
const addAccountdetails = (values) => {
  return axios.post(
    API_URL + "/finance/banking-details/add",
    {
      branch_id: values.branch_id,
      name: values.name,
      account_no: values.account_no,
      account_title: values.account_title,
      date: values.date,
    },
    { headers: authHeader() }
  );
};
const getAccountDetails = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/finance/banking-details/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
      },
      headers: authHeader(),
    }
  );
};
const inactiveAccountDetails = (account_Id) => {
  return axios.put(
    API_URL + "/finance/banking-details/inactive/" + account_Id,
    {},
    { headers: authHeader() }
  );
};
const activeAccountDetails = (account_Id) => {
  return axios.put(
    API_URL + "/finance/banking-details/active/" + account_Id,
    {},
    { headers: authHeader() }
  );
};
const deleteDetails = (id) => {
  return axios.put(
    API_URL + "/finance/banking-details/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const getBankDetailsInfo = (id) => {
  return axios.get(API_URL + "/finance/banking-details/get/" + id, {
    params: {},
    headers: authHeader(),
  });
};
const bankDetailsUpdate = (values, id) => {
  return axios.put(
    API_URL + "/finance/banking-details/update/" + id,
    {
      branch_id: values.branch_id,
      name: values.name,
      account_no: values.account_no,
      account_title: values.account_title,
    },

    { headers: authHeader() }
  );
};
const bankList = (bId) => {
  return axios.get(API_URL + "/finance/banking-details/listing", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const totalInstallment = (libility) => {
  return axios.get(
    API_URL + "/finance/liability-installments/installment-sum/" + libility,
    {
      headers: authHeader(),
    }
  );
};
const totalLoans = (loan) => {
  return axios.get(API_URL + "/loan-installments/loan-status/" + loan, {
    headers: authHeader(),
  });
};
const notificationGet = (bId) => {
  return axios.get(API_URL + "/notifications/get", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const readMessage = (Record_Id) => {
  return axios.put(
    API_URL + "/notifications/read/" + Record_Id,
    {},
    { headers: authHeader() }
  );
};
const notificationDelete = (Record_Id) => {
  return axios.put(
    API_URL + "/notifications/delete/" + Record_Id,
    {},
    { headers: authHeader() }
  );
};
const getApprovals = (bId, type) => {
  return axios.get(API_URL + "/approval/get", {
    params: {
      branch_id: bId,
      status: type,
    },
    headers: authHeader(),
  });
};
const getPkgApprovals = (bId, type) => {
  return axios.get(API_URL + "/approval/get", {
    params: {
      branch_id: bId,
      type: type,
    },
    headers: authHeader(),
  });
};
const rejectRecord = (id) => {
  return axios.put(
    API_URL + "/approval/denied/" + id,
    {},
    { headers: authHeader() }
  );
};
const rejectRequest = (id, today) => {
  return axios.put(
    API_URL + "/hr/leave-application/update/" + id,
    {
      application_status: "Rejected",
      review_date: today,
    },
    { headers: authHeader() }
  );
};
const leaveApplicationActions = (package_Id, action) => {
  return axios.put(
    API_URL + "/hr/leave-application/actions/" + package_Id + "/" + action,
    {},
    { headers: authHeader() }
  );
};
const approveRecord = (id) => {
  return axios.put(
    API_URL + "/approval/approve/" + id,
    {},
    { headers: authHeader() }
  );
};
const approveRequest = (id, today) => {
  return axios.put(
    API_URL + "/hr/leave-application/update/" + id,
    {
     application_status: "Approved",
     review_date: today,
    },
    { headers: authHeader() }
  );
};
const getactiveTimeSlot = (bId, status, limitNo, pageNo) => {
  return axios.get(API_URL + "/fitness/time-slot/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};

const addTimeSlot = (bid, sTime, eTime) => {
  return axios.post(
    API_URL + "/fitness/time-slot/add",
    {
      branch_id: bid,
      start_time: sTime,
      end_time: eTime,
    },
    { headers: authHeader() }
  );
};
const checkExistingTime = (bid, sTime, eTime) => {
  return axios.post(
    API_URL + "/fitness/time-slot/is-exist",
    {
      branch_id: bid,
      start_time: sTime,
      end_time: eTime,
    },
    { headers: authHeader() }
  );
};
const checkExistingTimeUpdate = (bid, sTime, eTime, packageId) => {
  return axios.post(
    API_URL + "/fitness/time-slot/is-exist/" + packageId,
    {
      branch_id: bid,
      start_time: sTime,
      end_time: eTime,
    },
    { headers: authHeader() }
  );
};

const changeTimeSlot = (package_Id, status) => {
  return axios.put(
    API_URL + `/fitness/time-slot/action/${package_Id}/${status}`,
    {},
    { headers: authHeader() }
  );
};

const freeTime = (package_Id, status) => {
  return axios.put(
    API_URL + `/fitness/trainer-schedule/action/${package_Id}/${status}`,
    {},
    { headers: authHeader() }
  );
};

const getTimeSlotInfo = (id) => {
  return axios.get(API_URL + "/fitness/time-slot/get/" + id, {
    headers: authHeader(),
  });
};

const updateTimeSlot = (bid, sTime, eTime, packageId) => {
  return axios.put(
    API_URL + "/fitness/time-slot/update/" + packageId,
    {
      branch_id: bid,
      start_time: sTime,
      end_time: eTime,
    },
    { headers: authHeader() }
  );
};
const getCafeTax = (bId, price, paymnt) => {
  return axios.get(
    API_URL +
      "/finance/setting/tax-calculator/" +
      bId +
      "/" +
      price +
      "/" +
      paymnt,
    {
      params: {},
      headers: authHeader(),
    }
  );
};
const getStaffCafeTax = (bId, price, paymnt) => {
  return axios.get(
    API_URL +
      "/finance/setting/tax-calculator/" +
      bId +
      "/" +
      price +
      "/" +
      paymnt +
      "/" +
      "staffDiscount",
    {
      params: {},
      headers: authHeader(),
    }
  );
};
const cafeCheckOut = (
  bId,
  cId,
  total_price,
  total_discount,
  total,
  payment_id,
  type,
  calculatedTax,
  selectedName,
  selectedId
) => {
  return axios.post(
    API_URL + "/orders/add",
    {
      branch_id: bId,
      client_id: cId,
      price: total_price,
      discount: total_discount,
      net_price: total,
      payment_method_id: payment_id,
      payment_received: total,
      pending_payment: 0,
      cheque_number: "",
      sale_type: type,
      tax: calculatedTax,
      note: selectedName,
      user_id: selectedId,
      discount_type: "Percentage",
      category: "10",
    },
    {
      headers: authHeader(),
    }
  );
};
const cafeManagemnetCheckOut = (
  bId,
  cId,
  total_price,
  total_discount,
  total,
  payment_id,
  type,
  calculatedTax,
  selectedName,
  selectedId
) => {
  return axios.post(
    API_URL + "/orders/add",
    {
      branch_id: bId,
      client_id: cId,
      price: total_price,
      discount: total_discount,
      net_price: total,
      payment_method_id: payment_id,
      payment_received: 0,
      pending_payment: total,
      cheque_number: "",
      sale_type: type,
      tax: calculatedTax,
      note: selectedName,
      user_id: selectedId,
      discount_type: "Amount",
      category: "10",
    },
    {
      headers: authHeader(),
    }
  );
};
const staffPendings = (bId, values) => {
  return axios.get(API_URL + "/cafe-accounts/get", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
    },
    headers: authHeader(),
  });
};
const cafeStaffCheckOut = (
  bId,
  cId,
  total_price,
  total_discount,
  total,
  payment_id,
  type,
  calculatedTax,
  selectedName
) => {
  return axios.post(
    API_URL + "/orders/add",
    {
      branch_id: bId,
      client_id: cId,
      price: total_price,
      discount: total_discount,
      net_price: total,
      payment_method_id: payment_id,
      payment_received: total,
      pending_payment: 0,
      cheque_number: "",
      sale_type: type,
      tax: calculatedTax,
      note: selectedName,
      user_id: "",
      discount_type: "Amount",
      category: "10",
    },
    {
      headers: authHeader(),
    }
  );
};
const getCafeTransactionReport = (bId, values) => {
  return axios.get(API_URL + "/transaction-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      category: "10",
    },
    headers: authHeader(),
  });
};
const getNewCafeReport = (bId, values) => {
  return axios.get(API_URL + "/transaction-report-cafe", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      // category: "10",
    },
    headers: authHeader(),
  });
};
const getDepositHistory = (bId, values) => {
  return axios.get(API_URL + "/transaction-report", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      client_id: values.client_id,
      category: "13",
    },
    headers: authHeader(),
  });
};
const getCafeSummaryReport = (bId, values) => {
  return axios.get(API_URL + "/transaction-report-summery", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      category: "10",
    },
    headers: authHeader(),
  });
};
const getDepositSummaryReport = (bId, values) => {
  return axios.get(API_URL + "/transaction-report-summery", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      client_id: values.client_id,
      category: "13",
    },
    headers: authHeader(),
  });
};
const timeSlotGet = (bId, status, limitNo) => {
  return axios.get(API_URL + "/fitness/time-slot/get", {
    params: {
      branch_id: bId,
      status: status,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};

const assignSlots = (values, time) => {
  return axios.post(
    API_URL + "/fitness/time-slot-assignment/store",
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      time_slot_id: time,
    },
    { headers: authHeader() }
  );
};
const getAssignedSlots = (bId, status, limitNo, pageNo) => {
  return axios.get(
    API_URL +
      "/fitness/time-slot-assignment/assigned-time-slots?page=" +
      pageNo,
    {
      params: {
        branch_id: bId,
        status: status,
        limit: limitNo,
      },
      headers: authHeader(),
    }
  );
};
const deleteSlots = (package_Id) => {
  return axios.put(
    API_URL + "/fitness/time-slot-assignment/delete/" + package_Id,
    {},
    { headers: authHeader() }
  );
};

const getAssignedSlotsById = (id) => {
  return axios.get(API_URL + "/fitness/time-slot-assignment/get", {
    params: {
      user_id: id,
      status: "1",
    },
    headers: authHeader(),
  });
};

const updateAssignedSlot = (values, time_slot_id) => {
  return axios.post(
    API_URL + "/fitness/time-slot-assignment/update",
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      time_slot_id: time_slot_id,
    },

    { headers: authHeader() }
  );
};

const getCafeInvoiceRecord = (br_id, values) => {
  return axios.get(API_URL + "/transaction-slip", {
    params: {
      branch_id: br_id,
      start_date: values.sdate,
      end_date: values.edate,
      category: "10",
    },
    headers: authHeader(),
  });
};
const clientBalanceCredit = (cId, amount) => {
  return axios.post(
    API_URL + "/client-balance/credit",
    {
      client_id: cId,
      amount: amount,
    },
    {
      headers: authHeader(),
    }
  );
};
const CafeRecordUpdate = (bId, cId) => {
  return axios.put(
    API_URL + "/cart/cart-clientId-update",
    {
      branch_id: bId,
      client_id: cId,
      category: "10",
    },

    { headers: authHeader() }
  );
};
const getClientsDeposits = (bId, limitNo, pageNo) => {
  return axios.get(API_URL + "/client-balance/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const ClientsDepositSearch = (search_name, bId, limit, pageNo) => {
  return axios.get(API_URL + "/client-balance/get", {
    params: {
      name: search_name,
      branch_id: bId,
      limit: limit,
      page: pageNo,
    },
    headers: authHeader(),
  });
};
const staffNameforCafe = (bId, ans) => {
  return axios.get(API_URL + "/auth/get-name", {
    params: {
      branch_id: bId,
      management: ans,
    },
    headers: authHeader(),
  });
};
const addHistory = (cId) => {
  return axios.post(
    API_URL + "/search-history/add",
    {
      client_id: cId,
    },
    { headers: authHeader() }
  );
};
const getHistory = (bid) => {
  return axios.get(API_URL + "/search-history/get", {
    params: {
      branch_id: bid,
    },
    headers: authHeader(),
  });
};
// const payCafeBalance = (uId, sDate, eDate) => {
//   return axios.get(
//     API_URL + "/cafe-accounts/sum-staff-receivable",  {
//        params:{
//      user_ids: uId,
//      start_date:sDate,
//      end_date:eDate
//      }
//      headers: authHeader(),
//     });
// };
const getCafeBalance = (uId, sDate, eDate) => {
  return axios.get(API_URL + "/cafe-accounts/sum-staff-receivable", {
    params: {
      user_ids: uId,
      start_date: sDate,
      end_date: eDate,
    },
    headers: authHeader(),
  });
};

const payCafeBalance = (uId, sDate, eDate, payValues) => {
  return axios.post(
    API_URL + "/cafe-accounts/receive-payments",
    {
      user_ids: uId,
      start_date: sDate,
      end_date: eDate,
      date: payValues.date,
      amount: payValues.amount,
      payment_method_id: payValues.payment_type_id,
      cheque_number: payValues.cheque_number,
    },
    { headers: authHeader() }
  );
};
const AddModuleForPermission = (values) => {
  return axios.post(
    API_URL + "/modules/add",
    {
      branch_id: values.branch_id,
      name: values.name,
    },
    { headers: authHeader() }
  );
};
const getModules = (bId) => {
  return axios.get(API_URL + "/modules/get", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const checkModuleName = (check_name) => {
  return axios.get(API_URL + "/modules/is-exist", {
    params: {
      name: check_name,
    },
    headers: authHeader(),
  });
};
const activeModule = (id) => {
  return axios.put(
    API_URL + "/modules/active/" + id,
    {},
    { headers: authHeader() }
  );
};
const inActiveModule = (id) => {
  return axios.put(
    API_URL + "/modules/inactive/" + id,
    {},
    { headers: authHeader() }
  );
};
const deleteModule = (id) => {
  return axios.put(
    API_URL + "/modules/delete/" + id,
    {},
    { headers: authHeader() }
  );
};
const getLaibilityBalance = (lId) => {
  return axios.get(API_URL + "/finance/liability-installments/due-amount", {
    params: {
      liability_ids: lId,
    },
    headers: authHeader(),
  });
};
const payLiabilityBalance = (bId, lId, payValues) => {
  return axios.post(
    API_URL + "/finance/liability-installments/pay",
    {
      branch_id: bId,
      liability_id: lId,
      date: payValues.date,
      amount: payValues.amount,
      resource: payValues.resource,
      bank_account_id: payValues.bank_account_id,
      cheque_no: payValues.cheque_no,
      description: payValues.description,
    },
    { headers: authHeader() }
  );
};
const getLiabilityLedger = (bId, values) => {
  return axios.get(
    API_URL + "/finance/liability-ledger/get",

    {
      params: {
        branch_id: bId,
        start_date: values.sdate,
        end_date: values.edate,
        status: "1",
        limit: "9999999999999999999999999999999999",
      },
      headers: authHeader(),
    }
  );
};
const getLiabilityLedgerById = (id) => {
  return axios.get(
    API_URL + "/finance/liability-ledger/get/" + id,

    {
      headers: authHeader(),
    }
  );
};
const deleteLiabilitiesLedger = (id) => {
  return axios.put(
    API_URL + "/finance/liability-ledger/delete/" + id,
    {},
    { headers: authHeader() }
  );
};

const addNutritionAssessments = (values, client_id) => {
  return axios.post(
    API_URL + "/fitness/nutrition-assessments/store",

    {
      ...values,
      client_id: client_id,
    },
    {
      headers: authHeader(),
    }
  );
};
const updateNutritionAssessments = (values, client_id, id) => {
  return axios.put(
    API_URL + "/fitness/nutrition-assessments/update/" + id,

    {
      ...values,
      client_id: client_id,
    },
    {
      headers: authHeader(),
    }
  );
};

const getNutritionAssessments = (bId, ClId, lim, page) => {
  return axios.get(
    API_URL + "/fitness/nutrition-assessments/index",

    {
      params: {
        branch_id: bId,
        client_id: ClId,
        limit: lim,
        page: page,
      },
      headers: authHeader(),
    }
  );
};

const getNutritionAssessmentsById = (bId, id) => {
  return axios.get(
    API_URL + "/fitness/nutrition-assessments/show/" + id,

    {
      params: {
        branch_id: bId,
      },
      headers: authHeader(),
    }
  );
};

const deleteNutritionAssessments = (id) => {
  return axios.put(
    API_URL + "/fitness/nutrition-assessments/actions/" + id + "/2",
    {},
    { headers: authHeader() }
  );
};

const addMealPlan = (values, client_id) => {
  return axios.post(
    API_URL + "/fitness/meal-plane/store",

    {
      ...values,
      client_id: client_id,
    },
    {
      headers: authHeader(),
    }
  );
};

const addMealPlanByMonth = (values, branch_id, client_id, sTime, eTime) => {
  return axios.post(
    API_URL + "/fitness/meal-plane/store",

    {
      plan: values,
      client_id: client_id,
      branch_id: branch_id,
      start_date: sTime,
      end_date: eTime,
    },
    {
      headers: authHeader(),
    }
  );
};

const updateMealPlan = (values, client_id, id) => {
  return axios.put(
    API_URL + "/fitness/meal-plane/update/" + id,

    {
      ...values,
      client_id: client_id,
    },
    {
      headers: authHeader(),
    }
  );
};
const getMealPlanList = (bId, ClId, sDate, eDate, limit, page) => {
  return axios.get(
    API_URL + "/fitness/meal-plane/listing?page=" + page,

    {
      params: {
        branch_id: bId,
        client_id: ClId,
        start_date: sDate,
        end_date: eDate,
        limit: limit,
      },
      headers: authHeader(),
    }
  );
};
const getMealPlan = (id) => {
  return axios.get(
    API_URL + "/fitness/meal-plane/get",

    {
      params: {
        uuid: id,
      },
      headers: authHeader(),
    }
  );
};

const updateMealPlanSpec = (week, catagry, day, spec, planId) => {
  return axios.put(
    API_URL + "/fitness/meal-plane/update-spec",

    {
      week: week + 1,
      meal_type: catagry,
      day: day,
      spec: spec,
      plane_id: planId,
    },
    {
      headers: authHeader(),
    }
  );
};

const deleteMealPlanSpec = (id) => {
  return axios.put(
    API_URL + "/fitness/meal-plane/destroy/" + id,
    {},
    { headers: authHeader() }
  );
};

const getMealPlanById = (bId, id) => {
  return axios.get(
    API_URL + "/fitness/meal-plane/show/" + id,

    {
      params: {
        branch_id: bId,
      },
      headers: authHeader(),
    }
  );
};

const deleteMealPlan = (id) => {
  return axios.put(
    API_URL + "/fitness/meal-plane/actions/" + id + "/2",
    {},
    { headers: authHeader() }
  );
};

const getTrainingRoster = (bId, lim, page, TrId, status) => {
  return axios.get(
    API_URL + "/fitness/time-slot-assignment/index",

    {
      params: {
        branch_id: bId,
        limit: lim,
        page: page,
        user_id: TrId,
        // status: status,
      },
      headers: authHeader(),
    }
  );
};

const getTrainingDairy = (bId, lim, page, TrId, status) => {
  return axios.get(
    API_URL + "/fitness/time-slot-assignment/assigned-time-slots",

    {
      params: {
        branch_id: bId,
        limit: lim,
        page: page,
        user_id: TrId,
        // status: status,
      },
      headers: authHeader(),
    }
  );
};

const getClientInductionPost = (bId, TrId, status, id) => {
  return axios.get(
    API_URL + "/post-assessment/listing",

    {
      params: {
        branch_id: bId,
        // start_date: "2024-01-01",
        // end_date: "2024-02-20",
        client_id: id,
        status: status,

        user_id: TrId,
      },
      headers: authHeader(),
    }
  );
};

const getClientsForTimeAssign = (bid, tr_id, cl_id, cat) => {
  return axios.get(API_URL + "/orders-detail/list-trainer-packages", {
    params: {
      branch_id: bid,
      trainer_id: tr_id,
      trainer_reservation: "Pending",
      status: "1",
      client_id: cl_id,
      limit: "999999999999999999",
      category: cat ? cat : "",
    },
    headers: authHeader(),
  });
};

const assignSlotToClient = (values, type = "") => {
  return axios.post(
    API_URL + "/fitness/trainer-schedule/add",
    {
      branch_id: values.branch_id,
      time_slot_assignment_id: values.time_id,
      order_detail_id: values.order_id,
      start_date: values.start,
      end_date: values.end,
      type: type,
    },
    { headers: authHeader() }
  );
};
const switchSlotToClient = (values) => {
  return axios.post(
    API_URL + "/fitness/time-slot-switching/store",
    {
      branch_id: values.branch_id,
      start_date: values.start,
      date: values.date,
      end_date: values.end,
      new_time_slot_id: values.time_id,
      reason: values.reason,
      schedule_id: values.schedule_id,
    },
    { headers: authHeader() }
  );
};

const dayCheckForSlot = (tm_id, start) => {
  return axios.get(API_URL + "/fitness/bookings/listing", {
    params: {
      start_date: start,
      tsa_id: tm_id,
    },
    headers: authHeader(),
  });
};
const assignDaysToClientSlot = (id, days) => {
  return axios.post(
    API_URL + "/fitness/bookings/store",
    {
      appointment_id: id,
      day: days,
    },
    { headers: authHeader() }
  );
};

const getClientsPackages = (limitNo, pageNo, values, client_id, cat) => {
  return axios.get(
    API_URL + "/orders-detail/list-trainer-packages?page=" + pageNo,
    {
      params: {
        branch_id: values.branch_id,
        trainer_id: values.trainer_id,
        trainer_reservation: values.trainer_reservation,
        client_id: client_id,
        limit: limitNo,
        category: cat ? cat : "2",
        status: "1",
      },
      headers: authHeader(),
    }
  );
};

const getTrainerAvaliability = (tr_id, sDate) => {
  return axios.get(API_URL + "/fitness/trainer-schedule/availability", {
    params: {
      start_date: sDate,
      time_slot_assignment_id: tr_id,
    },
    headers: authHeader(),
  });
};

const getClientsPreAssesmentList = (limitNo, pageNo, values, client_id) => {
  return axios.get(API_URL + "/pre-assessment/index?page=" + pageNo, {
    params: {
      branch_id: values.branch_id,
      client_id: client_id,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};

const getClientsPostAssesmentList = (limitNo, pageNo, values, client_id) => {
  return axios.get(API_URL + "/post-assessment/index?page=" + pageNo, {
    params: {
      branch_id: values.branch_id,
      client_id: client_id,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const postAssesmentComparison = (bId, lim, page, TrId, status) => {
  return axios.get(
    API_URL + "/fitness/time-slot-assignment/assigned-time-slots",

    {
      params: {
        branch_id: bId,
        limit: lim,
        page: page,
        user_id: TrId,
        // status: status,
      },
      headers: authHeader(),
    }
  );
};

const getNewPtBookings = (limitNo, pageNo, values) => {
  return axios.get(
    API_URL + "/fitness/trainer-schedule/new-appointments?page=" + pageNo,
    {
      params: {
        branch_id: values.branch_id,
        limit: limitNo,
        type: values.type,
        user_id: values.trainer_id,
      },
      headers: authHeader(),
    }
  );
};

const getBookingsForTrainer = (bId, TrId, opt) => {
  return axios.get(API_URL + "/fitness/trainer-schedule/new-appointments", {
    params: {
      branch_id: bId,
      user_id: TrId,
      have_days: opt,
    },
    headers: authHeader(),
  });
};

const getTrainerNewAppointments = (limitNo, pageNo, values) => {
  return axios.get(API_URL + "/fitness/trainer-schedule/index?page=" + pageNo, {
    params: {
      branch_id: values.branch_id,
      limit: limitNo,
      start_date: values.sDate,
      end_date: values.eDate,
      user_id: values.trainer_id,
    },
    headers: authHeader(),
  });
};

const getPTReport = (bId, values, ClId, page, limit) => {
  return axios.get(API_URL + "/orders-detail/list-trainer-packages", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      trainer_reservation: values.trStatus,
      trainer_id: values.trainer_id,
      client_id: ClId,
      sale_type: values.sale_type,
      exclude_old_record: false,
      page: page,
      limit: limit,
    },
    headers: authHeader(),
  });
};
const getPTSessionAttendanceReport = (bId, values, page, limit, type = "") => {
  return axios.get(API_URL + "/fitness/session-attendance/get", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      trainer_id: values.trainer_id,
      order_id: values.order_id ? values.order_id : "",
      client_status: values.client_status,
      client_id: values.client_id,
      staff_status: values.trainer_status,
      type: values.type ? values.type : "",
      package_id: values.package_id ? values.package_id : "",
      page: page,
      limit: limit,
      type: type,
    },
    headers: authHeader(),
  });
};

// TODO
const getSummaryReport = (bId, values, page, limit, type = "") => {
  return axios.get(API_URL + "/fitness/session-attendance/summery-get", {
    params: {
      branch_id: bId,
      start_date: values.sdate,
      end_date: values.edate,
      trainer_id: values.trainer_id,
      order_id: values.order_id ? values.order_id : "",
      client_status: values.client_status,
      client_id: values.client_id,
      staff_status: values.trainer_status,
      type: values.type ? values.type : "",
      package_id: values.package_id ? values.package_id : "",
      page: page,
      limit: limit,
      type: type,
    },
    headers: authHeader(),
  });
};

const getClientsFitnessPlans = (limitNo, pageNo, values, client_id) => {
  return axios.get(API_URL + "/fitness/plan/listing?page=" + pageNo, {
    params: {
      branch_id: values.branch_id,
      start_date: values.start,
      end_date: values.end,
      trainer_id: values.trainer_id,
      client_id: client_id,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getClientsFitnessPlansDetail = (id, day, tanningType) => {
  return axios.get(API_URL + "/fitness/plan/index", {
    params: {
      plan_id: id,
      day: day,
      tanning_type: tanningType,
    },
    headers: authHeader(),
  });
};

const addMultipleFitnessPlans = (training, values, client_id) => {
  return axios.post(
    API_URL + "/fitness/plan/store",
    {
      traing: training,
      branch_id: values.branch_id,
      trainer_id: values.trainer_id,
      start_date: values.start_date,
      note: values.note,
      end_date: values.end_date,
      client_id: client_id,
    },
    {
      headers: authHeader(),
    }
  );
};

const changeExercise = (package_Id, status) => {
  return axios.put(
    API_URL + `/fitness/plan/actions/${package_Id}/${status}`,
    {},
    { headers: authHeader() }
  );
};

const updateMultipleFitnessPlans = (training, values, id) => {
  return axios.put(
    API_URL + "/fitness/plan/update/" + id,
    {
      traing: training,
      branch_id: values.branch_id,
      trainer_id: values.trainer_id,
      start_date: values.start_date,
      note: values.note,
      end_date: values.end_date,
      client_id: values.client_name,
    },
    {
      headers: authHeader(),
    }
  );
};

const switchedTimeSlot = (limitNo, pageNo, bId, trainer_id) => {
  return axios.get(
    API_URL + "/fitness/time-slot-switching/index?page=" + pageNo,
    {
      params: {
        branch_id: bId,
        limit: limitNo,
        trainer_id: trainer_id,
      },
      headers: authHeader(),
    }
  );
};

const switchedTimeSlotAction = (id, status) => {
  return axios.put(
    API_URL + `/fitness/time-slot-switching/actions/${id}/${status}`,
    {},
    { headers: authHeader() }
  );
};

const switchedTimeProcess = (id, status) => {
  return axios.post(
    API_URL + "/fitness/time-slot-switching/process-request/" + id,
    {
      request_status: status,
    },
    { headers: authHeader() }
  );
};

const createPlanForClient = (values, client_id) => {
  return axios.post(
    API_URL + "/fitness/plan/create",
    {
      branch_id: values.branch_id,
      trainer_id: values.trainer_id,
      start_date: values.start_date,
      note: values.note,
      end_date: values.end_date,
      client_id: client_id,
    },
    {
      headers: authHeader(),
    }
  );
};

const addDetailForFitnessPlan = (plan, planId, catagroyId) => {
  return axios.post(
    API_URL + "/fitness/plan/store",
    {
      plan: plan,
      plan_id: planId,
      category_id: catagroyId,
    },
    {
      headers: authHeader(),
    }
  );
};

const SubCategoriesDropdoenNameBranchLess = (ctgry) => {
  return axios.get(
    API_URL + "/finance/sub-categories/fetch-subcategories-names",
    {
      params: {
        category_id: ctgry,

        status: "1",
      },
      headers: authHeader(),
    }
  );
};

const befitPkgTypesList = (catagry) => {
  return axios.get(API_URL + "/packages/names-list", {
    params: {
      category: catagry,

      status: "1",
    },
    headers: authHeader(),
  });
};

const befitPkgSell = (bId, cId, eDate, catgry, values, pkgId) => {
  return axios.post(
    API_URL + "/cart/add",
    {
      branch_id: bId,
      client_id: cId,
      start_date: values.sdate,
      end_date: eDate,
      price: 0,
      discount_type: values.discount_type,
      discount: values.discount_amount,
      net_price: values.pay_after_dis,
      note: values.salesnotes,
      sale_date: values.salesdate,
      category: catgry,
      package_id: pkgId,
      quantity: values.qty,
      discount_approve_by: values.approveBy,
      reference_by: values.referencedBy,
    },
    {
      headers: authHeader(),
    }
  );
};

const checkGfxSpace = (pkg_type) => {
  return axios.get(API_URL + "/packages/check-space/" + pkg_type, {
    params: {},
    headers: authHeader(),
  });
};

const getGxClasses = (limitNo, pageNo, values) => {
  return axios.get(API_URL + "/packages/gx?page=" + pageNo, {
    params: {
      branch_id: values.branch_id,
      trainer_id: values.trainer_id,
      package_id: values.class_id,
      category: values.catagrey ? values.catagrey : "",
      limit: limitNo,
    },
    headers: authHeader(),
  });
};

const assignSlotToPkg = (values) => {
  return axios.post(
    API_URL + "/fitness/trainer-schedule/add",
    {
      branch_id: values.branch_id,
      time_slot_assignment_id: values.time_id,
      package_id: values.order_id,

      type: "GX",
    },
    { headers: authHeader() }
  );
};

const getGXNewAppointments = (limitNo, pageNo, values) => {
  return axios.get(
    API_URL + "/fitness/trainer-schedule/index-gx?page=" + pageNo,
    {
      params: {
        branch_id: values.branch_id,
        limit: limitNo,
        start_date: values.sDate,
        end_date: values.eDate,
        user_id: values.trainer_id,
      },
      headers: authHeader(),
    }
  );
};

const befitAssessmentsData = (values, clId, id) => {
  return axios.post(
    API_URL + "/fitness/befit/store",
    {
      branch_id: values.branch_id,
      client_id: clId,
      order_item_id: id,
      training_goals: values.training_goals,
      prior_exercise_experience: values.prior_exercise_experience,
      injuries: values.injuries,
      exercise_advice: values.exercise_advice,
      date: values.date,
      trainer_id: values.trainer_id,
      age: values.age,
    },
    { headers: authHeader() }
  );
};

const getBefitAssessmentsData = (limitNo, pageNo, branch_id) => {
  return axios.get(API_URL + "/fitness/befit/index?page=" + pageNo, {
    params: {
      branch_id: branch_id,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};

const deleteBefitAssessmentsData = (id) => {
  return axios.put(
    API_URL + "/fitness/befit/actions/" + id + "/2",
    {},
    { headers: authHeader() }
  );
};

const addGfxClass = (values, selectedDays) => {
  return axios.post(
    API_URL + "/packages/add",
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      package_name: values.package_name,
      status: "0",
      time_id: values.time_id,
      day: selectedDays,
      session_count: values.session_count,
      booking_capacity: values.booking_space,
      category: "15",
    },
    { headers: authHeader() }
  );
};

const UpdateGfxClass = (values, id, sTime, eTime) => {
  return axios.put(
    API_URL + "/packages/update/" + id,
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      package_name: values.package_name,

      start_time: sTime,
      end_time: eTime,
      session_count: values.session_count,
      booking_capacity: values.booking_space,
      category: "15",
    },

    { headers: authHeader() }
  );
};

const allClassesList = (bId, catagry, tr_id) => {
  return axios.get(API_URL + "/packages/gx-classes-listing", {
    params: {
      branch_id: bId,
      category: catagry,

      user_id: tr_id ? tr_id : "",
    },
    headers: authHeader(),
  });
};

const addGfxTrainee = (values) => {
  return axios.post(
    API_URL + "/auth/update/" + values.user_id,
    {
      branch_id: values.branch_id,
      is_gx_trainer: values.is_gx_trainer,
    },
    { headers: authHeader() }
  );
};

const getGfxTrainee = (bId, limitNo, pageNo) => {
  return axios.get(
    API_URL + "/auth/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
        is_gx_trainer: "1",
      },
      headers: authHeader(),
    }
  );
};
const delFromGxTrainer = (id) => {
  return axios.post(
    API_URL + "/auth/update/" + id,
    {
      is_gx_trainer: false,
    },
    { headers: authHeader() }
  );
};

const assignClassToSlot = (values) => {
  return axios.post(
    API_URL + "/fitness/gx-class/store",
    {
      package_id: values.slot_id,
      name: values.package_name,

      day: values.day,
    },
    { headers: authHeader() }
  );
};

const getclassesListWithSlots = (bId, limitNo, pageNo, pkgId) => {
  return axios.get(
    API_URL + "/fitness/gx-class/index?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        package_id: pkgId,
        limit: limitNo,
        value: "15",
        key: "category",
      },
      headers: authHeader(),
    }
  );
};

const deleteClassInSlot = (package_Id, status) => {
  return axios.put(
    API_URL + `/fitness/gx-class/actions/${package_Id}/${status}`,
    {},
    { headers: authHeader() }
  );
};

const getClassInSlot = (id) => {
  return axios.get(API_URL + "/fitness/gx-class/show/" + id, {
    headers: authHeader(),
  });
};
const updateClassInSlot = (values, id) => {
  return axios.put(
    API_URL + "/fitness/gx-class/update/" + id,
    {
      package_id: values.slot_id,
      name: values.package_name,

      day: values.day,
    },

    { headers: authHeader() }
  );
};

const getDaysOfSlots = (id) => {
  return axios.get(API_URL + "/fitness/trainer-schedule/fetch-days/" + id, {
    params: {},
    headers: authHeader(),
  });
};

const getBefitPkgs = (tId, cat = "") => {
  return axios.get(API_URL + "/fitness/trainer-schedule/fetch-befit/" + tId, {
    params: {
      category: cat,
      // trainer_id: value,
    },
    headers: authHeader(),
  });
};

const getSPTPackages = (bId, limitNo, pageNo, val = "") => {
  return axios.get(
    API_URL + "/packages/get?page=" + pageNo,

    {
      params: {
        branch_id: bId,
        limit: limitNo,
        value: val,
        key: "category",
      },
      headers: authHeader(),
    }
  );
};

const assignSlotToSPT = (values, type = "") => {
  return axios.post(
    API_URL + "/fitness/trainer-schedule/add",
    {
      branch_id: values.branch_id,
      time_slot_assignment_id: values.time_id,
      package_id: values.order_id,

      type: type,
    },
    { headers: authHeader() }
  );
};

const getSptAppointments = (limitNo, pageNo, values) => {
  return axios.get(
    API_URL + "/fitness/trainer-schedule/index-small-pt?page=" + pageNo,
    {
      params: {
        branch_id: values.branch_id,
        limit: limitNo,
        start_date: values.sDate,
        end_date: values.eDate,
        user_id: values.trainer_id,
      },
      headers: authHeader(),
    }
  );
};

const getNewGXBookings = (limitNo, pageNo, values, client_id) => {
  return axios.get(
    API_URL + "/orders-detail/training-indexing?page=" + pageNo,
    {
      params: {
        branch_id: values.branch_id,
        limit: limitNo,
        status: "1",
        category: values.category,
        user_id: values.trainer_id,
        package_id: values.class_id,
        client_id: client_id,
      },
      headers: authHeader(),
    }
  );
};
const addLeaveApplication = (values,numberOfDays) => {
  return axios.post(
    API_URL + "/hr/leave-application/store",
    {
       branch_id: values.branch_id,
        user_id:  values.user_id,
        leave_status: values.leave_status,
        from: values.from,
        to: values.to,
        reason: values.reason,
        leave_type: values.type,
        category: values.category,
        number_of_leaves: numberOfDays,
    },
    { headers: authHeader() }
  );
};
const addEmployeHours = (values, time) => {
  return axios.post(
    API_URL + "/staff-timing/store",
    {
      branch_id: values.branch_id,
      staff_id: values.user_id,
      day: time,
      start_time: values.start_time,
      end_time: values.end_time,
    },
    { headers: authHeader() }
  );
};
const getActiveEmployeHours = (bId, limitNo, pageNo) => {
  return axios.get(API_URL + "/staff-timing/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getFilterActiveEmployeHours = (bId, staff_id, limitNo, pageNo) => {
  return axios.get(API_URL + "/staff-timing/get?page=" + pageNo, {
    params: {
      branch_id: bId,
      staff_id: staff_id,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const deutyHoursActions = (package_Id, action) => {
  return axios.put(
    API_URL + "/staff-timing/actions/" + package_Id + "/" + action,
    {},
    { headers: authHeader() }
  );
};
const getDeutyHoursInfo = (id) => {
  return axios.get(API_URL + "/staff-timing/show/" + id, {
    headers: authHeader(),
  });
};
const UpdateDutyHours = (values, id) => {
  return axios.put(
    API_URL + "/staff-timing/update/" + id,
    {
      branch_id: values.branch_id,
      staff_id: values.user_id,
      day: values.day,
      start_time: values.start_time,
      end_time: values.end_time,
    },

    { headers: authHeader() }
  );
};
const checkExistingDutyHours = (user, day) => {
  return axios.get(API_URL + "/staff-timing/is-exist", {
    params: {
      staff_id: user,
      day: day,
    },
    headers: authHeader(),
  });
};
const checkUpdateExistingDutyHours = (values, id) => {
  return axios.get(API_URL + "/staff-timing/is-exist/" + id, {
    params: {
      staff_id: values.user_id,
      day: values.day,
    },
    headers: authHeader(),
  });
};

const addStaffPromotion = (
  values,
  prev_department,
  prev_designation,
  prev_salary,
  catType
) => {
  return axios.post(
    API_URL + "/hr/promotion/store",
    {
      branch_id: values.branch_id,
      user_id: values.user_id,
      previous_department: prev_department,
      previous_designation: prev_designation,
      new_department: values.new_department,
      new_designation: values.new_designation,
      previous_salary: prev_salary,
      new_salary: values.new_salary,
      date: values.date,
      type: catType,
      commission: values.commission,
      promotion_type: values.promotion_type,
      details: values.details,
    },
    { headers: authHeader() }
  );
};
const getActivePromotions = (bId, limitNo, pageNo) => {
  return axios.get(API_URL + "/hr/promotion/index?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const staffAttendanceCount = (bId) => {
  return axios.get(API_URL + "/attendance/summery", {
    params: {
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const promotionsActions = (package_Id, action) => {
  return axios.put(
    API_URL + "/hr/promotion/actions/" + package_Id + "/" + action,
    {},
    { headers: authHeader() }
  );
};
const getPromotionsInfo = (id) => {
  return axios.get(API_URL + "/hr/promotion/show/" + id, {
    headers: authHeader(),
  });
};
const getFilteredPromotions = (id, bId) => {
  return axios.get(API_URL + "/hr/promotion/index", {
    params: {
      user_id: id,
      branch_id: bId,
    },
    headers: authHeader(),
  });
};
const checkQuotaLeave = (values) => {
  return axios.post(
    API_URL + "/hr/leaves-quota/is-exist",
    {
      leave_type: values.leave_type,
      user_id: values.user_id,
    },
    { headers: authHeader() }
  );
};
const checkQuotaLeaveUpdate = (values, id) => {
  return axios.post(
    API_URL + "/hr/leaves-quota/is-exist/" + id,
    {
      leave_type: values.leave_type,
      user_id: values.user_id,
    },
    { headers: authHeader() }
  );
};
const addQuotaLeave = (values) => {
  return axios.post(
    API_URL + "/hr/leaves-quota/store",
    {
      branch_id: values.branch_id,
      leave_type: values.leave_type,
      user_id: values.user_id,
      number_of_leaves: values.number_of_leaves,
    },
    { headers: authHeader() }
  );
};
const getLeaveQuota = (bId, limitNo, pageNo, user) => {
  return axios.get(API_URL + "/hr/leaves-quota/index?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      user_id: user
    },
    headers: authHeader(),
  });
};
const leaveQuotaActions = (package_Id, action) => {
  return axios.put(
    API_URL + "/hr/leaves-quota/actions/" + package_Id + "/" + action,
    {},
    { headers: authHeader() }
  );
};
const getLeaveQuotaInfo = (id) => {
  return axios.get(API_URL + "/hr/leaves-quota/show/" + id, {
    headers: authHeader(),
  });
};
const UpdateLeaveQuota = (values, id) => {
  return axios.put(
    API_URL + "/hr/leaves-quota/update/" + id,
    {
        branch_id: values.branch_id,
      leave_type: values.leave_type,
      user_id: values.user_id,
      number_of_leaves: values.number_of_leaves,
    },

    { headers: authHeader() }
  );
};
const existingLeaveCheck = (numberOfDays, values) => {
  return axios.post(
    API_URL + "/hr/leave-application/is-exist",
    {
       user_id: values.user_id,
       from: values.from,
        to: values.to,
        leave_type: values.type,
        number_of_leaves: numberOfDays,
    },
    { headers: authHeader() }
  );
};
const eligibilityLeaveCheck = (values) => {
  return axios.post(
    API_URL + "/attendance/check-leave-eligibility",
    {
       user_id: values.user_id,
       date: values.from,
    },
    { headers: authHeader() }
  );
};
const availabilityLeaveCheck = (numberOfDays, values) => {
  return axios.post(
    API_URL + "/hr/leave-application/check-leave-availability",
    {
       user_id: values.user_id,
       leave_type: values.type,
       number_of_leaves: numberOfDays,
    },
    { headers: authHeader() }
  );
};
const availabilityLeaveCheckForApproval = ({ number_of_leaves, leave_type, user_id }) => {
  return axios.post(
    API_URL + "/hr/leave-application/check-leave-availability",
    {
      number_of_leaves,
      leave_type,
      user_id,
    },
    { headers: authHeader() }
  );
};
const getProfileLeaveQuota = (id, bId, limitNo, pageNo) => {
  return axios.get(API_URL + "/hr/leaves-quota/index?page=" + pageNo, {
    params: {
      user_id: id,
      branch_id: bId,
      limit: limitNo,
    },
    headers: authHeader(),
  });
};
const getLatestStaffSalarySlip = (br_id, sdate, edate, id) => {
  return axios.get(API_URL + "/salary", {
    params: {
      branch_id: br_id,
      start_date: edate + "-" + parseInt(sdate - 1) + "-26",
      end_date: edate + "-" + sdate + "-25",
      user_id: id,
    },
    headers: authHeader(),
  });
};
const addCertificatesLetters = (values) => {
  return axios.post(
    API_URL + "/hr/staff-documents/store",
    {
      branch_id: values.branch_id,
    user_id: values.user_id,
    document_category: values.document_category,
    document_type: values.document_type,
    issue_date: values.issue_date,
    document_code: values.document_code,
    subject: values.subject,
    },
    { headers: authHeader() }
  );
};
const getCertificatesLetters = (bId, limitNo, pageNo, user) => {
  return axios.get(API_URL + "/hr/staff-documents/index?page=" + pageNo, {
    params: {
      branch_id: bId,
      limit: limitNo,
      user_id: user
    },
    headers: authHeader(),
  });
};
const CertificatesLettersActions = (record_Id, action) => {
  return axios.put(
    API_URL + "/hr/staff-documents/actions/" + record_Id + "/" + action,
    { headers: authHeader() }
  );
};
const getCertificatesLettersInfo = (id) => {
  return axios.get(API_URL + "/hr/staff-documents/show/" + id, {
    headers: authHeader(),
  });
};
const UpdateCertificatesLetters = (values, id) => {
  return axios.put(
    API_URL + "/hr/staff-documents/update/" + id,
    {
    branch_id: values.branch_id,
    user_id: values.user_id,
    document_category: values.document_category,
    document_type: values.document_type,
    issue_date: values.issue_date,
    document_code: values.document_code,
    subject: values.subject,
    },

    { headers: authHeader() }
  );
};
const sessionVerification = (id) => {
  return axios.put(
    API_URL + "/session_attendance/update-session-verification/" + id,
    {},
    { headers: authHeader() }
  );
};
const UserServices = {
  Login,
  getProfInfo,
  updateProfInfo,
  addStaff,
  departmentName,
  designationName,
  branchName,
  categoriesName,
  getactiveUsers,
  staffNameSearch,
  getStaffDataByType,
  getStaffDataByDepartment,
  deleteStaff,
  getClientReport,
  getDetailSaleReport,
  getCafeSaleReport,
  getSummerySaleReport,
  getSummeryCafeSaleReport,
  getSummerySaleReportAll,
  getSummerySaleReportAllNew,
  getDetailSaleReportAll,
  getDetailSaleReportAllNew,
  getDetailSaleReportBootcamp,
  getDetailedTransactionReport,
  getTransactionReport,
  clientPreAssessment,
  getAssessmentDetailsById,
  getMeasurementsById,
  addPostAssessment,
  paymentNames,
  membershipType,
  dynamicRegNo,
  membershipFee,
  gymPkgList,
  timeSlot,
  ptGroupPkgs,
  registrationPkgs,
  trainerPkgList,
  trainerTimeList,
  trainerPkgTypesList,
  pkgFee,
  validityDates,
  staffcatagries,
  checkEmail,
  checkEmailwId,
  checkPhone,
  checkStaffPhone,
  checkCard,
  checkCnic,
  checkStaffCnic,
  addCheckPhone,
  addCheckCnic,
  addStaffCheckCnic,
  addClients,
  getClients,
  getClientDataByType,
  deleteClient,
  deleteClientCartItem,
  cartDestroy,
  getinactiveClients,
  inactiveStaff,
  activeStaff,
  inactiveClients,
  activeClients,
  clientCount,
  staffCount,
  packageCount,
  sellpackageCount,
  expensesCount,
  getClientProfileInfo,
  UpdateClientProfile,
  getStartEndDates,
  staffName,
  addStaffFines,
  addStaffAdvances,
  addStaffLoan,
  getStaffFines,
  getStaffAdvanceDataByTime,
  getStaffLoanDataByTime,
  getStaffFinesDataByTime,
  getStaffAdvances,
  getStaffLoans,
  getStaffLoansInfo,
  getStaffFinesInfo,
  UpdateStaffFines,
  deleteStaffFines,
  UpdateStaffAdvances,
  UpdateStaffLoan,
  getStaffProfileInfo,
  UpdateStaffProfile,
  getStaffFinesDataByYear,
  getStaffAdvanceDataByYear,
  addPackages,
  getStaffSalary,
  getTaxy,
  getSales,
  getPackages,
  MembershipType,
  TrainerCategory,
  TrainerName,
  NutritionistName,
  clientSearch,
  overAllClientSearch,
  getClientName,
  getclientCatagriesList,
  getClientsName,
  getClientsPurchases,
  getClientsPurchasesinfo,
  getClientsPurchasesForInvoice,
  getPaymentsDataForInvoice,
  getClientsAccountsForInvoice,
  getClientsAccountsForInvoicePurchases,
  getBranchesForInvoice,
  getClientsAccounts,
  getClientsPayments,
  paymentPendAdd,
  orderDateUpdate,
  getOrderPayments,
  getOrderDates,
  getClientsCart,
  getTaxPercent,
  getCafeCart,
  updateCafeCart,
  getactivePackage,
  deletePackages,
  getPackagesInfo,
  UpdatePackages,
  UpdateLesMills,
  UpdateClientPurchaseDate,
  UpdateClientPurchaseMethod,
  checkPackageName,
  checkAddPackageName,
  getClientsNameById,
  getAssessedClient,
  getClientActiveGymPkg,
  getClientActiveRegistration,
  gymPkgSell,
  physioPkgSell,
  trainerPkgSell,
  registerPkgSell,
  depositPkgSell,
  genralPkgSell,
  CFTpkgSell,
  sessionPkgSell,
  checkOutOrder,
  guestPassSell,
  smallGroupSell,
  NutritionistPkgSell,
  addBranches,
  checkBranchCode,
  getBranches,
  getBranchesFiltration,
  getActiveBranches,
  deleteBrnch,
  getBrnchInfo,
  UpdateBrnch,
  MemPkgSearch,
  getExpences,
  // getExpencesFiltration,
  getCurrentYearExpences,
  getCurrentTodayExpences,
  getCurrentMonthExpences,
  paymentType,
  expenseType,
  addExpenses,
  expenseAddMultiple,
  getExpencesInfo,
  UpdateExp,
  deleteExpenses,
  getExpenseDataByTime,
  searchExpenseType,
  getExpncFiltration,
  getExpenseDataByYear,
  addNutritionPackages,
  getactiveNutritionPackage,
  addTrainerPackages,
  getactiveTrainerPackage,
  addPtGroupPackages,
  getactivePtPackage,
  addMembershipPackages,
  addPhysioPackages,
  addCafeDeposit,
  addLesMillsPackages,
  addCFTpackages,
  addGenralPkg,
  getactiveMembershipPackage,
  getactivePhysioPackage,
  getCafeDeposit,
  getactiveLesMils,
  getactiveCFTpackage,
  getactiveGenralPkg,
  addSessionPackages,
  getactiveSessionPackage,
  MembershipCategory,
  getNutritionPackagesInfo,
  getPtPackageInfo,
  getSessionPackageInfo,
  getTrainerPackagesInfo,
  getMembershipPackagesInfo,
  getPhysioPackagesInfo,
  getCafeDepositInfo,
  getLesMillsInfo,
  getGenralPackagesInfo,
  getGymPackagesInfo,
  getCFTpackagesInfo,
  inactivePackage,
  activePackage,
  TrainingCategory,
  getClientInfo,
  getStaffInfo,
  addClientCard,
  getCards,
  getCardsData,
  getVisitorCards,
  getStaffLoanDataByYear,
  getStaffName,
  staffSearch,
  getVisitorCardsData,
  inactiveCard,
  activeCard,
  getClientsCards,
  getClientsCardsData,
  getStaffCards,
  getStaffCardsData,
  getStfCrd,
  getStfCrdData,
  getGymPackages,
  getGymPackagesByTime,
  getFinesYearExport,
  RevenueCategoryNames,
  RevenueCafeCategoryNames,
  getAllCards,
  searchAllCards,
  searchStaffCards,
  deleteCards,
  getStaffAttendence,
  getEmployeeAttendence,
  getClientAttendence,
  checkClientName,
  freezingPkgSell,
  getActiveFee,
  inactiveFee,
  getInactiveFee,
  deleteFee,
  getFeeInfo,
  getAllCardsData,
  addManagerResources,
  getActiveResources,
  getActiveFilterResources,
  inactiveResource,
  activeResource,
  deleteRescource,
  getResourceInfo,
  updateResource,
  addTowls,
  getTowls,
  getTowelsFiltration,
  deleteTowels,
  getTowelsInfo,
  getRoleInfo,
  UpdateTowels,
  getAttendance,
  getAttendanceInfo,
  getSingleStaffAttendence,
  getStaffAttendanceInfo,
  deleteStaffAdvance,
  getTrainerNutritionistName,
  getOrderName,
  getClientFilterName,
  checkSessiuonAttendanceForTrainer,
  checkCompleteAttendanceForTrainer,
  checkScheduleForTrainerPkg,
  addAttendanceForTrainer,
  getActiveAttendanceForTrainer,
  getAttendanceByTypeTrainer,
  getAttendanceByPackageTrainer,
  checkAttendance,
  inactiveAttendance,
  activeAttendance,
  deleteAttendance,
  getSessionAttendanceInfo,
  UpdateAttendance,
  getPrindCards,
  getPrintStaffCards,
  checkName,
  updateCheckName,
  checkAccountBlance,
  getGymCardByType,
  uploadFile,
  getUserRoles,
  getUserRolesData,
  UpdateUserRoll,
  freezingPkgName,
  checkAuthPhone,
  checkCategoriesName,
  addCatagory,
  addCafeCatagory,
  activeCategories,
  cafeCategory,
  getCategoryDataByType,
  categorySearch,
  deleteCategory,
  getCategoriesInfo,
  updateCategoriesName,
  UpdateCategories,
  UpdateCafeCategory,
  addExercise,
  CategoriesName,
  deleteExercise,
  UpdateExercise,
  addSubCatagory,
  checkSubCategoriesName,
  activeSubCategories,
  subCategorySearch,
  getSubCategoriesInfo,
  UpdateSubCategories,
  activeExercises,
  getExerciseInfo,
  deleteSubCategory,
  SubCategoriesName,
  addCafePackages,
  updateCafePackages,
  CafeCategory,

  getActiveCafe,
  getActiveCafeProducts,
  getActiveCafeByCatagryId,
  getCafeInfo,
  getCafeCtgry,
  cafePkgList,
  cafePkgFee,
  cafePkgSell,
  officeCashFlowAdd,
  bankCashFlowAdd,
  keeneCashFlowAdd,
  PettyCashAdd,
  GthirteenCashAdd,
  getOfficeCashFlow,
  getBankCashFlow,
  getPettyCashFlow,
  getGthirteenCashFlow,
  getKneeLedger,
  bankCashSearch,
  deleteOfficeCashFlow,
  deleteBankCashFlow,
  deletePettyCashFlow,
  deleteGthirteenCashFlow,
  deleteKneeCashFlow,
  getCashFlowInfo,
  getBankCashFlowInfo,
  getPettyCashFlowInfo,
  getGthirteenCashFlowInfo,
  keeneCashFlowInfo,
  officeCashUpdate,
  laibilitiesAdd,
  laibilitieLedgersAdd,
  laibilitieLedgersUpdate,
  laibilitiesAddMultiple,
  laibilitiesPay,
  loanPay,
  liabilitiesGet,
  liabilitiesSearch,
  getLiabilitiesFiltration,
  inactiveLiabilities,
  liabilitiesUpdate,
  getInactiveLiabilities,
  deleteLiabilities,
  deleteInstallmets,
  deleteLoanInstallmets,
  getLaibilitiesInfo,
  assetsAdd,
  assetsGet,
  getAssetsFiltration,
  getAssetsInfo,
  assetsUpdate,
  inactivAssets,
  deleteAssets,
  activeAssets,
  balanceBank,
  balanceOffice,
  creditBalanceBank,
  debitBalanceBank,
  debitBalanceOffice,
  creditBalanceOffice,
  balanceSales,
  debitBalanceSales,
  creditBalanceSales,
  monthSaleExpenses,
  monthSaleExpensesForDashboard,
  getDaliySalesCount,
  dailySaleExpenses,
  paymentMethodDetails,
  categorySale,
  getDailyClosingExpense,
  getAsetFiltration,
  getLaibilitiesFiltration,
  addTax,
  getTax,
  inactiveSettings,
  activeSettings,
  deleteSettings,
  getSettingInfo,
  UpdateSetting,
  getSettingsFiltration,
  SubCategoriesDropdoenName,
  CategoriesDropdoenName,
  exercisesDropdoenName,
  addAccountdetails,
  getAccountDetails,
  inactiveAccountDetails,
  activeAccountDetails,
  deleteDetails,
  getBankDetailsInfo,
  bankDetailsUpdate,
  bankList,
  totalInstallment,
  totalLoans,
  notificationGet,
  readMessage,
  notificationDelete,
  bankCashUpdate,
  pettyCashUpdate,
  keeneCashUpdate,
  getApprovals,
  getPkgApprovals,
  rejectRecord,
  approveRecord,
  getactiveTimeSlot,
  addTimeSlot,
  checkExistingTime,
  checkExistingTimeUpdate,
  changeTimeSlot,
  freeTime,
  getTimeSlotInfo,
  updateTimeSlot,
  getAttendenceSummary,
  getStaffAttendenceSummary,
  getCafeTax,
  cafeCheckOut,
  cafeStaffCheckOut,
  cafeManagemnetCheckOut,
  getCafeTransactionReport,
  getNewCafeReport,
  getDepositHistory,
  getCafeSummaryReport,
  getDepositSummaryReport,
  timeSlotGet,
  assignSlots,
  getAssignedSlots,
  getAssignedSlotsById,
  updateAssignedSlot,
  deleteSlots,
  getCafeClient,
  getCafeClientDeposit,
  DeleteClientPurchase,
  getCafeInvoiceRecord,
  getStaffCafeTax,
  clientBalanceCredit,
  CafeRecordUpdate,
  getClientsDeposits,
  ClientsDepositSearch,
  staffNameforCafe,
  addHistory,
  getHistory,
  staffPendings,
  getCafeBalance,
  payCafeBalance,
  AddModuleForPermission,
  getModules,
  checkModuleName,
  activeModule,
  inActiveModule,
  deleteModule,
  getLaibilityBalance,
  payLiabilityBalance,
  getLiabilityLedger,
  deleteLiabilitiesLedger,
  getLiabilityLedgerById,
  addNutritionAssessments,
  getNutritionAssessments,
  deleteNutritionAssessments,
  getNutritionAssessmentsById,
  updateNutritionAssessments,
  addMealPlan,
  addMealPlanByMonth,
  updateMealPlan,
  getMealPlan,
  getMealPlanList,
  updateMealPlanSpec,
  deleteMealPlanSpec,
  getMealPlanById,
  deleteMealPlan,
  getTrainingRoster,
  getTrainingDairy,
  getClientInductionPost,
  getClientsForTimeAssign,
  assignSlotToClient,
  assignDaysToClientSlot,
  switchSlotToClient,
  dayCheckForSlot,
  getClientsPackages,
  getTrainerAvaliability,
  getClientsPreAssesmentList,
  getClientsPostAssesmentList,
  postAssesmentComparison,
  getNewPtBookings,
  getBookingsForTrainer,
  getTrainerNewAppointments,
  getPTReport,
  getPTSessionAttendanceReport,
  getSummaryReport,
  getClientsFitnessPlans,
  getClientsFitnessPlansDetail,
  addMultipleFitnessPlans,
  changeExercise,
  updateMultipleFitnessPlans,
  switchedTimeSlot,
  switchedTimeSlotAction,
  switchedTimeProcess,
  createPlanForClient,
  addDetailForFitnessPlan,
  SubCategoriesDropdoenNameBranchLess,

  UpdateGfxPackages,
  getGfxPackages,
  getGfxPackageInfo,
  befitPkgTypesList,
  befitPkgSell,
  checkGfxSpace,
  getGxClasses,
  assignSlotToPkg,
  getGXNewAppointments,
  befitAssessmentsData,
  getBefitAssessmentsData,
  deleteBefitAssessmentsData,
  addGfxClass,
  UpdateGfxClass,
  allClassesList,
  addGfxTrainee,
  getGfxTrainee,
  delFromGxTrainer,
  assignClassToSlot,
  getclassesListWithSlots,
  deleteClassInSlot,
  getClassInSlot,
  updateClassInSlot,
  getDaysOfSlots,
  getBefitPkgs,
  getSPTPackages,
  assignSlotToSPT,
  getSptAppointments,
  getNewGXBookings,
  addLeaveApplication,
  getLeaveApplication,
  getProfileLeaveApplication,
  approveRequest,
  rejectRequest,
  leaveApplicationActions,
  addEmployeHours,
  getActiveEmployeHours,
  getFilterActiveEmployeHours,
  deutyHoursActions,
  getDeutyHoursInfo,
  UpdateDutyHours,
  checkExistingDutyHours,
  checkUpdateExistingDutyHours,
  filteredStaffName,
  getProfileStaffFines,
  getStaffProfileSalary,
  addStaffPromotion,
  getActivePromotions,
  staffAttendanceCount,
  getStaffProfileFinesDataByYear,
  promotionsActions,
  getPromotionsInfo,
  getFilteredPromotions,
  getFilterEmployeeAttendence,
  getLatestStaffSalary,
  getLatestStaffCommision,
  checkQuotaLeave,
  checkQuotaLeaveUpdate,
  addQuotaLeave,
  getLeaveQuota,
  leaveQuotaActions,
  getLeaveQuotaInfo,
  UpdateLeaveQuota,
  existingLeaveCheck,
  eligibilityLeaveCheck,
  availabilityLeaveCheck,
  availabilityLeaveCheckForApproval,
  getProfileLeaveQuota,
  getLatestStaffSalarySlip,
  addCertificatesLetters,
  getCertificatesLetters,
  CertificatesLettersActions,
  getCertificatesLettersInfo,
  UpdateCertificatesLetters,
  sessionVerification,
};

export default UserServices;

/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ViewTrainer = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("25");
  const [clientType, setClientType] = useState("");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [exportLink, setExportLink] = useState("");
  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;
  const [selectedOption, setSelectedOption] = useState("");
  // for inactive table
  const [inactiveLimit, setInactiveLimit] = useState("25");
  const [inactiveTotalResults, setInactiveTotalResults] = useState("0");
  const [inactiveTotalPages, setInactiveTotalPages] = useState("1");
  const [inactiveData, setInactiveData] = useState([]);
  const [myDepartment, setMyDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);

  const getUsers = async (page, limit) => {
    setTableLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getactiveUsers(
        branch_id,
        "1",
        newLimit,
        pageNo,
        selectedOption,
        selectedDepartment
      );
      let myDataarr;

      if (responce.status === 200) {
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/auth/exportusers?status=1&branch_id=" +
            branch_id
        );
        myDataarr = responce.data.data.data;
        setMyData(...myDataarr);
        const res = myDataarr;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            branch_name: value.branch_name,
            first_name: value.first_name,
            department: value.department,
            last_name: value.last_name,
            email: value.email,
            designation: value.designation,
            phone: value.phone,
            gender: value.gender,
            address: value.address,
            city: value.city,
          });
          return null;
        });
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setTableLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setExportLink();
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setTableLoading(false);
      } else {
        setTableLoading(false);
        setExportLink();
      }
    }
  };
  const getusersData = async (page, limit) => {
    setTableLoading(true);
    setInactiveCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setInactiveLimit(newLimit);
    try {
      const responce = await UserServices.getactiveUsers(
        branch_id,
        "0",
        newLimit,
        pageNo
      );
      let myInactivedataarr;
      if (responce.status === 200) {
        myInactivedataarr = responce.data.data.data;

        const res = myInactivedataarr;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            branch_name: value.branch_name,
            department: value.department,
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            designation: value.designation,
            phone: value.phone,
            gender: value.gender,
            address: value.address,
            city: value.city,
          });
          return null;
        });
        setInactiveData([...results]);
        setInactiveLimit(responce.data.data.per_page);
        setInactiveTotalResults(responce.data.totalRecord);
        setInactiveTotalPages(responce.data.totalPages);
        setInactiveCurrentPage(responce.data.data.current_page);
        setTableLoading(false);
      }
    } catch (err) {
      setInactiveData([]);
      setTableLoading(false);
    }
  };
  const department = async () => {
    try {
      const responce = await UserServices.departmentName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDepartment([{ name: "Select Department", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setMyDepartment([]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit);
    getusersData(inactiveCurrentPage, inactiveLimit);
    department();
  }, []);



  const makeInActive = async (client_Id) => {
    try {
      setLoading(true);
  
      const responce = await UserServices.inactiveStaff(client_Id);
  
      if (responce.status === 204) {
        setLoading(false);
  
        toast.error("Inactive Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
        getusersData(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  const inactiveStaff = (client_Id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to inactive this user?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6 ', // Red color with !important to override defaults
      cancelButtonColor: '#f44336 !important',
      confirmButtonText: 'Yes, inactive it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        makeInActive(client_Id);
        Swal.fire(
          'Inactive!',
          'The user has been inactive.',
          'success'
        );
      }
    });
  };
  


  const makeActive = async (client_id) => {
    try {
      setLoading(true);
  
      const responce = await UserServices.activeStaff(client_id);
  
      if (responce.status === 204) {
        setLoading(false);
  
        toast.success("Active Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getUsers(currentPage, limit);
        getusersData(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  const activeStaff = (client_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to active this user? ",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6 ', 
      cancelButtonColor: '#f44336 !important',
      confirmButtonText: 'Yes, active it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        makeActive(client_id);
        Swal.fire(
          'Active!',
          'The user has been active.',
          'success'
        );
      }
    });
  };
  
  const deleteRecord = async (user_id) => {
    try {
      const responce = await UserServices.deleteStaff(user_id);

      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getusersData(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const delStaff = (user_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecord(user_id);
      }
    });
  };
  const changeStaffType = (e) => {
    setSelectedOption(e);
    let staffType = e;
    setClientType(staffType);
    const getStaffData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getactiveUsers(
          branch_id,
          "1",
          limit,
          "1",
          staffType,
          selectedDepartment
        );
        console.log(responce);
        let myDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/auth/exportusers?page=" +
              // 1 +
              "&status=1&limit=" +
              // limit +
              "&gender=" +
              staffType
          );
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              desigination: value.desigination,
              department: value.department,
              phone: value.phone,
              gender: value.gender,
              address: value.address,
              city: value.city,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
          setExportLink();
        }
      }
    };
    getStaffData();
  };
  const changeDepartment = (e) => {
    let staffType = e;
    setSelectedDepartment(e);
    const getStaffData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getactiveUsers(
          branch_id,
          "1",
          limit,
          "1",
          selectedOption,
          staffType
        );
        console.log(responce);
        let myDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/auth/exportusers?page=" +
              // 1 +
              "&status=1&limit=" +
              // limit +
              "&depeartment_id=" +
              staffType
          );
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              desigination: value.desigination,
              department: value.department,
              phone: value.phone,
              gender: value.gender,
              address: value.address,
              city: value.city,
            });
            return null;
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
          setExportLink();
        }
      }
    };
    getStaffData();
  };
  let searchTimer;

  const searchRecord = async (e) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(async () => {
      var search_name = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.staffNameSearch(
          search_name,
          branch_id,
          "1",
          limit,
          1
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              branch_name: value.branch_name,
              first_name: value.first_name,
              last_name: value.last_name,
              email: value.email,
              designation: value.designation,
              department: value.department,
              phone: value.phone,
              gender: value.gender,
              address: value.address,
              city: value.city,
            });
            return null;
          });
          setMyData([...results]);

          setLimit(response.data.data.per_page);
          setTotalResults(response.data.totalRecord);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");
    const captionText = table.getAttribute("data-caption");
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });
    doc.save("staff.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
      {tableLoading ? (
        <SkeletonTheme height="40px">
          <Skeleton count={5} />
        </SkeletonTheme>
      ) : (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="All Active Staff"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("desigination")}
                className={getClassNamesFor("desigination")}
              >
                Designation
              </th>
              <th
                scope="col"
                onClick={() => requestSort("department")}
                className={getClassNamesFor("department")}
              >
                Department
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Phone
              </th>
              <th
                scope="col"
                onClick={() => requestSort("gender")}
                className={getClassNamesFor("gender")}
              >
                Gender
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Address
              </th>

              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
                City
              </th>
              {role === "1" || role === "3" ? (
                <th scope="col" className="no_sort">
                  Action
                </th>
              ) : null}

              <th scope="col" className="no_sort">
                Print Card
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>

                  <td>
                    <Link to={"/staff-profile/" + el.id}>
                      {el.first_name} {el.last_name}
                    </Link>
                  </td>
                  <td>{el.designation}</td>
                  <td>{el.department}</td>
                  <td>{el.email}</td>
                  <td>{el.phone}</td>
                  <td>{el.gender}</td>
                  <td>{el.address}</td>
                  <td>{el.city}</td>

                  {role === "1" || role === "3"  ? (
                    <td>
                      <button
                        type="button"
                        onClick={() => inactiveStaff(el.id)}
                        className=" inactive-btn"
                        disabled={UId === el.id}
                      >
                        <i className="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Inactive
                        </span>
                      </button>
                    </td>
                  ) : null}

                  <td>
                    <Link
                      className="print-btn"
                      to={"/print-staff-card/" + el.id}
                    >
                      <i className="bi bi-printer"></i>{" "}
                      <span className="inative-text">Print Card</span>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    )}
    </>
    );
  };
  const InactiveTable = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
      {tableLoading ? (
        <SkeletonTheme height="40px">
          <Skeleton count={5} />
        </SkeletonTheme>
      ) : (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("desigination")}
                className={getClassNamesFor("desigination")}
              >
                Designation
              </th>
              <th
                scope="col"
                onClick={() => requestSort("department")}
                className={getClassNamesFor("department")}
              >
                Department
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Phone
              </th>

              <th
                scope="col"
                onClick={() => requestSort("gender")}
                className={getClassNamesFor("gender")}
              >
                Gender
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("city")}
                className={getClassNamesFor("city")}
              >
                City
              </th>
              {role === "1" || role === "3"  ? (
                <>
                  <th scope="col" className="no_sort">
                    Actions
                  </th>
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {inactiveCurrentPage === 1
                      ? index + 1
                      : (inactiveCurrentPage - 1) * inactiveLimit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>

                  <td>
                    <Link to={"/staff-profile/" + el.id}>
                      {el.first_name} {el.last_name}
                    </Link>
                  </td>
                  <td>{el.desigination}</td>
                  <td>{el.department}</td>
                  <td>{el.email}</td>
                  <td>{el.phone}</td>
                  <td>{el.gender}</td>
                  <td>{el.address}</td>
                  <td>{el.city}</td>
                  {role === "1" || role === "3"   ? (
                    <>
                    <td>
                      <button
                        type="button"
                        onClick={() => activeStaff(el.id)}
                        className="active-btn"
                      >
                        <i className="bi bi-check-circle text-success"></i>{" "}
                        <span className="text-success inative-text ms-1">
                          Active
                        </span>
                      </button>
                      <button
                        type="button"
                        className=" inactive-btn ms-2"
                        onClick={() => delStaff(el.id)}
                      >
                        <i className="bi bi-trash3 text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Delete
                        </span>
                      </button>
                    </td>
                    </>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {inactiveTotalResults > inactiveLimit && inactiveTotalPages > 1 ? (
          <Pagination
            activePage={inactiveCurrentPage}
            itemsCountPerPage={parseInt(inactiveLimit)}
            totalItemsCount={inactiveTotalResults}
            onChange={(e) => {
              getusersData(e, inactiveLimit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
      )}
      </>
    );
  };

  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">
                  Active Staff
                  <Link
                    to="/add-staff"
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    <i className="bi bi-plus" /> Add New Staff
                  </Link>
                </h5>
              </div>
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Search By Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(e) => {
                          searchRecord(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 col-lg-2">
                  <div className="form-group custom-group">
                    <label className="form-label">Select Type</label>
                    <div className="input-group">
                      <select
                        className="form-select"
                        onChange={(e) => changeStaffType(e.target.value)}
                        value={selectedOption}
                      >
                        <option value="">Select Staff Type</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Select Department</label>
                      {myDepartment.length ? (
                        <select
                          className="form-select"
                          name="select_department"
                          onChange={(e) => changeDepartment(e.target.value)}
                          value={selectedDepartment}
                        >
                          {myDepartment.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select className="form-select">
                          <option value="">Select Department</option>
                        </select>
                      )}
                    </div>
                  </div>
                  {/* {totalResults > limit ? ( */}
                  <div className="col-md-1 col-lg-1 mt-4 ms-auto">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getUsers("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  {/* ) : null} */}
                  <div className=" col-md-2 text-end mt-4">
                    <button
                      className="btn btn-primary mx-2 file-download"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                    <a
                      href={exportLink}
                      rel="noreferrer"
                      // target="_blank"
                      className="btn btn-primary mx-2 file-download"
                    >
                      <i className="bi bi-download" /> Excel
                    </a>
                  </div>
                </div>
                <div className="table-responsive">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Inactive Staff</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* {totalResults > limit ? ( */}
                  <div className="col-md-1 col-lg-1 mb-2 ">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={inactiveLimit}
                        onChange={(e) => getusersData("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <InactiveTable
                      data={inactiveData}
                      rowsPerPage={inactiveLimit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ViewTrainer;

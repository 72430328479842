import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import { addModules } from "../schemas";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import UserServices from "../../UserServices/UserServices";
import { useEffect } from "react";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import { useState } from "react";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import jsPDF from "jspdf";

export default function AddModule() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [nameError, setNameError] = useState("");
  const [limit, setLimit] = useState("25");
  const [myData, setMyData] = useState([]);

  const getPkg = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getModules(branch_id);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_id: values.branch_id,
            branch_name: values.branch_name,
            name: values.name,
            status: values.status,
          });
          return null;
        });

        setMyData([...results]);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg();
    branches();
  }, []);
  const makeInActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.inActiveModule(package_Id);
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactivePackage = (package_Id) => {
    makeInActive(package_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteModule(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  const makeActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.activeModule(package_Id);
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activePackage = (package_Id) => {
    makeActive(package_Id);
  };
  const nameVerify = async (e) => {
    var check_name = e.target.value;
    try {
      const response = await UserServices.checkModuleName(check_name);
      if (response.status === 200) {
        setNameError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setNameError("Name already exists");
      }
    }
  };
  const moduleAdd = async (addPkgValues, action) => {
    if (nameError === "") {
      try {
        setLoading(true);
        const response = await UserServices.AddModuleForPermission(
          addPkgValues
        );

        if (response.status === 201) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          getPkg();
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
        } else if (err.response.status === 500) {
          // toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 400) {
          // toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    }
  };
  const addPkginitialValues = {
    branch_id: branch_id,
    name: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: addModules,
    onSubmit: (addPkgValues, action) => {
      moduleAdd(addPkgValues, action);
    },
  });

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("modules.pdf");
  };
  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, limit);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Active Membership Package"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Module Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("status")}
                className={getClassNamesFor("status")}
              >
                Status
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {page === 1 ? index + 1 : (page - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>
                  <td>{el.name}</td>
                  <td>
                    {el.status === 1 ? (
                      <span className="badge bg-success">Active</span>
                    ) : (
                      <span className="badge bg-danger">Inactive</span>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        // getPkgs(el.id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => inactivePackage(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Inactive
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => activePackage(el.id)}
                      className="active-btn ms-2"
                    >
                      <i className="bi bi-check-circle text-success"></i>{" "}
                      <span className="text-success inative-text ms-1">
                        Active
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > limit ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Add Module</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  addPkgHandleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={addPkgValues.branch_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                      <p className="text-danger">{addPkgErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Module Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Module Name"
                      name="name"
                      autoComplete="off"
                      value={addPkgValues.name}
                      onChange={async (e) => {
                        addPkgHandleChange(e);
                        nameVerify(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    />

                    {nameError ? (
                      <div className="errorMsg text-danger">{nameError}</div>
                    ) : null}
                    {addPkgErrors.name && addPkgTouched.name ? (
                      <p className="text-danger">{addPkgErrors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <button
            className="btn btn-primary mx-2 file-download float-end mb-3"
            onClick={() => {
              generatePdf(document.getElementById("report-POS"));
            }}
          >
            <i className="bi bi-file-earmark-pdf"></i> PDF
          </button>
          <div className="clearfix"></div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Active Modules</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-1 ms-auto mb-2">
                  <div className="input-group limit-input">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      value={limit}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                      <option value={myData.length}>All</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import { useFormik } from "formik";
import { managerResource } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";

const ResourceManager = () => {
  const [loading, setLoading] = useState(false);
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [nameError, setNameError] = useState("");
  // inactive
  const [inactiveLimit, setInactiveLimit] = useState("25");
  const [inactiveTotalResults, setInactiveTotalResults] = useState("0");
  const [inactiveTotalPages, setInactiveTotalPages] = useState("1");
  const [inactiveData, setInactiveData] = useState([]);
  const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);
  const [myDepartment, setMyDepartment] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const verifyName = async (e) => {
    var check_Name = e.target.value;
    try {
      const response = await UserServices.checkName(check_Name);
      if (response.status === 200) {
        setNameError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setNameError("Name already exists");
      }
    }
  };
  const getResource = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getActiveResources(
        branch_id,
        newLimit,
        pageNo,
        "Department"
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            type: values.type,
            description: values.description,
            status: values.status,
          });
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getInactiveResource = async (page, limit) => {
    setLoading(true);
    setInactiveCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setInactiveLimit(newLimit);
    try {
      const responce = await UserServices.getActiveResources(
        branch_id,
        newLimit,
        pageNo,
        "Designations"
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            name: values.name,
            branches_name: values.branches_name,
            type: values.type,
            description: values.description,
            department: values.department,
            status: values.status,
          });
        });

        setInactiveData([...results]);
        setInactiveLimit(responce.data.data.per_page);
        setInactiveTotalResults(responce.data.totalRecord);
        setInactiveTotalPages(responce.data.totalPages);
        setInactiveCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setInactiveTotalResults("0");
        setInactiveTotalPages("1");
        setInactiveData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const department = async () => {
    try {
      const responce = await UserServices.departmentName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDepartment([{ name: "Select Department", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setMyDepartment([]);
    }
  };
  useEffect(() => {
    getResource(currentPage, limit);
    getInactiveResource(inactiveCurrentPage, inactiveLimit);
    department();
  }, []);
  const makeInActive = async (resource_Id) => {
    try {
      setLoading(true);

      const responce = await UserServices.inactiveResource(resource_Id);

      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getResource(currentPage, limit);
        getInactiveResource(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactiveRc = (resource_Id) => {
    makeInActive(resource_Id);
  };
  const makeActive = async (resource_Id) => {
    try {
      setLoading(true);

      const responce = await UserServices.activeResource(resource_Id);

      if (responce.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getInactiveResource(inactiveCurrentPage, inactiveLimit);
        getResource(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activeResource = (resource_Id) => {
    makeActive(resource_Id);
  };
  const deleteRecord = async (resource_Id) => {
    try {
      const responce = await UserServices.deleteRescource(resource_Id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getInactiveResource(inactiveCurrentPage, inactiveLimit);
        getResource(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delResource = (resource_Id) => {
    deleteRecord(resource_Id);
  };
  const chnageDepartment = (e) => {
    setSelectedOption(e);
    let DepartmentType = e;
    const getDesignationsData = async () => {
      setLoading(true);

      try {
        const responce = await UserServices.getActiveFilterResources(
          branch_id,
          limit,
          "1",
          DepartmentType,
          "Designations"
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              branches_name: values.branches_name,
              type: values.type,
              description: values.description,
              department: values.department,
              status: values.status,
            });
          });

          setInactiveData([...results]);
          setInactiveLimit(responce.data.data.per_page);
          setInactiveTotalResults(responce.data.totalRecord);
          setInactiveTotalPages(responce.data.totalPages);
          setInactiveCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setInactiveTotalResults("0");
          setInactiveTotalPages("1");
          setInactiveData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getDesignationsData();
  };
  const addResource = async (values, action) => {
    if (nameError === "") {
      try {
        setLoading(true);
        const response = await UserServices.addManagerResources(values);
        if (response.status === 201) {
          setLoading(false);

          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          getResource(currentPage, limit);
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
        } else if (err.response.status === 500) {
          toast.error("Internal Server Error!", { position: "top-center" });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    }
  };
  const initialValues = {
    name: "",
    type: "",
    description: "N/A",
    department_id: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: managerResource,
      onSubmit: (values, action) => {
        addResource(values, action);
      },
    });
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Department Name
              </th>
              <th scope="col" className="no_sort">
                Status
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  <td>
                    {el.id === 2 ||
                    el.id === 1 ||
                    el.id === 32 ||
                    el.id === 76 ||
                    el.id === 25 ? (
                      <>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={"flexSwitchCheckChecked" + el.id}
                            checked={el.status === "1"}
                            disabled={true}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id={"flexSwitchCheckChecked" + el.id} // Unique id for each checkbox
                          checked={el.status === "1"}
                          onChange={(e) => {
                            if (e.target.checked) {
                              activeResource(el.id);
                            } else {
                              inactiveRc(el.id);
                            }
                          }}
                        />
                      </div>
                    )}
                  </td>

                  <td>
                    {el.id === 2 ||
                    el.id === 1 ||
                    el.id === 32 ||
                    el.id === 76 ||
                    el.id === 25 ? (
                      <>
                        <button
                          className="updt-btn inactive-btn"
                          disabled={true}
                        >
                          <i className="bi bi-repeat text-success"></i>{" "}
                          <span className="text-success ms-1 updt-text">
                            Update
                          </span>
                        </button>
                        <button
                          type="button"
                          disabled={true}
                          className="ms-2 inactive-btn"
                        >
                          <i className="bi bi-trash3 text-danger"></i>{" "}
                          <span className="text-danger inative-text ms-1">
                            Delete
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        {el.status === "0" ? (
                          <button
                            className="updt-btn inactive-btn"
                            disabled={true}
                          >
                            <i className="bi bi-repeat text-success"></i>{" "}
                            <span className="text-success ms-1 updt-text">
                              Update
                            </span>
                          </button>
                        ) : (
                          <Link
                            to={"/update-resource/" + el.id}
                            className="updt-btn inactive-btn"
                          >
                            <i className="bi bi-repeat text-success"></i>{" "}
                            <span className="text-success ms-1 updt-text">
                              Update
                            </span>
                          </Link>
                        )}
                        <button
                          type="button"
                          onClick={() => delResource(el.id)}
                          className="ms-2 inactive-btn"
                        >
                          <i className="bi bi-trash3 text-danger"></i>{" "}
                          <span className="text-danger inative-text ms-1">
                            Delete
                          </span>
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getResource(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const InactiveTable = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table table-striped table-borderless table-style-1">
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Designation Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("department")}
                className={getClassNamesFor("department")}
              >
                Department
              </th>

              <th scope="col" className="no_sort">
                Status
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {inactiveCurrentPage === 1
                      ? index + 1
                      : (inactiveCurrentPage - 1) * inactiveLimit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  <td>{el.department ? el.department : "N/A"}</td>
                  <td>
                    {el.id === 2 ||
                    el.id === 1 ||
                    el.id === 32 ||
                    el.id === 76 ||
                    el.id === 25 ? (
                      <>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={"flexSwitchCheckChecked" + el.id}
                            checked={el.status === "1"}
                            disabled={true}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id={"flexSwitchCheckChecked" + el.id} // Unique id for each checkbox
                          checked={el.status === "1"}
                          onChange={(e) => {
                            if (e.target.checked) {
                              activeResource(el.id);
                            } else {
                              inactiveRc(el.id);
                            }
                          }}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    {el.id === 2 ||
                    el.id === 1 ||
                    el.id === 32 ||
                    el.id === 76 ||
                    el.id === 25 ? (
                      <>
                        <button
                          className="updt-btn inactive-btn"
                          disabled={true}
                        >
                          <i className="bi bi-repeat text-success"></i>{" "}
                          <span className="text-success ms-1 updt-text">
                            Update
                          </span>
                        </button>
                        <button
                          type="button"
                          disabled={true}
                          className="ms-2 inactive-btn"
                        >
                          <i className="bi bi-trash3 text-danger"></i>{" "}
                          <span className="text-danger inative-text ms-1">
                            Delete
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        {el.status === "0" ? (
                          <button
                            className="updt-btn inactive-btn"
                            disabled={true}
                          >
                            <i className="bi bi-repeat text-success"></i>{" "}
                            <span className="text-success ms-1 updt-text">
                              Update
                            </span>
                          </button>
                        ) : (
                          <Link
                            to={"/update-resource/" + el.id}
                            className="updt-btn inactive-btn"
                          >
                            <i className="bi bi-repeat text-success"></i>{" "}
                            <span className="text-success ms-1 updt-text">
                              Update
                            </span>
                          </Link>
                        )}
                        <button
                          type="button"
                          onClick={() => delResource(el.id)}
                          className="ms-2 inactive-btn"
                        >
                          <i className="bi bi-trash3 text-danger"></i>{" "}
                          <span className="text-danger inative-text ms-1">
                            Delete
                          </span>
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {inactiveTotalResults > inactiveLimit && inactiveTotalPages > 1 ? (
          <Pagination
            activePage={inactiveCurrentPage}
            itemsCountPerPage={parseInt(inactiveLimit)}
            totalItemsCount={inactiveTotalResults}
            onChange={(e) => {
              getInactiveResource(e, inactiveLimit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      <div className="wrapper">
        <Navbar />
        <Sidebar />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">Resource Manager</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Category<span className="text-danger">*</span>
                      </label>
                      <select
                        type="text"
                        className="form-select"
                        placeholder="Select Type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Category</option>
                        <option value="Department">Department</option>
                        <option value="Designations">Designations</option>
                        {/* <option value="Expense">Expense</option>
                        <option value="PaymentMethod">Payment Method</option>
                        <option value="TrainerPackageType">
                          Trainer Package Type
                        </option> */}
                      </select>
                      {errors.type && touched.type ? (
                        <p className="text-danger">{errors.type}</p>
                      ) : null}
                    </div>
                  </div>
                  {values.type === "Department" ? (
                    <>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Department Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            onChange={(e) => {
                              handleChange(e);
                              verifyName(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {nameError ? (
                            <div className="errorMsg text-danger">
                              {nameError}
                            </div>
                          ) : null}
                          {errors.name && touched.name ? (
                            <p className="text-danger">{errors.name}</p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {values.type === "Designations" ? (
                    <>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Select Department{" "}
                            <span className="text-danger">*</span>
                          </label>
                          {myDepartment.length ? (
                            <select
                              className="form-select"
                              name="department_id"
                              value={values.department_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {myDepartment.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              autoComplete="off"
                              name="department_id"
                              value={values.department_id}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Department</option>
                            </select>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Designation Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            onChange={(e) => {
                              handleChange(e);
                              verifyName(e);
                            }}
                            onBlur={handleBlur}
                          />
                          {nameError ? (
                            <div className="errorMsg text-danger">
                              {nameError}
                            </div>
                          ) : null}
                          {errors.name && touched.name ? (
                            <p className="text-danger">{errors.name}</p>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-12 col-md-6 col-lg-4 d-none">
                    <div className="form-group custom-group">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.description && touched.description ? (
                        <p className="text-danger">{errors.description}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-12 col-md-4 col-lg-2 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mt-4"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">Departments</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-1 col-lg-1 mt-2 mb-3 ms-auto">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getResource("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-22">Designations</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 col-lg-2 mb-3">
                    <div className="form-group custom-group">
                      <label className="form-label">Select Department</label>
                      {myDepartment.length ? (
                        <select
                          className="form-select"
                          onChange={(e) => chnageDepartment(e.target.value)}
                          value={selectedOption}
                        >
                          {myDepartment.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          autoComplete="off"
                          name="department_id"
                          value={values.department_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Department</option>
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="col-md-1 col-lg-1 mt-4 ms-auto">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={inactiveLimit}
                        onChange={(e) =>
                          getInactiveResource("1", e.target.value)
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <InactiveTable
                      data={inactiveData}
                      rowsPerPage={inactiveLimit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ResourceManager;

import React, { useEffect, useState } from "react";
import logo from "../../icons/latest-logo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate, useParams } from "react-router";
import UserServices from "../../UserServices/UserServices";
import moment from "moment";

export default function ExitInterviewForm() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedJoining, setSelectedJoining] = useState("");
  const navigate = useNavigate();
  const [serviceLength, setServiceLength] = useState({
    years: 0,
    months: 0,
    days: 0,
  });

  const calculateLengthOfService = (joiningDate) => {
    const startDate = moment(joiningDate);
    const currentDate = moment();

    const years = currentDate.diff(startDate, "years");
    startDate.add(years, "years");

    const months = currentDate.diff(startDate, "months");
    startDate.add(months, "months");

    const days = currentDate.diff(startDate, "days");

    return { years, months, days };
  };

  const downloadImage = async (blob, fileName) => {
    const div = window.document.createElement("div");
    div.style = "display:none; text-align:center;";
    const myImage = window.document.createElement("img");
    myImage.src = blob;
    myImage.style = "display:block; margin:0 auto;";
    var printMe = await div.appendChild(myImage);
    setLoading(false);
    var print_area = window.open();
    if (print_area && print_area.document) {
      print_area.document.write(div.innerHTML);
      print_area.document.close();
      print_area.focus();
      print_area.print();
      print_area.close();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to open print window",
      });
    }
  };
  const printSlip = async (el, imageFileName) => {
    setLoading(true);
    const canvas = await html2canvas(el, { scale: 3 });
    const image = canvas.toDataURL("image/jpeg", 0.95);
    downloadImage(image, imageFileName);
  };
  const generatePdf = async (el) => {
    setLoading(true);
    const canvas = await html2canvas(el, {
      scale: 5,
      allowTaint: true,
      useCORS: true,
    });
    const image = canvas.toDataURL("image/png", 1.0);
    const invoiceWidth = 150; // in mm
    const invoiceHeight = 297; // in mm
    const pdf = new jsPDF("p", "mm", [invoiceWidth, invoiceHeight]);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const scaleX = pdfWidth / canvasWidth;
    const scaleY = pdfHeight / canvasHeight;
    const scale = Math.min(scaleX, scaleY);
    const canvasScaledWidth = canvasWidth * scale;
    const canvasScaledHeight = canvasHeight * scale;
    const pdfX = (pdfWidth - canvasScaledWidth) / 2;
    const pdfY = (pdfHeight - canvasScaledHeight) / 2;
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(
      imgData,
      "PNG",
      pdfX,
      pdfY,
      canvasScaledWidth,
      canvasScaledHeight
    );
    const pdfData = pdf.output("blob");
    const pdfURL = URL.createObjectURL(pdfData);
    setLoading(false);
    window.open(pdfURL, "_blank");
  };

  const getRecordss = async (id) => {
    setLoading(true);
    try {
      const response = await UserServices.getCertificatesLettersInfo(id);
      if (response.status === 200) {
        let data = response.data.data;
        // eslint-disable-next-line array-callback-return
        data.map((values) => {
          setSelectedUser(values.employee.name);
          setSelectedPosition(values.employee.position);
          setSelectedDepartment(values.employee.depart);
          setSelectedJoining(values.employee.joining);
          setSelectedAddress(values.branch_address);
        });
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getRecordss(id);
  }, []);
  useEffect(() => {
    if (selectedJoining) {
      const length = calculateLengthOfService(selectedJoining);
      setServiceLength(length);
    }
  }, [selectedJoining]);

  const { years, months, days } = serviceLength;
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3 d-flex justify-content-between">
              <h5 className="mb-0 font-20">Exit Interview Form</h5>
              <div className="d-flex">
                <button
                  className="btn certificate-button me-2"
                  onClick={() => navigate(-1)}
                >
                  Go Back <i className="bi bi-arrow-left ms-1"></i>
                </button>
                <button
                  className="btn certificate-button me-2"
                  onClick={() => {
                    printSlip(
                      document.getElementById("employment-certificate"),
                      "slip"
                    );
                  }}
                >
                  Print <i className="bi bi-printer-fill ms-1"></i>
                </button>

                <button
                  className="btn certificate-button"
                  onClick={() => {
                    generatePdf(
                      document.getElementById("employment-certificate")
                    );
                  }}
                >
                  PDF <i className="bi bi-file-earmark-pdf-fill ms-1"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div
                id="employment-certificate"
                className="printable-area"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 1in -0.25in",
                  width: "210mm",
                  height: "297mm",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgb(255, 255, 255)",
                  margin: " 0px auto",
                  border: "10px groove #ed1c24",
                }}
              >
                <div style={{ width: "98%", height: "98%" }}>
                  <div className="letter-head d-flex justify-content-center">
                    <img src={logo} alt="logo" height={50} />
                  </div>
                  <div className="letter-head d-flex justify-content-center mt-3">
                    <h3
                      style={{
                        color: "#ed1c24",
                      }}
                    >
                      EXIT INTERVIEW FORM
                    </h3>
                  </div>
                  <div className="form-data mt-3 d-flex justify-content-between">
                    <div className="d-flex" style={{ minWidth: "220px" }}>
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Name:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedUser}
                      </div>
                    </div>
                    <div className="d-flex" style={{ minWidth: "220px" }}>
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Designation:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedPosition}
                      </div>
                    </div>
                  </div>
                  <div className="form-data d-flex justify-content-between">
                    <div className="d-flex" style={{ minWidth: "220px" }}>
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Length of service:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {years > 0 && `${years} years `}
                        {months > 0 && `${months} months `}
                        {days > 0 && `${days} days`}
                      </div>
                    </div>
                    <div className="d-flex" style={{ minWidth: "220px" }}>
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Department:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedDepartment}
                      </div>
                    </div>
                  </div>
                  <div className="form-data d-flex justify-content-between">
                    <div className="d-flex" style={{ minWidth: "220px" }}>
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Location:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedAddress}
                      </div>
                    </div>

                    <div className="d-flex" style={{ minWidth: "220px" }}>
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Immediate Supervisor:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {" "}
                        Xyz..
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="form-body">
                    <h5 className="text-danger">Genral information</h5>
                    <p style={{ fontSize: "16px", textAlign: "justify" }}>
                      We realize that there is always more than one factor in a
                      decision to leave an organization. We would appreciate
                      your cooperation in helping us build a better Vostro World
                      by discussing with us what you liked and what you didn't
                      like about your employment. Your feedback will assist us
                      in helping others.
                    </p>
                    <span className="d-flex">
                      <div
                        className="mt-2"
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          background: "#000",
                        }}
                      ></div>
                      <p className="ms-2">
                        Please identify the reason(s) for initially seeking and
                        accepting a position with the Vostro World
                      </p>
                    </span>
                    <div className="check-boxes d-flex justify-content-between">
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Compensation
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Location
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Career Change
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Benefits
                        </label>
                      </div>
                    </div>
                    <div className="check-boxes mt-3 d-flex justify-content-between">
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Job Responsibilities
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Schedule
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Reputation
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Other
                        </label>
                      </div>
                    </div>
                    <hr />
                    <span className="d-flex">
                      <div
                        className="mt-2"
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          background: "#000",
                        }}
                      ></div>
                      <p className="ms-2">
                        What is your primary reason for leaving?
                      </p>
                    </span>
                    <div className="check-boxes d-flex justify-content-between">
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Compensation
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Location
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Career Change
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Benefits
                        </label>
                      </div>
                    </div>
                    <hr />
                    <span className="d-flex">
                      <div
                        className="mt-2"
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          background: "#000",
                        }}
                      ></div>
                      <p className="ms-2">
                        Would you be interested in future full-time employment
                        with Vostro World?
                      </p>
                    </span>
                    <div className="check-boxes d-flex ">
                      <div className="form-check" style={{ minWidth: "190px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Yes
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          No (briefly explain below)
                        </label>
                      </div>
                      <div
                        className="form-check ms-auto"
                        style={{ minWidth: "170px" }}
                      >
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Don't Know
                        </label>
                      </div>
                    </div>
                    <div className="text-section mt-3">
                      <input
                        type="text"
                        style={{ width: "100%", height: "150px" }}
                      />
                    </div>
                    <hr />
                    <span className="d-flex">
                      <div
                        className="mt-2"
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          background: "#000",
                        }}
                      ></div>
                      <p className="ms-2">
                        What did you like most about your job, the department,
                        and Vostro World?
                      </p>
                    </span>
                    <div className="text-section mt-3">
                      <input
                        type="text"
                        style={{ width: "100%", height: "150px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import useSortableData from "../../hooks/useSortableData";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { useFormik } from "formik";
import { balanceSheet } from "../schemas";
import Swal from "sweetalert2";
import jsPDF from "jspdf";

export default function DailySales() {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("100000000");
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [expenses, setExpenses] = useState([]);
  const [category, setCategory] = useState([]);

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const sMonth = date.getMonth();
  const monthString = (parseInt(sMonth) + 1).toString();
  let strtDate = moment().startOf("month").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(strtDate);
  const [endDate, setEndDate] = useState(dateSelected);
  const [DAteSeted, setDAteSeted] = useState(false);

  const getSales = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getDaliySalesCount(
        branch_id,
        values.sdate,
        values.edate
      );

      let myDataarr;
      if (responce.status === 200) {
        setLoading(false);
        myDataarr = responce.data.data;

        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            package_id: values.package_id,
            package_name: values.package_name,
            total_net_price: values.total_net_price,
            total_quantity: values.total_quantity,
          });
          return null;
        });
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const totalSalesExpenses = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.dailySaleExpenses(
        branch_id,
        values.sdate,
        values.edate
      );

      const res = responce.data.data;
      const expensesArr = [];

      res?.expenses?.map((value) => {
        return expensesArr.push({
          category: value.category,
          total_quantity: parseInt(value.total_quantity),
          total_price: parseInt(value.total_price),
        });
      });

      setExpenses([...expensesArr]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setExpenses([]);
      setLoading(false);
    }
  };
  const totalCategoryData = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.categorySale(
        branch_id,
        values.sdate,
        values.edate
      );

      const res = responce.data.data;
      const gymArr = [];

      const newArray = Object.entries(res).map(([index, paymentMethods]) => {
        const formattedMethods = paymentMethods.map((method) => {
          const name = Object.keys(method)[0];
          const price = method[name];
          return { name, price };
        });

        return formattedMethods;
      });

      setCategory([...newArray]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setCategory([]);
      setLoading(false);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getSales();
    totalSalesExpenses();
    totalCategoryData();
  }, []);

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(   captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("daily-sales-counter.pdf");
  };

  const Table = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    let total_price = 0;

    return (
      <div className="adj-hight">
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Sales"
        >
          <thead>
            <tr>
              {/* <th scope="col" className="no_sort">
                    Sr#
                  </th> */}
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>
              <th scope="col" className="no_sort">
                Percentage
              </th>
              <th
                scope="col"
                onClick={() => requestSort("total_quantity")}
                className={getClassNamesFor("total_quantity")}
              >
                Quantity
              </th>
              <th
                onClick={() => requestSort("total_net_price")}
                className={getClassNamesFor("total_net_price")}
              >
                Amount
              </th>
            </tr>
          </thead>

          <tbody>
            {items.length ? (
              <>
                {items.map((el, index) => (
                  <tr key={index}>
                    <td>{el.package_name}</td>
                    <td>
                      {(
                        (el.total_net_price /
                          items.reduce(
                            (a, v) => (a = a + parseInt(v.total_net_price)),
                            0
                          )) *
                        100
                      ).toFixed(2)}
                      %
                    </td>
                    <td className="d-none">
                      {
                        (total_price =
                          total_price +
                          (el.total_net_price /
                            items.reduce(
                              (a, v) => (a = a + parseInt(v.total_net_price)),
                              0
                            )) *
                            100)
                      }
                    </td>
                    <td>{el.total_quantity} </td>
                    <td>
                      <CurrencyFormat
                        value={el.total_net_price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        thousandSpacing={"3s"}
                      />
                    </td>
                  </tr>
                ))}
                <tr className="total-fot">
                  <td className="font-20">Total Sales</td>
                  <td className="font-20">{total_price.toFixed(0)}%</td>
                  <td></td>
                  <td className="font-20">
                    <CurrencyFormat
                      value={items.reduce(
                        (total, item) => total + parseInt(item.total_net_price),
                        0
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3s"}
                    />
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const ExpenseTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    let total_price = 0;
    return (
      <div className="adj-hight">
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Expenses"
        >
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("category")}
                className={getClassNamesFor("category")}
              >
                Particulars
              </th>
              <th scope="col" className="no_sort">
                Percentage
              </th>
              <th
                scope="col"
                onClick={() => requestSort("total_quantity")}
                className={getClassNamesFor("total_quantity")}
              >
                Quantity
              </th>
              <th
                scope="col"
                onClick={() => requestSort("total_price")}
                className={getClassNamesFor("total_price")}
              >
                Debit
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              <>
                {items.map((el, index) => (
                  <tr key={index}>
                    <td>{el.category}</td>
                    <td>
                      {(
                        (el.total_price /
                          items.reduce((a, v) => (a = a + v.total_price), 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </td>
                    <td className="d-none">
                      {
                        (total_price =
                          total_price +
                          (el.total_price /
                            items.reduce(
                              (a, v) => (a = a + v.total_price),
                              0
                            )) *
                            100)
                      }
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.total_quantity}
                        displayType={"text"}
                        thousandSpacing={"3s"}
                        thousandSeparator={true}
                        prefix={""}
                        suffix={""}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.total_price}
                        displayType={"text"}
                        thousandSpacing={"3s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                  </tr>
                ))}
                <tr className="total-fot">
                  <td className="font-20">Total Expenses Paid</td>
                  <td className="font-20">{total_price.toFixed(0)}%</td>
                  <td></td>
                  <td className="font-20">
                    <CurrencyFormat
                      value={items.reduce((a, v) => (a = a + v.total_price), 0)}
                      displayType={"text"}
                      thousandSpacing={"3s"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const PaymentBasedSales = ({ data }) => {
    let total_price = 0;
    return (
      <>
        {data.length ? (
          <div className="row">
            {data.map((val, index) => (
              <div className="col-md-6" key={index}>
                <div className="card">
                  <span className="d-none">{(total_price = 0)}</span>
                  <div className="card-header py-3">
                    <h5 className="mb-0 font-20">
                      {index + 1 === 1
                        ? "Gym"
                        : index + 1 === 2
                        ? "Personal Training"
                        : index + 1 === 3
                        ? "Guest Pass"
                        : index + 1 === 4
                        ? "Small Group PT"
                        : index + 1 === 5
                        ? "Nutritionist"
                        : index + 1 === 6
                        ? "Registration"
                        : index + 1 === 7
                        ? "Bootcamp"
                        : index + 1 === 8
                        ? "Freezing"
                        : index + 1 === 9
                        ? "General"
                        : index + 1 === 10
                        ? "Cafe"
                        : index + 1 === 12
                        ? "LesMIlls"
                        : index + 1 === 11
                        ? "CFT"
                        : null}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      <div className="adj-hight">
                        <table
                          className="table table-striped table-borderless table-style-1"
                          data-caption={
                            index + 1 === 1
                              ? "Gym"
                              : index + 1 === 2
                              ? "Personal Training"
                              : index + 1 === 3
                              ? "Guest Pass"
                              : index + 1 === 4
                              ? "Small Group PT"
                              : index + 1 === 5
                              ? "Nutritionist"
                              : index + 1 === 6
                              ? "Registration"
                              : index + 1 === 7
                              ? "Bootcamp"
                              : index + 1 === 8
                              ? "Freezing"
                              : index + 1 === 9
                              ? "General"
                              : index + 1 === 10
                              ? "Cafe"
                              : null
                          }
                        >
                          <thead>
                            <tr>
                              <th scope="col" className="no_sort">
                                Payment Type
                              </th>
                              <th scope="col" className="no_sort">
                                Percentage
                              </th>

                              <th scope="col" className="no_sort">
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {val.map((el, index) => (
                              <tr key={index}>
                                <td>{el.name}</td>
                                <td>
                                  {val.reduce(
                                    (a, v) => (a = a + v.price),
                                    0
                                  ) !== 0
                                    ? (
                                        (el.price /
                                          val.reduce(
                                            (a, v) => (a = a + v.price),
                                            0
                                          )) *
                                        100
                                      ).toFixed(2)
                                    : 0}
                                  %
                                </td>
                                <td className="d-none">
                                  {val.reduce(
                                    (a, v) => (a = a + v.price),
                                    0
                                  ) !== 0
                                    ? (total_price =
                                        total_price +
                                        (el.price /
                                          val.reduce(
                                            (a, v) => (a = a + v.price),
                                            0
                                          )) *
                                          100)
                                    : (total_price = total_price + 0)}
                                </td>

                                <td>
                                  <CurrencyFormat
                                    value={el.price}
                                    displayType={"text"}
                                    thousandSpacing={"3s"}
                                    thousandSeparator={true}
                                    prefix={"Rs "}
                                    suffix={"/-"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className="font-20">Total Paid</td>
                              <td className="font-20">
                                {total_price.toFixed(0)}%
                              </td>

                              <td className="font-20">
                                <CurrencyFormat
                                  value={val.reduce(
                                    (a, v) => (a = a + v.price),
                                    0
                                  )}
                                  displayType={"text"}
                                  thousandSpacing={"3s"}
                                  thousandSeparator={true}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card">
            <div className="card-header py-3 bg-white text-danger">
              <h5 className="mb-0 font-20">No Sales found</h5>
            </div>
          </div>
        )}
      </>
    );
  };

  const setDates = async (param) => {
    setDAteSeted(true);
    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);
        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: balanceSheet,
      onSubmit: (values) => {
        if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
          getSales();
          totalSalesExpenses();
          totalCategoryData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "End date should be greater then start date!",
          });
        }
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="card">
                <div className="card-body">
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-12">
                      <h5>Dates</h5>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Start date</label>
                        <input
                          type="date"
                          className={`form-control ${DAteSeted ? "glow" : ""}`}
                          placeholder="Enter Start date"
                          name="sdate"
                          value={values.sdate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {errors.sdate && touched.sdate ? (
                          <p className="text-danger">{errors.sdate}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">End date</label>
                        <input
                          type="date"
                          className={`form-control ${DAteSeted ? "glow" : ""}`}
                          placeholder="Enter End date"
                          name="edate"
                          value={values.edate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {errors.edate && touched.edate ? (
                          <p className="text-danger">{errors.edate}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <h5>Quick Dates</h5>
                    </div>
                    <div className="row quick multi-button custom-multi-button">
                      <div className="col-md-4">
                        <label className="form-label d-block">Last</label>
                        <input
                          type="button"
                          check-val="lastYear"
                          onClick={() => {
                            setDates("lastYear");
                          }}
                          className="generate_report"
                          defaultValue="Year"
                        />
                        <input
                          type="button"
                          check-val="lastQuarter"
                          onClick={() => {
                            setDates("lastQuarter");
                          }}
                          className="generate_report"
                          defaultValue="Quarter"
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="lastMonth"
                          onClick={() => {
                            setDates("lastMonth");
                          }}
                          className="generate_report"
                        />
                        <input
                          type="button"
                          defaultValue="Yesterday"
                          check-val="lastDay"
                          className="generate_report"
                          onClick={() => {
                            setDates("lastDay");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">To-Date</label>
                        <input
                          type="button"
                          defaultValue="Year"
                          check-val="toDateYear"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateYear");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Quarter"
                          check-val="toDateQuarter"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateQuarter");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="toDateMonth"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateMonth");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Today"
                          check-val="toDateToday"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateToday");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">Previous</label>
                        <input
                          type="button"
                          defaultValue="365 Days"
                          check-val="previous365"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous365");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="90 Days"
                          check-val="previous90"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous90");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="30 Days"
                          check-val="previous30"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous30");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="9 Days"
                          check-val="previous9"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous9");
                          }}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>

                    <div className="col-md-4 col-lg-5"></div>
                    <div className="col-12 col-md-4 col-lg-2">
                      <button
                        disabled={DAteSeted ? true : false}
                        type="submit"
                        className="btn btn-primary px-5 rounded-0 w-100"
                      >
                        Go
                      </button>
                    </div>
                    <div className="col-md-4 col-lg-5"></div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12">
              <button
                className="btn btn-primary mx-2 file-download float-end mb-4"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
              <div className="clearfix"></div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Sales</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive mt-3">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Expenses</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive mt-3">
                    <ExpenseTable data={expenses} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <span className="dailysales-heading">
                <i class="bi bi-graph-up-arrow"></i> Sales Breakdown By Category
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <PaymentBasedSales data={category} />
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import logo from "../../icons/latest-logo.png";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import CurrencyFormat from "react-currency-format";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate, useParams } from "react-router";
import UserServices from "../../UserServices/UserServices";

export default function AppointmentLetter() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedIssueDate, setSelectedIssueDate] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedDocumentCode, setSelectedDocumentCode] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedJoining, setSelectedJoining] = useState("");
  const [selectedSalary, setSelectedSalary] = useState("");
  const navigate = useNavigate();

  const downloadImage = async (blob, fileName) => {
    const div = window.document.createElement("div");
    div.style = "display:none; text-align:center;";
    const myImage = window.document.createElement("img");
    myImage.src = blob;
    myImage.style = "display:block; margin:0 auto;";
    var printMe = await div.appendChild(myImage);
    setLoading(false);
    var print_area = window.open();
    if (print_area && print_area.document) {
      print_area.document.write(div.innerHTML);
      print_area.document.close();
      print_area.focus();
      print_area.print();
      print_area.close();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to open print window",
      });
    }
  };
  const printSlip = async (el, imageFileName) => {
    setLoading(true);
    const canvas = await html2canvas(el, { scale: 3 });
    const image = canvas.toDataURL("image/jpeg", 0.95);
    downloadImage(image, imageFileName);
  };
  const generatePdf = async (el) => {
    setLoading(true);
    const canvas = await html2canvas(el, {
      scale: 5,
      allowTaint: true,
      useCORS: true,
    });
    const image = canvas.toDataURL("image/png", 1.0);
    const invoiceWidth = 150; // in mm
    const invoiceHeight = 297; // in mm
    const pdf = new jsPDF("p", "mm", [invoiceWidth, invoiceHeight]);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const scaleX = pdfWidth / canvasWidth;
    const scaleY = pdfHeight / canvasHeight;
    const scale = Math.min(scaleX, scaleY);
    const canvasScaledWidth = canvasWidth * scale;
    const canvasScaledHeight = canvasHeight * scale;
    const pdfX = (pdfWidth - canvasScaledWidth) / 2;
    const pdfY = (pdfHeight - canvasScaledHeight) / 2;
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(
      imgData,
      "PNG",
      pdfX,
      pdfY,
      canvasScaledWidth,
      canvasScaledHeight
    );
    const pdfData = pdf.output("blob");
    const pdfURL = URL.createObjectURL(pdfData);
    setLoading(false);
    window.open(pdfURL, "_blank");
  };
  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  const getRecordss = async (id) => {
    setLoading(true);
    try {
      const response = await UserServices.getCertificatesLettersInfo(id);
      if (response.status === 200) {
        let data = response.data.data;
        // eslint-disable-next-line array-callback-return
        data.map((values) => {
          setSelectedUser(values.employee.name);
          setSelectedPosition(values.employee.position);
          setSelectedJoining(values.employee.joining);
          setSelectedGender(values.employee.gender);
          setSelectedSalary(values.employee.salary);
          setSelectedIssueDate(values.issue_date);
          setSelectedDocumentCode(values.document_code);
        });
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getRecordss(id);
  }, []);
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3 d-flex justify-content-between">
              <h5 className="mb-0 font-20">Appointment Letter</h5>
              <div className="d-flex">
                <button
                  className="btn certificate-button me-2"
                  onClick={() => navigate(-1)}
                >
                  Go Back <i className="bi bi-arrow-left ms-1"></i>
                </button>
                <button
                  className="btn certificate-button me-2"
                  onClick={() => {
                    printSlip(
                      document.getElementById("employment-certificate"),
                      "slip"
                    );
                  }}
                >
                  Print <i className="bi bi-printer-fill ms-1"></i>
                </button>

                <button
                  className="btn certificate-button"
                  onClick={() => {
                    generatePdf(
                      document.getElementById("employment-certificate")
                    );
                  }}
                >
                  PDF <i className="bi bi-file-earmark-pdf-fill ms-1"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div
                className="letter"
                id="employment-certificate"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 1in -0.25in",
                  width: "210mm",
                  height: "297mm",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgb(255, 255, 255)",
                  margin: " 0px auto",
                  border: "10px groove #ed1c24",
                }}
              >
                <div style={{ width: "98%", height: "98%" }}>
                  <div className="letter-head d-flex justify-content-center">
                    <img src={logo} alt="logo" height={50} />
                  </div>
                  <div className="letter-head d-flex justify-content-center mt-5">
                    <h2
                      style={{
                        fontFamily: "Inconsolata, monospace",
                        fontOpticalSizing: "auto",
                        fontWeight: 700,
                        fontStyle: "normal",
                        color: "#ed1c24",
                      }}
                    >
                      Appointment Letter
                    </h2>
                  </div>
                  <div
                    style={{ fontSize: "14px", fontWeight: 600, color: "#000" }}
                    className="d-flex justify-content-between mt-3"
                  >
                    <div>
                      <span className="text-danger">Letter Code:</span>
                      <span className="ms-2">{selectedDocumentCode}</span>
                    </div>
                    <div>
                      <span className="text-danger">Date:</span>
                      <span className="ms-2">
                        {moment(selectedIssueDate).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                  <div
                    className=" mt-3"
                    style={{
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <br />
                      <div className="">
                        <h5 className="mt-2">To,</h5>
                        <br />
                        <h3
                          style={{
                            fontFamily: "Inconsolata, monospace",
                            fontOpticalSizing: "auto",
                            fontWeight: 700,
                            fontStyle: "normal",
                            color: "#ed1c24",
                          }}
                        >
                          {selectedUser}
                        </h3>
                      </div>
                      <div className="mt-3">
                        {" "}
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                            lineHeight: "35px",
                          }}
                        >
                          <span className="text-danger fw-bolder">
                            Subject:
                          </span>{" "}
                          Appointment Letter for the position of Chief Operating
                          Officer
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div
                    className="letter-content"
                    style={{
                      textAlign: "justify",
                      fontSize: "15px",
                      color: "#000",
                      lineHeight: "35px",
                    }}
                  >
                    <p className="mt-3">
                      Dear{" "}
                      <span>
                        {selectedGender === "Male" && " Mr."}
                        {selectedGender === "Female" && " Ms."}
                        {selectedGender === "Others" && " Mr. / Ms."}{" "}
                      </span>{" "}
                      <span
                        style={{
                          fontFamily: "Inconsolata, monospace",
                          fontOpticalSizing: "auto",
                          fontWeight: 700,
                          fontStyle: "normal",
                          color: "#ed1c24",
                          fontSize: "21px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedUser}
                      </span>{" "}
                      , We are pleased to confirm that you have been appointed
                      to the position of{" "}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "rgb(232 0 43)",
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedPosition}
                      </span>{" "}
                      with Vostro World, effective from the{" "}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "rgb(232 0 43)",
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedJoining}
                      </span>{" "}
                      . We believe that your extensive experience and skills
                      make you a perfect fit for this role. As a{" "}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "rgb(232 0 43)",
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedPosition}
                      </span>{" "}
                      , you will be entitled to a monthly salary of{" "}
                      <span
                        style={{
                          fontWeight: "600",
                          color: "rgb(232 0 43)",
                          fontSize: "16px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <CurrencyFormat
                          value={selectedSalary}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"3"}
                        />
                      </span>{" "}
                      . We are excited about the contributions we know you will
                      make to our team and look forward to welcoming you on
                      board. If you have any questions or need further
                      information, please do not hesitate to contact the HR
                      department. Congratulations once again on your new
                      position, and we look forward to a successful
                      collaboration.
                    </p>
                    <div
                      className="mt-3"
                      style={{ fontWeight: "600", fontSize: "17px" }}
                    >
                      <span>Sincerely,</span>
                    </div>
                  </div>
                  <div className="letter-footer" style={{ marginTop: "80px" }}>
                    <div
                      style={{ width: "170px", borderTop: "1px solid #000" }}
                    >
                      <h6 className="mt-3">Authorized Signatory</h6>
                    </div>
                    <div
                      className="mt-5"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      <h6 className="mt-3">Cc:</h6>
                      <span>HR department</span> <br />
                      <span>Executive Office</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

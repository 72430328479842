import React, { useContext, useEffect, useRef, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { addLettersCertificates } from "../schemas";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import $ from "jquery";

export default function LetterManagement() {
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myBranches, setMyBranches] = useState([]);
  const [names, setNames] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");

  // update
  var clickEvent = $.Event("click");
  const [selectedBranches, setSelectedBranches] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedIssueDate, setSelectedIssueDate] = useState("");
  const [selectedDocumentCode, setSelectedDocumentCode] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [recordId, setRecordId] = useState("");

  const closeButtonRef = useRef(null);

  const getRecordss = async (id) => {
    setLoading(true);
    try {
      const response = await UserServices.getCertificatesLettersInfo(id);
      if (response.status === 200) {
        let data = response.data.data;
        // eslint-disable-next-line array-callback-return
        data.map((values) => {
          setSelectedBranches(values.branch_id);
          setSelectedUser(values.user_id);
          setSelectedDocumentCategory(values.document_category);
          setSelectedDocumentType(values.document_type);
          setSelectedIssueDate(values.issue_date);
          setSelectedDocumentCode(values.document_code);
          setSelectedSubject(values.subject);
          setRecordId(values.id);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const branches = async () => {
    try {
      const response = await UserServices.branchName();
      const res = response.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const staffNames = async () => {
    try {
      const response = await UserServices.staffName(branch_id);
      const res = response.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ name: "Select Staff Name", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const response = await UserServices.getCertificatesLetters(
        branch_id,
        newLimit,
        pageNo,
        selectedOption
      );
      let myDataarr;

      if (response.status === 200) {
        myDataarr = response.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch: values.branch,
            employee: values.employee,
            document_category: values.document_category,
            document_type: values.document_type,
            issue_date: values.issue_date,
            document_code: values.document_code,
            subject: values.subject,
            status: values.status,
            user_id: values.user_id,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(response.data.pagination.per_page);
        setTotalResults(response.data.pagination.total_record);
        setTotalPages(response.data.pagination.total_pages);
        setCurrentPage(response.data.pagination.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else if (err.response.status === 500 || err.response.status === 400) {
        toast.error("Internal Server Error!", {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const filterData = (e) => {
    setSelectedOption(e);
    let staffType = e;
    const getStaffData = async () => {
      setLoading(true);

      try {
        const response = await UserServices.getCertificatesLetters(
          branch_id,
          limit,
          "1",
          staffType
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              branch: values.branch,
              employee: values.employee,
              document_category: values.document_category,
              document_type: values.document_type,
              issue_date: values.issue_date,
              document_code: values.document_code,
              subject: values.subject,
              status: values.status,
              user_id: values.user_id,
            });
            return null;
          });

          setMyData([...results]);
          setLimit(response.data.pagination.per_page);
          setTotalResults(response.data.pagination.total_record);
          setTotalPages(response.data.pagination.total_pages);
          setCurrentPage(response.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getStaffData();
  };
  const makeInActive = async (record_Id) => {
    try {
      setLoading(true);
      const response = await UserServices.CertificatesLettersActions(
        record_Id,
        "0"
      );
      if (response.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getData(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const makeActive = async (record_Id) => {
    try {
      setLoading(true);
      const response = await UserServices.CertificatesLettersActions(
        record_Id,
        "1"
      );
      if (response.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getData(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteRecord = async (record_Id) => {
    try {
      const response = await UserServices.CertificatesLettersActions(
        record_Id,
        "2"
      );
      if (response.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getData(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    branches();
    staffNames();
    getData(currentPage, limit);
  }, []);

  const addData = async (addPkgValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.addCertificatesLetters(addPkgValues);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getData(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        toast.error("Internal Server Error!", {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 400) {
        toast.error("Unproccessable content!", {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const addPkginitialValues = {
    branch_id: branch_id,
    user_id: "",
    document_category: "",
    document_type: "",
    issue_date: eDate,
    document_code: "",
    subject: "N/A",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: addLettersCertificates,
    onSubmit: (addPkgValues, action) => {
      addData(addPkgValues, action);
    },
  });
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Leave Quota"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch")}
                className={getClassNamesFor("branch")}
              >
                Branch Name
              </th>
              <th scope="col" className="no_sort">
                Staff Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("document_category")}
                className={getClassNamesFor("document_category")}
              >
                Document Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("document_type")}
                className={getClassNamesFor("document_type")}
              >
                Document Type
              </th>
              <th
                scope="col"
                onClick={() => requestSort("issue_date")}
                className={getClassNamesFor("issue_date")}
              >
                Issue Date
              </th>
              <th scope="col" className="no_sort">
                Document Code
              </th>
              <th scope="col" className="no_sort">
                Subject
              </th>
              <th scope="col" className="no_sort">
                Status
              </th>
              <th scope="col" className="no_sort">
                Print
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{el.branch}</td>
                  <td>
                    <Link to={"/staff-profile/" + el.user_id}>
                      {el.employee.name}
                    </Link>
                  </td>
                  <td>{el.document_category}</td>
                  <td>{el.document_type}</td>
                  <td>{moment(el.issue_date).format("DD-MM-YYYY")}</td>
                  <td>{el.document_code}</td>
                  <td>{el.subject}</td>
                  <td>{el.status === "0" ? "Inactive" : "Active"}</td>
                  <td>
                    {el.document_type === "Offer Letter" ||
                    el.document_type === "Certificate Of Employment" ||
                    el.document_type === "Appointment Letter" ||
                    el.document_type === "Confirmation Letter" ||
                    el.document_type === "Warning Letter" ||
                    el.document_type === "Exit Interview Form" ||
                    el.document_type === "Employee Clearance Form" ||
                    el.document_type === "Bank Account Opening Letter" ? (
                      <Link
                        className="print-btn"
                        to={
                          el.document_type === "Offer Letter"
                            ? `/offer-letter/${el.id}`
                            : el.document_type === "Certificate Of Employment"
                            ? `/certificate-of-employment/${el.id}`
                            : el.document_type === "Appointment Letter"
                            ? `/appointment-letter/${el.id}`
                            : el.document_type === "Confirmation Letter"
                            ? `/confirmation-letter/${el.id}`
                            : el.document_type === "Warning Letter"
                            ? `/warning-letter/${el.id}`
                            : el.document_type === "Exit Interview Form"
                            ? `/exit-interview-form/${el.id}`
                            : el.document_type === "Employee Clearance Form"
                            ? `/employee-clearance-form/${el.id}`
                            : `/account-opening-letter/${el.id}`
                        }
                      >
                        <i className="bi bi-printer"></i>{" "}
                        <span className="inative-text">Print</span>
                      </Link>
                    ) : null}
                  </td>
                  <td>
                    {el.status === "0" ? (
                      <button className="updt-btn inactive-btn" disabled={true}>
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          getRecordss(el.id);
                        }}
                        className="updt-btn inactive-btn"
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </button>
                    )}
                    {el.status === "0" ? (
                      <button
                        type="button"
                        onClick={() => makeActive(el.id)}
                        className="ms-2 active-btn"
                      >
                        <i className="bi bi-check-circle text-success"></i>{" "}
                        <span className="text-success inative-text ms-1">
                          Active
                        </span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => makeInActive(el.id)}
                        className="ms-2 inactive-btn"
                      >
                        <i className="bi bi-x-circle text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Inactive
                        </span>
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => deleteRecord(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 && (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              setCurrentPage(e);
              getData(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        )}
      </>
    );
  };

  const UpdateRecord = async (values, action) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdateCertificatesLetters(
        values,
        recordId
      );
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getData(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const initialValues = {
    branch_id: selectedBranches,
    user_id: selectedUser,
    document_category: selectedDocumentCategory,
    document_type: selectedDocumentType,
    issue_date: selectedIssueDate,
    document_code: selectedDocumentCode,
    subject: selectedSubject,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: addLettersCertificates,
      onSubmit: (values, action) => {
        UpdateRecord(values, action);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Letter Management</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  addPkgHandleSubmit(e);
                }}
              >
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={addPkgValues.branch_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                      <p className="text-danger">{addPkgErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Staff Name <span className="text-danger">*</span>
                    </label>
                    {names ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={addPkgValues.user_id}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.user_id && addPkgTouched.user_id ? (
                      <p className="text-danger">{addPkgErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Document Category <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="document_category"
                      value={addPkgValues.document_category}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    >
                      <option value="">Select Document Category</option>
                      <option value="Letter">Letter</option>
                      <option value="Certificate">Certificate</option>
                      <option value="Form">Form</option>
                    </select>
                    {addPkgErrors.document_category &&
                    addPkgTouched.document_category ? (
                      <p className="text-danger">
                        {addPkgErrors.document_category}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Document Type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="document_type"
                      value={addPkgValues.document_type}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    >
                      <option value="">Select Document Type</option>
                      {addPkgValues.document_category === "Letter" && (
                        <>
                          <option value="Offer Letter">Offer Letter</option>
                          <option value="Bank Account Opening Letter">
                            Bank Account Opening Letter
                          </option>
                          <option value="Appointment Letter">
                            Appointment Letter
                          </option>
                          <option value="Confirmation Letter">
                            Confirmation Letter
                          </option>
                          <option value="Warning Letter">Warning Letter</option>
                        </>
                      )}
                      {addPkgValues.document_category === "Certificate" && (
                        <>
                          <option value="Certificate Of Employment">
                            Certificate Of Employment
                          </option>
                        </>
                      )}
                      {addPkgValues.document_category === "Form" && (
                        <>
                          <option value="Exit Interview Form">
                            Exit Interview Form
                          </option>
                          <option value="Employee Clearance Form">
                            Employee Clearance Form
                          </option>
                        </>
                      )}
                    </select>
                    {addPkgErrors.document_type &&
                    addPkgTouched.document_type ? (
                      <p className="text-danger">
                        {addPkgErrors.document_type}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Issue Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="issue_date"
                      value={addPkgValues.issue_date}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.issue_date && addPkgTouched.issue_date ? (
                      <p className="text-danger">{addPkgErrors.issue_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Document Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" Enter Document Code"
                      autoComplete="off"
                      name="document_code"
                      value={addPkgValues.document_code}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.document_code &&
                    addPkgTouched.document_code ? (
                      <p className="text-danger">
                        {addPkgErrors.document_code}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" Enter Subject"
                      autoComplete="off"
                      name="subject"
                      value={addPkgValues.subject}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.subject && addPkgTouched.subject ? (
                      <p className="text-danger">{addPkgErrors.subject}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-2 mb-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Staff Name</label>
                    {names ? (
                      <select
                        className="form-select"
                        onChange={(e) => filterData(e.target.value)}
                        value={selectedOption}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-1 col-lg-1 mt-3 mb-3 ms-auto me-3">
                  <div className="input-group">
                    <select
                      className="form-select"
                      value={limit}
                      onChange={(e) => getData("1", e.target.value)}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Record
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={values.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectedBranches(e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      staff Name <span className="text-danger">*</span>
                    </label>
                    {names ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={values.user_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.user_id && touched.user_id ? (
                      <p className="text-danger">{errors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Document Category <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="document_category"
                      value={values.document_category}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Document Category</option>
                      <option value="Letter">Letter</option>
                      <option value="Certificate">Certificate</option>
                      <option value="Form">Form</option>
                    </select>
                    {errors.document_category && touched.document_category ? (
                      <p className="text-danger">{errors.document_category}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Document Type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="document_type"
                      value={values.document_type}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Document Type</option>
                      {values.document_category === "Letter" && (
                        <>
                          <option value="Offer Letter">Offer Letter</option>
                          <option value="Bank Account Opening Letter">
                            Bank Account Opening Letter
                          </option>
                          <option value="Appointment Letter">
                            Appointment Letter
                          </option>
                          <option value="Confirmation Letter">
                            Confirmation Letter
                          </option>
                          <option value="Warning Letter">Warning Letter</option>
                        </>
                      )}
                      {values.document_category === "Certificate" && (
                        <>
                          <option value="Certificate Of Employment">
                            Certificate Of Employment
                          </option>
                        </>
                      )}
                      {values.document_category === "Form" && (
                        <>
                          <option value="Exit Interview Form">
                            Exit Interview Form
                          </option>
                          <option value="Employee Clearance Form">
                            Employee Clearance Form
                          </option>
                        </>
                      )}
                    </select>
                    {errors.document_type && touched.document_type ? (
                      <p className="text-danger">{errors.document_type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Issue Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="issue_date"
                      value={values.issue_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.issue_date && touched.issue_date ? (
                      <p className="text-danger">{errors.issue_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Document Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" Enter Document Code"
                      autoComplete="off"
                      name="document_code"
                      value={values.document_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.document_code && touched.document_code ? (
                      <p className="text-danger">{errors.document_code}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" Enter Subject"
                      autoComplete="off"
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.subject && touched.subject ? (
                      <p className="text-danger">{errors.subject}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import { NoteState } from "../../context/notes/NoteState";
import CurrencyFormat from "react-currency-format";
import useSortableData from "../../hooks/useSortableData";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function StaffProfilInfo() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myData, setMyData] = useState([]);
  const { id } = useParams();
  const [tableLoading, setTableLoading] = useState(false);
  const [joiningDate, setJoiningDate] = useState("");
  const [startingSalary, setStartingSalary] = useState();

  const getPkg = async () => {
    setTableLoading(true);
    try {
      const responce = await UserServices.getFilteredPromotions(id, branch_id);
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch: values.branch,
            employee_id: values.employee_id,
            employee: values.employee,
            pervs_depart: values.pervs_depart,
            new_depart: values.new_depart,
            pervs_designation: values.pervs_designation,
            new_designation: values.new_designation,
            previous_salary: values.previous_salary,
            new_salary: values.new_salary,
            date: values.date,
            promotion_type: values.promotion_type,
            details: values.details
          });
          return null;
        });

        setMyData([...results]);
        setTableLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setMyData([]);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    }
  };
  const getUsers = async () => {
    try {
      const responce = await UserServices.getStaffProfileInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((value) => {
          setJoiningDate(value.joining);
          setStartingSalary(value.salary);
          return null;
        });
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Network Error") {
      } else if (err.response.status === 404) {
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg();
    getUsers();
  }, []);

  // const Table = ({ data }) => {
  //   const { items } = useSortableData(data);
  //   return (
  //     <>
  //       {tableLoading ? (
  //         <SkeletonTheme height="40px">
  //           <Skeleton count={5} />
  //         </SkeletonTheme>
  //       ) : (
  //         <>
  //           <table
  //             className="table table-striped table-borderless table-style-1"
  //             data-caption="Active Promotions"
  //           >
  //             <thead>
  //               <tr>
  //                 <th scope="col" className="no_sort">
  //                   Date
  //                 </th>
  //                 <th scope="col" className="no_sort">
  //                   Current Salary
  //                 </th>
  //                 <th scope="col" className="no_sort">
  //                   Increment
  //                 </th>
  //                 <th scope="col" className="no_sort">
  //                   Revised Salary
  //                 </th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {items.length ? (
  //                 items.map((el, index) =>
  //                   el.promotion_type === "Salary" ? (
  //                     <tr key={index}>
  //                       <td>{moment(el.date).format("DD-MM-YYYY")}</td>
  //                       <td>
  //                         {" "}
  //                         <CurrencyFormat
  //                           value={el.previous_salary}
  //                           displayType={"text"}
  //                           thousandSeparator={true}
  //                           prefix={"Rs "}
  //                           suffix={"/-"}
  //                           thousandSpacing={"3"}
  //                         />
  //                       </td>
  //                       <td>
  //                         <CurrencyFormat
  //                           value={
  //                             el.new_salary > 0
  //                               ? el.new_salary - el.previous_salary
  //                               : 0
  //                           }
  //                           displayType={"text"}
  //                           thousandSeparator={true}
  //                           prefix={"Rs "}
  //                           suffix={"/-"}
  //                           thousandSpacing={"3"}
  //                         />
  //                       </td>
  //                       <td>
  //                         {" "}
  //                         <CurrencyFormat
  //                           value={el.new_salary}
  //                           displayType={"text"}
  //                           thousandSeparator={true}
  //                           prefix={"Rs "}
  //                           suffix={"/-"}
  //                           thousandSpacing={"3"}
  //                         />
  //                       </td>
  //                     </tr>
  //                   ) : (
  //                     <tr>
  //                       <td
  //                         colSpan="14"
  //                         className="text-center text-capitalize"
  //                       >
  //                         N/A
  //                       </td>
  //                     </tr>
  //                   )
  //                 )
  //               ) : (
  //                 <tr>
  //                   <td colSpan="14" className="text-center text-capitalize">
  //                     No record found
  //                   </td>
  //                 </tr>
  //               )}
  //             </tbody>
  //           </table>
  //         </>
  //       )}
  //     </>
  //   );
  // };
  const Table = ({ data }) => {
    const { items } = useSortableData(data);

    // Filter items for promotion_type "Salary"
    const salaryItems = items.filter(
      (el) => el.promotion_type === "Salary" || el.promotion_type === "All"
    );

    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={5} />
          </SkeletonTheme>
        ) : (
          <>
            <table
              className="table table-striped table-borderless table-style-1"
              data-caption="Active Promotions"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Date
                  </th>
                  <th scope="col" className="no_sort">
                    Current Salary
                  </th>
                  <th scope="col" className="no_sort">
                    Increment
                  </th>
                  <th scope="col" className="no_sort">
                    Revised Salary
                  </th>
                  <th scope="col" className="no_sort">
                   Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {salaryItems.length ? (
                  salaryItems.map((el, index) => (
                    <tr key={index}>
                      <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                      <td>
                        <CurrencyFormat
                          value={el.previous_salary}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"3"}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={
                            el.new_salary > 0
                              ? el.new_salary - el.previous_salary
                              : 0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"3"}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.new_salary}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"3"}
                        />
                      </td>
                      <td>{el.details ? el.details: "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center text-capitalize">
                      N/A
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  };
  // const STable = ({ data }) => {
  //   const { items } = useSortableData(data);
  //   return (
  //     <>
  //       {tableLoading ? (
  //         <SkeletonTheme height="40px">
  //           <Skeleton count={5} />
  //         </SkeletonTheme>
  //       ) : (
  //         <>
  //           <table
  //             className="table table-striped table-borderless table-style-1"
  //             data-caption="Active Promotions"
  //           >
  //             <thead>
  //               <tr>
  //                 <th scope="col" className="no_sort">
  //                   Date
  //                 </th>
  //                 <th scope="col" className="no_sort">
  //                  Promoted Department
  //                 </th>
  //                 <th scope="col" className="no_sort">
  //                  Promoted Designation
  //                 </th>
  //                 <th scope="col" className="no_sort">
  //                   Salary
  //                 </th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {items.length ? (
  //                 items.map((el, index) =>
  //                   el.promotion_type !== "Salary" ? (
  //                     <tr key={index}>
  //                       <td>{moment(el.date).format("DD-MM-YYYY")}</td>
  //                       <td>{el.new_depart ? el.new_depart : "N/A"}</td>
  //                       <td>
  //                         {el.new_designation ? el.new_designation : "N/A"}
  //                       </td>
  //                       <td>
  //                         {" "}
  //                         <CurrencyFormat
  //                           value={el.new_salary}
  //                           displayType={"text"}
  //                           thousandSeparator={true}
  //                           prefix={"Rs "}
  //                           suffix={"/-"}
  //                           thousandSpacing={"3"}
  //                         />
  //                       </td>
  //                     </tr>
  //                   ) : (
  //                     <tr>
  //                       <td
  //                         colSpan="14"
  //                         className="text-center text-capitalize"
  //                       >

  //                       </td>
  //                     </tr>
  //                   )
  //                 )
  //               ) : (
  //                 <tr>
  //                   <td colSpan="14" className="text-center text-capitalize">
  //                     No record found
  //                   </td>
  //                 </tr>
  //               )}
  //             </tbody>
  //           </table>
  //         </>
  //       )}
  //     </>
  //   );
  // };
  const STable = ({ data }) => {
    const { items } = useSortableData(data);

    // Filter items for promotion_type not equal to "Salary"
    const nonSalaryItems = items.filter(
      (el) => el.promotion_type !== "Salary" || el.promotion_type === "All"
    );

    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={5} />
          </SkeletonTheme>
        ) : (
          <>
            <table
              className="table table-striped table-borderless table-style-1"
              data-caption="Active Promotions"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Date
                  </th>
                  <th scope="col" className="no_sort">
                    Promoted Department
                  </th>
                  <th scope="col" className="no_sort">
                    Previous Designation
                  </th>
                  <th scope="col" className="no_sort">
                    Promoted Designation
                  </th>
                  <th scope="col" className="no_sort">
                    Salary
                  </th>
                </tr>
              </thead>
              <tbody>
                {nonSalaryItems.length ? (
                  nonSalaryItems.map((el, index) => (
                    <tr key={index}>
                      <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                      <td>{el.new_depart ? el.new_depart : "N/A"}</td>
                      <td>
                        {el.pervs_designation ? el.pervs_designation : "N/A"}
                      </td>
                      <td>
                        {" "}
                        {el.new_designation ? el.new_designation : "N/A"}
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.new_salary}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          thousandSpacing={"3"}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center text-capitalize">
                      N/A
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  };
  return (
    <div className="card-body">
      <div className="d-flex justify-content-between">
        <h6 className="mb-0 mt-2">
          <strong>Joining Date :</strong>{" "}
          {joiningDate ? moment(joiningDate).format("DD-MM-YYYY") : "N/A"}
        </h6>
        <h6 className="mb-0 mt-2">
          <strong>Starting Salary :</strong>{" "}
          {startingSalary ? (
            <CurrencyFormat
              value={startingSalary}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Rs "}
              suffix={"/-"}
              thousandSpacing={"3"}
            />
          ) : (
            "N/A"
          )}
        </h6>
      </div>
      <div className="ms-auto mt-4">
        {" "}
        <Link
          to="/add-staff-promotion"
          className="btn btn-primary d-inline-block float-end btn-sm"
        >
          <i className="bi bi-plus" /> Add Promotion
        </Link>
      </div>
      <h5 className="mb-0 text-danger mt-4">Salary Increment</h5>
      <div className="table-responsive mt-3">
        <Table data={myData} />
      </div>
      <h5 className="mb-0 text-danger mt-3">Promotion</h5>
      <div className="table-responsive mt-3">
        <STable data={myData} />
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { addDutyHours } from "../schemas";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import jsPDF from "jspdf";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";

export default function StaffDeutyHours() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myBranches, setMyBranches] = useState([]);
  const [names, setNames] = useState([]);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nameError, setNameError] = useState("");
  const [nameUpdtError, setNameUpdtError] = useState("");
  // update

  const [selectedBranches, setSelectedBranches] = useState("");
  const [myDay, setMyDay] = useState("");
  const [staffId, setStaffId] = useState("");
  const [packageId, setPackageId] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  var clickEvent = $.Event("click");

  const closeButtonRef = useRef(null);
  const getPkgs = async (id) => {
    setLoading(true);
    try {
      const response = await UserServices.getDeutyHoursInfo(id);
      if (response.status === 200) {
        const data = response.data.data;
        if (data) {
          const values = data; // Assuming data is a single object
          setSelectedBranches(values.branch_id);
          setMyDay(values.day);
          setStaffId(values.staff_id);
          setPackageId(values.id);
          setStartTime(values.start_time);
          setEndTime(values.end_time);
          $("#membership1").trigger(clickEvent); // Trigger click event on the hidden button to open the modal
          setLoading(false);
        } else {
          console.error("Error: No data found in response");
          setLoading(false);
        }
      } else {
        console.error("Error: Unexpected response status:", response.status);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error:", err);
      setLoading(false);
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const generateReport = async (values) => {
    setLoading(true);
    let e = document.getElementById("answerInput");
    var input = e,
      list = input.getAttribute("list"),
      options = document.querySelectorAll("#" + list + " option"),
      hiddenInput = document.getElementById(
        input.getAttribute("id") + "-hidden"
      ),
      inputValue = input.value;

    // hiddenInput.value = inputValue;

    for (var i = 0; i < options.length; i++) {
      var option = options[i];

      if (option.innerText === inputValue) {
        hiddenInput.value = await option.getAttribute("data-value");
        break;
      } else {
        hiddenInput.value = "";
      }
    }

    let staff_id = await document.getElementById("answerInput-hidden").value;

    try {
      const responce = await UserServices.getFilterActiveEmployeHours(
        branch_id,
        staff_id,
        limit,
        "1"
      );

      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_name: values.branch_name,
            day: values.day,
            name: values.name,
            uid: values.uid,
            duty_hours: values.duty_hours,
          });
          return null;
        });

        setMyData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 401) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time Out!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "LogIn Again!",
        });
      } else if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const staffNames = async () => {
    try {
      const responce = await UserServices.staffName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ name: "Select Names", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  // Define a debounce function
  function debounce(func, delay) {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  // Debounced function for generating report
  const debouncedGenerateReport = debounce((values) => {
    if (!isGeneratingReport) {
      setIsGeneratingReport(true);
      generateReport(values)
        .then(() => setIsGeneratingReport(false))
        .catch((error) => {
          setIsGeneratingReport(false);
          // Handle error if needed
        });
    }
  }, 500); // Adjust the delay as needed, here it's set to 500ms
  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getActiveEmployeHours(
        branch_id,
        // "1",
        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];
        const daysOrder = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        const sortDutyHoursByDay = (dutyHours) => {
          return dutyHours.sort((a, b) => {
            return (
              daysOrder.indexOf(a.day.trim()) - daysOrder.indexOf(b.day.trim())
            );
          });
        };

        res.map((values) => {
          results.push({
            id: values.id,
            branch_name: values.branch_name,
            day: values.day,
            name: values.name,
            uid: values.uid,
            duty_hours: sortDutyHoursByDay(values.duty_hours),
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    branches();
    staffNames();
    getPkg(currentPage, limit);
  }, []);
  const makeInActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.deutyHoursActions(package_Id, "0");
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactivePackage = (package_Id) => {
    makeInActive(package_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deutyHoursActions(id, "2");
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  const makeActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.deutyHoursActions(package_Id, "1");
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activePackage = (package_Id) => {
    makeActive(package_Id);
  };

  const verifyRecord = async (user_id, day) => {
    try {
      setLoading(true);
      const response = await UserServices.checkExistingDutyHours(user_id, day);
      if (response.status === 200) {
        setLoading(false);
        setNameError("");
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.status === 409) {
        toast.error("Already exists!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setNameError("Already exists!");
      }
    }
  };

  const addHours = async (values, action) => {
    var selectedTime = $(
      ".add-time-slot-time .form-check input:checkbox:checked"
    )
      .map(function () {
        return this.value;
      })
      .get()
      .join(", ");
    if (!selectedTime.length) {
      toast.error("Please select days", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      if (nameError === "") {
        try {
          setLoading(true);
          const response = await UserServices.addEmployeHours(
            values,
            selectedTime
          );

          if (response.status === 201) {
            setLoading(false);
            toast.success("Added Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            action.resetForm();
            $(".add-time-slot-time .form-check input:checkbox").prop(
              "checked",
              false
            );
            getPkg(currentPage, limit);
          }
        } catch (err) {
          setLoading(false);
          if (err.response.status === 422) {
          } else if (err.response.status === 500) {
            toast.error("Unproccessable content!", { position: "top-center" });
          } else if (err.response.status === 400) {
            // toast.error("Unproccessable content!", { position: "top-center" });
          } else if (err.response.status === 404) {
            toast.error("Unproccessable content!", { position: "top-center" });
          }
        }
      }
    }
  };
  const initialValues = {
    branch_id: branch_id,
    user_id: "",
    // day: "",
    start_time: "",
    end_time: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: addDutyHours,
      onSubmit: (values, action) => {
        addHours(values, action);
      },
    });
  const formatTime12Hour = (time24) => {
    // Split the time into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);
    // Determine whether it's AM or PM
    const period = hours >= 12 ? "PM" : "AM";
    // Convert hours to 12-hour format with leading zero if necessary
    const hours12 = (hours % 12 || 12).toString().padStart(2, "0");
    // Format the time in 12-hour format (with leading zeros for minutes)
    return `${hours12}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };
  const showInstalDetails = (id) => {
    $("#" + id).fadeToggle();
  };
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }
      doc.text(captionText, 10, currentY);
      currentY += 5;
      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("Staff_Duty_Hours.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table  table-style-1"
          data-caption="Active Duty Hours"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("uid")}
                className={getClassNamesFor("uid")}
              >
                Staff ID
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <>
                  <tr key={index}>
                    <td>
                      <div className="d-flex">
                        <span className="ms-2">
                          {currentPage === 1
                            ? index + 1
                            : (currentPage - 1) * limit + index + 1}
                        </span>
                        {el.duty_hours.length ? (
                          <div
                            className="ms-2"
                            onClick={() => {
                              showInstalDetails(el.id);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-chevron-down fw-bold"></i>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>{el.branch_name}</td>
                    <td>{el.uid}</td>
                    <td>
                      <Link to={"/staff-profile/" + el.id}>{el.name}</Link>
                    </td>
                  </tr>
                  {el.duty_hours.length ? (
                    <tr
                      className="instalmnt-info table table-striped"
                      id={el.id}
                    >
                      <td colSpan="9">
                        <tr>
                          <th
                            style={{
                              width: "40%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Day
                          </th>
                          <th
                            style={{
                              width: "30%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Duty Hours
                          </th>
                          <th
                            style={{
                              width: "30%",
                              background: "rgb(252 239 239);",
                              color: "#000",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              background: "rgb(252 239 239);",
                              color: "#000",
                              width: "10%",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                        {el.duty_hours.map((inl, index) => (
                          <tr key={index}>
                            <td>{inl.day}</td>

                            <td>
                              {formatTime12Hour(inl.start_time)}{" "}
                              <strong>To</strong>{" "}
                              {formatTime12Hour(inl.end_time)}
                            </td>
                            <td>
                              {inl.status === "0" ? "Inactive" : "Active"}
                            </td>
                            <td>
                              {inl.status === "0" ? (
                                <button
                                  onClick={() => {
                                    getPkgs(inl.id);
                                  }}
                                  className="updt-btn inactive-btn"
                                  disabled={true}
                                >
                                  <i className="bi bi-repeat text-success"></i>{" "}
                                  <span className="text-success ms-1 updt-text">
                                    Update
                                  </span>
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    getPkgs(inl.id);
                                  }}
                                  className="updt-btn inactive-btn"
                                >
                                  <i className="bi bi-repeat text-success"></i>{" "}
                                  <span className="text-success ms-1 updt-text">
                                    Update
                                  </span>
                                </button>
                              )}
                              {inl.status === "0" ? (
                                <button
                                  type="button"
                                  onClick={() => activePackage(inl.id)}
                                  className="ms-2 active-btn"
                                >
                                  <i className="bi bi-check-circle text-success"></i>{" "}
                                  <span className="text-success inative-text ms-1">
                                    Active
                                  </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => inactivePackage(inl.id)}
                                  className="ms-2 inactive-btn"
                                >
                                  <i className="bi bi-x-circle text-danger"></i>{" "}
                                  <span className="text-danger inative-text ms-1">
                                    Inactive
                                  </span>
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => delFine(inl.id)}
                                className="ms-2 inactive-btn"
                              >
                                <i className="bi bi-trash3 text-danger"></i>{" "}
                                <span className="text-danger inative-text ms-1">
                                  Delete
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  ) : null}
                </>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const verifyForUpdate = async (updateValues) => {
    try {
      setLoading(true);
      const response = await UserServices.checkUpdateExistingDutyHours(
        updateValues,
        packageId
      );
      if (response.status === 200) {
        setLoading(false);
        setNameUpdtError("");
        UpdatePackage(updateValues);
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.status === 409) {
        toast.error("Already exists!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setNameUpdtError("Already exists!");
      }
    }
  };
  const UpdatePackage = async (updateValues) => {
    setLoading(true);

    try {
      const response = await UserServices.UpdateDutyHours(
        updateValues,
        packageId
      );
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getPkg(currentPage, limit);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    branch_id: selectedBranches,
    day: myDay,
    user_id: staffId,
    start_time: startTime,
    end_time: endTime,
  };
  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: addDutyHours,
    onSubmit: (updateValues, action) => {
      verifyForUpdate(updateValues);
    },
  });
  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Staff Duty Hours Management</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-lg-3">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Staff Name<span className="text-danger">*</span>
                      </label>
                      {names ? (
                        <select
                          className="form-select"
                          name="user_id"
                          value={values.user_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {names.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.user_id && touched.user_id ? (
                        <p className="text-danger">{errors.user_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        From <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        ref={inputRef}
                        className="form-control"
                        placeholder="Start Time"
                        name="start_time"
                        value={values.start_time}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                        title="Please enter time in 24-hour format (HH:MM)"
                      />
                      {errors.start_time && touched.start_time ? (
                        <p className="text-danger">{errors.start_time}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        To <span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        ref={inputRef}
                        className="form-control"
                        placeholder="End Time"
                        name="end_time"
                        value={values.end_time}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                        title="Please enter time in 24-hour format (HH:MM)"
                      />
                      {errors.end_time && touched.end_time ? (
                        <p className="text-danger">{errors.end_time}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-12">
                    <div className="form-group custom-group add-time-slot-time">
                      Select Days<span className="text-danger">*</span>
                      <div className="row">
                        <div className="col-lg-1 mt-2">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Monday"
                                onChange={(e) => {
                                  if (e.target.checked && values.user_id) {
                                    verifyRecord(values.user_id, "Monday");
                                  }
                                }}
                              />
                              Monday
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-1 mt-2">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Tuesday"
                                onChange={(e) => {
                                  if (e.target.checked && values.user_id) {
                                    verifyRecord(values.user_id, "Tuesday");
                                  }
                                }}
                              />
                              Tuesday
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-1 mt-2">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Wednesday"
                                onChange={(e) => {
                                  if (e.target.checked && values.user_id) {
                                    verifyRecord(values.user_id, "Wednesday");
                                  }
                                }}
                              />
                              Wednesday
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-1 mt-2">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Thursday"
                                onChange={(e) => {
                                  if (e.target.checked && values.user_id) {
                                    verifyRecord(values.user_id, "Thursday");
                                  }
                                }}
                              />
                              Thursday
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-1 mt-2">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Friday"
                                onChange={(e) => {
                                  if (e.target.checked && values.user_id) {
                                    verifyRecord(values.user_id, "Friday");
                                  }
                                }}
                              />
                              Friday
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-1 mt-2">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Saturday"
                                onChange={(e) => {
                                  if (e.target.checked && values.user_id) {
                                    verifyRecord(values.user_id, "Saturday");
                                  }
                                }}
                              />
                              Saturday
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-1 mt-2">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Sunday"
                                onChange={(e) => {
                                  if (e.target.checked && values.user_id) {
                                    verifyRecord(values.user_id, "Sunday");
                                  }
                                }}
                              />
                              Sunday
                            </label>
                          </div>
                        </div>
                      </div>
                      {nameError ? (
                        <div className="errorMsg text-danger">{nameError}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <button
              className="btn btn-primary mx-2 file-download float-end mb-3"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <div className="clearfix"></div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">View Duty Hours</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group custom-group">
                      <input
                        style={{ width: "462px" }}
                        type="text"
                        className="form-control"
                        placeholder="Search Staff"
                        name="member_id"
                        autoComplete="off"
                        value={values.member_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          debouncedGenerateReport({
                            ...values,
                            member_id: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        list="members_name"
                        id="answerInput"
                      />
                      <input
                        type="hidden"
                        name="answer"
                        id="answerInput-hidden"
                      />

                      <datalist id="members_name">
                        {names.map((el, index) => {
                          return (
                            <option
                              key={index}
                              data-value={el.id}
                              value={el.name}
                            >
                              {el.name}
                            </option>
                          );
                        })}
                      </datalist>
                    </div>
                  </div>
                  <div className="col-1 mb-3 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership1"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Duty Hours
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={updateHandleSubmit}>
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={updateValues.branch_id || ""}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.branch_id && updateTouched.branch_id ? (
                      <p className="text-danger">{updateErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Staff Name<span className="text-danger">*</span>
                    </label>
                    {names ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={updateValues.user_id}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {names.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.user_id && updateTouched.user_id ? (
                      <p className="text-danger">{updateErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Day <span className="text-danger">*</span>
                    </label>
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Day"
                      name="day"
                      value={updateValues.day}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    /> */}
                    <select
                      className="form-select"
                      name="day"
                      value={updateValues.day}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    >
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                    {nameUpdtError ? (
                      <div className="errorMsg text-danger">
                        {nameUpdtError}
                      </div>
                    ) : null}
                    {updateErrors.day && updateTouched.day ? (
                      <p className="text-danger">{updateErrors.day}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      From <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      ref={inputRef}
                      className="form-control"
                      placeholder="Start Time"
                      name="start_time"
                      value={updateValues.start_time}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                      pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                      title="Please enter time in 24-hour format (HH:MM)"
                    />
                    {updateErrors.start_time && updateTouched.start_time ? (
                      <p className="text-danger">{updateErrors.start_time}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      To <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      ref={inputRef}
                      className="form-control"
                      placeholder="End Time"
                      name="end_time"
                      value={updateValues.end_time}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                      pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                      title="Please enter time in 24-hour format (HH:MM)"
                    />
                    {updateErrors.end_time && updateTouched.end_time ? (
                      <p className="text-danger">{updateErrors.end_time}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}

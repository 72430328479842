import React, { useContext, useEffect, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useParams } from "react-router";

import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
// import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { Link } from "react-router-dom";
import weightIcon from "../../../src/icons/Weight.png";
import neckIcon from "../../../src/icons/neck.png";
import shoulderIcon from "../../../src/icons/shoulder.png";
import chestIcon from "../../../src/icons/chest.png";
import wristIcon from "../../../src/icons/wrist.png";
import absIcon from "../../../src/icons/abs.png";
import armIcon from "../../../src/icons/arm.png";
import waistIcon from "../../../src/icons/waist.png";
import glutesIcon from "../../../src/icons/glutes.png";
import thighIcon from "../../../src/icons/thigh.png";
import calfIcon from "../../../src/icons/calf.png";
import ibmIcon from "../../../src/icons/ibm.png";
import fatIcon from "../../../src/icons/fat.png";
import rprIcon from "../../../src/icons/rpr.png";
import mhrIcon from "../../../src/icons/mhr.png";
import vfatIcon from "../../../src/icons/vfat.png";
import bpIcon from "../../../src/icons/bp.png";
import systolicIcon from "../../../src/icons/systolic.png";
import PostAssessmentForm from "./PostAssessmentForm";
import { useFormik } from "formik";

export default function ViewClientAssesment() {
  const { state } = useContext(NoteState);
  // FIXME change into to selection branch id
  const { branch_id } = state;
  const { id, type } = useParams();
  const [loading, setLoading] = useState(false);
  const [measurementAvaliable, setMeasurementAvaliable] = useState(false);

  const [heartCond, setheartCond] = useState("");
  const [cheastPain, setcheastPain] = useState("");
  const [cheastPastPain, setcheastPastPain] = useState("");
  const [lightHeated, setlightHeated] = useState("");
  const [injuries, setinjuries] = useState("");
  const [medicalSupervision, setmedicalSupervision] = useState("");
  const [medicalPrescribed, setmedicalPrescribed] = useState("");

  const [pregnant, setpregnant] = useState("");
  const [takingMedicine, settakingMedicine] = useState("");
  const [smokingHabit, setsmokingHabit] = useState("");
  const [healthClubMember, sethealthClubMember] = useState("");
  const [havePersonalTrainer, sethavePersonalTrainer] = useState("");
  const [triedPlan, setTriedPlan] = useState("");

  const [gymMember, setGymMember] = useState("");

  const [followingDiet, setFollowingDiet] = useState("");
  const [trainingType, setTrainingType] = useState("");
  const [currentSupliments, setCurrentSupliments] = useState("");
  const [exerciseDays, setexerciseDays] = useState("");

  const [reason, setreason] = useState("");
  const [fitness_goals, setfitness_goals] = useState([]);

  const [measurement, setMeasurement] = useState([]);

  const [clientName, setclientName] = useState("...");

  let goalsList = [];

  // FIXME
  const getUsers = async () => {
    try {
      console.log(`testing`);
      // change endpoint acc to shallu
      console.log(branch_id);
      const responce = await UserServices.getClientsNameById(branch_id, id);

      setclientName(
        responce.data.data[0].first_name + " " + responce.data.data[0].last_name
      );
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers();
    const convertTimeTo24HourFormat = (input) => {
      const [time, period] = input.split(" ");
      const [hours, minutes] = time.split(":").map(Number);
      const isPM = period === "PM";
      const formattedHours = isPM ? hours + 12 : hours;
      return `${formattedHours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    };
    const getAssessmentDetails = async () => {
      try {
        setLoading(true);
        const responce = await UserServices.getAssessmentDetailsById(id);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;

          res.map(async (value) => {
            setheartCond(value.doctor_recommended_activity);
            setcheastPain(value.chest_pain_during_activity);
            setcheastPastPain(value.chest_pain_past_month);

            setlightHeated(value.balance_issues);
            setinjuries(value.bone_joint_problem);
            setmedicalSupervision(value.medical_supervision);
            setmedicalPrescribed(value.medication_prescribed);

            setpregnant(value.pregnant);
            settakingMedicine(value.taking_medicine);
            setsmokingHabit(value.smoking_habit);
            sethealthClubMember(value.health_club_member);
            sethavePersonalTrainer(value.have_personal_trainer);

            setexerciseDays(value.exercise_days);
            setreason(value.other_reason);
            setTriedPlan(value.tried_diet_plan);
            setGymMember(value.gym_member);
            setFollowingDiet(value.following_diet);
            setTrainingType(value.have_training);
            setCurrentSupliments(value.medication_supplement_use);
            form2.setFieldValue(
              "timeBreakfast",
              await convertTimeTo24HourFormat(value.breakfast_time)
            );
            form2.setFieldValue("specBreakfast", value.breakfast);
            form2.setFieldValue(
              "timeLunch",
              await convertTimeTo24HourFormat(value.lunch_time)
            );
            form2.setFieldValue("specLunch", value.lunch);
            form2.setFieldValue(
              "timeDinner",
              await convertTimeTo24HourFormat(value.dinner_time)
            );
            form2.setFieldValue("specDinner", value.dinner);
            form2.setFieldValue(
              "timeSnack",
              await convertTimeTo24HourFormat(value.snack_time)
            );
            form2.setFieldValue("specSnack", value.snack);
            form2.setFieldValue(
              "timeMunching",
              await convertTimeTo24HourFormat(value.munching_time)
            );
            form2.setFieldValue("specMunching", value.munching);

            form2.setFieldValue("waterIntake", value.water_intake);
            form2.setFieldValue("foodNotPrefer", value.disliked_foods);
            form2.setFieldValue("foodAllergies", value.allergic_foods);
            form2.setFieldValue("foodPrefer", value.favorite_foods);

            goalsList = value.fitness_goals.split(",");
            return null;
          });

          setfitness_goals(responce.data.data[0].fitness_goals?.split(","));
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getAssessmentDetails(id);

    const getMeasurements = async () => {
      try {
        setLoading(true);
        const responce = await UserServices.getMeasurementsById(id);
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              abs: value.abs,
              arm: value.arm,
              blood_pressure: value.blood_pressure,
              body_mass_index: value.body_mass_index,
              calf: value.calf,
              chest: value.chest,
              diastolic: value.diastolic,
              fat: value.fat,
              gluts: value.gluts,
              maximum_heart_rate: value.maximum_heart_rate,
              neck: value.neck,
              resting_heart_rate: value.resting_heart_rate,
              shoulder: value.shoulder,
              systolic: value.systolic,
              thigh: value.thigh,
              waist: value.waist,
              weight: value.weight,
              wrist: value.wrist,
              v_fat: value.v_fat,
              fitness_goals: value.fitness_goals,
            });
            return null;
          });

          setMeasurement([...results]);
          setMeasurementAvaliable(true);
          setLoading(false);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getMeasurements(id);
  }, []);

  // const initialValues = {
  //   Height: "",
  //   Weight: "",
  //   Waist: "",
  //   Glutes: "",
  //   Fat: "",
  //   BMI: "",
  // };

  //validation........................................................................................

  const List = ({ data }) => {
    return (
      <>
        {data.length ? (
          data.map((el, index) => (
            <div className="col-md-2 mb-3 p-0 ms-2" key={index}>
              <div className="fitness-goals">
                <i className="bi bi-check"></i> {el}
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-2 mb-3">
            <div className="fitness-goals">N/A</div>
          </div>
        )}
      </>
    );
  };

  const Measurements = ({ data }) => {
    return (
      <>
        {data.length ? (
          <div className="card">
            {data.map((el, index) => (
              <>
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">Assessment</h5>
                </div>

                <div className="card-body">
                  <div className="row" key={index}>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={weightIcon}
                                  alt="weight-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Weight</h6>
                                <h4 className="fw-bold">{el.weight}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={neckIcon}
                                  alt="neck-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Neck</h6>
                                <h4 className="fw-bold">{el.neck}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={shoulderIcon}
                                  alt="shoulder-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Shoulder</h6>
                                <h4 className="fw-bold">{el.shoulder}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={chestIcon}
                                  alt="chest-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Chest</h6>
                                <h4 className="fw-bold">{el.chest}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={wristIcon}
                                  alt="wrist-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Wrist</h6>
                                <h4 className="fw-bold">{el.wrist}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={absIcon}
                                  alt="order-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Abs</h6>
                                <h4 className="fw-bold">{el.abs}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={armIcon}
                                  alt="arm-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Arm</h6>
                                <h4 className="fw-bold">{el.arm}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={waistIcon}
                                  alt="waist-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Waist</h6>
                                <h4 className="fw-bold">{el.waist}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={glutesIcon}
                                  alt="glutes-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Glutes</h6>
                                <h4 className="fw-bold">{el.gluts}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={thighIcon}
                                  alt="thigh-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Thigh</h6>
                                <h4 className="fw-bold">{el.thigh}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={calfIcon}
                                  alt="calf-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Calf</h6>
                                <h4 className="fw-bold">{el.calf}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={ibmIcon}
                                  alt="ibm-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>BMI</h6>
                                <h4 className="fw-bold">
                                  {el.body_mass_index}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={fatIcon}
                                  alt="fat-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>FAT</h6>
                                <h4 className="fw-bold">{el.fat}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={rprIcon}
                                  alt="rpr-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>RHR</h6>
                                <h4 className="fw-bold">
                                  {el.resting_heart_rate}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={mhrIcon}
                                  alt="mhr-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>MHR</h6>
                                <h4 className="fw-bold">
                                  {el.maximum_heart_rate}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={vfatIcon}
                                  alt="vfat-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>V-FAT</h6>
                                <h4 className="fw-bold">{el.v_fat}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={bpIcon}
                                  alt="bp-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>BP </h6>
                                <h4 className="fw-bold">{el.blood_pressure}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={systolicIcon}
                                  alt="systolic-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Systolic</h6>
                                <h4 className="fw-bold">{el.systolic}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={systolicIcon}
                                  alt="diastolic-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Diastolic</h6>
                                <h4 className="fw-bold">{el.diastolic}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row" key={index}>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Weight :</div>
                        <span>{el.weight}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Neck :</div>
                        <span>{el.neck}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Shoulder :</div>
                        <span>{el.shoulder}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Chest :</div>
                        <span>{el.chest}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Wrist :</div>
                        <span>{el.wrist}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Abs :</div>
                        <span>{el.abs}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Arm :</div>
                        <span>{el.arm}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Waist :</div>
                        <span>{el.waist}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Glutes :</div>
                        <span>{el.gluts}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Thigh :</div>
                        <span>{el.thigh}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Calf :</div>
                        <span>{el.calf}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">BMI :</div>
                        <span>{el.body_mass_index}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">FAT% :</div>
                        <span>{el.fat}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">RHR :</div>
                        <span>{el.resting_heart_rate}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">MHR :</div>
                        <span>{el.maximum_heart_rate}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">V-FAT :</div>
                        <span>{el.v_fat}</span>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">BP :</div>
                        <span>{el.blood_pressure}</span>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Systolic :</div>
                        <span>{el.systolic}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Diastolic :</div>
                        <span>{el.diastolic}</span>
                      </div>
                    </div>{" "}
                  </div> */}
                </div>
              </>
            ))}
          </div>
        ) : null}
      </>
    );
  };
  const initialValues2 = {
    branch_id: branch_id,
    timeBreakfast: "",
    timeLunch: "",
    timeDinner: "",
    timeSnack: "",
    timeMunching: "",
    specBreakfast: "",
    specLunch: "",
    specDinner: "",
    specSnack: "",
    specMunching: "",
    waterIntake: "",
    foodNotPrefer: "",
    foodAllergies: "",
    foodPrefer: "",
  };
  const form2 = useFormik({
    initialValues: initialValues2,
    // validationSchema: clientPreAssesment,
    onSubmit: (values) => {},
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Client: {clientName}
                {/* <Link
                  to={"/invoice/assessment/" + id}
                  className="ms-md-2 btn btn-primary d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-printer" /> Quick Slip
                </Link> */}
                {/* {measurementAvaliable ? null : (
                  <Link
                    to={"/postAssessment/" + id}
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    <i className="bi bi-rulers" /> Add Measurements
                  </Link>
                )} */}
                <div className="clearfix" />
              </h5>
            </div>
            {
              <div className="card-body">
                <div className="row">
                  {/* <hr />

                  <div className="col-12 mb-2">
                    <div className="row">
                      <div className="col-lg-12">
                        <h5 className="mb-3 mt-3 mt-lg-0">
                          Fitness Appointment Details
                        </h5>
                        <div className="row">
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Height </label>
                              <div className="input-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Height"
                                  name="Height"
                                  value={height}
                                  readOnly={true}
                                />
                                <span className="input-group-text">Cm</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Weight </label>
                              <div className="input-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Weight"
                                  name="Weight"
                                  value={weight}
                                  readOnly={true}
                                />
                                <span className="input-group-text">Kg</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Waist </label>
                              <div className="input-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Waist"
                                  name="Waist"
                                  value={waist}
                                  readOnly={true}
                                />
                                <span className="input-group-text">Cm</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Glutes </label>
                              <div className="input-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Glutes"
                                  name="Glutes"
                                  value={gluts}
                                  readOnly={true}
                                />
                                <span className="input-group-text">Cm</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">Body Fat </label>
                              <div className="input-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Body Fat"
                                  name="Fat"
                                  value={fat}
                                  readOnly={true}
                                />
                                <span className="input-group-text">
                                  Body Fat %
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-4  mb-2">
                            <div className="form-group custom-group">
                              <label className="form-label">BMI </label>
                              <div className="input-group ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="BMI"
                                  name="BMI"
                                  value={bmi}
                                  readOnly={true}
                                />
                                <span className="input-group-text">kg/m2.</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr /> */}
                  <div className="col-12 mb-2">
                    <div className="row">
                      <div className="col-lg-6">
                        <h5 className="mb-3">Physical Activity Readiness</h5>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Has your doctor ever said you have a heart
                                condition and that you should only do physical
                                activity recommended by a doctor?
                              </td>
                              <td align="center">
                                {heartCond === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td align="center">
                                {heartCond === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you feel pain in your chest when you do
                                physical activity?
                              </td>
                              <td>
                                {cheastPain === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {cheastPain === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                In the past month, have you had chest pain when
                                you were not doing physical activity?
                              </td>
                              <td>
                                {cheastPastPain === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {cheastPastPain === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you lose balance because of dizziness or do
                                you ever lose consciousness?
                              </td>
                              <td>
                                {lightHeated === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {lightHeated === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Do you have a bone or joint problem (for example
                                back, knee, or hip) that could be made worse by
                                a change in your physical activity?
                              </td>
                              <td>
                                {injuries === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {injuries === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Are you aware of any reason against excercise
                                without medical supervision?{" "}
                              </td>
                              <td>
                                {medicalSupervision === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {medicalSupervision === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Women only: Are you pregnant or nursing?</td>
                              <td>
                                {pregnant === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {pregnant === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Is your doctor currently prescribing medication
                                for your blood pressure or heart condition?
                              </td>
                              <td>
                                {medicalPrescribed === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {medicalPrescribed === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Are you taking any prescribed medications that
                                could effect you during excercise (Women;
                                excluded birth control)?{" "}
                              </td>
                              <td>
                                {takingMedicine === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {takingMedicine === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-3 mt-3 mt-lg-0">Healthy Habbits</h5>
                        <table className="table table-bordered mb-2">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Do you smoke or use tobacco? ? </td>
                              <td>
                                {smokingHabit === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {smokingHabit === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Are you a member of a health club?</td>
                              <td>
                                {healthClubMember === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {healthClubMember === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Do you use a personal trainer?</td>
                              <td>
                                {havePersonalTrainer === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {havePersonalTrainer === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                How many times do you excercise in a typical
                                week?{" "}
                              </td>

                              <td colSpan="2" className="font-16 text-center">
                                {exerciseDays} Day(s)
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="col-12 col-lg-12 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Do you know of <strong>any other reason</strong>{" "}
                              why you should not take part in physical activity?
                            </label>

                            <textarea
                              className="form-control "
                              readOnly={true}
                              value={reason}
                              style={{ height: "217px" }}
                            ></textarea>
                          </div>
                        </div>
                        {/* <div className="col-12 col-lg-12 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Resting Pulse </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                value={pulseRate}
                                readOnly={true}
                              />
                              <span className="input-group-text">
                                1.5 sec. count
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-12 mb-2">
                          <div className="form-group custom-group">
                            <label className="form-label">Recovery Rate </label>
                            <div className="input-group ">
                              <input
                                type="text"
                                className="form-control"
                                value={recoveryRate}
                                readOnly={true}
                              />
                              <span className="input-group-text">
                                1 minute count after
                              </span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-3 mt-2">
                          The Objective Of The Plan{" "}
                        </h5>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Statement</th>
                              <th>Yes</th>
                              <th>No</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Have you tried any diet plans before?</td>
                              <td>
                                {triedPlan === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {triedPlan === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Gym member or not</td>
                              <td>
                                {gymMember === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {gymMember === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Already following any diet:</td>
                              <td>
                                {followingDiet === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {followingDiet === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Undergoing any type of training</td>
                              <td>
                                {trainingType === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {trainingType === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Currently using any medicine or supplements:
                              </td>
                              <td>
                                {currentSupliments === "yes" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                              <td>
                                {currentSupliments === "no" ? (
                                  <i className="bi bi-check inside-table"></i>
                                ) : (
                                  "__"
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-6">
                        <h5 className="mb-3 mt-2">Your Daily Dietary Intake</h5>
                        <table className="table table-bordered mb-2 align-middle">
                          <thead>
                            <tr>
                              <th>Meals</th>
                              <th>Time</th>
                              <th>Specifications</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Breakfast</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeBreakfast"
                                  value={form2.values.timeBreakfast || ""}
                                  readOnly={true}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specBreakfast"
                                  value={form2.values.specBreakfast || ""}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Lunch</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeLunch"
                                  value={form2.values.timeLunch || ""}
                                  readOnly={true}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specLunch"
                                  readOnly={true}
                                  value={form2.values.specLunch || ""}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Dinner</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeDinner"
                                  value={form2.values.timeDinner || ""}
                                  readOnly={true}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specDinner"
                                  readOnly={true}
                                  value={form2.values.specDinner || ""}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Snack</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeSnack"
                                  readOnly={true}
                                  value={form2.values.timeSnack || ""}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specSnack"
                                  readOnly={true}
                                  value={form2.values.specSnack || ""}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Munching</td>
                              <td>
                                <input
                                  type="time"
                                  className="form-control"
                                  name="timeMunching"
                                  value={form2.values.timeMunching || ""}
                                  readOnly={true}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Specification"
                                  className="form-control"
                                  name="specMunching"
                                  readOnly={true}
                                  value={form2.values.specMunching || ""}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Daily Water Intake
                          </label>

                          <input
                            type="text"
                            className="form-control "
                            name="waterIntake"
                            readOnly={true}
                            value={form2.values.waterIntake || ""}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Food you don’t prefer
                          </label>

                          <input
                            type="text"
                            className="form-control "
                            name="foodNotPrefer"
                            readOnly={true}
                            value={form2.values.foodNotPrefer || ""}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Food Allergies if any
                          </label>

                          <input
                            type="text"
                            className="form-control "
                            name="foodAllergies"
                            readOnly={true}
                            value={form2.values.foodAllergies || ""}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 mt-2">
                        <div className="form-group custom-group">
                          <label className="form-label">Foods you prefer</label>

                          <input
                            type="text"
                            className="form-control "
                            name="foodPrefer"
                            readOnly={true}
                            value={form2.values.foodPrefer || ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <h5 className="mb-3 font-20">
                      Goal Setting
                      {/* The Results Client Like To Achieve With Your Fitness
                      Training Are */}
                    </h5>
                  </div>
                  <List data={fitness_goals} />
                </div>
              </div>
            }
            <hr />
            <PostAssessmentForm id={id} length={measurement.length} />
            {/* {measurementAvaliable ? null : <PostAssessmentForm id={id} />} */}
          </div>

          {/* <Measurements data={measurement} /> */}
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

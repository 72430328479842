import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import { clientReport, leaveApplication } from "../schemas";
import UserServices from "../../UserServices/UserServices";
import moment from "moment";
import { toast } from "react-toastify";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";

export default function LeaveApplication() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [myBranches, setMyBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trainerName, setTrainerName] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("25");
  const [numberOfDays, setNumberOfDays] = useState(0);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const staffNames = async () => {
    try {
      const responce = await UserServices.staffName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setTrainerName([{ name: "Select Name", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const getPkg = async (addPkgValues) => {
    if (Date.parse(addPkgValues.sdate) <= Date.parse(addPkgValues.edate)) {
      setLoading(true);
      try {
        const response = await UserServices.getLeaveApplication(
          branch_id,
          addPkgValues,
          limit,
          "1"
        );
        let myDataarr;
        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              application_status: value.application_status,
              user_info: value.user_info,
              from: value.from,
              to: value.to,
              leave_type: value.leave_type,
              leave_status: value.leave_status,
              category: value.category,
              number_of_leaves: value.number_of_leaves,
            });
            return null;
          });

          setMyData(results);
          setLimit(response.data.pagination.per_page);
          setTotalResults(response.data.pagination.total_record);
          setTotalPages(response.data.pagination.total_pages);
          setCurrentPage(response.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else if (err.response.status === 500) {
          toast.error("Internal Server Error!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater than start date!",
      });
    }
  };

  const changePagination = (e) => {
    setCurrentPage(e);

    let pageNo = e;

    const getPaginationData = async () => {
      setLoading(true);

      try {
        const response = await UserServices.getLeaveApplication(
          branch_id,
          addPkgValues,
          limit,
          pageNo
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              application_status: value.application_status,
              user_info: value.user_info,
              from: value.from,
              to: value.to,
              leave_type: value.leave_type,
              leave_status: value.leave_status,
              category: value.category,
              number_of_leaves: value.number_of_leaves,
            });
            return null;
          });

          setMyData([...results]);
          setLimit(response.data.pagination.per_page);
          setTotalResults(response.data.pagination.total_record);
          setTotalPages(response.data.pagination.total_pages);
          setCurrentPage(response.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getPaginationData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    const getLimitData = async () => {
      setLoading(true);
      try {
        const response = await UserServices.getLeaveApplication(
          branch_id,
          addPkgValues,
          newLimit,
          "1"
        );

        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              id: value.id,
              application_status: value.application_status,
              user_info: value.user_info,
              from: value.from,
              to: value.to,
              leave_type: value.leave_type,
              leave_status: value.leave_status,
              category: value.category,
              number_of_leaves: value.number_of_leaves,
            });
            return null;
          });

          setMyData(results);
          setLimit(response.data.pagination.per_page);
          setTotalResults(response.data.pagination.total_record);
          setTotalPages(response.data.pagination.total_pages);
          setCurrentPage(response.data.pagination.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          //   setExportLink();
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getLimitData();
  };
  const checkApprovalAvailability = async (
    number_of_leaves,
    leave_type,
    user_id,
    el_id
  ) => {
    let timerInterval;
    Swal.fire({
      title: "Checking leave availability...",
      html: "Please wait while we check leave availability.",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    try {
      const response = await UserServices.availabilityLeaveCheckForApproval({
        number_of_leaves,
        leave_type,
        user_id,
      });

      if (response.status === 200) {
        Swal.close();
        activeRecord(el_id); // Call activeRecord with el_id
      }
    } catch (err) {
      Swal.close();
      if (err.response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Leave Not Available!",
          position: "top-center",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  const makeActive = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.approveRequest(id, dateSelected);
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Approved Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(addPkgValues);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response.status === 500) {
        toast.error("Internal Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const activeRecord = (id) => {
    makeActive(id);
  };
  const makeInActive = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.rejectRequest(id, dateSelected);
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Rejected Successfully !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(addPkgValues);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response.status === 500) {
        toast.error("Internal Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const inactiveRecord = (id) => {
    makeInActive(id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.leaveApplicationActions(id, "2");
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(addPkgValues);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response.status === 500) {
        toast.error("Internal Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const delRcd = (id) => {
    deleteRecord(id);
  };
  useEffect(() => {
    branches();
    staffNames();
    getPkg(addPkgValues);
  }, []);
  const checkLeaveEligibility = async (values, action) => {
    if (Date.parse(values.from) <= Date.parse(values.to)) {
      let timerInterval;
      Swal.fire({
        title: "Checking leave eligibility...",
        html: "Please wait while we check your eligibility.",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });

      try {
        const response = await UserServices.eligibilityLeaveCheck(
          values,
          action
        );

        if (response.status === 200) {
          Swal.close();
          checkLeave(values, action);
        }
      } catch (err) {
        Swal.close();
        if (err.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "User Not Eligible!",
            position: "top-center",
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater than start date!",
      });
    }
  };

  const checkLeaveAvailability = async (values, action) => {
    let timerInterval;
    Swal.fire({
      title: "Checking leave availability...",
      html: "Please wait while we check leave availability.",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    try {
      const response = await UserServices.availabilityLeaveCheck(
        numberOfDays,
        values,
        action
      );

      if (response.status === 200) {
        Swal.close();
        addStaffAttendace(values, action);
      }
    } catch (err) {
      Swal.close();
      if (err.response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Leave Not Available!",
          position: "top-center",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  const checkLeave = async (values, action) => {
    let timerInterval;
    Swal.fire({
      title: "Checking leave...",
      html: "Please wait while we check your leave status.",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });

    try {
      const response = await UserServices.existingLeaveCheck(
        numberOfDays,
        values
      );

      if (response.status === 200) {
        Swal.close();
        setLoading(false);
        if (values.leave_status === "Absent") {
          addStaffAttendace(values, action);
        } else if (values.leave_status === "Leave") {
          checkLeaveAvailability(values, action);
        }
      }
    } catch (err) {
      Swal.close();
      if (err.response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Already Exist!",
          position: "top-center",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else if (err.response.status === 500 || err.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Unprocessable content!",
          position: "top-center",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  const addStaffAttendace = async (values, action) => {
    if (Date.parse(values.from) <= Date.parse(values.to)) {
      try {
        setLoading(true);
        const response = await UserServices.addLeaveApplication(
          values,
          numberOfDays
        );

        if (response.status === 201) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          getPkg(addPkgValues);
        }
      } catch (err) {
        setLoading(false);
        if (err.response.status === 422) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 404) {
          toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater than start date!",
      });
    }
  };
  const initialValues = {
    branch_id: branch_id,
    user_id: "",
    leave_status: "",
    from: dateSelected,
    to: dateSelected,
    reason: "",
    type: "",
    category: "",
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: leaveApplication,
    onSubmit: (values, action) => {
      if (values.leave_status === "Absent") {
        checkLeave(values, action);
      } else {
        checkLeaveEligibility(values, action);
      }
    },
  });

  const addPkginitialValues = {
    sdate: dateSelected,
    edate: dateSelected,
    trainer_id: "",
    attendance_status: "",
    request_status: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: addPkginitialValues,
    validationSchema: clientReport,
    onSubmit: (addPkgValues) => {
      getPkg(addPkgValues);
    },
  });
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");
      const captionText = table.getAttribute("data-caption");
      doc.text(captionText, 10, 10);
      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 },
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });

      doc.save("Leave_Applications.pdf");
    };

    return (
      <>
        <div className="row mb-2">
          <div className="col-md-1 col-lg-1">
            <div className="input-group">
              <select
                className="form-select"
                value={limit}
                onChange={(e) => changeLimit(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-4 ms-auto text-end">
            <button
              className="btn btn-primary mx-2 file-download"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
          </div>
        </div>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Leave Applications"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>

              <th scope="col" className="no_sort">
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("leave_status")}
                className={getClassNamesFor("leave_status")}
              >
                Leave Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("leave_type")}
                className={getClassNamesFor("leave_type")}
              >
                Leave Type
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category")}
                className={getClassNamesFor("category")}
              >
                Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("from")}
                className={getClassNamesFor("from")}
              >
                From
              </th>
              <th
                scope="col"
                onClick={() => requestSort("to")}
                className={getClassNamesFor("to")}
              >
                To
              </th>
              <th
                scope="col"
                onClick={() => requestSort("number_of_leaves")}
                className={getClassNamesFor("number_of_leaves")}
              >
                No. Of Leaves
              </th>
              <th
                scope="col"
                onClick={() => requestSort("application_status")}
                className={getClassNamesFor("application_status")}
              >
                Application Status
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    <Link to={"/staff-profile/" + el.user_info.id}>
                      {el.user_info.name}
                    </Link>
                  </td>
                  <td>{el.leave_status}</td>
                  <td>{el.leave_type ? el.leave_type : "N/A"}</td>
                  <td>{el.category ? el.category : "N/A"}</td>
                  <td>{moment(el.from).format("DD-MM-YYYY")}</td>
                  <td>{moment(el.to).format("DD-MM-YYYY")}</td>
                  <td>{el.number_of_leaves}</td>
                  <td>
                    <span className="d-inline-flex" style={{ width: "70px" }}>
                      {el.application_status === "Pending" ? (
                        <span className="badge bg-warning text-dark w-100">
                          {el.application_status}
                        </span>
                      ) : el.application_status === "Rejected" ? (
                        <span className="badge bg-danger w-100">
                          {el.application_status}
                        </span>
                      ) : (
                        <span className="badge bg-success w-100">
                          {el.application_status}
                        </span>
                      )}
                    </span>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        if (el.leave_status === "Absent") {
                          activeRecord(el.id);
                        } else {
                          checkApprovalAvailability(
                            el.number_of_leaves,
                            el.leave_type,
                            el.user_info.id,
                            el.id
                          );
                        }
                      }}
                      className="updt-btn inactive-btn"
                      disabled={el.application_status !== "Pending"}
                    >
                      <i className="bi bi-check-circle text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Approve
                      </span>
                    </button>

                    <button
                      type="button"
                      onClick={() => inactiveRecord(el.id)}
                      className="ms-2 inactive-btn"
                      disabled={el.application_status !== "Pending"}
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Reject
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => delRcd(el.id)}
                      className="ms-2 inactive-btn"
                      disabled={el.application_status !== "Pending"}
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const calculateDifference = () => {
    const date1 = new Date(values.from);
    const date2 = new Date(values.to);
    const timeDiff = date2.getTime() - date1.getTime();
    let dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    if (dayDiff < 1) {
      dayDiff = 1;
    }
    if (values.category === "Half") {
      dayDiff = 0.5;
    }

    setNumberOfDays(dayDiff);
  };

  useEffect(() => {
    if (values.from && values.to) {
      calculateDifference();
    }
  }, [values.from, values.to, values.category]);
  useEffect(() => {
    if (values.category === "Half") {
      const today = new Date().toISOString().split("T")[0];
      setFieldValue("from", today);
      setFieldValue("to", today);
    }
  }, [values.category, setFieldValue]);
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Add Leave/Absent</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={values.branch_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      {" "}
                      Available Staff<span className="text-danger">*</span>
                    </label>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={values.user_id || ""}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.user_id && touched.user_id ? (
                      <p className="text-danger">{errors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Type of Absence<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="leave_status"
                      value={values.leave_status || ""}
                      onChange={(e) => {
                        handleChange(e);
                        // if (e.target.value === "Absent") {
                        //   checkLeave(values);
                        // }
                        values.type = "";
                        values.category = "";
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Type</option>
                      <option value="Absent">Absent</option>
                      <option value="Leave">Leave</option>
                    </select>
                    {errors.leave_status && touched.leave_status ? (
                      <p className="text-danger">{errors.leave_status}</p>
                    ) : null}
                  </div>
                </div>

                {values.leave_status === "Leave" ? (
                  <>
                    <div className="col-12 col-lg-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Leave Type<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="type"
                          value={values.type || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Leave Type</option>
                          <option value="Medical">Medical</option>
                          <option value="Casual">Casual</option>
                          <option value="Annual">Annual</option>
                        </select>
                        {errors.type && touched.type ? (
                          <p className="text-danger">{errors.type}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Category<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="category"
                          value={values.category || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Type</option>
                          <option value="Half">Half</option>
                          <option value="Full">Full</option>
                        </select>
                        {errors.category && touched.category ? (
                          <p className="text-danger">{errors.category}</p>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">From</label>
                    <span className="text-danger">*</span>
                    <input
                      type="date"
                      className="form-control"
                      name="from"
                      value={values.from}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      disabled={values.category === "Half"}
                    />

                    {errors.from && touched.from ? (
                      <p className="text-danger">{errors.from}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">To</label>
                    <span className="text-danger">*</span>
                    <input
                      type="date"
                      className="form-control"
                      name="to"
                      value={values.to}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      disabled={values.category === "Half"}
                    />

                    {errors.to && touched.to ? (
                      <p className="text-danger">{errors.to}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      No. Of Leaves/Absent<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter No Of Leaves"
                      name="number_of_leaves"
                      disabled={true}
                      value={numberOfDays}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Reason</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Reason"
                      name="reason"
                      value={values.reason}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">View Leave/Absent</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={addPkgHandleSubmit}>
                <div className="col-12 col-md-2">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={addPkgValues.sdate}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />

                    {addPkgErrors.sdate && addPkgTouched.sdate ? (
                      <p className="text-danger">{addPkgErrors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control`}
                      placeholder="Enter End date"
                      name="edate"
                      value={addPkgValues.edate}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />

                    {addPkgErrors.edate && addPkgTouched.edate ? (
                      <p className="text-danger">{addPkgErrors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group custom-group">
                    <label className="form-label"> Available Staff</label>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={addPkgValues.trainer_id || ""}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group custom-group">
                    <label className="form-label">Type of Absence</label>
                    <select
                      className="form-select"
                      name="attendance_status"
                      value={addPkgValues.attendance_status || ""}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    >
                      <option value="">Select Type</option>
                      <option value="Absent">Absent</option>
                      <option value="Leave">Leave</option>
                    </select>
                    {addPkgErrors.attendance_status &&
                    addPkgTouched.attendance_status ? (
                      <p className="text-danger">
                        {addPkgErrors.attendance_status}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group custom-group">
                    <label className="form-label">Application Status</label>
                    <select
                      className="form-select"
                      name="request_status"
                      value={addPkgValues.request_status || ""}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    >
                      <option value="">All</option>
                      <option value="Approved">Approved</option>
                      <option value="Pending">Pending</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                    {addPkgErrors.request_status &&
                    addPkgTouched.request_status ? (
                      <p className="text-danger">
                        {addPkgErrors.request_status}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-1 ms-auto me-2">
                  <button type="submit" className="btn btn-primary px-5 mt-4">
                    Go
                  </button>
                </div>
              </form>
              <div className="mt-2">
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

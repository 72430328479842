import React, { useEffect, useContext, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addstaff } from "../schemas";
import { useState } from "react";
import { useMask } from "react-mask-field";
import "react-phone-number-input/style.css";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { toast, ToastContainer } from "react-toastify";
import Webcam from "react-webcam";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";

const AddStaffMembers = () => {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let eDate = moment(defaultValue).format("YYYY-MM-DD");

  const [loading, setLoading] = useState(false);
  const [myDepartment, setMyDepartment] = useState([]);
  const [myDesignation, setMyDesignation] = useState([]);
  const [myBranches, setMyBranches] = useState([]);
  const [staffCatagry, setStaffCatagry] = useState([]);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cnicError, setCnicError] = useState("");
  const [file, setFile] = useState("");
  const [picture, setPicture] = useState("");
  const [cardError, setCardError] = useState("");

  const navigate = useNavigate();

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const emergency_contact_no = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const cnic_format = useMask({
    mask: "_____-_______-_-",
    replacement: { _: /\d/ },
  });
  const handleUpload = async (e) => {
    setFile(e.target.files[0]);
  };

  const formData = new FormData();
  const initialValues = {
    name: "",
    last_name: "",
    father_name: "",
    designation: "",
    department: "",
    card: "",
    cnic: "",
    phone: "",
    select_gender: "",
    address: "",
    country: "",
    city: "",
    date: eDate,
    appointment_date: eDate,
    probation_duration: "",
    start: "",
    end: "",
    salary: "",
    birthday: "",
    select_department: "",
    email: "",
    branch_id: branch_id,
    commission: "",
    medical: "",
    emergency_contact_no:"",
  };
  const videoConstraints = {
    width: 450,
    height: 400,
    facingMode: "user",
  };
  const department = async (e) => {
    try {
      const responce = await UserServices.departmentName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDepartment([{ name: "Select Department", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setMyDepartment([]);
    }
  };

  const designation = async (e) => {
    try {
      const responce = await UserServices.designationName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDesignation([{ name: "Select Designation", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setMyDesignation([]);
    }
  };
  useEffect(() => {
    department();
    designation();
  }, []);
  const staffCatagryGet = async (e) => {
    if (e.target.value === "1" || e.target.value === "2") {
      try {
        const responce = await UserServices.staffcatagries(branch_id);
        const res = responce.data.data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });

        setStaffCatagry([...results]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useLayoutEffect(() => {
    const branches = async () => {
      try {
        const responce = await UserServices.branchName();
        const res = responce.data.data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
      }
    };

    branches();
  }, []);

  const addMember = async (values, action, catType) => {
    if (phoneError === "" && cardError === "" && cnicError === "") {
      if (file !== "" && picture === "") {
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("image_upload_from", "gallery");
      } else if (picture !== "") {
        formData.append("file", picture);
        formData.append("image_upload_from", "webcam");
      }

      const phNum = values.phone
        .replace(/-/g, "")
        .replace(/[\])}[{(]/g, "")
        .replace(/ /g, "");

        const ephNum = values.emergency_contact_no
        .replace(/-/g, "")
        .replace(/[\])}[{(]/g, "")
        .replace(/ /g, "");
      formData.append("first_name", values.name);
      formData.append("last_name", values.last_name);
      formData.append("father_name", values.father_name);
      formData.append("email", values.email);
      formData.append("designation_id", values.designation);
      formData.append("department_id", values.select_department);
      formData.append("card_number", values.card);
      formData.append("phone", phNum);
      formData.append("emergency_contact_no", ephNum);
      formData.append("address", values.address);
      formData.append("city", values.city);
      formData.append("joining", values.date);
      formData.append("appointment_date", values.appointment_date);
      formData.append("probation_duration", values.probation_duration);
      formData.append("start_time", values.start);
      formData.append("end_time", values.end);
      formData.append("salary", values.salary);
      formData.append("dob", values.birthday);

      formData.append("branch_id", values.branch_id);
      formData.append("gender", values.select_gender);
      formData.append("cnic", values.cnic);
      formData.append("commission", values.commission);
      formData.append("monthly_medical", values.medical);
      formData.append("blood_group", values.blood_group);
      formData.append("type", catType);

      try {
        setLoading(true);

        const response = await UserServices.addStaff(formData);
        if (response.status === 201) {
          setLoading(false);
          toast.success("Staff Add Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          navigate("/view-staff");
        }
      } catch (err) {
        setLoading(false);

        if (err.response.status === 422) {
        } else if (err.response.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 500) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 400) {
          toast.error("Unproccessable content!", { position: "top-center" });
        } else if (err.response.status === 409) {
          console.log("Erros Show1");
        } else {
          console.log("Erros Show2");
        }
      }
    }
  };

  const emailVerify = async (e) => {
    var check_Email = e.target.value;
    try {
      const response = await UserServices.checkEmailwId(check_Email);
      if (response.status === 200) {
        setEmailError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setEmailError("email already exists");
      }
    }
  };

  const verifyCard = async (e) => {
    var check_Card = e.target.value;
    try {
      const response = await UserServices.checkCard(check_Card);
      if (response.status === 200) {
        setCardError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setCardError("Card Number already exists");
      }
    }
  };
  const verifyPhone = async (e) => {
    var check_Phone = e.target.value;
    const phNum = check_Phone
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");
    try {
      const response = await UserServices.checkAuthPhone(phNum, "");
      if (response.status === 200) {
        setPhoneError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setPhoneError("phone number already exists");
      } else if (err.response?.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };
  const verifyCnic = async (e) => {
    var check_Cnic = e.target.value;
    try {
      const response = await UserServices.addStaffCheckCnic(check_Cnic);
      if (response.status === 200) {
        setCnicError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setCnicError("CNIC already exists");
      }
    }
  };

  const webcamRef = React.useRef(null);
  const capture = () => {
    setPicture("");
    const pictureSrc = webcamRef.current.getScreenshot();

    setPicture(pictureSrc);
  };

  //validation........................................................................................

  // const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  //   useFormik({
  //     initialValues: initialValues,
  //     validationSchema: addstaff,
  //     onSubmit: (values, action) => {
  //       var selectedCat = $("input:checkbox:checked")
  //         .map(function () {
  //           return this.value;
  //         })
  //         .get()
  //         .join(",");
  //       // console.log(selectedCat);
  //       addMember(values, action, selectedCat);
  //     },
  //   });
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues: initialValues,
    validationSchema: addstaff,
    onSubmit: (values, action) => {
      var selectedCat = $("input:checkbox:checked")
        .map(function () {
          return this.value;
        })
        .get()
        .join(",");
      addMember(values, action, selectedCat);
    },
  });

  //......................................................................................................

  // show and hide fields.............................................................................................
  return (
    <>
      <div className="wrapper">
        <ToastContainer />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">
                  Add New Staff
                  <Link
                    to="/view-staff"
                    className="btn custom-btn-danger d-inline-block float-end btn-sm"
                  >
                    Add Staff
                  </Link>
                </h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>{" "}
                  Must Required Or Fill.
                </p>
                <form
                  className="row g-3 trainee-add"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Enter First Name"
                        autoComplete="off"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name ? (
                        <p className="text-danger">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Enter Last Name"
                        autoComplete="off"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.last_name && touched.last_name ? (
                        <p className="text-danger">{errors.last_name}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Father's Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Enter Father's Name"
                        autoComplete="off"
                        name="father_name"
                        value={values.father_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label htmlFor="#{email}" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        id="#{email}"
                        className="form-control"
                        placeholder="Email"
                        autoComplete="off"
                        name="email"
                        value={values.email || ""}
                        onChange={(e) => {
                          handleChange(e);
                          emailVerify(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {emailError ? (
                        <div className="errorMsg text-danger">{emailError}</div>
                      ) : null}
                      {errors.email && touched.email ? (
                        <p className="text-danger">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        CNIC <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control cnic-mask"
                        placeholder="xxxxx-xxxxxxx-x"
                        name="cnic"
                        value={values.cnic}
                        onChange={(e) => {
                          handleChange(e);
                          verifyCnic(e);
                        }}
                        onBlur={handleBlur}
                        ref={cnic_format}
                      />

                      {cnicError ? (
                        <div className="errorMsg text-danger">{cnicError}</div>
                      ) : null}
                      {errors.cnic && touched.cnic ? (
                        <p className="text-danger">{errors.cnic}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">DOB</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Birthday"
                        name="birthday"
                        value={values.birthday}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Cell Number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="(e.g 92xxxxxxxxxx"
                        name="phone"
                        value={values.phone}
                        onChange={(e) => {
                          handleChange(e);
                          verifyPhone(e);
                        }}
                        onBlur={handleBlur}
                        ref={phone_number}
                      />
                      {phoneError ? (
                        <div className="errorMsg text-danger">{phoneError}</div>
                      ) : null}
                      {errors.phone && touched.phone ? (
                        <p className="text-danger">{errors.phone}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                      Emergency Contact No
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="(e.g 92xxxxxxxxxx"
                        name="emergency_contact_no"
                        value={values.emergency_contact_no}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        ref={emergency_contact_no}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Blood Group</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Enter Blood Group"
                        name="blood_group"
                        value={values.blood_group}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="select_gender"
                        value={values.select_gender}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                      {errors.select_gender && touched.select_gender ? (
                        <p className="text-danger">{errors.select_gender}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">City</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        autoComplete="off"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.city && touched.city ? (
                        <p className="text-danger">{errors.city}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Address</label>
                      <input
                        type="address"
                        className="form-control"
                        placeholder="Address"
                        autoComplete="off"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.address && touched.address ? (
                        <p className="text-danger">{errors.address}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Department <span className="text-danger">*</span>
                      </label>
                      {myDepartment.length ? (
                        <select
                          className="form-select"
                          name="select_department"
                          value={values.select_department}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {myDepartment.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          autoComplete="off"
                          name="select_department"
                          value={values.select_department}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Department</option>
                        </select>
                      )}
                      {errors.select_department && touched.select_department ? (
                        <p className="text-danger">
                          {errors.select_department}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Designation <span className="text-danger">*</span>
                      </label>
                      {myDesignation.length ? (
                        <select
                          className="form-select"
                          name="designation"
                          value={values.designation}
                          onChange={(e) => {
                            handleChange(e);
                            staffCatagryGet(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {myDesignation.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          name="designation"
                          value={values.designation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Select Designation</option>
                        </select>
                      )}
                      {errors.designation && touched.designation ? (
                        <p className="text-danger">{errors.designation}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Card Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Card Number"
                        autoComplete="off"
                        name="card"
                        value={values.card}
                        onChange={(e) => {
                          handleChange(e);
                          verifyCard(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <div className="errorMsg text-danger">{cardError}</div>
                      {errors.card && touched.card ? (
                        <p className="text-danger">{errors.card}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Joining Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Date"
                        autoComplete="off"
                        name="date"
                        value={values.date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.date && touched.date ? (
                        <p className="text-danger">{errors.date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Appointment Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Appointment Date"
                        autoComplete="off"
                        name="appointment_date"
                        value={values.appointment_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Probation Period
                        <span className="text-black-50">
                          (Please enter the Probation Period in form of months)
                        </span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Please enter the Probation Period in form of months"
                        name="probation_duration"
                        value={values.probation_duration}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Start Time</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Enter Time"
                        autoComplete="off"
                        name="start"
                        value={values.start}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.start && touched.start ? (
                        <p className="text-danger">{errors.start}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">End Time</label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Enter Time"
                        autoComplete="off"
                        name="end"
                        value={values.end}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.end && touched.end ? (
                        <p className="text-danger">{errors.end}</p>
                      ) : null}
                    </div>
                  </div> */}
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Salary <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Salary "
                        autoComplete="off"
                        min={0}
                        name="salary"
                        value={values.salary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.salary && touched.salary ? (
                        <p className="text-danger">{errors.salary}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Medical</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Medical "
                        autoComplete="off"
                        min={0}
                        name="medical"
                        value={values.medical}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label
                        htmlFor="formFileMultiple"
                        className="form-label w-100"
                      >
                        Upload Image{" "}
                        <span
                          className={`text-danger cursor-pointer ${
                            picture === "" ? null : "img-link"
                          }`}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        >
                          {picture === "" ? "Capture with webcam" : picture}
                        </span>
                        {picture !== "" ? <img src={picture} alt="" /> : null}
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        onClick={(e) => {
                          e.target.value = null;
                          setFile("");
                          setPicture("");
                        }}
                        onChange={handleUpload}
                        accept=".png, .jpg, .jpeg"
                      />
                    </div>
                  </div>
                  {/* { show && */}
                  {values.designation === "1" || values.designation === "2" ? (
                    <>
                      <div className="col-12 col-md-4">
                        <div className="form-group custom-group">
                          <label className="form-label">Commission</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Commission "
                            autoComplete="off"
                            name="commission"
                            value={values.commission}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.commission && touched.commission ? (
                            <p className="text-danger">{errors.commission}</p>
                          ) : null}
                        </div>
                      </div>

                      {staffCatagry.length ? (
                        <div className="col-md-6 col-lg-4 ps-lg-5 ">
                          <label className="form-label">Package Category</label>
                          <div className="custom-raio">
                            <ul className="cat-type">
                              {staffCatagry.map((el, index) => (
                                <li key={index}>
                                  <input
                                    type="checkbox"
                                    id={el.id}
                                    value={el.id}
                                    className="option[17]"
                                    defaultChecked={true}
                                  />
                                  <label htmlFor={el.id}>{el.name}</label>
                                  <div className="check" />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  <div className="clearfix" />
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add Staff
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Capture Image
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {picture === "" ? (
                  <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    width={450}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className="webcam"
                  />
                ) : (
                  <img src={picture} alt="" />
                )}
              </div>
              <div>
                {picture !== "" ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPicture("");
                      }}
                      className="btn btn-danger custom-butn3 mt-2"
                    >
                      Retake
                    </button>
                    <button
                      className="btn btn-danger custom-butn3 mt-2 ms-2"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                    className="btn btn-danger custom-butn3 "
                  >
                    Capture
                  </button>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default AddStaffMembers;

import React, { useContext, useEffect, useState } from "react";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toast } from "react-toastify";
import useTable from "../../hooks/useTable";
import moment from "moment";
import { Link } from "react-router-dom";

export default function StaffProfileLeaves() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myData, setMyData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("25");
  const [applicationLimit, setApplicationLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const { id } = useParams();
  const [tableLoading, setTableLoading] = useState(false);

  const getPkg = async (page, limit) => {
    setTableLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getProfileLeaveQuota(
        id,
        branch_id,
        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            leave_type: values.leave_type,
            number_of_leaves: values.number_of_leaves,
            user_info: values.user_info,
            branch_info: values.branch_info,
            leaves_taken: values.leaves_taken,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setTableLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    }
  };
  const getApplications = async () => {
    setTableLoading(true);
    try {
      const response = await UserServices.getProfileLeaveApplication(
        branch_id,
        id,
        "99999999",
        "1"
      );
      let myDataarr;
      if (response.status === 200) {
        myDataarr = response.data.data;
        const res = myDataarr;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            application_status: value.application_status,
            user_info: value.user_info,
            from: value.from,
            to: value.to,
            leave_type: value.leave_type,
            leave_status: value.leave_status,
            category: value.category,
            number_of_leaves: value.number_of_leaves,
          });
          return null;
        });

        setApplicationData(results);
        setApplicationLimit(response.data.pagination.per_page);
        setTableLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setApplicationData([]);
        setTableLoading(false);
      } else if (err.response.status === 500) {
        toast.error("Internal Server Error!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);
    getApplications();
  }, []);

  const allLeaveTypes = ["Medical", "Casual", "Annual"];

  const Table = ({ data }) => {
    const { items } = useSortableData(data);

    // Create a map from the API response
    const leaveDataMap = items.reduce((map, el) => {
      map[el.leave_type] = el;
      return map;
    }, {});

    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={5} />
          </SkeletonTheme>
        ) : (
          <>
            <table
              className="table table-striped table-borderless table-style-1"
              data-caption="Leave Quota"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Description
                  </th>
                  <th scope="col" className="no_sort">
                    Entitlement
                  </th>
                  <th scope="col" className="no_sort">
                    Approved
                  </th>
                  <th scope="col" className="no_sort">
                    Pending
                  </th>
                  <th scope="col" className="no_sort">
                    Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                {allLeaveTypes.map((type, index) => {
                  const leave = leaveDataMap[type] || {
                    leave_type: type,
                    number_of_leaves: 0,
                    leaves_taken: 0,
                    user_info: {
                      pending: 0,
                      pendingAnnualLeaves: 0,
                      pendingCasualLeaves: 0,
                      pendingMedicalLeaves: 0,
                    },
                  };

                  let pendingLeaves;
                  if (type === "Annual") {
                    pendingLeaves = leave.user_info.pendingAnnualLeaves;
                  } else if (type === "Casual") {
                    pendingLeaves = leave.user_info.pendingCasualLeaves;
                  } else if (type === "Medical") {
                    pendingLeaves = leave.user_info.pendingMedicalLeaves;
                  } else {
                    pendingLeaves = leave.user_info.pending;
                  }

                  return (
                    <tr key={index}>
                      <td>{leave.leave_type} Leave</td>
                      <td>{leave.number_of_leaves}</td>
                      <td>{leave.leaves_taken}</td>
                      <td>{pendingLeaves}</td>
                      <td>{leave.number_of_leaves - leave.leaves_taken}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {totalResults > limit && totalPages > 1 && (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={parseInt(limit)}
                totalItemsCount={totalResults}
                onChange={(e) => {
                  setCurrentPage(e);
                  getPkg(e, limit);
                }}
                pageRangeDisplayed={8}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First Page"
                lastPageText="Last Page"
              />
            )}
          </>
        )}
      </>
    );
  };
  const STable = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items } = useSortableData(slice);
    return (
      <>
        {tableLoading ? (
          <SkeletonTheme height="40px">
            <Skeleton count={5} />
          </SkeletonTheme>
        ) : (
          <>
            <table
              className="table table-striped table-borderless table-style-1"
              id="report-POS"
              data-caption="Staff Attendance Report"
            >
              <thead>
                <tr>
                  <th scope="col" className="no_sort">
                    Type of Absence
                  </th>
                  <th scope="col" className="no_sort">
                    Leave Type
                  </th>
                  <th scop="col" className="no_sort">
                    Category
                  </th>
                  <th scope="col" className="no_sort">
                    From
                  </th>
                  <th scope="col" className="no_sort">
                    To
                  </th>
                  <th scope="col" className="no_sort">
                    No. Of Leaves
                  </th>
                  <th scope="col" className="no_sort">
                    Application Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.length ? (
                  items.map((el, index) => (
                    <tr key={index}>
                      <td>{el.leave_status}</td>
                      <td>{el.leave_type ? el.leave_type : "N/A"}</td>
                      <td>{el.category ? el.category : "N/A"}</td>
                      <td>{moment(el.from).format("DD-MM-YYYY")}</td>
                      <td>{moment(el.to).format("DD-MM-YYYY")}</td>
                      <td>{el.number_of_leaves}</td>
                      <td>
                        <span
                          className="d-inline-flex"
                          style={{ width: "70px" }}
                        >
                          {el.application_status === "Pending" ? (
                            <span className="badge bg-warning text-dark w-100">
                              {el.application_status}
                            </span>
                          ) : el.application_status === "Rejected" ? (
                            <span className="badge bg-danger w-100">
                              {el.application_status}
                            </span>
                          ) : (
                            <span className="badge bg-success w-100">
                              {el.application_status}
                            </span>
                          )}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="14" className="text-center text-capitalize">
                      No record found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <div className="card-body">
        <h5 className="mb-0 text-danger mt-2">Leave Quota</h5>
        <div className="table-responsive mt-3">
          <Table data={myData} rowsPerPage={limit} />
        </div>
        <h5 className="mb-0 text-danger mt-2">Applications</h5>
        <div className="table-responsive mt-3">
          <STable data={applicationData} rowsPerPage={applicationLimit} />
        </div>
      </div>
    </>
  );
}

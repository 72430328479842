import React, { useLayoutEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Link, useLocation } from "react-router-dom";
import MetisMenu from "@metismenu/react";
import "metismenujs/style";
import $ from "jquery";
import { useContext } from "react";
import { NoteState } from "../../../context/notes/NoteState";

const Sidebar = () => {
  const { state } = useContext(NoteState);
  const { role } = state;

  const locate = useLocation();
  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      ),
      !1
    );
  };

  useLayoutEffect(() => {
    if (window.innerWidth < 992) {
      $(".site-main-design").removeClass("full-show");
      $(".site-footer-main").removeClass("full-show");
      $("#sidebar").addClass("show");
    }
  }, [locate.pathname]);
  return (
    <div className="row">
      {/* <!--main start div content--> */}
      <main className="col-md-4 ">
        <div
          id="sidebar"
          className="collapse collapse-horizontal show border-end"
        >
          <div
            id="sidebar-nav"
            className="sidebar-wrapper list-group border-0 rounded-0 text-sm-start min-vh-100"
          >
            <MetisMenu>
              {role === "3" || role === "1" || role === "4"? (
                <li
                  className={`${
                    locate.pathname === "/dashboard" ? "mm-active" : ""
                  } `}
                >
                  <Link to="/dashboard">
                    <div className="parent-icon">
                      <i className="bi bi-house-fill"></i>
                    </div>
                    <div className="menu-title">Dashboard</div>
                  </Link>
                </li>
              ) : null}

              {role === "3" || role === "1" || role === "4" ? (
                <li
                  className={`${
                    locate.pathname === "/view-clients" ||
                    locate.pathname === "/add-clients" ||
                    locate.pathname === "/clients-reports" ||
                    locate.pathname === "/add-post-assessment"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="ManageClients"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/view-clients" ||
                      locate.pathname === "/add-clients" ||
                      locate.pathname === "/clients-reports" ||
                      locate.pathname === "/add-post-assessment"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-person-lines-fill"></i>
                    </div>
                    <div className="menu-title"> Manage Clients</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/view-clients" ||
                      locate.pathname === "/add-clients" ||
                      locate.pathname === "/clients-reports" ||
                      locate.pathname === "/add-post-assessment"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/view-clients"
                        className={`${
                          locate.pathname === "/view-clients" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>View Clients
                      </Link>
                    </li>
                    {role === "9" || role === "10" ? null : (
                      <>
                        <li>
                          <Link
                            to="/add-clients"
                            className={`${
                              locate.pathname === "/add-clients" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Client
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/clients-reports"
                            className={`${
                              locate.pathname === "/clients-reports"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Clients Report
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "12" ? (
                <li
                  className={`${
                    locate.pathname === "/view-staff" ||
                    locate.pathname === "/add-staff" ||
                    locate.pathname === "/add-staff-promotion" ||
                    locate.pathname === "/leave-quota" ||
                    locate.pathname === "/staff-fines" ||
                    locate.pathname === "/staff-advances" ||
                    locate.pathname === "/staff-loans" ||
                    locate.pathname === "/staff-salary" ||
                    locate.pathname === "/staff-attendance" ||
                    locate.pathname === "/staff-duty-hours" ||
                    locate.pathname === "/employee-attendance" ||
                    locate.pathname === "/leave-application" ||
                    locate.pathname === "/pt-attendance" ||
                    locate.pathname === "/staff-commission" ||
                    locate.pathname === "/leave-quota" ||
                    locate.pathname === "/leave-application" ||
                    locate.pathname === "/letter-managemnt" ||
                    locate.pathname === "/resource-manager"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="Groupsession"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/view-staff" ||
                      locate.pathname === "/add-staff" ||
                      locate.pathname === "/add-staff-promotion" ||
                      locate.pathname === "/leave-quota" ||
                      locate.pathname === "/staff-fines" ||
                      locate.pathname === "/staff-advances" ||
                      locate.pathname === "/staff-loans" ||
                      locate.pathname === "/staff-salary" ||
                      locate.pathname === "/staff-attendance" ||
                      locate.pathname === "/staff-duty-hours" ||
                      locate.pathname === "/employee-attendance" ||
                      locate.pathname === "/leave-application" ||
                      locate.pathname === "/pt-attendance" ||
                      locate.pathname === "/staff-commission" ||
                      locate.pathname === "/leave-quota" ||
                      locate.pathname === "/leave-application" ||
                      locate.pathname === "/letter-managemnt" ||
                      locate.pathname === "/resource-manager"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-people-fill"></i>
                    </div>
                    <div className="menu-title">Human Resource</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/view-staff" ||
                      locate.pathname === "/add-staff" ||
                      locate.pathname === "/add-staff-promotion" ||
                      locate.pathname === "/leave-quota" ||
                      locate.pathname === "/staff-fines" ||
                      locate.pathname === "/staff-advances" ||
                      locate.pathname === "/staff-loans" ||
                      locate.pathname === "/staff-salary" ||
                      locate.pathname === "/staff-attendance" ||
                      locate.pathname === "/staff-duty-hours" ||
                      locate.pathname === "/employee-attendance" ||
                      locate.pathname === "/leave-application" ||
                      locate.pathname === "/pt-attendance" ||
                      locate.pathname === "/staff-commission" ||
                      locate.pathname === "/leave-quota" ||
                      locate.pathname === "/leave-application" ||
                      locate.pathname === "/letter-managemnt" ||
                      locate.pathname === "/resource-manager"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li
                      className={`${
                        locate.pathname === "/view-staff" ||
                        locate.pathname === "/add-staff" ||
                        locate.pathname === "/add-staff-promotion" ||
                        locate.pathname === "/leave-quota" ||
                        locate.pathname === "/staff-fines" ||
                        locate.pathname === "/staff-advances" ||
                        locate.pathname === "/staff-loans" ||
                        locate.pathname === "/staff-salary" ||
                        locate.pathname === "/staff-attendance" ||
                        locate.pathname === "/staff-duty-hours" ||
                        locate.pathname === "/employee-attendance" ||
                        locate.pathname === "/leave-application" ||
                        locate.pathname === "/pt-attendance" ||
                        locate.pathname === "/staff-commission"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="ManageStaff"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/view-staff" ||
                          locate.pathname === "/add-staff" ||
                          locate.pathname === "/add-staff-promotion" ||
                          locate.pathname === "/leave-quota" ||
                          locate.pathname === "/staff-fines" ||
                          locate.pathname === "/staff-advances" ||
                          locate.pathname === "/staff-loans" ||
                          locate.pathname === "/staff-salary" ||
                          locate.pathname === "/staff-attendance" ||
                          locate.pathname === "/staff-duty-hours" ||
                          locate.pathname === "/employee-attendance" ||
                          locate.pathname === "/leave-application" ||
                          locate.pathname === "/pt-attendance" ||
                          locate.pathname === "/staff-commission"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Manage Staff</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/view-staff" ||
                          locate.pathname === "/add-staff" ||
                          locate.pathname === "/add-staff-promotion" ||
                          locate.pathname === "/leave-quota" ||
                          locate.pathname === "/staff-fines" ||
                          locate.pathname === "/staff-advances" ||
                          locate.pathname === "/staff-loans" ||
                          locate.pathname === "/staff-salary" ||
                          locate.pathname === "/staff-attendance" ||
                          locate.pathname === "/staff-duty-hours" ||
                          locate.pathname === "/employee-attendance" ||
                          locate.pathname === "/leave-application" ||
                          locate.pathname === "/pt-attendance" ||
                          locate.pathname === "/staff-commission"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/view-staff"
                            className={`${
                              locate.pathname === "/view-staff" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>View Staff
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/add-staff"
                            className={`${
                              locate.pathname === "/add-staff" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Staff
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/add-staff-promotion"
                            className={`${
                              locate.pathname === "/add-staff-promotion"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Staff Promotion
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/staff-fines"
                            className={`${
                              locate.pathname === "/staff-fines" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Manage Staff Fines
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/staff-advances"
                            className={`${
                              locate.pathname === "/staff-advances"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Manage Staff
                            Advances
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/staff-loans"
                            className={`${
                              locate.pathname === "/staff-loans" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Manage Staff Loans
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/staff-salary"
                            className={`${
                              locate.pathname === "/staff-salary"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Manage Staff
                            Salaries
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/staff-commission"
                            className={`${
                              locate.pathname === "/staff-commission"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Staff Commissions
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to="/staff-attendance"
                            className={`${
                              locate.pathname === "/staff-attendance"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Staff Attendance
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to="/staff-duty-hours"
                            className={`${
                              locate.pathname === "/staff-duty-hours"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Staff Duty Hours
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/employee-attendance"
                            className={`${
                              locate.pathname === "/employee-attendance"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Employee Attendance
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/pt-attendance"
                            className={`${
                              locate.pathname === "/pt-attendance"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Pt Attendance
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/leave-quota" ||
                        locate.pathname === "/leave-application"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="LeaveManagement"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        aria-expanded={`${
                          locate.pathname === "/leave-quota" ||
                          locate.pathname === "/leave-application"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Leave Management</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/leave-quota" ||
                          locate.pathname === "/leave-application"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/leave-quota"
                            className={`${
                              locate.pathname === "/leave-quota" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Leave Quota
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/leave-application"
                            className={`${
                              locate.pathname === "/leave-application"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Leave Application
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/letter-managemnt"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="LetterManagement"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        aria-expanded={`${
                          locate.pathname === "/letter-managemnt" 
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Letter Management</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/letter-managemnt" 
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/letter-managemnt"
                            className={`${
                              locate.pathname === "/letter-managemnt"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Employee Certificates & Letters
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/resource-manager"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="/resource-manager"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/resource-manager"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title"> Resource Manager</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/resource-manager"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/resource-manager"
                            className={`${
                              locate.pathname === "/resource-manager"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Departments &
                            Designations
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "4" ? (
                <li
                  className={`${
                    locate.pathname === "/membership-package" ||
                    locate.pathname === "/gym-package" ||
                    locate.pathname === "/trainer-package" ||
                    locate.pathname === "/add-nutrition-package" ||
                    locate.pathname === "/add-bootcamp-calsses" ||
                    locate.pathname === "/physiotherapy-package" ||
                    locate.pathname === "/add-group" ||
                    locate.pathname === "/add-gx-package" ||
                    locate.pathname === "/cft-package" ||
                    locate.pathname === "/genral-package" ||
                    locate.pathname === "/cafe" ||
                    locate.pathname === "/lesmills"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="ManageServices"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/membership-package" ||
                      locate.pathname === "/gym-package" ||
                      locate.pathname === "/trainer-package" ||
                      locate.pathname === "/add-nutrition-package" ||
                      locate.pathname === "/add-bootcamp-calsses" ||
                      locate.pathname === "/physiotherapy-package" ||
                      locate.pathname === "/add-group" ||
                      locate.pathname === "/add-gx-package" ||
                      locate.pathname === "/cft-package" ||
                      locate.pathname === "/genral-package" ||
                      locate.pathname === "/cafe" ||
                      locate.pathname === "/lesmills"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-boxes"></i>
                    </div>
                    <div className="menu-title">
                      Manage Services <br /> & Pricing
                    </div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/membership-package" ||
                      locate.pathname === "/gym-package" ||
                      locate.pathname === "/trainer-package" ||
                      locate.pathname === "/add-nutrition-package" ||
                      locate.pathname === "/add-bootcamp-calsses" ||
                      locate.pathname === "/physiotherapy-package" ||
                      locate.pathname === "/add-group" ||
                      locate.pathname === "/add-gx-package" ||
                      locate.pathname === "/cft-package" ||
                      locate.pathname === "/genral-package" ||
                      locate.pathname === "/cafe" ||
                      locate.pathname === "/lesmills"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/membership-package"
                        className={`${
                          locate.pathname === "/membership-package"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Membership Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/gym-package"
                        className={`${
                          locate.pathname === "/gym-package" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Gym Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/trainer-package"
                        className={`${
                          locate.pathname === "/trainer-package" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Trainer Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/add-nutrition-package"
                        className={`${
                          locate.pathname === "/add-nutrition-package"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Nutrition Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/add-bootcamp-calsses"
                        className={`${
                          locate.pathname === "/add-bootcamp-calsses"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Bootcamp Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/physiotherapy-package"
                        className={`${
                          locate.pathname === "/physiotherapy-package"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Physiotherapy Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/lesmills"
                        className={`${
                          locate.pathname === "/lesmills" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>LesMills
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/add-group"
                        className={`${
                          locate.pathname === "/add-group" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Small Pt Group Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/add-gx-package"
                        className={`${
                          locate.pathname === "/add-gx-package" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>GX Packages
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/cft-package"
                        className={`${
                          locate.pathname === "/cft-package" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>CFT
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/genral-package"
                        className={`${
                          locate.pathname === "/genral-package" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>General Packages
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cafe"
                        className={`${
                          locate.pathname === "/cafe" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Cafe Products
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "4" ? (
                <li
                  className={`${
                    locate.pathname === "/add-cafe-category" ||
                    locate.pathname === "/cafe" ||
                    locate.pathname === "/cafe-deposit" ||
                    locate.pathname === "/deposit-history" ||
                    locate.pathname === "/deposit-client-balance" ||
                    locate.pathname === "/clients-available-balance" ||
                    locate.pathname === "/new-cafe-report" ||
                    locate.pathname === "/management-pendings" ||
                    locate.pathname === "/cafe-sales-report"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="ManageCafe"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/add-cafe-category" ||
                      locate.pathname === "/cafe" ||
                      locate.pathname === "/cafe-deposit" ||
                      locate.pathname === "/deposit-history" ||
                      locate.pathname === "/deposit-client-balance" ||
                      locate.pathname === "/clients-available-balance" ||
                      locate.pathname === "/new-cafe-report" ||
                      locate.pathname === "/management-pendings" ||
                      locate.pathname === "/cafe-sales-report"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-cup-hot-fill"></i>
                    </div>
                    <div className="menu-title"> Cafe</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/add-cafe-category" ||
                      locate.pathname === "/cafe" ||
                      locate.pathname === "/cafe-deposit" ||
                      locate.pathname === "/deposit-history" ||
                      locate.pathname === "/deposit-client-balance" ||
                      locate.pathname === "/clients-available-balance" ||
                      locate.pathname === "/new-cafe-report" ||
                      locate.pathname === "/management-pendings" ||
                      locate.pathname === "/cafe-sales-report"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/add-cafe-category"
                        className={`${
                          locate.pathname === "/add-cafe-category"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Cafe Categories
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cafe"
                        className={`${
                          locate.pathname === "/cafe" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Cafe Products
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cafe-deposit"
                        className={`${
                          locate.pathname === "/cafe-deposit" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Cafe Deposits
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/deposit-client-balance"
                        className={`${
                          locate.pathname === "/deposit-client-balance"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Add Clients Deposit
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/clients-available-balance"
                        className={`${
                          locate.pathname === "/clients-available-balance"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Clients Available
                        Balance
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/deposit-history"
                        className={`${
                          locate.pathname === "/deposit-history" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Deposits History
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cafe-sales-report"
                        className={`${
                          locate.pathname === "/cafe-sales-report"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Cafe Sales Report
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/management-pendings"
                        className={`${
                          locate.pathname === "/management-pendings"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Management Pendings
                      </Link>
                    </li>
                    {/* <li>
                        <Link
                          to="/new-cafe-report"
                          className={`${
                            locate.pathname === "/new-cafe-report"
                              ? "active"
                              : ""
                          } `}
                        >
                          <i className="bi bi-circle"></i>New Cafe Report
                        </Link>
                      </li> */}
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "4" ? (
                <li
                  className={`${
                    locate.pathname === "/search-client" ? "mm-active" : ""
                  } `}
                >
                  <Link
                    to="/retail"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/search-client" ? "true" : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-cart-check-fill"></i>
                    </div>
                    <div className="menu-title"> Retail - POS</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/search-client"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/search-client"
                        className={`${
                          locate.pathname === "/search-client" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Sell Package
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "4" || role === "5" ? (
                <li
                  className={`${
                    locate.pathname === "/sales-report" ||
                    locate.pathname === "/sales-by-services" ||
                    locate.pathname === "/sales-by-bootcamp" ||
                    locate.pathname === "/transactions-report" ||
                    locate.pathname === "/attendance-report"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="Reports"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/sales-report" ||
                      locate.pathname === "/sales-by-services" ||
                      locate.pathname === "/sales-by-bootcamp" ||
                      locate.pathname === "/transactions-report" ||
                      locate.pathname === "/attendance-report"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-bar-chart"></i>
                    </div>
                    <div className="menu-title"> Reports</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/sales-report" ||
                      locate.pathname === "/sales-by-services" ||
                      locate.pathname === "/sales-by-bootcamp" ||
                      locate.pathname === "/transactions-report" ||
                      locate.pathname === "/attendance-report"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    {role === "3" || role === "1" || role === "4" ? (
                      <li>
                        <Link
                          to="/clients-reports"
                          className={`${
                            locate.pathname === "/clients-reports"
                              ? "active"
                              : ""
                          } `}
                        >
                          <i className="bi bi-circle"></i>Clients Reports
                        </Link>
                      </li>
                    ) : null}

                    <li>
                      <Link
                        to="/sales-report"
                        className={`${
                          locate.pathname === "/sales-report" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Sales
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sales-by-services"
                        className={`${
                          locate.pathname === "/sales-by-services"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Sales By Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sales-by-bootcamp"
                        className={`${
                          locate.pathname === "/sales-by-bootcamp"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Sales By Bootcamp
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cafe-sales-report"
                        className={`${
                          locate.pathname === "/cafe-sales-report"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Cafe Sales
                      </Link>
                    </li>
                    <li>
                      <Link to="/transactions-report">
                        <i className="bi bi-circle"></i>Transaction Report
                      </Link>
                    </li>
                    {role === "3" ? (
                      <li>
                        <Link
                          to="/staff-attendance"
                          className={`${
                            locate.pathname === "/staff-attendance"
                              ? "active"
                              : ""
                          } `}
                        >
                          <i className="bi bi-circle"></i>Staff Attendance
                        </Link>
                      </li>
                    ) : null}
                    {role === "3" ? (
                      <li>
                        <Link
                          to="/attendance-report"
                          className={`${
                            locate.pathname === "/attendance-report"
                              ? "active"
                              : ""
                          } `}
                        >
                          <i className="bi bi-circle"></i>Clients Attendance
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "4" ? (
                <li
                  className={`${
                    locate.pathname === "/view-freezing" ? "mm-active" : ""
                  } `}
                >
                  <Link
                    to="/retail"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/view-freezing" ? "true" : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-clipboard-x-fill"></i>
                    </div>
                    <div className="menu-title">Freezing</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/view-freezing"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/view-freezing"
                        className={`${
                          locate.pathname === "/view-freezing" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>View Freezing
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "5" ? (
                <li
                  className={`${
                    locate.pathname === "/finance-dashboard" ? "mm-active" : ""
                  } 
                    ${role === "5" ? "order-first" : ""}
                    
                    `}
                >
                  <Link to="/finance-dashboard">
                    <div className="parent-icon">
                      <i className="bi bi-bank"></i>
                    </div>
                    <div className="menu-title">Finance Dashboard</div>
                  </Link>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "5" ? (
                <li
                  className={`${
                    locate.pathname === "/manage-assets" ||
                    locate.pathname === "/balance-sheet" ||
                    locate.pathname === "/daily-sales-counter" ||
                    locate.pathname === "/daily-office-closing" ||
                    locate.pathname === "/bank-details" ||
                    locate.pathname === "/add-expenses" ||
                    locate.pathname === "/view-expenses" ||
                    locate.pathname === "/add-office-cash" ||
                    locate.pathname === "/view-cash-ledger" ||
                    locate.pathname === "/add-bank-cash" ||
                    locate.pathname === "/bank-ledger" ||
                    locate.pathname === "/liabilities-management" ||
                    locate.pathname === "/view-liabilities" ||
                    locate.pathname === "/add-liabilities-ledger"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="Groupsession"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/manage-assets" ||
                      locate.pathname === "/balance-sheet" ||
                      locate.pathname === "/daily-sales-counter" ||
                      locate.pathname === "/daily-office-closing" ||
                      locate.pathname === "/bank-details" ||
                      locate.pathname === "/add-expenses" ||
                      locate.pathname === "/view-expenses" ||
                      locate.pathname === "/add-office-cash" ||
                      locate.pathname === "/view-cash-ledger" ||
                      locate.pathname === "/add-bank-cash" ||
                      locate.pathname === "/bank-ledger" ||
                      locate.pathname === "/liabilities-management" ||
                      locate.pathname === "/view-liabilities" ||
                      locate.pathname === "/add-liabilities-ledger"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-cash-stack"></i>
                    </div>
                    <div className="menu-title">Finance</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/manage-assets" ||
                      locate.pathname === "/balance-sheet" ||
                      locate.pathname === "/daily-sales-counter" ||
                      locate.pathname === "/daily-office-closing" ||
                      locate.pathname === "/bank-details" ||
                      locate.pathname === "/add-expenses" ||
                      locate.pathname === "/view-expenses" ||
                      locate.pathname === "/add-office-cash" ||
                      locate.pathname === "/view-cash-ledger" ||
                      locate.pathname === "/add-bank-cash" ||
                      locate.pathname === "/bank-ledger" ||
                      locate.pathname === "/liabilities-management" ||
                      locate.pathname === "/liabilities-management" ||
                      locate.pathname === "/liabilities-ledger" ||
                      locate.pathname === "/view-liabilities" ||
                      locate.pathname === "/add-liabilities-ledger"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li
                      className={`${
                        locate.pathname === "/add-expenses" ||
                        locate.pathname === "/view-expenses"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="Accounts"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/add-expenses" ||
                          locate.pathname === "/view-expenses"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Expense</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/add-expenses" ||
                          locate.pathname === "/view-expenses"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/add-expenses"
                            className={`${
                              locate.pathname === "/add-expenses"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Expense
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/view-expenses"
                            className={`${
                              locate.pathname === "/view-expenses"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>View Expenses
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      className={`${
                        locate.pathname === "/add-office-cash" ||
                        locate.pathname === "/view-cash-ledger"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="Accounts"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/add-office-cash" ||
                          locate.pathname === "/view-cash-ledger"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Office Ledger</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/manage-categories" ||
                          locate.pathname === "/sub-categories"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/add-office-cash"
                            className={`${
                              locate.pathname === "/add-office-cash"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Office Cash
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/view-cash-ledger"
                            className={`${
                              locate.pathname === "/view-cash-ledger"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>View Office Ledger
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/add-bank-cash" ||
                        locate.pathname === "/bank-ledger"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="Accounts"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/add-bank-cash" ||
                          locate.pathname === "/bank-ledger"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Bank Ledger</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/add-bank-cash" ||
                          locate.pathname === "/bank-ledger"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/add-bank-cash"
                            className={`${
                              locate.pathname === "/add-bank-cash"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Bank Cash
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/bank-ledger"
                            className={`${
                              locate.pathname === "/bank-ledger" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>View Bank Ledger
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/add-petty-cash" ||
                        locate.pathname === "/petty-cash-ledger"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="Accounts"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/add-petty-cash" ||
                          locate.pathname === "/petty-cash-ledger"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Petty Cash Ledger</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/add-petty-cash" ||
                          locate.pathname === "/petty-cash-ledger"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/add-petty-cash"
                            className={`${
                              locate.pathname === "/add-petty-cash"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Petty Cash
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/petty-cash-ledger"
                            className={`${
                              locate.pathname === "/petty-cash-ledger"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Petty Cash Ledger
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/add-branch-cash" ||
                        locate.pathname === "/branch-cash-ledger"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="Accounts"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/add-branch-cash" ||
                          locate.pathname === "/branch-cash-ledger"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">G13 Ledger</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/add-branch-cash" ||
                          locate.pathname === "/branch-cash-ledger"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/add-branch-cash"
                            className={`${
                              locate.pathname === "/add-branch-cash"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add G13 Cash
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/branch-cash-ledger"
                            className={`${
                              locate.pathname === "/branch-cash-ledger"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>G13 Cash Ledger
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/liabilities-management" ||
                        locate.pathname === "/liabilities-ledger" ||
                        locate.pathname === "/view-liabilities" ||
                        locate.pathname === "/add-liabilities-ledger"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="Accounts"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/liabilities-management" ||
                          locate.pathname === "/liabilities-ledger" ||
                          locate.pathname === "/view-liabilities" ||
                          locate.pathname === "/add-liabilities-ledger"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Liabilities</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/liabilities-management" ||
                          locate.pathname === "/liabilities-ledger" ||
                          locate.pathname === "/view-liabilities" ||
                          locate.pathname === "/add-liabilities-ledger"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/liabilities-management"
                            className={`${
                              locate.pathname === "/liabilities-management"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Liabilities
                          </Link>
                        </li>
                        {/* <li>
                            <Link
                              to="/view-liabilities"
                              className={`${
                                locate.pathname === "/view-liabilities"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>View Liabilities
                            </Link>
                          </li> */}
                        <li>
                          <Link
                            to="/add-liabilities-ledger"
                            className={`${
                              locate.pathname === "/add-liabilities-ledger"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Pay Liabilities
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/liabilities-ledger"
                            className={`${
                              locate.pathname === "/liabilities-ledger"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>View Liabilities
                            Ledger
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={`${
                        locate.pathname === "/add-keene" ||
                        locate.pathname === "/keene-ledger"
                          ? "mm-active"
                          : ""
                      } `}
                    >
                      <Link
                        to="Accounts"
                        className="has-arrow"
                        onClick={(e) => e.preventDefault()}
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-expanded={`${
                          locate.pathname === "/add-keene" ||
                          locate.pathname === "/keene-ledger"
                            ? "true"
                            : "false"
                        } `}
                      >
                        <div className="parent-icon">
                          <i className="bi bi-circle"></i>
                        </div>
                        <div className="menu-title">Keene Ledger</div>
                      </Link>
                      <ul
                        className={`${
                          locate.pathname === "/add-keene" ||
                          locate.pathname === "/keene-ledger"
                            ? "mm-show"
                            : "mm-collapse"
                        } `}
                      >
                        <li>
                          <Link
                            to="/add-keene"
                            className={`${
                              locate.pathname === "/add-keene" ? "active" : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Add Keene
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/keene-ledger"
                            className={`${
                              locate.pathname === "/keene-ledger"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Keene Ledger
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        to="/manage-assets"
                        className={`${
                          locate.pathname === "/manage-assets" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Assets
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/balance-sheet"
                        className={`${
                          locate.pathname === "/balance-sheet" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Balance Sheet
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/daily-sales-counter"
                        className={`${
                          locate.pathname === "/daily-sales-counter"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Daily Sales Counter
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/daily-office-closing"
                        className={`${
                          locate.pathname === "/daily-office-closing"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Daily Office Closing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bank-details"
                        className={`${
                          locate.pathname === "/bank-details" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Bank Details
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {role === "3" ||
              role === "1" ||
              role === "10" ||
              role === "9" ||
              role === "11" ? (
                <li
                  className={`${
                    locate.pathname === "/add-meal-plan" ||
                    locate.pathname === "/view-meal-plan" ||
                    locate.pathname === "/nutrition-assessments" ||
                    locate.pathname === "/view-nutrition-assessments" ||
                    locate.pathname === "/time-slot" ||
                    locate.pathname === "/assign-time-slot" ||
                    locate.pathname === "/personal-trainer" ||
                    locate.pathname === "/trainer-appointments" ||
                    locate.pathname === "/new-pt-packages" ||
                    locate.pathname === "/session-attendance" ||
                    locate.pathname === "/new-pt-bookings" ||
                    locate.pathname === "/session-attendance-report" ||
                    locate.pathname === "/pt-sales-report" ||
                    locate.pathname ===
                      "/pt-sales-reportreportreportreportairy" ||
                    locate.pathname === "/trainer-diary" ||
                    locate.pathname === "/manage-exercises" ||
                    locate.pathname === "/fitness-plan" ||
                    locate.pathname === "/add-fitness-plan" ||
                    locate.pathname === "/switch-time" ||
                    locate.pathname === "/gx-classes" ||
                    locate.pathname === "/gx-classes-attendance" ||
                    locate.pathname === "/befit-list" ||
                    locate.pathname === "/gx-appointments" ||
                    locate.pathname === "/gx-attendance-report" ||
                    locate.pathname === "/gx-trainers" ||
                    locate.pathname === "/new-gx-bookings" ||
                    locate.pathname === "/add-gx-slots" ||
                    locate.pathname === "/add-gx-classes" ||
                    locate.pathname === "/befit-attendance-report" ||
                    locate.pathname === "/befit-attendance" ||
                    locate.pathname === "/new-befit-bookings" ||
                    locate.pathname === "/befit-appointments" ||
                    locate.pathname === "/spt-list" ||
                    locate.pathname === "/spt-attendance-report" ||
                    locate.pathname === "/spt-attendance" ||
                    locate.pathname === "/new-spt-bookings" ||
                    locate.pathname === "/staff-commission" ||
                    locate.pathname === "/spt-appointments"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="Groupsession"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    aria-expanded={`${
                      locate.pathname === "/add-meal-plan" ||
                      locate.pathname === "/view-meal-plan" ||
                      locate.pathname === "/nutrition-assessments" ||
                      locate.pathname === "/view-nutrition-assessments" ||
                      locate.pathname === "/time-slot" ||
                      locate.pathname === "/assign-time-slot" ||
                      locate.pathname === "/personal-trainer" ||
                      locate.pathname === "/trainer-appointments" ||
                      locate.pathname === "/new-pt-packages" ||
                      locate.pathname === "/session-attendance" ||
                      locate.pathname === "/new-pt-bookings" ||
                      locate.pathname === "/session-attendance-report" ||
                      locate.pathname === "/pt-sales-report" ||
                      locate.pathname === "/trainer-diary" ||
                      locate.pathname === "/manage-exercises" ||
                      locate.pathname === "/fitness-plan" ||
                      locate.pathname === "/add-fitness-plan" ||
                      locate.pathname === "/switch-time" ||
                      locate.pathname === "/befit-list" ||
                      locate.pathname === "/gx-classes" ||
                      locate.pathname === "/gx-appointments" ||
                      locate.pathname === "/gx-classes-attendance" ||
                      locate.pathname === "/gx-trainers" ||
                      locate.pathname === "/new-gx-bookings" ||
                      locate.pathname === "/gx-attendance-report" ||
                      locate.pathname === "/add-gx-slots" ||
                      locate.pathname === "/add-gx-classes" ||
                      locate.pathname === "/befit-attendance-report" ||
                      locate.pathname === "/befit-attendance" ||
                      locate.pathname === "/new-befit-bookings" ||
                      locate.pathname === "/befit-appointments" ||
                      locate.pathname === "/spt-list" ||
                      locate.pathname === "/spt-attendance-report" ||
                      locate.pathname === "/spt-attendance" ||
                      locate.pathname === "/new-spt-bookings" ||
                      locate.pathname === "/staff-commission" ||
                      locate.pathname === "/spt-appointments"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-clipboard2-heart"></i>
                    </div>
                    <div className="menu-title">Fitness</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/add-meal-plan" ||
                      locate.pathname === "/view-meal-plan" ||
                      locate.pathname === "/nutrition-assessments" ||
                      locate.pathname === "/view-nutrition-assessments" ||
                      locate.pathname === "/time-slot" ||
                      locate.pathname === "/assign-time-slot" ||
                      locate.pathname === "/personal-trainer" ||
                      locate.pathname === "/trainer-appointments" ||
                      locate.pathname === "/new-pt-bookings" ||
                      locate.pathname === "/new-pt-packages" ||
                      locate.pathname === "/session-attendance" ||
                      locate.pathname === "/pt-sales-report" ||
                      locate.pathname === "/session-attendance-report" ||
                      locate.pathname === "/trainer-diary" ||
                      locate.pathname === "/manage-exercises" ||
                      locate.pathname === "/fitness-plan" ||
                      locate.pathname === "/add-fitness-plan" ||
                      locate.pathname === "/switch-time" ||
                      locate.pathname === "/befit-list" ||
                      locate.pathname === "/gx-appointments" ||
                      locate.pathname === "/gx-classes" ||
                      locate.pathname === "/gx-classes-attendance" ||
                      locate.pathname === "/gx-trainers" ||
                      locate.pathname === "/new-gx-bookings" ||
                      locate.pathname === "/gx-attendance-report" ||
                      locate.pathname === "/add-gx-slots" ||
                      locate.pathname === "/add-gx-classes" ||
                      locate.pathname === "/befit-attendance-report" ||
                      locate.pathname === "/befit-attendance" ||
                      locate.pathname === "/new-befit-bookings" ||
                      locate.pathname === "/befit-appointments" ||
                      locate.pathname === "/spt-list" ||
                      locate.pathname === "/spt-attendance-report" ||
                      locate.pathname === "/spt-attendance" ||
                      locate.pathname === "/new-spt-bookings" ||
                      locate.pathname === "/staff-commission" ||
                      locate.pathname === "/spt-appointments"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    {role === "3" ||
                    role === "1" ||
                    role === "9" ||
                    role === "11" ? (
                      <>
                        <li
                          className={`${
                            locate.pathname === "/trainer-diary"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link to="/trainer-diary">
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">Trainer Diary</div>
                          </Link>
                        </li>
                        <li
                          className={`${
                            locate.pathname === "/personal-trainer"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link to="/personal-trainer">
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">Personal Trainer</div>
                          </Link>
                        </li>
                        {role === "3" || role === "1" || role === "11" ? (
                          <>
                            <li
                              className={`${
                                locate.pathname === "/pt-sales-report"
                                  ? "mm-active"
                                  : ""
                              } `}
                            >
                              <Link to="/pt-sales-report">
                                <div className="parent-icon">
                                  <i className="bi bi-circle"></i>
                                </div>
                                <div className="menu-title">
                                  PT Sales Report
                                </div>
                              </Link>
                            </li>
                            <li
                              className={`${
                                locate.pathname === "/switch-time"
                                  ? "mm-active"
                                  : ""
                              } `}
                            >
                              <Link to="/switch-time">
                                <div className="parent-icon">
                                  <i className="bi bi-circle"></i>
                                </div>
                                <div className="menu-title">
                                  Switch Booking Time
                                </div>
                              </Link>
                            </li>
                          </>
                        ) : null}

                        <li
                          className={`${
                            locate.pathname === "/trainer-appointments"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link to="/trainer-appointments">
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">
                              Trainer Appointments
                            </div>
                          </Link>
                        </li>
                        {role === "3" ||
                        role === "1" ||
                        role === "9" ||
                        role === "11" ? (
                          <>
                            <li
                              className={`${
                                locate.pathname === "/session-attendance"
                                  ? "mm-active"
                                  : ""
                              } `}
                            >
                              <Link to="/session-attendance">
                                <div className="parent-icon">
                                  <i className="bi bi-circle"></i>
                                </div>
                                <div className="menu-title">
                                  Session Attendance
                                </div>
                              </Link>
                            </li>
                            <li
                              className={`${
                                locate.pathname === "/session-attendance-report"
                                  ? "mm-active"
                                  : ""
                              } `}
                            >
                              <Link to="/session-attendance-report">
                                <div className="parent-icon">
                                  <i className="bi bi-circle"></i>
                                </div>
                                <div className="menu-title">
                                  Session Attendance Report
                                </div>
                              </Link>
                            </li>
                          </>
                        ) : null}

                        <li
                          className={`${
                            locate.pathname === "/new-pt-bookings"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link to="/new-pt-bookings">
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">New PT Bookings</div>
                          </Link>
                        </li>
                        <li
                          className={`${
                            locate.pathname === "/new-pt-packages"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link to="/new-pt-packages">
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">New PT Clients</div>
                          </Link>
                        </li>
                      </>
                    ) : null}
                    {role === "10" ||
                    role === "3" ||
                    role === "1" ||
                    role === "9" ||
                    role === "11" ? (
                      <li
                        className={`${
                          locate.pathname === "/gx-appointments" ||
                          locate.pathname === "/gx-classes" ||
                          locate.pathname === "/gx-classes-attendance" ||
                          locate.pathname === "/gx-trainers" ||
                          locate.pathname === "/gx-attendance-report" ||
                          locate.pathname === "/new-gx-bookings" ||
                          locate.pathname === "/add-gx-slots" ||
                          locate.pathname === "/add-gx-classes"
                            ? "mm-active"
                            : ""
                        } `}
                      >
                        <Link
                          to="Accounts"
                          className="has-arrow"
                          onClick={(e) => e.preventDefault()}
                          aria-expanded={`${
                            locate.pathname === "/gx-classes" ||
                            locate.pathname === "/gx-appointments" ||
                            locate.pathname === "/gx-classes-attendance" ||
                            locate.pathname === "/gx-trainers" ||
                            locate.pathname === "/gx-attendance-report" ||
                            locate.pathname === "/new-gx-bookings" ||
                            locate.pathname === "/add-gx-slots" ||
                            locate.pathname === "/add-gx-classes"
                              ? "true"
                              : "false"
                          } `}
                        >
                          <div className="parent-icon">
                            <i className="bi bi-circle"></i>
                          </div>
                          <div className="menu-title">GX Classes</div>
                        </Link>
                        <ul
                          className={`${
                            locate.pathname === "/gx-classes" ||
                            locate.pathname === "/gx-classes-attendance" ||
                            locate.pathname === "/gx-appointments" ||
                            locate.pathname === "/gx-trainers" ||
                            locate.pathname === "/gx-attendance-report" ||
                            locate.pathname === "/new-gx-bookings" ||
                            locate.pathname === "/add-gx-slots" ||
                            locate.pathname === "/add-gx-classes"
                              ? "mm-show"
                              : "mm-collapse"
                          } `}
                        >
                          <li>
                            <Link
                              to="/add-gx-slots"
                              className={`${
                                locate.pathname === "/add-gx-slots"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>Add Slots
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/add-gx-classes"
                              className={`${
                                locate.pathname === "/add-gx-classes"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>Add Class
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gx-trainers"
                              className={`${
                                locate.pathname === "/gx-trainers"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>GX Trainers
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gx-classes"
                              className={`${
                                locate.pathname === "/gx-classes"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>GX Slots List
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/new-gx-bookings"
                              className={`${
                                locate.pathname === "/new-gx-bookings"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>GX Bookings
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gx-appointments"
                              className={`${
                                locate.pathname === "/gx-appointments"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>GX Appointments
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/gx-classes-attendance"
                              className={`${
                                locate.pathname === "/gx-classes-attendance"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>GX Attendence
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gx-attendance-report"
                              className={`${
                                locate.pathname === "/gx-attendance-report"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>GX Attendence
                              Report
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : null}
                    {role === "9" ||
                    role === "3" ||
                    role === "1" ||
                    role === "11" ? (
                      <>
                        <li
                          className={`${
                            locate.pathname === "/befit-list" ||
                            locate.pathname === "/befit-attendance-report" ||
                            locate.pathname === "/befit-attendance" ||
                            locate.pathname === "/new-befit-bookings" ||
                            locate.pathname === "/befit-appointments"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link
                            to="Accounts"
                            className="has-arrow"
                            onClick={(e) => e.preventDefault()}
                            aria-expanded={`${
                              locate.pathname === "/befit-list" ||
                              locate.pathname === "/befit-attendance-report" ||
                              locate.pathname === "/befit-attendance" ||
                              locate.pathname === "/new-befit-bookings" ||
                              locate.pathname === "/befit-appointments"
                                ? "true"
                                : "false"
                            } `}
                          >
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">Befit</div>
                          </Link>
                          <ul
                            className={`${
                              locate.pathname === "/befit-list" ||
                              locate.pathname === "/befit-attendance-report" ||
                              locate.pathname === "/befit-attendance" ||
                              locate.pathname === "/new-befit-bookings" ||
                              locate.pathname === "/befit-appointments"
                                ? "mm-show"
                                : "mm-collapse"
                            } `}
                          >
                            <li>
                              <Link
                                to="/befit-list"
                                className={`${
                                  locate.pathname === "/befit-list"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>Befit List
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/new-befit-bookings"
                                className={`${
                                  locate.pathname === "/new-befit-bookings"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>Befit Bookings
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/befit-appointments"
                                className={`${
                                  locate.pathname === "/befit-appointments"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>Befit
                                Appointments
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/befit-attendance"
                                className={`${
                                  locate.pathname === "/befit-attendance"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>Befit Attendance
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/befit-attendance-report"
                                className={`${
                                  locate.pathname === "/befit-attendance-report"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>Befit Attendance
                                Report
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li
                          className={`${
                            locate.pathname === "/spt-list" ||
                            locate.pathname === "/spt-attendance-report" ||
                            locate.pathname === "/spt-attendance" ||
                            locate.pathname === "/new-spt-bookings" ||
                            locate.pathname === "/spt-appointments"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link
                            to="Accounts"
                            className="has-arrow"
                            onClick={(e) => e.preventDefault()}
                            aria-expanded={`${
                              locate.pathname === "/spt-list" ||
                              locate.pathname === "/spt-attendance-report" ||
                              locate.pathname === "/spt-attendance" ||
                              locate.pathname === "/new-spt-bookings" ||
                              locate.pathname === "/spt-appointments"
                                ? "true"
                                : "false"
                            } `}
                          >
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">SPT</div>
                          </Link>
                          <ul
                            className={`${
                              locate.pathname === "/spt-list" ||
                              locate.pathname === "/spt-attendance-report" ||
                              locate.pathname === "/spt-attendance" ||
                              locate.pathname === "/new-spt-bookings" ||
                              locate.pathname === "/spt-appointments"
                                ? "mm-show"
                                : "mm-collapse"
                            } `}
                          >
                            <li>
                              <Link
                                to="/spt-list"
                                className={`${
                                  locate.pathname === "/spt-list"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>SPT List
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/new-spt-bookings"
                                className={`${
                                  locate.pathname === "/new-spt-bookings"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>SPT Bookings
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/spt-appointments"
                                className={`${
                                  locate.pathname === "/spt-appointments"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>SPT Appointments
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/spt-attendance"
                                className={`${
                                  locate.pathname === "/spt-attendance"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>SPT Attendance
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/spt-attendance-report"
                                className={`${
                                  locate.pathname === "/spt-attendance-report"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>SPT Attendance
                                Report
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li
                          className={`${
                            locate.pathname === "/manage-exercises" ||
                            locate.pathname === "/fitness-plan" ||
                            locate.pathname === "/add-fitness-plan"
                              ? "mm-active"
                              : ""
                          } `}
                        >
                          <Link
                            to="Accounts"
                            className="has-arrow"
                            onClick={(e) => e.preventDefault()}
                            aria-expanded={`${
                              locate.pathname === "/manage-exercises" ||
                              locate.pathname === "/fitness-plan" ||
                              locate.pathname === "/add-fitness-plan"
                                ? "true"
                                : "false"
                            } `}
                          >
                            <div className="parent-icon">
                              <i className="bi bi-circle"></i>
                            </div>
                            <div className="menu-title">Fitness Plan</div>
                          </Link>
                          <ul
                            className={`${
                              locate.pathname === "/manage-exercises" ||
                              locate.pathname === "/fitness-plan" ||
                              locate.pathname === "/add-fitness-plan"
                                ? "mm-show"
                                : "mm-collapse"
                            } `}
                          >
                            <li>
                              <Link
                                to="/fitness-plan"
                                className={`${
                                  locate.pathname === "/fitness-plan"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>View Plans
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/add-fitness-plan"
                                className={`${
                                  locate.pathname === "/add-fitness-plan"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>Add Plans
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/manage-exercises"
                                className={`${
                                  locate.pathname === "/manage-exercises"
                                    ? "active"
                                    : ""
                                } `}
                              >
                                <i className="bi bi-circle"></i>Manage Exercises
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    ) : null}

                    {role === "10" ||
                    role === "3" ||
                    role === "1" ||
                    role === "11" ? (
                      <li
                        className={`${
                          locate.pathname === "/add-meal-plan" ||
                          locate.pathname === "/view-meal-plan"
                            ? "mm-active"
                            : ""
                        } `}
                      >
                        <Link
                          to="Accounts"
                          className="has-arrow"
                          onClick={(e) => e.preventDefault()}
                          aria-expanded={`${
                            locate.pathname === "/add-meal-plan" ||
                            locate.pathname === "/view-meal-plan"
                              ? "true"
                              : "false"
                          } `}
                        >
                          <div className="parent-icon">
                            <i className="bi bi-circle"></i>
                          </div>
                          <div className="menu-title">Meals Plan</div>
                        </Link>
                        <ul
                          className={`${
                            locate.pathname === "/add-meal-plan" ||
                            locate.pathname === "/view-meal-plan"
                              ? "mm-show"
                              : "mm-collapse"
                          } `}
                        >
                          <li>
                            <Link
                              to="/add-meal-plan"
                              className={`${
                                locate.pathname === "/add-meal-plan"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>Add Meals Plan
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/view-meal-plan"
                              className={`${
                                locate.pathname === "/view-meal-plan"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>View Meals Plan
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : null}
                    {role === "10" ||
                    role === "3" ||
                    role === "1" ||
                    role === "11" ? (
                      <li
                        className={`${
                          locate.pathname === "/nutrition-assessments" ||
                          locate.pathname === "/view-nutrition-assessments"
                            ? "mm-active"
                            : ""
                        } `}
                      >
                        <Link
                          to="Accounts"
                          className="has-arrow"
                          onClick={(e) => e.preventDefault()}
                          aria-expanded={`${
                            locate.pathname === "/nutrition-assessments" ||
                            locate.pathname === "/view-nutrition-assessments"
                              ? "true"
                              : "false"
                          } `}
                        >
                          <div className="parent-icon">
                            <i className="bi bi-circle"></i>
                          </div>
                          <div className="menu-title">
                            Nutrition Assessments
                          </div>
                        </Link>
                        <ul
                          className={`${
                            locate.pathname === "/nutrition-assessments" ||
                            locate.pathname === "/view-nutrition-assessments"
                              ? "mm-show"
                              : "mm-collapse"
                          } `}
                        >
                          <li>
                            <Link
                              to="/nutrition-assessments"
                              className={`${
                                locate.pathname === "/nutrition-assessments"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>Add Nutrition
                              Assessments
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/view-nutrition-assessments"
                              className={`${
                                locate.pathname ===
                                "/view-nutrition-assessments"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>View Nutrition
                              Assessments
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : null}
                    {role === "3" || role === "1" || role === "11" ? (
                      <li
                        className={`${
                          locate.pathname === "/time-slot" ||
                          locate.pathname === "/assign-time-slot"
                            ? "mm-active"
                            : ""
                        } `}
                      >
                        <Link
                          to="Accounts"
                          className="has-arrow"
                          onClick={(e) => e.preventDefault()}
                          aria-expanded={`${
                            locate.pathname === "/time-slot" ||
                            locate.pathname === "/assign-time-slot"
                              ? "true"
                              : "false"
                          } `}
                        >
                          <div className="parent-icon">
                            <i className="bi bi-circle"></i>
                          </div>
                          <div className="menu-title">Trainer Schedule</div>
                        </Link>
                        <ul
                          className={`${
                            locate.pathname === "/time-slot" ||
                            locate.pathname === "/assign-time-slot"
                              ? "mm-show"
                              : "mm-collapse"
                          } `}
                        >
                          <li>
                            <Link
                              to="/time-slot"
                              className={`${
                                locate.pathname === "/time-slot" ? "active" : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>Time Slots
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/assign-time-slot"
                              className={`${
                                locate.pathname === "/assign-time-slot"
                                  ? "active"
                                  : ""
                              } `}
                            >
                              <i className="bi bi-circle"></i>Manage
                              Availability
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : null}
                    { role === "11" ? (
                        <li>
                          <Link
                            to="/staff-commission"
                            className={`${
                              locate.pathname === "/staff-commission"
                                ? "active"
                                : ""
                            } `}
                          >
                            <i className="bi bi-circle"></i>Staff Commissions
                          </Link>
                        </li>
                         ) : null}
                  </ul>
                </li>
              ) : null}

              {role === "3" || role === "1" || role === "5" ? (
                <li
                  className={`${
                    locate.pathname === "/manage-categories" ||
                    locate.pathname === "/sub-categories"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="Accounts"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/manage-categories" ||
                      locate.pathname === "/sub-categories"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-grid-1x2"></i>
                    </div>
                    <div className="menu-title">Manage Categories</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/manage-categories" ||
                      locate.pathname === "/sub-categories"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/manage-categories"
                        className={`${
                          locate.pathname === "/manage-categories"
                            ? "active"
                            : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Categories
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sub-categories"
                        className={`${
                          locate.pathname === "/sub-categories" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Sub-Categories
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {role === "3" || role === "1" || role === "5" ? (
                <li
                  className={`${
                    locate.pathname === "/manage-towels" ? "mm-active" : ""
                  } `}
                >
                  <Link
                    to="Accounts"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/manage-towels" ? "true" : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-credit-card-2-front"></i>
                    </div>
                    <div className="menu-title">Accounts</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/manage-towels"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/manage-towels"
                        className={`${
                          locate.pathname === "/manage-towels" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Manage Towels
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "4" ? (
                <li
                  className={`${
                    locate.pathname === "/assign-cards" ||
                    locate.pathname === "/view-cards"
                      ? "mm-active"
                      : ""
                  } `}
                >
                  <Link
                    to="Groupsession"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/assign-cards" ||
                      locate.pathname === "/view-cards"
                        ? "true"
                        : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-card-checklist"></i>
                    </div>
                    <div className="menu-title">Manage Cards</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/assign-cards" ||
                      locate.pathname === "/view-cards"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/assign-cards"
                        className={`${
                          locate.pathname === "/assign-cards" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Assign Cards
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/view-cards"
                        className={`${
                          locate.pathname === "/view-cards" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>View Cards
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" ? (
                <li
                  className={`${
                    locate.pathname === "/manage-branches" ? "mm-active" : ""
                  } `}
                >
                  <Link
                    to="Groupsession"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/manage-branches" ? "true" : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-folder-plus"></i>
                    </div>
                    <div className="menu-title">Manage Branches</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/manage-branches"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/manage-branches"
                        className={`${
                          locate.pathname === "/manage-branches" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Manage Branches
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "5" ? (
                <li
                  className={`${
                    locate.pathname === "/settings" ? "mm-active" : ""
                  } `}
                >
                  <Link
                    to="/retail"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/settings" ? "true" : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-gear-wide"></i>
                    </div>
                    <div className="menu-title"> Settings</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/settings"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/settings"
                        className={`${
                          locate.pathname === "/settings" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Settings
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {role === "3" || role === "1" || role === "4" ? (
                <li
                  className={`${
                    locate.pathname === "/approval" ? "mm-active" : ""
                  } `}
                >
                  <Link
                    to="/retail"
                    className="has-arrow"
                    onClick={(e) => e.preventDefault()}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-expanded={`${
                      locate.pathname === "/approval" ? "true" : "false"
                    } `}
                  >
                    <div className="parent-icon">
                      <i className="bi bi-check-circle"></i>
                    </div>
                    <div className="menu-title"> Approval</div>
                  </Link>
                  <ul
                    className={`${
                      locate.pathname === "/approval"
                        ? "mm-show"
                        : "mm-collapse"
                    } `}
                  >
                    <li>
                      <Link
                        to="/approval"
                        className={`${
                          locate.pathname === "/approval" ? "active" : ""
                        } `}
                      >
                        <i className="bi bi-circle"></i>Approval
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
            </MetisMenu>
          </div>
        </div>
      </main>
      {/* <!--Start Back To Top Button--> */}
      <button className="back-to-top custom-button" onClick={backToTop}>
        <i className="bi bi-arrow-up"></i>
      </button>
      {/* <!--end page main div--> */}
    </div>
  );
};

export default Sidebar;

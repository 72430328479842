import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { NoteState } from "../../../context/notes/NoteState";
import MenuButton from "../../MenuButton/MenuButton";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import html2canvas from "html2canvas";
import { useEffect } from "react";
import UserServices from "../../../UserServices/UserServices";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import jsPDF from "jspdf";
import Swal from "sweetalert2";

export default function CafeDepositSlip() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const { state } = useContext(NoteState);
    const { branch_id } = state;
  
    const downloadImage = async (blob, fileName) => {
      const div = window.document.createElement("div");
      div.style = "display:none; text-align:center;";
      const myImage = window.document.createElement("img");
      myImage.src = blob;
      myImage.style = "display:block; margin:0 auto;";
      var printMe = await div.appendChild(myImage);
      setLoading(false);
      var print_area = window.open();
      if (print_area && print_area.document) {
        print_area.document.write(div.innerHTML);
        print_area.document.close();
        print_area.focus();
        print_area.print();
        print_area.close();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to open print window",
        });
      }
    };
    const printSlip = async (el, imageFileName) => {
      setLoading(true);
  
      // Increase the resolution of the canvas by setting a higher scale option
      const canvas = await html2canvas(el, { scale: 3 });
  
      // Convert to a higher resolution JPEG image format with increased quality
      const image = canvas.toDataURL("image/jpeg", 0.95); // You can adjust the quality (0.0 to 1.0) as needed
  
      downloadImage(image, imageFileName);
    };
    const generatePdf = async (el) => {
      setLoading(true);
      const canvas = await html2canvas(el, {
        scale: 5, // Set the scale to 1 to get the actual size of the element
        allowTaint: true, // Allow cross-origin images to be drawn on the canvas
        useCORS: true, // Use CORS to fetch the images if needed
      });
      const image = canvas.toDataURL("image/png", 1.0);
      const invoiceWidth = 150; // in mm
      const invoiceHeight = 297; // in mm
      const pdf = new jsPDF("p", "mm", [invoiceWidth, invoiceHeight]);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const scaleX = pdfWidth / canvasWidth;
      const scaleY = pdfHeight / canvasHeight;
      const scale = Math.min(scaleX, scaleY);
      const canvasScaledWidth = canvasWidth * scale;
      const canvasScaledHeight = canvasHeight * scale;
      const pdfX = (pdfWidth - canvasScaledWidth) / 2;
      const pdfY = (pdfHeight - canvasScaledHeight) / 2;
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(
        imgData,
        "PNG",
        pdfX,
        pdfY,
        canvasScaledWidth,
        canvasScaledHeight
      );
      const pdfData = pdf.output("blob");
      const pdfURL = URL.createObjectURL(pdfData);
      setLoading(false);
      window.open(pdfURL, "_blank");
    };
  
    const { id, type } = useParams();
    const [clientName, setClientName] = useState("");
  
    const [myData, setMyData] = useState([]);
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString("en-CA");
  
    var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  
    const [price, setprice] = useState("");
    const [order_date, setorder_date] = useState(dateSelected);
    const [net_price, setnet_price] = useState("");
    const [order_id, setorder_id] = useState(
      Math.floor(Math.random() * 90000) + 10000
    );
    const [payment_method, setpayment_method] = useState("");
    const [discount, setdiscount] = useState("");
    const [total_received, settotal_received] = useState("");
    const [total_tax, settotal_tax] = useState(0);
    const [Address, setAddress] = useState("");
    const [City, setCity] = useState("");
    const [branchName, setbranchName] = useState("");
    const [branchNo, setbranchNo] = useState("");
    const [ntnNo, setNtnNo] = useState("");
    const [strnNo, setStrenNo] = useState("");
    const [payment_detail, setpayment_detail] = useState([]);
    const [taxPer, setTaxPer] = useState(0);
  
    const getUsers = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getClientsPurchasesForInvoice(id);
  
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          setClientName(
            myDataarr[0]?.client_first_name + " " + myDataarr[0]?.client_last_name
          );
  
          res.map((value) => {
            setprice(value.price);
            setorder_date(value.date);
            setnet_price(value.net_price);
            setdiscount(value.discount);
            setorder_id(value.id);
            settotal_received(value.total_received);
            settotal_tax(value.tax ? value.tax : 0);
            setpayment_detail(value.payment_history ? value.payment_history : []);
            const taxcal = Math.floor(
              (value.tax * 100) / (value.price - value.discount)
            );
            setTaxPer(taxcal);
            if (value.payment_history?.length) {
              const keysToJoin = ["payment_type"];
              var payments;
              payments = value.payment_history?.map((obj) =>
                keysToJoin.map((key) => obj[key]).join(",")
              );
  
              const uniqueArray = [...new Set(payments)];
              if (uniqueArray.length < 3) {
                setpayment_method(uniqueArray.join(", "));
              } else {
                setpayment_method("Multiple");
              }
            } else {
              setpayment_method("N/A");
            }
          });
  
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
  
    const getAccountDetails = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getClientsAccountsForInvoicePurchases(
          id
        );
  
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
  
          const results = [];
  
          res.map((value) => {
            results.push({
              price: value.price,
              quantity: value.quantity,
              package_name: value.package_name,
            });
          });
  
          setMyData([...results]);
  
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const branches = async () => {
      try {
        const responce = await UserServices.getBranchesForInvoice(branch_id);
  
        const res = responce.data.data;
  
        res.map((value) => {
          setAddress(value.address);
          setCity(value.city);
          setbranchName(value.name);
          setbranchNo(value.phone);
          setNtnNo(value.ntn);
          setStrenNo(value.strn);
        });
      } catch (err) {
        console.log(err);
      }
    };
  
    const getUserName = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getClientsNameById(branch_id, id);
  
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          setClientName(myDataarr[0]?.first_name + " " + myDataarr[0]?.last_name);
  
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const getAccountDetailsForAll = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getClientsAccountsForInvoice(id);
  
        let myDataarr;
        console.log(responce);
        if (responce.status === 200) {
          myDataarr = responce.data.data;
          setClientName(
            myDataarr[0]?.client_first_name + " " + myDataarr[0]?.client_last_name
          );
          const res = myDataarr;
  
          const results = [];
  
          res.map((value) => {
            setprice(value.price);
            setorder_date(value.date);
            setnet_price(value.net_price);
            setdiscount(value.discount);
            setorder_id(value.order_id);
            settotal_tax(value.tax ? value.tax : 0);
  
            const taxcal = Math.floor(
              (value.tax * 100) / (value.price - value.discount)
            );
            setTaxPer(taxcal);
  
            results.push({
              price: value.price,
              quantity: value.quantity,
              package_name: value.package_name,
            });
          });
  
          setMyData([...results]);
  
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
  
    const getPaymentsData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getPaymentsDataForInvoice(id);
  
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          setClientName(myDataarr[0]?.name);
          const res = myDataarr;
  
          res.map((value) => {
            setprice(value.received + value.pending);
            setorder_date(value.receiving_date);
            setnet_price(value.received);
            setdiscount(value.pending);
            setorder_id(value.order_id);
            setpayment_method(value.payment_type);
          });
  
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
  
        setLoading(false);
      }
    };
  
    useEffect(() => {
      branches();
  
      if (type === "purchases") {
        getUsers();
        getAccountDetails();
      } else if (type === "assessment") {
        getUserName();
      } else if (type === "account-details") {
        getAccountDetailsForAll();
      } else if (type === "payments") {
        getPaymentsData();
      }
    }, []);
  
    const ItemSold = ({ data }) => {
      return (
        <>
          {data.length ? (
            data.map((el, index) => (
              <tr key={index} style={{ fontWeight: "800" }}>
                <td valign="middle">
                  {el.package_name !== null ? el.package_name : "Guest Pass"}
                </td>
                <td valign="middle">{el.quantity}</td>
                <td valign="middle">
                  <CurrencyFormat
                    value={el.price}
                    displayType={"text"}
                    thousandSeparator={true}
                   thousandSpacing={"3"}
                    prefix={"Rs "}
                    suffix={"/-"}
                    renderText={(value) => <>{value}</>}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="text-center text-capitalize">
                No record found
              </td>
            </tr>
          )}
        </>
      );
    };
  
    const PaymentsDtl = ({ data }) => {
      return (
        <>
          <tr>
            <th
              align="center"
              style={{
                color: "black",
                fontWeight: "800",
                verticalAlign: "middle",
              }}
              colSpan={type === "assessment" ? "1" : "2"}
            >
              <strong>Payment Method</strong>
            </th>
            <th
              align="center"
              style={{
                color: "black",
                fontWeight: "800",
                verticalAlign: "middle",
              }}
            >
              <strong>Amount</strong>
            </th>
          </tr>
          {data.length ? (
            <>
              {type === "purchases"
                ? data.map((el, index) => (
                    <tr key={index}>
                      {" "}
                      <th
                        align="right"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "800",
                        }}
                        valign="middle"
                        colSpan="2"
                      >
                        {el.payment_type}
                      </th>
                      <th
                        align="right"
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "800",
                        }}
                        valign="middle"
                      >
                        <CurrencyFormat
                          value={el.received}
                          displayType={"text"}
                          thousandSeparator={true}
                         thousandSpacing={"3"}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <>{value}</>}
                        />
                      </th>
                    </tr>
                  ))
                : null}
            </>
          ) : type === "assessment" ? (
            <tr>
              <th
                align="right"
                style={{
                  fontSize: "14px",
                  color: "black",
                  fontWeight: "800",
                }}
                valign="middle"
              >
                Cash
              </th>
              <th
                align="right"
                style={{
                  fontSize: "14px",
                  color: "black",
                  fontWeight: "800",
                }}
                valign="middle"
              >
                <CurrencyFormat
                  value="200"
                  displayType={"text"}
                  thousandSeparator={true}
                 thousandSpacing={"3"}
                  prefix={"Rs "}
                  suffix={"/-"}
                  renderText={(value) => <>{value}</>}
                />
              </th>
            </tr>
          ) : null}
        </>
      );
    };
  return (
    <div className="wrapper">
    <Navbar />
    <Sidebar />
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <MenuButton />
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">
              Print Invoice
              <button
                className="btn btn-danger d-inline-block ms-2  me-2 btn-sm"
                onClick={() => {
                  printSlip(document.getElementById("invoice-POS"), "slip");
                }}
              >
                <i className="bi bi-printer-fill"></i>
              </button>
              Generate PDF
              <button
                className="btn btn-danger d-inline-block ms-2 btn-sm"
                onClick={() => {
                  generatePdf(document.getElementById("invoice-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf-fill"></i>
              </button>
              <button
                className="btn btn-primary d-inline-block float-end btn-sm"
                onClick={() => navigate(-1)}
              >
                Continue Without Print
              </button>
            </h5>
          </div>
          <div className="card-body">
            <div id="printableArea">
              <div
                style={{
                  boxShadow: "0 0 1in -0.25in rgba(0, 0, 0, 0.5)",
                  width: "90mm",
                  background: "#FFF",
                  margin: "0px auto",
                }}
              >
                <div
                  id="invoice-POS"
                  style={{
                    padding: "2mm",
                    margin: "20px auto",
                    width: "90mm",
                    background: "#FFF",
                  }}
                >
                  <center id="top">
                    <div className="info">
                      <img
                        src="/assets/images/logo.png"
                        alt=""
                        style={{
                          margin: "0px auto",
                          marginTop: "10px",
                          width: "80%",
                        }}
                      />
                    </div>
                  </center>

                  <div id="mid" style={{ textAlign: "center" }}>
                    <div className="info">
                      <p
                        style={{
                          marginBottom: "0",
                          marginTop: "15px",
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: "800",
                          color: "#000",
                        }}
                      >
                        Address : {Address} 
                        .
                        <br />
                        Phone : {branchNo}
                        <br />
                        NTN # : {ntnNo}
                        <br />
                        STRN # : {strnNo}
                        <br />
                        www.vostroworld.com
                      </p>
                    </div>
                  </div>

                  <div id="mid2">
                    <div
                      className="info"
                      style={{ marginTop: "15px", color: "#000" }}
                    >
                      <span style={{ fontWeight: "800" }}>
                        Date: {moment(order_date).format("DD-MM-YYYY")}
                      </span>

                      <span style={{ marginLeft: "78px", fontWeight: "800" }}>
                        Order No: {order_id}
                      </span>
                      <br />
                      <p
                        style={{
                          marginTop: "15px",
                          textalign: "left",
                          fontWeight: "800",
                        }}
                      >
                        Client Name:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "Capitalize",
                            fontWeight: "800",
                          }}
                        >
                          {clientName}
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>

                  <div id="bot">
                    <div id="table" style={{ marginTop: "3px" }}>
                      <table
                        className="table"
                        style={{ marginBottom: "0px" }}
                      >
                        {type !== "payments" ? (
                          <tbody>
                            <tr>
                              <th width="50%">
                                <b style={{ color: "black" }}>Item</b>
                              </th>
                              {type !== "assessment" ? (
                                <th width="10%">
                                  <b style={{ color: "black" }}>Quantity</b>
                                </th>
                              ) : null}

                              <th width="40%">
                                <b style={{ color: "black" }}>Price</b>
                              </th>
                            </tr>
                            {type !== "assessment" ? (
                              <>
                                <ItemSold data={myData} />
                                <tr className="tabletitle">
                                  <th
                                    align="center"
                                    style={{
                                      color: "black",
                                      fontWeight: "800",
                                      verticalAlign: "middle",
                                    }}
                                    colSpan="2"
                                  >
                                    Total
                                  </th>

                                  <th
                                    align="right"
                                    style={{
                                      fontSize: "14px",
                                      color: "#000",
                                      fontWeight: "800",
                                    }}
                                    valign="middle"
                                  >
                                    <CurrencyFormat
                                      value={price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                     thousandSpacing={"3"}
                                      prefix={"Rs "}
                                      suffix={"/-"}
                                      renderText={(value) => <>{value}</>}
                                    />
                                  </th>
                                </tr>
                                {total_tax === 0 ? null : (
                                  <tr className="tabletitle">
                                    <th
                                      align="center"
                                      style={{
                                        color: "black",
                                        verticalAlign: "middle",
                                        fontWeight: "800",
                                      }}
                                      colSpan="2"
                                    >
                                      GST ({taxPer}%)
                                    </th>

                                    <th
                                      align="right"
                                      style={{
                                        fontSize: "14px",
                                        color: "#000",
                                        fontWeight: "800",
                                      }}
                                      valign="middle"
                                    >
                                      <CurrencyFormat
                                        value={total_tax}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                       thousandSpacing={"3"}
                                        prefix={"Rs "}
                                        suffix={"/-"}
                                        renderText={(value) => <>{value}</>}
                                      />
                                    </th>
                                  </tr>
                                )}

                                <tr className="tabletitle">
                                  <th
                                    align="center"
                                    style={{
                                      color: "black",
                                      fontWeight: "800",
                                      verticalAlign: "middle",
                                    }}
                                    colSpan="2"
                                  >
                                    Net Price
                                  </th>

                                  <th
                                    align="right"
                                    style={{
                                      fontSize: "14px",
                                      color: "black",
                                      fontWeight: "800",
                                    }}
                                    valign="middle"
                                  >
                                    <CurrencyFormat
                                      value={net_price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                     thousandSpacing={"3"}
                                      prefix={"Rs "}
                                      suffix={"/-"}
                                      renderText={(value) => <>{value}</>}
                                    />
                                  </th>
                                </tr>
                                {type !== "account-details" ? (
                                  <>
                                    <tr className="tabletitle1">
                                      <th
                                        align="center"
                                        style={{
                                          color: "black",
                                          fontWeight: "800",
                                          verticalAlign: "middle",
                                        }}
                                        colSpan="2"
                                      >
                                        Paid Amount
                                      </th>

                                      <th
                                        align="right"
                                        style={{
                                          fontSize: "14px",
                                          color: "black",
                                          fontWeight: "800",
                                        }}
                                        valign="middle"
                                      >
                                        <CurrencyFormat
                                          value={total_received}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                         thousandSpacing={"3"}
                                          prefix={"Rs "}
                                          suffix={"/-"}
                                          renderText={(value) => <>{value}</>}
                                        />
                                      </th>
                                    </tr>
                                  </>
                                ) : null}
                              </>
                            ) : null}

                            {type !== "account-details" ? (
                              <PaymentsDtl data={payment_detail} />
                            ) : null}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr className="tabletitle">
                              <th
                                align="center"
                                style={{
                                  color: "black",
                                  fontWeight: "800",
                                }}
                              >
                                Total
                              </th>

                              <th
                                align="right"
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "800",
                                }}
                                valign="middle"
                              >
                                <CurrencyFormat
                                  value={price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                 thousandSpacing={"3"}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <>{value}</>}
                                />
                              </th>
                            </tr>
                            <tr className="tabletitle">
                              <th
                                align="center"
                                style={{
                                  color: "black",
                                  fontWeight: "800",
                                }}
                              >
                                Paid Amount
                              </th>

                              <th
                                align="right"
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "800",
                                }}
                                valign="middle"
                              >
                                <CurrencyFormat
                                  value={net_price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                 thousandSpacing={"3"}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <>{value}</>}
                                />
                              </th>
                            </tr>
                            <tr className="tabletitle">
                              <th
                                align="center"
                                style={{
                                  color: "black",
                                  fontWeight: "800",
                                }}
                              >
                                <strong>Payment Method</strong>
                              </th>

                              <th
                                align="right"
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "800",
                                }}
                                valign="middle"
                              >
                                <strong>Amount</strong>
                              </th>
                            </tr>

                            <tr className="tabletitle">
                              <th
                                align="center"
                                style={{
                                  color: "black",
                                  fontWeight: "800",
                                }}
                              >
                                {payment_method}
                              </th>

                              <th
                                align="right"
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "800",
                                }}
                                valign="middle"
                              >
                                <CurrencyFormat
                                  value={net_price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                 thousandSpacing={"3"}
                                  prefix={"Rs "}
                                  suffix={"/-"}
                                  renderText={(value) => <>{value}</>}
                                />
                              </th>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                    <img
                      src="/assets/images/divider.png"
                      alt=""
                      style={{
                        margin: "10px auto",
                        width: "90%",
                        display: "block",
                        opacity: ".6",
                      }}
                    />
                    <div id="legalcopy">
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: "10px",
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "800",
                          color: "black",
                        }}
                      >
                        Please retain this receipt for your records.
                        <br />
                        Thank you!
                      </p>

                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: "5px",
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "800",
                          color: "black",
                        }}
                      >
                        Developed By: Spark Solutionz
                        <br />
                        (+92) 321-604-4363
                      </p>

                      <p></p>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
  </div>
  )
}

import React, { useContext, useEffect, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useParams } from "react-router";

import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
// import { toast, ToastContainer } from "react-toastify";
import { NoteState } from "../../context/notes/NoteState";
import { Link } from "react-router-dom";
import weightIcon from "../../../src/icons/Weight.png";

import chestIcon from "../../../src/icons/chest.png";

import armIcon from "../../../src/icons/arm.png";
import waistIcon from "../../../src/icons/waist.png";
import glutesIcon from "../../../src/icons/glutes.png";
import thighIcon from "../../../src/icons/thigh.png";

import ibmIcon from "../../../src/icons/ibm.png";
import fatIcon from "../../../src/icons/fat.png";
import rprIcon from "../../../src/icons/rpr.png";
import mhrIcon from "../../../src/icons/mhr.png";
import vfatIcon from "../../../src/icons/vfat.png";
import height from "../../../src/icons/height.png";
import age from "../../../src/icons/age.png";

import systolicIcon from "../../../src/icons/systolic.png";

import moment from "moment";

export default function ViewClientAssesmentInfo() {
  const { state } = useContext(NoteState);
  // FIXME change into to selection branch id
  const { branch_id } = state;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [measurement, setMeasurement] = useState([]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const getMeasurements = async () => {
      try {
        setLoading(true);
        const responce = await UserServices.getMeasurementsById(id);

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          console.log(res);
          // const results = [];

          // res.map((value) => {
          //   results.push({
          //     id: value.id,
          //     date: value.date,
          //     arm: value.arm,
          //     age: value.age,
          //     height: value.height,
          //     belly: value.belly,
          //     blood_pressure: value.blood_pressure,
          //     body_mass_index: value.body_mass_index,
          //     chest: value.chest,
          //     fat: value.fat,
          //     gluts: value.gluts,
          //     maximum_heart_rate: value.mhr,
          //     whr: value.whr,
          //     resting_heart_rate: value.rhr,
          //     thigh: value.thigh,
          //     waist: value.waist,
          //     weight: value.weight,
          //     v_fat: value.v_fat,
          //     fitness_goals: value.fitness_goals,
          //     name: value.fname + " " + value.lname,
          //   });
          //   return null;
          // });

          setMeasurement([...res]);

          setLoading(false);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getMeasurements();
  }, []);

  // const initialValues = {
  //   Height: "",
  //   Weight: "",
  //   Waist: "",
  //   Glutes: "",
  //   Fat: "",
  //   BMI: "",
  // };

  //validation........................................................................................

  const Measurements = ({ data }) => {
    return (
      <>
        {data.length ? (
          <div className="card">
            {data.map((el, index) => (
              <>
                <div className="card-header py-3" >
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="mb-0 font-20">Client Name: {el.fname}</h5>
                    </div>
                    <div className="col-lg-6 text-end">
                      <h5 className="mb-0 font-20">
                        Assessment Date: {moment(el.date).format("DD-MM-YYYY")}
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row" key={index}>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={age}
                                  alt="weight-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Age</h6>
                                <h4 className="fw-bold">{el.age}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={height}
                                  alt="weight-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Height</h6>
                                <h4 className="fw-bold">{el.height}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={weightIcon}
                                  alt="weight-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Weight</h6>
                                <h4 className="fw-bold">{el.weight}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={chestIcon}
                                  alt="chest-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Chest</h6>
                                <h4 className="fw-bold">{el.chest}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={armIcon}
                                  alt="arm-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Arm</h6>
                                <h4 className="fw-bold">{el.arm}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={waistIcon}
                                  alt="waist-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Waist</h6>
                                <h4 className="fw-bold">{el.waist}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={glutesIcon}
                                  alt="glutes-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Glutes</h6>
                                <h4 className="fw-bold">{el.gluts}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={thighIcon}
                                  alt="thigh-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>Thigh</h6>
                                <h4 className="fw-bold">{el.thigh}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={ibmIcon}
                                  alt="ibm-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>BMI</h6>
                                <h4 className="fw-bold">
                                  {el.body_mass_index}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={fatIcon}
                                  alt="fat-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>FAT</h6>
                                <h4 className="fw-bold">{el.fat}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={rprIcon}
                                  alt="rpr-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>RHR</h6>
                                <h4 className="fw-bold">{el.rhr}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={mhrIcon}
                                  alt="mhr-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>MHR</h6>
                                <h4 className="fw-bold">{el.mhr}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={systolicIcon}
                                  alt="diastolic-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>WHR</h6>
                                <h4 className="fw-bold">{el.whr}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="card dashboard-cards">
                        <div className="card-body bgColor">
                          <div className="white-bg">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="icon-shape">
                                <img
                                  src={vfatIcon}
                                  alt="vfat-icon"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div>
                                <h6>V-FAT</h6>
                                <h4 className="fw-bold">{el.v_fat}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row" key={index}>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Weight :</div>
                        <span>{el.weight}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Neck :</div>
                        <span>{el.neck}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Shoulder :</div>
                        <span>{el.shoulder}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Chest :</div>
                        <span>{el.chest}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Wrist :</div>
                        <span>{el.wrist}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Abs :</div>
                        <span>{el.abs}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Arm :</div>
                        <span>{el.arm}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Waist :</div>
                        <span>{el.waist}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Glutes :</div>
                        <span>{el.gluts}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Thigh :</div>
                        <span>{el.thigh}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Calf :</div>
                        <span>{el.calf}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">BMI :</div>
                        <span>{el.body_mass_index}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">FAT% :</div>
                        <span>{el.fat}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">RHR :</div>
                        <span>{el.resting_heart_rate}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">MHR :</div>
                        <span>{el.maximum_heart_rate}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">V-FAT :</div>
                        <span>{el.v_fat}</span>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">BP :</div>
                        <span>{el.blood_pressure}</span>
                      </div>
                    </div>{" "}
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Systolic :</div>
                        <span>{el.systolic}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2 mb-3">
                      <div className="expenses-num">
                        <div className="heading">Diastolic :</div>
                        <span>{el.diastolic}</span>
                      </div>
                    </div>{" "}
                  </div> */}
                </div>
              </>
            ))}
          </div>
        ) : (
          <div className="card">
            <div className="card-body">
              <h6 className="fw-bold text-danger mb-0">No Assessment Found</h6>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Measurements data={measurement} />
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

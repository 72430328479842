import React, { useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { managerResource } from "../schemas";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
import MenuButton from "../MenuButton/MenuButton";

const UpdateResource = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [myDepartment, setMyDepartment] = useState([]);
  const [updateNameError, setUpdateNameError] = useState("");

  const { id } = useParams();

  const updateVerifyName = async (e) => {
    var check_Name = e.target.value;
    try {
      const response = await UserServices.updateCheckName(check_Name, id);
      if (response.status === 200) {
        setUpdateNameError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setUpdateNameError("Name already exists");
      }
    }
  };

  const getRes = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getResourceInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;
        data.map((values) => {
          setSelectedName(values.name);
          setSelectedType(values.type);
          setSelectedDescription(values.description);
          setSelectedDepartment(values.department_id);
        });

        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const department = async () => {
    try {
      const responce = await UserServices.departmentName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDepartment([{ name: "Select Department", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setMyDepartment([]);
    }
  };
  useEffect(() => {
    getRes(id);
    department();
  }, []);

  const updateResource = async (values) => {
    setLoading(true);
    if (updateNameError === "") {
      try {
        const response = await UserServices.updateResource(values, id);
        console.log(response);
        if (response.status === 204) {
          setLoading(false);
          toast.success("Updated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/resource-manager");
        }
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };
  const initialValues = {
    name: selectedName,
    type: selectedType,
    description: selectedDescription,
    department_id: selectedDepartment,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: managerResource,
      onSubmit: (values, action) => {
        updateResource(values, action);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Update Resource Manager</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Category<span className="text-danger">*</span>
                    </label>
                    <select
                      type="text"
                      className="form-select"
                      placeholder="Select Type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Category</option>
                      <option value="Department">Department</option>
                      <option value="Designations">Designations</option>
                      {/* <option value="Expense">Expense</option>
                      <option value="PaymentMethod">Payment Method</option>
                      <option value="TrainerCategory">Trainer Category</option>
                      <option value="MembershipType">Membership Type</option> */}
                    </select>
                    {errors.type && touched.type ? (
                      <p className="text-danger">{errors.type}</p>
                    ) : null}
                  </div>
                </div>
                {values.type === "Department" ? (
                  <>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Department Name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={(e) => {
                            handleChange(e);
                            updateVerifyName(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {updateNameError ? (
                          <div className="errorMsg text-danger">
                            {updateNameError}
                          </div>
                        ) : null}
                        {errors.name && touched.name ? (
                          <p className="text-danger">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                {values.type === "Designations" ? (
                  <>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Select Department{" "}
                          <span className="text-danger">*</span>
                        </label>
                        {myDepartment.length ? (
                          <select
                            className="form-select"
                            name="department_id"
                            value={values.department_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {myDepartment.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            autoComplete="off"
                            name="department_id"
                            value={values.department_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Department</option>
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Designation Name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={(e) => {
                            handleChange(e);
                            updateVerifyName(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {updateNameError ? (
                          <div className="errorMsg text-danger">
                            {updateNameError}
                          </div>
                        ) : null}
                        {errors.name && touched.name ? (
                          <p className="text-danger">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="col-12 col-md-6 col-lg-4 d-none">
                  <div className="form-group custom-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description ? (
                      <p className="text-danger">{errors.description}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 col-md-4 col-lg-2 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mt-4"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
};

export default UpdateResource;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { NoteState } from "../../../context/notes/NoteState";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../../App.css";
import "bootstrap/dist/js/bootstrap.min.js";
import logo from "../../../icons/logo.png"

export default function CafeNav() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(NoteState);

  const LogOut = (e) => {
    e.preventDefault();
    ctxDispatch({
      type: "LOGOUT",
    });
    navigate("/");
  };

  return (
    <div className="wrapper">
      <header className="top-header customer-header">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-fluid">
            <Link className="navbar-brand custom-logo" to="/cafe-dashboard">
              <img src={logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent5"
              aria-controls="navbarSupportedContent5"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="bi bi-list"></i>
            </button>
            <div
              className="collapse navbar-collapse top-head-nav"
              id="navbarSupportedContent5"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown ">
                  <Link
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-person-fill"></i>
                    User
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(e) => {
                          LogOut(e);
                        }}
                      >
                        LogOut
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { clientReport } from "../schemas";
import Swal from "sweetalert2";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";

import Pagination from "react-js-pagination";
import "jspdf-autotable";
import jsPDF from "jspdf";
import MenuButton from "../MenuButton/MenuButton";

export default function SPTAttendenceReport() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [DAteSeted, setDAteSeted] = useState(false);

  const [radioOption, setRadioOption] = useState("Detail");
  const [show, setShow] = useState(false);
  const [option, setOption] = useState("");
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [trainerName, setTrainerName] = useState([]);

  const [exportLink, setExportLink] = useState("");
  const [limit, setLimit] = useState("25");
  const [myPackage, setMyPackage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };

  const getClassesList = async (br_Id, tr_id) => {
    try {
      const responce = await UserServices.registrationPkgs(br_Id, "4", tr_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          order_id: value.id,
          package_name: value.name,
        });
        return null;
      });
      setMyPackage([
        { package_name: "Select Package ", order_id: "" },
        ...results,
      ]);
    } catch (err) {
      console.log(err);

      setMyPackage([{ package_name: "No Package Found", order_id: "" }]);
    }
  };

  useEffect(() => {
    gymTrainer(branch_id);

    if (role === "9") {
      getClassesList(values.branch_id, values.trainer_id);
    }
  }, []);

  const setDates = async (param) => {
    setDAteSeted(true);

    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);

        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };
  const generateReport = async (page, limit, values) => {
    if (radioOption == "Summary") {
      if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
        setLoading(true);
        setCurrentPage(page);

        let newLimit = limit;
        setLimit(newLimit);

        try {
          setMyData([]);

          setExportLink(
            process.env.REACT_APP_API_Link +
              "/fitness/session-attendance/export-session-attendance?branch_id=" +
              branch_id +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&trainer_id=" +
              values.trainer_id +
              "&package_id=" +
              values.package_id +
              "&client_status=" +
              values.client_status +
              "&staff_status=" +
              values.trainer_status +
              "&type=SPT"
          );
          const responce = await UserServices.getSummaryReport(
            branch_id,
            values,
            page,
            limit,
            "SPT"
          );
          let myDataarr;
          console.log(responce);
          if (responce.status === 200) {
            myDataarr = responce.data;
            const res = myDataarr;
            const results = [];

            res.map((values) => {
              results.push({
                branch_name: values.Contacted,
                trainer_nam: values.trainer_name,
                client_status_counts: values.client_status_counts,
                staff_status_counts: values.staff_status_counts,
              });
              return null;
            });
            setMyData([...results]);
            // setLimit(responce.data.data.per_page);
            // setTotalResults(responce.data.totalRecord);
            // setTotalPages(responce.data.totalPages);
            // setCurrentPage(responce.data.data.current_page);
            setShow(true);
            setLoading(false);

            setOption("Summary");
          }
        } catch (err) {
          setOption("Summary");
          console.log(err);
          setShow(true);
          setExportLink("");
          setMyData([]);
          setTotalResults("0");
          setTotalPages("1");
          setLoading(false);
          if (err?.response?.status === 401) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Request time Out!",
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "LogIn Again!",
            });
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "End date should be greater then start date!",
        });
      }
    } else {
      if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
        setLoading(true);
        setCurrentPage(page);
        let pageNo = page;

        let newLimit = limit;
        setLimit(newLimit);

        try {
          setMyData([]);

          setExportLink(
            process.env.REACT_APP_API_Link +
              "/fitness/session-attendance/export-session-attendance?branch_id=" +
              branch_id +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&trainer_id=" +
              values.trainer_id +
              "&package_id=" +
              values.package_id +
              "&client_status=" +
              values.client_status +
              "&staff_status=" +
              values.trainer_status +
              "&type=SPT"
          );

          const responce = await UserServices.getPTSessionAttendanceReport(
            branch_id,
            values,
            page,
            limit,
            "SPT"
          );
          let myDataarr;

          if (responce.status === 200) {
            myDataarr = responce.data?.data?.data;
            const res = myDataarr;
            const results = [];

            res.map((values) => {
              results.push({
                branch_name: values.branch?.name,
                trainer_nam: values.trainer?.trainer_name,
                client_name: values.order?.client_name,
                package_name: values.order?.name,
                day: values.day,
                staff_status: values.staff_status,
                client_status: values.client_status,
                date: values.date,
                start_time: values.time_slot?.start_time,
                end_time: values.time_slot?.end_time,
                validate_status: values.validate_status,
                status: values.status,
              });
              return null;
            });
            setMyData([...results]);
            setLimit(responce.data.data.per_page);
            setTotalResults(responce.data.totalRecord);
            setTotalPages(responce.data.totalPages);
            setCurrentPage(responce.data.data.current_page);
            setShow(true);
            setLoading(false);
            setOption("Detail");
          }
        } catch (err) {
          setOption("Detail");
          console.log(err);
          setShow(true);
          setExportLink("");
          setMyData([]);
          setTotalResults("0");
          setTotalPages("1");
          setLoading(false);
          if (err?.response?.status === 401) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Request time Out!",
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "LogIn Again!",
            });
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "End date should be greater then start date!",
        });
      }
    }
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
    trainer_id: role === "9" ? UId : "",
    package_id: "",
    trainer_status: "",
    client_status: "",
    type: "GX",
  };

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("SPT-Attendance-Report.pdf");
  };

  const Table = ({ data, type }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, limit);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <div className="col-md-12 mt-2">
          {type == "Detail" && (
            <div className="row">
              <div className="col-md-6">
                <h5 className="mb-0 text-uppercase">Filtered Result</h5>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-3 text-end">
                <a
                  href={exportLink}
                  rel="noreferrer"
                  // target="_blank"
                  className="btn btn-primary mx-2 file-download float-end mb-3"
                >
                  <i className="bi bi-download"></i> Excel
                </a>
                <button
                  className="btn btn-primary mx-2 file-download float-end mb-3"
                  onClick={() => {
                    generatePdf(document.getElementById("report-POS"));
                  }}
                >
                  <i className="bi bi-file-earmark-pdf"></i> PDF
                </button>
              </div>
            </div>
          )}
          <hr />
        </div>
        <div>
          <div className="card">
            <div className="card-body">
              {type == "Detail" && (
                <div className="row">
                  <div className="col-md-1 ms-auto">
                    <select
                      className="form-select mb-3"
                      onChange={(e) => {
                        generateReport("1", e.target.value, values);
                      }}
                      value={limit}
                    >
                      <option value="25">25</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="1500">1500</option>
                    </select>
                  </div>
                </div>
              )}
              <div className="table-responsive ">
                <table
                  className="table  table-borderless table-style-1  table-striped"
                  data-caption="SPT Attendance Report"
                  id="report-POS"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("trainer_nam")}
                        className={getClassNamesFor("trainer_nam")}
                      >
                        Trainer Name
                      </th>
                      {show && type == "Summary" && (
                        <>
                          <th
                            scope="col"
                            onClick={() => requestSort("Present")}
                            className={getClassNamesFor("Present")}
                          >
                            Present Clients
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("Absent")}
                            className={getClassNamesFor("Absent")}
                          >
                            Absent Clients
                          </th>
                        </>
                      )}

                      {show && type == "Detail" && (
                        <>
                          <th
                            scope="col"
                            onClick={() => requestSort("client_name")}
                            className={getClassNamesFor("client_name ")}
                          >
                            Client Name
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("branch_name")}
                            className={getClassNamesFor("branch_name")}
                          >
                            Branch Name
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("package_name")}
                            className={getClassNamesFor("package_name")}
                          >
                            Package Name
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("day")}
                            className={getClassNamesFor("day")}
                          >
                            Day
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("date")}
                            className={getClassNamesFor("date")}
                          >
                            Date
                          </th>

                          <th
                            scope="col"
                            onClick={() => requestSort("client_status")}
                            className={getClassNamesFor("client_status")}
                          >
                            Client Attendance
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("start_time")}
                            className={getClassNamesFor("start_time")}
                          >
                            Start Time
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("end_time")}
                            className={getClassNamesFor("end_time")}
                          >
                            End Time
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("validate_status")}
                            className={getClassNamesFor("validate_status")}
                          >
                            Validate Status
                          </th>
                          <th
                            scope="col"
                            onClick={() => requestSort("status")}
                            className={getClassNamesFor("status")}
                          >
                            Status
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <tr key={index}>
                          <td>
                            {currentPage === 1
                              ? index + 1
                              : (currentPage - 1) * limit + index + 1}
                          </td>
                          {show && type == "Summary" && (
                            <>
                              <td>{el.trainer_nam}</td>
                              <td>{el.client_status_counts?.Present ?? "0"}</td>
                              <td>{el.client_status_counts?.Absent ?? "0"}</td>
                            </>
                          )}
                          {show && type == "Detail" && (
                            <>
                              <td>{el.trainer_nam}</td>
                              <td>{el.client_name}</td>
                              <td>{el.branch_name}</td>
                              <td>{el.package_name}</td>
                              <td>{el.day}</td>
                              <td>{moment(el.date).format("DD-MM-YYYY")}</td>

                              <td>{el.client_status}</td>
                              <td>{el.start_time}</td>
                              <td>{el.end_time}</td>
                              <td>
                                {el.validate_status == "0" ? (
                                  <span style={{ color: "red" }}>Unverify</span>
                                ) : (
                                  <span style={{ color: "green" }}>Verify</span>
                                )}
                              </td>
                              <td>
                                {el.status == "1" ? (
                                  <span style={{ color: "green" }}>Active</span>
                                ) : (
                                  <span style={{ color: "red" }}>Inactive</span>
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="14"
                          className="text-center text-capitalize"
                        >
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {totalResults > limit && totalPages > 1 ? (
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={parseInt(limit)}
                    totalItemsCount={totalResults}
                    onChange={(e) => {
                      generateReport(e, limit, values);
                    }}
                    pageRangeDisplayed={8}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First Page"
                    lastPageText="Last Page"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        generateReport("1", limit, values);
      },
    });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">SPT Attendance Report</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <h5>Quick Dates</h5>
                </div>
                <div className="row quick multi-button custom-multi-button mb-3">
                  <div className="col-md-4">
                    <label className="form-label d-block">Last</label>
                    <input
                      type="button"
                      check-val="lastYear"
                      onClick={() => {
                        setDates("lastYear");
                      }}
                      className="generate_report"
                      defaultValue="Year"
                    />
                    <input
                      type="button"
                      check-val="lastQuarter"
                      onClick={() => {
                        setDates("lastQuarter");
                      }}
                      className="generate_report"
                      defaultValue="Quarter"
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="lastMonth"
                      onClick={() => {
                        setDates("lastMonth");
                      }}
                      className="generate_report"
                    />
                    <input
                      type="button"
                      defaultValue="Yesterday"
                      check-val="lastDay"
                      className="generate_report"
                      onClick={() => {
                        setDates("lastDay");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">To-Date</label>
                    <input
                      type="button"
                      defaultValue="Year"
                      check-val="toDateYear"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateYear");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Quarter"
                      check-val="toDateQuarter"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateQuarter");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="toDateMonth"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateMonth");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Today"
                      check-val="toDateToday"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateToday");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">Previous</label>
                    <input
                      type="button"
                      defaultValue="365 Days"
                      check-val="previous365"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous365");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="90 Days"
                      check-val="previous90"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous90");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="30 Days"
                      check-val="previous30"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous30");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="9 Days"
                      check-val="previous9"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous9");
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    Available Trainers
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={values.trainer_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          getClassesList(values.branch_id, e.target.value);
                          values.package_id = "";
                        }}
                        disabled={role === "9" && UId ? true : false}
                        onBlur={handleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Slots</label>
                    {myPackage.length ? (
                      <select
                        className="form-select"
                        name="package_id"
                        value={values.package_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {myPackage.map((el, index) => {
                          return (
                            <option key={index} value={el.order_id}>
                              {el.package_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="off"
                        name="package_id"
                        value={values.package_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {/* <option value="">Select Package</option> */}
                        <option value="">Select All</option>
                      </select>
                    )}

                    {errors.package_id && touched.package_id ? (
                      <p className="text-danger">{errors.package_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Client Attendance</label>

                    <select
                      className="form-select"
                      autoComplete="off"
                      name="client_status"
                      value={values.client_status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Status</option>
                      <option value="Present">Present</option>
                      <option value="Absent">Absent</option>
                    </select>
                  </div>
                </div>

                <div className="clearfix" />
                <div className="col-12">
                  <h5>Options</h5>
                </div>
                <div className="col-md-6 col-lg-3 ">
                  <div className="custom-raio">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                            // setMyData([]);
                          }}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Detail"
                          defaultChecked={true}
                          onChange={() => {
                            setRadioOption("Detail");
                            // setMyData([]);
                          }}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="clearfix"></div>
                <div className="col-md-4 col-lg-5"></div>
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5"></div>
              </form>
            </div>
          </div>
          {/* TODO */}
          {/* {show ? <Table data={myData} rowsPerPage={limit} /> : null} */}
          {show && (
            <div className="row">
              {/* {option === "Detail" ? ( */}
              <>
                <div className="table-responsive mt-3">
                  <Table data={myData} rowsPerPage={limit} type={option} />
                </div>
              </>
              {/* ) : (
                <div className="table-responsive mt-3">
                  <table className="table table-striped table-borderless table-style-1">
                    <thead>
                      <tr>
                        <th scope="col" className="no_sort">
                          Sr#
                        </th>
                        <th scope="col" className="no_sort">
                        Trainer Name
                        </th>
                        <th scope="col" className="no_sort">
                        Delivered
                        </th>
                        <th scope="col" className="no_sort">
                        Cancel
                        </th>
                        <th scope="col" className="no_sort">
                        No Show
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{el.client_name}</td>
                        <td>{el.Delivered}</td>
                        <td>{el.Cancel}</td>
                        <td>{el.No_Show}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )} */}
            </div>
          )}
        </div>
      </main>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import {React,useRef,useEffect,useContext} from "react";
import { useFormik } from "formik";
import { CafeCategoryAdd } from "../schemas";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { ToastContainer, toast } from "react-toastify";
import jsPDF from "jspdf";
import $ from "jquery";
import CafeNav from "../Includes/Navbar/CafeNav";
import CafeSidebar from "../Includes/Sidebar/CafeSidebar";
import CafeMenu from "./CafeMenu";

export default function CafeCategory() {
    const { state } = useContext(NoteState);
    const { branch_id } = state;
    const [myBranches, setMyBranches] = useState([]);
    const [nameError, setNameError] = useState("");
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState("25");
    const [totalResults, setTotalResults] = useState("0");
    const [totalPages, setTotalPages] = useState("1");
    const [myData, setMyData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // update
    const [selectedBranches, setSelectedBranches] = useState("");
    const [selectedName, setSelectedName] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [namError, setNamError] = useState("");
    var clickEvent = $.Event("click");
    const closeButtonRef = useRef(null);

    const getCtgry = async (id) => {
      setLoading(true);
      try {
        const responce = await UserServices.getCategoriesInfo(id);
        if (responce.status === 200) {
          let data = responce.data.data;
          // eslint-disable-next-line array-callback-return
          data.map((values) => {
            setSelectedBranches(values.branch_id);
            setSelectedName(values.name);
            setCategoryId(values.id);
          });
          $("#membership").trigger(clickEvent);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const branches = async () => {
      try {
        const responce = await UserServices.branchName();
        const res = responce.data.data;
        const results = [];
  
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
      }
    };
    const nameVerify = async (e) => {
      var check_name = e.target.value;
      try {
        const response = await UserServices.checkCategoriesName(
          check_name,
          branch_id
        );
        if (response.status === 200) {
          setNameError("");
        }
      } catch (err) {
        if (err.response.status === 409) {
          setNameError("Name already exists");
        }
      }
    };
    const verifyName = async (e) => {
      var check_name = e.target.value;
      try {
        const response = await UserServices.updateCategoriesName(
          categoryId,
          check_name,
          branch_id
        );
        if (response.status === 200) {
          setNamError("");
        }
      } catch (err) {
        if (err.response.status === 409) {
          setNamError("Name already exists");
        }
      } 
    };
    const getPkg = async (page, limit) => {
      setLoading(true);
      setCurrentPage(page);
      let pageNo = page;
  
      let newLimit = limit;
      setLimit(newLimit);
      try {
        const responce = await UserServices.cafeCategory( 
          branch_id,
          newLimit,
          pageNo);
        let myDataarr;
  
        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              type: values.type,
              date: values.date,
            });
            return null;
          });
  
          setMyData([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    useEffect(() => {
      branches();
      getPkg(currentPage, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const addCatgry = async (values, action) => {
      if (nameError === "") {
        try {
          setLoading(true);
          const response = await UserServices.addCafeCatagory(values);
  
          if (response.status === 201) {
            setLoading(false);
            toast.success("Added Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            action.resetForm();
            getPkg();
          }
        } catch (err) {
          setLoading(false);
          if (err.response.status === 422) {
          } else if (err.response.status === 500) {
            // toast.error("Unproccessable content!", { position: "top-center" });
          } else if (err.response.status === 400) {
            // toast.error("Unproccessable content!", { position: "top-center" });
          }
        }
      }
    };
    const deleteRecord = async (id) => {
      try {
        const responce = await UserServices.deleteCategory(id);
        if (responce.status === 204) {
          Swal.fire({
            title: "Deleted !",
            text: "Record deleted successfully.",
            icon: "success",
            confirmButtonColor: "#ed1c24",
          });
          getPkg();
        }
      } catch (err) {
        console.log(err);
      }
    };
    const delCatgry = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this record ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ed1c24",
        cancelButtonColor: "#303030",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteRecord(id);
        }
      });
    };
    let searchTimer; 
    const searchCategory = async (e) => {
      clearTimeout(searchTimer); 
    
      
      searchTimer = setTimeout(async () => {
      var search_name = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.categorySearch(
          search_name,
          branch_id,
          "1",
          limit,
          1
        );
        let myDataarr;
  
        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];
  
          res.map((values) => {
            results.push({
              id: values.id,
              name: values.name,
              type: values.type,
              date: values.date,
            });
            return null;
          });
          setMyData([...results]);
          setLimit(response.data.data.per_page);
          setTotalResults(response.data.totalRecord);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.data.current_page);
  
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000); 
    };
    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");
  
      // Loop through all the tables in the section and add them to the PDF
  
      // Add a new page for each table (skip first table as it is already on the first page)
      const captionText = table.getAttribute("data-caption");
      // Add the table caption as regular text above the table
      doc.text(  captionText, 10, 10);
      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });
  
      doc.save("cafe_categories.pdf");
    };
  
    const Table = ({ data, rowsPerPage }) => {
      const { slice } = useTable(data, 1, rowsPerPage);
      const { items, requestSort, sortConfig } = useSortableData(slice);
  
      const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
      };
  
      return (
        <>
          <table
            className="table table-striped table-borderless table-style-1"
            id="report-POS"
            data-caption="View Categories"
          >
            <thead>
              <tr>
                <th scope="col" className="no_sort">
                  Sr#
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("name")}
                  className={getClassNamesFor("name")}
                >
                  Name
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("type")}
                  className={getClassNamesFor("type")}
                >
                  Type
                </th>
                <th scope="col" className="no_sort">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                items.map((el, index) => (
                  <tr key={index}>
                    <td>
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>
                    <td>{el.name}</td>
                    <td>{el.type}</td>
                    <td>
                    <button
                        onClick={() => {
                          getCtgry(el.id);
                        }}
                        className="updt-btn inactive-btn"
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Update
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={() => delCatgry(el.id)}
                        className="ms-2 inactive-btn"
                      >
                        <i className="bi bi-trash3 text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              onChange={(e) => {
                getPkg(e, limit);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </>
      );
    };
    const initialValues = {
      branch_id: branch_id,
      name: "",
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CafeCategoryAdd,
      onSubmit: (values, action) => {
        addCatgry(values, action);
      },
    });
    const CategoryUpdate = async (updateValues, action) => {
      if (
        namError === "" 
      ) {
        setLoading(true);
        try {
          const response = await UserServices.UpdateCafeCategory(updateValues, categoryId);
          if (response.status === 204) {
            setLoading(false);
            toast.success("Updated Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
            getPkg(currentPage, limit);
            // Trigger the close button click event
            closeButtonRef.current.click();
          }
        } catch (err) {
          if (err.response.status === 422) {
            toast.error("Some data is missing", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
    
            setLoading(false);
          } else {
            toast.error("Some thing went wronge !", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
          }
        }
      }
    };
    const updateinitialValues = {
      branch_id: selectedBranches,
      name: selectedName,
    };
      const {
        values: updateValues,
        errors: updateErrors,
        touched: updateTouched,
        handleBlur: updateHandleBlur,
        handleChange: updateHandleChange,
        handleSubmit: updateHandleSubmit,
      } = useFormik({
        enableReinitialize: true,
        initialValues: updateinitialValues,
        validationSchema: CafeCategoryAdd,
        onSubmit: (updateValues, action) => {
          CategoryUpdate(updateValues, action);
        },
      });
  return (
    <div className="wrapper">
     <CafeNav />
      <CafeSidebar />
      <ToastContainer />
    <main className="page-content customer-contnent">
      <div className="site-main-design">
        <CafeMenu  />
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">Add Categories</h5>
          </div>
          <div className="card-body">
            <p>
              <i
                className="bi bi-exclamation-lg text-danger"
                aria-hidden="true"
              />
              The Fields With <strong className="text-danger">*</strong>
              Must Required Or Fill.
            </p>
            <form
              className="row g-3"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={values.branch_id || ""}
                      onChange={(e) => {
                        handleChange(e);
                        // changeCategory(e);
                      }}
                      onBlur={handleBlur}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  {errors.branch_id && touched.branch_id ? (
                    <p className="text-danger">{errors.branch_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    name="name"
                    autoComplete="off"
                    value={values.name}
                    onChange={(e) => {
                      handleChange(e);
                      nameVerify(e);
                    }}
                    onBlur={handleBlur}
                  />

                  {nameError ? (
                    <div className="errorMsg text-danger">{nameError}</div>
                  ) : null}
                  {errors.name && touched.name ? (
                    <p className="text-danger">{errors.name}</p>
                  ) : null}
                </div>
              </div>
              <div className="clearfix" />
              <div className="col-12 ">
                <button
                  type="submit"
                  className="btn btn-primary px-5 rounded-0 mb-1"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-20">View Categories</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-2 col-lg-2 ms-2 mb-3">
                <div className="form-group custom-group">
                  <label className="form-label">Search By Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => {
                      handleChange(e);
                      searchCategory(e);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="col-md-1 col-lg-1 ms-auto mt-3">
                <div className="input-group">
                  <select
                    className="form-select"
                    value={limit}
                    onChange={(e) => getPkg("1", e.target.value)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
              </div>
              <div className=" col-md-1 me-5  text-end mt-3">
                <button
                  className="btn btn-primary mx-2 file-download"
                  onClick={() => {
                    generatePdf(document.getElementById("report-POS"));
                  }}
                >
                  <i className="bi bi-file-earmark-pdf"></i> PDF
                </button>
              </div>
            </div>
            <div className="accordion-body">
              <div className="table-responsive ">
                <Table data={myData} rowsPerPage={limit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
          {/* ......Hidden Button....... */}
          <button
      className="btn btn-primary w-100 d-none"
      type="button"
      id="membership"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
    ></button>
    {/* Modal */}
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Update Categories
            </h1>
            <button
              type="button"
              className="btn-close me-3  mb-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={closeButtonRef}
            >
              <i className="bi bi-x-lg text-dark fs-4"></i>
            </button>
          </div>
          <div className="modal-body">
          <form className="row g-3" onSubmit={updateHandleSubmit}>
          <div className="col-12 col-md-6">
                <div className="form-group custom-group">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={updateValues.branch_id || ""}
                      onChange={(e) => {
                        updateHandleChange(e);
                        // changeCategory(e);
                      }}
                      onBlur={updateHandleBlur}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  {updateErrors.branch_id && updateTouched.branch_id ? (
                    <p className="text-danger">{updateErrors.branch_id}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="name"
                      autoComplete="off"
                      value={updateValues.name}
                      onChange={(e) => {
                        updateHandleChange(e);
                        verifyName(e);
                      }}
                      onBlur={updateHandleBlur}
                    />

                    {namError ? (
                      <div className="errorMsg text-danger">{namError}</div>
                    ) : null}
                    {updateErrors.name && updateTouched.name ? (
                      <p className="text-danger">{updateErrors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
          </form>
          </div>
        </div>
      </div>
    </div>
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
  </div>
  )
}

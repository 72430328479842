import React, { useContext, useState, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import moment from "moment";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

export default function PersonalTrainer() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [myBranches, setMyBranches] = useState([]);
  const [trainerName, setTrainerName] = useState([]);

  const getRecord = async (lim, page, bId, TrId, status) => {
    try {
      setLoading(true);
      const response = await UserServices.getTrainingRoster(
        bId,
        lim,
        page,
        TrId,
        status
      );

      let myDataarr = response?.data?.data?.data;
      const results = [];

      myDataarr.map((value) => {
        return results.push({
          Trainer_name: value.Trainer_name,
          name: value.name,
          appointments: value.appointments,
          total_time_slot: value.total_time_slot,
        });
      });
      setMyData([...results]);
      setTotalResults(response?.data?.totalRecord);
      setTotalPages(response?.data?.totalPages);
      setCurrentPage(response?.data?.data?.current_page);
      setLoading(false);
    } catch (err) {
      console.log(err);

      setMyData([]);
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage("1");
      setLoading(false);
    }
  };

  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    branches();

    gymTrainer(branch_id);

    getRecord(
      limit,
      currentPage,
      addPkgValues.branch_id,
      addPkgValues.trainer_id,
      addPkgValues.status
    );
  }, []);

  const TableOne = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    let act = 0;
    return slice.length ? (
      <>
        {slice.map((el, index) => (
          <div className="card-body p-0 pt-2" key={index}>
            <span className="d-none">{(act = 0)}</span>
            <div className="bg-black d-flex" style={{ padding: "18px" }}>
              <div className="text-white fw-bold">
                Trainer Name:{" "}
                <span style={{ fontWeight: "100" }}> {el.Trainer_name}</span>
              </div>
            </div>
            <div className="table-responsive">
              <table
                className="table table-striped table-borderless table-style-1"
                data-caption="Active Membership Package"
              >
                {el.appointments.length ? (
                  <>
                    <thead>
                      <tr>
                        <th scope="col" className="no_sort text-white">
                          Client Name
                        </th>
                        <th scope="col" className="no_sort">
                          PT Package
                        </th>
                        <th scope="col" className="no_sort">
                          Paid Date
                        </th>
                        <th scope="col" className="no_sort">
                          Renewal Date
                        </th>
                        <th scope="col" className="no_sort">
                          Time
                        </th>
                        <th scope="col" className="no_sort">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {el.appointments.map((apt, index) => (
                        <tr key={index}>
                          <td>
                            <Link to={"/trainer-client/" + apt.client_id}>
                              {apt.client_name}
                            </Link>
                          </td>
                          <td>{apt.total_sessions * apt.quantity}</td>
                          <td>
                            {moment(apt.sale_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td>
                            {moment(apt.renewal_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>

                          <td>
                            {apt.time_slot?.start_time} -{" "}
                            {apt.time_slot?.end_time}
                          </td>
                          <td>
                            <span
                              className={`${
                                apt.status === "1"
                                  ? "text-success "
                                  : "text-danger"
                              } fw-bold`}
                            >
                              {apt.status === "1" ? "Active" : "Expired"}
                            </span>
                            <span className="d-none">
                              {apt.status === "1" ? (act = act + 1) : null}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        <h5 className="text-danger text-center">
                          No Appointments Found
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                )}
                <tfoot>
                  <tr>
                    <td>
                      <span className="fw-bold">Total Slots:</span>{" "}
                      {el.total_time_slot}
                    </td>
                    <td>
                      <span className="fw-bold">Filled Slots:</span> {act}
                    </td>
                    <td></td>
                    <td></td>

                    <td>
                      <span className="fw-bold">Empty/Expire</span>:{" "}
                      {el.total_time_slot - act}
                    </td>
                    <td>
                      <span className="fw-bold">Active</span>: {act}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        ))}
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getRecord(
                limit,
                e,
                addPkgValues.branch_id,
                addPkgValues.trainer_id,
                addPkgValues.status
              );
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    ) : (
      ""
    );
  };

  const addPkginitialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    status: "",
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: "",
    onSubmit: (addPkgValues) => {
      getRecord(
        limit,
        "1",
        addPkgValues.branch_id,
        addPkgValues.trainer_id,
        addPkgValues.status
      );
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">PERSONAL TRAINING ROASTER</h5>
            </div>

            <div className="card-body">
              <form
                className="row g-3"
                onSubmit={(e) => {
                  addPkgHandleSubmit(e);
                }}
              >
                <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    <label className="form-label">Branch Name</label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={addPkgValues.branch_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                          gymTrainer(e.target.value);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    Available Trainers
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="trainer_id"
                        value={addPkgValues.trainer_id || ""}
                        disabled={role === "9" && UId ? true : false}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-lg-1">
                  <button
                    type="submit"
                    class="btn btn-primary  rounded-0 w-100 mt-md-4"
                  >
                    Search
                  </button>
                </div>
                <div className="col-12 col-lg-1 ms-auto">
                  <div className="form-group custom-group">
                    <label className="form-label">Show</label>

                    <select
                      className="form-select "
                      value={limit}
                      onChange={(e) =>
                        getRecord(
                          e.target.value,
                          currentPage,
                          addPkgValues.branch_id,
                          addPkgValues.trainer_id,
                          addPkgValues.status
                        )
                      }
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-12 col-lg-3">
                  <div className="form-group custom-group">
                    Paid Date
                    <select
                      className="form-select"
                      name="status"
                      value={addPkgValues.status || ""}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    >
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">Empty/Expired</option>
                    </select>
                  </div>
                </div> */}
              </form>
              <TableOne data={myData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React, { useContext, useState, useEffect } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { NoteState } from "../../context/notes/NoteState";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import moment from "moment";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export default function ClientInduction() {
  const { state } = useContext(NoteState);
  const { branch_id, UId, role } = state;
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);

  const { id } = useParams();

  const getRecord = async (bId, TrId, status) => {
    try {
      setLoading(true);
      const response = await UserServices.getClientInductionPost(
        bId,
        TrId,
        status,
        id
      );
      console.log(response);
      let myDataarr = response?.data?.data;
      // console.log(`data=${JSON.stringify(myDataarr)}`);
      const results = [];

      // myDataarr.map((value) => {
      //   return results.push({
      //     id: value.id,
      //   });
      // });
      // setMyData([...results]);
      setMyData(myDataarr);

      setLoading(false);
    } catch (err) {
      console.log(err);

      setMyData([]);

      setLoading(false);
    }
  };

  useEffect(() => {
    getRecord(
      addPkgValues.branch_id,
      addPkgValues.trainer_id,
      addPkgValues.status
    );
  }, []);

  const TableOne = ({ data }) => {
    return data.length ? (
      <>
        <div className="card-body p-0 pt-2">
          {/* Removed unused span tags */}

          <div
            className="bg-black row"
            style={{ padding: "20px 0px", margin: "0" }}
          >
            <div className="text-white col-2 col-md-6 fw-bold col-sm-12">
              Client Name:{" "}
              <span style={{ fontWeight: "100" }}>{data[0]?.client_name}</span>
            </div>
          </div>

          <div className="table-container-dairy">
            <div className="headings">
              <div className="headings-dairy time-dairy">Date</div>
              <div className="headings-dairy client-dairy">Branch Name</div>
              <div className="headings-dairy">Height</div>
              <div className="headings-dairy bg-body-tertiary">Age</div>
              <div className="headings-dairy">Weight</div>
              <div className="headings-dairy bg-body-tertiary">Chest</div>

              <div className="headings-dairy bg-body-tertiary">Arm</div>
              <div className="headings-dairy">Waist</div>
              <div className="headings-dairy bg-body-tertiary">Gluts</div>
              <div className="headings-dairy">Thigh</div>
              <div className="headings-dairy bg-body-tertiary">Body Mass</div>
              <div className="headings-dairy">Fat</div>
              <div className="headings-dairy bg-body-tertiary">V-Fat</div>
              <div className="headings-dairy">RHR</div>
              <div className="headings-dairy bg-body-tertiary">MHR</div>
              <div className="headings-dairy">WHR</div>
              <div className="headings-dairy bg-body-tertiary">
                Max Push Ups
              </div>
              <div className="headings-dairy">Max Sit Ups</div>
              <div className="headings-dairy bg-body-tertiary">1RM squats</div>
              <div className="headings-dairy pe-0">1RM Bench Press</div>
              <div className="headings-dairy pe-0">Sit And Reach</div>

              <div className="headings-dairy bg-body-tertiary">Action</div>
            </div>

            <div className="data-dairy">
              {data.map((el, index) => (
                <div key={index} className="data-row">
                  <div className="data-cell time-dairy">
                    {moment(el.date).format("DD-MM-YYYY")}
                  </div>
                  <div className="data-cell client-dairy">
                    {el.branch_name ? el.branch_name : "-"}
                  </div>
                  <div className="data-cell">{el.height ? el.height : "-"}</div>
                  <div className="data-cell bg-body-tertiary">
                    {el.age ? el.age : "-"}
                  </div>
                  <div className="data-cell ">
                    {el.weight ? el.weight : "-"}
                  </div>
                  <div className="data-cell bg-body-tertiary">
                    {el.chest ? el.chest : "-"}
                  </div>

                  <div className="data-cell bg-body-tertiary">
                    {el.arm ? el.arm : "-"}
                  </div>

                  <div className="data-cell"> {el.waist ? el.waist : "-"}</div>
                  <div className="data-cell bg-body-tertiary">
                    {el.gluts ? el.gluts : "-"}
                  </div>
                  <div className="data-cell"> {el.thigh ? el.thigh : "-"}</div>
                  <div className="data-cell bg-body-tertiary">
                    {" "}
                    {el.body_mass_index ? el.body_mass_index : "-"}
                  </div>
                  <div className="data-cell"> {el.fat ? el.fat : "-"}</div>
                  <div className="data-cell bg-body-tertiary">
                    {el.v_fat ? el.v_fat : "-"}
                  </div>
                  <div className="data-cell ">{el.rhr ? el.rhr : "-"}</div>
                  <div className="data-cell bg-body-tertiary">
                    {" "}
                    {el.mhr ? el.mhr : "-"}
                  </div>
                  <div className="data-cell ">{el.whr ? el.whr : "-"}</div>
                  <div className="data-cell bg-body-tertiary">
                    {el.max_push_ups ? el.max_push_ups : "-"}
                  </div>
                  <div className="data-cell ">
                    {el.max_push_ups_one_min ? el.max_push_ups_one_min : "-"}
                  </div>
                  <div className="data-cell bg-body-tertiary">
                    {el.one_rm_squats ? el.one_rm_squats : "-"}
                  </div>
                  <div className="data-cell">
                    {el.one_rm_bench_press ? el.one_rm_bench_press : "-"}
                  </div>
                  <div className="data-cell">
                    {el.fit_and_reach ? el.fit_and_reach : "-"}
                  </div>
                  <div className="data-cell bg-body-tertiary">
                    <Link to={"/viewAssessmentInfo/" + el.id}>Detail</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    ) : (
      <h6 className="text-danger">No Assessment Found</h6>
    );
  };

  const addPkginitialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    status: "1",
  };
  const { values: addPkgValues } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: "",
    onSubmit: (addPkgValues) => {
      getRecord(
        addPkgValues.branch_id,
        addPkgValues.trainer_id,
        addPkgValues.status
      );
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Client Assessments
                <Link
                  class="btn btn-primary d-inline-block float-end btn-sm"
                  to={"/preAssessment/" + id}
                >
                  <i class="bi bi-plus"></i> Add Assessment
                </Link>
              </h5>
            </div>

            <div className="card-body">
              {/* FIXME */}
              <TableOne data={myData} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React, { useLayoutEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import Navbar from "../Includes/Navbar/Navbar";
import Sidebar from "../Includes/Sidebar/Sidebar";
// import { Link } from "react-router-dom";
import UserServices from "../../UserServices/UserServices";
import { useParams } from "react-router";
import "./Card.css";
import moment from "moment";
import back from "../../icons/card-back.jpg";

export default function VostroCard() {
  const [date, setDate] = useState("");
  const [memberId, setMemberId] = useState("");
  const [firstName, setFirstName] = useState("");

  const [membershipType, setMembershipType] = useState("");
  const [image, setImage] = useState("");
  const [branch, setbranch] = useState("");
  const [loading, setLoading] = useState(false);
  const [front, setFront] = useState(true);

  const { branch_id, id } = useParams();

  const cardData = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getPrindCards(branch_id, id);
      const res = responce.data.data;

      setDate(moment(res.membership_date).format("DD-MM-YYYY"));
      const cleanedMemberId = res.uid.replace(/-/g, ""); // "abc123def456ghi789"
      setMemberId(cleanedMemberId);
      const uppercaseMemberId = cleanedMemberId.toUpperCase(); // "ABC123DEF456GHI789"
      setMemberId(uppercaseMemberId);
      setFirstName(res.name);
      setImage(res.avatar);
      setMembershipType(res.membership);
      setbranch(res.branch_name);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    cardData();
  }, []);

  const printSlip = () => {
    // Open a new window for printing
    const printWindow = window.open();
    
    // Get the print styles from the current document
    const printStyles = `
      @media print {
        body {
       margin: 0 ;
        padding: 0 ;
        font-family: "Open Sans", sans-serif;
        }
  
        // #card {
        //   width: 490px;
        //   height: 306px;
        //   margin: 0 auto;
        //   padding: 0;
        //   box-shadow: none;
        //   background: none;
        // }
  
        @page {
          size: 490px 306px;
        margin: 0 ;
        }
      }
    `;
  
    // Write HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <style>${printStyles}</style>
        </head>
        <body onload="window.print(); window.close();">
          <div id="card" style="width: 490px; height: 306px; background-color: #FFFFF; margin: 0 auto; display: block; box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12); WebkitPrintColorAdjust: exact; WebkitFilter: opacity(1);">
            <!-- Front Side Content -->
            ${front ? `
              <div style="position: absolute; left: 0; top: 0; bottom: 0; background-color: #e8002b; width: 45%; height: 100%;">
                <div style="position: relative; width: 100%; height: 100%;">
                  ${image === "" ? `
                    <div style="position: absolute; width: 70%; left: 15px; right: 0; top: 75px; bottom: 0; background-color: #929292; height: 50%; border: 1px solid #cacaca; background-image: url('/assets/images/placeholder2.png'); background-size: cover; background-position: top; border-radius: 50%;"></div>
                  ` : `
                    <div style="position: absolute; width: 70%; left: 15px; right: 0; top: 75px; bottom: 0; background-color: #929292; height: 50%; border: 1px solid #cacaca; background-image: url('${image}'); background-size: cover; background-position: top;"></div>
                  `}
                </div>
              </div>
              <div style="position: absolute; right: 0; top: 0; bottom: 0; width: 55%; height: 100%; padding: 0px 20px; background-color: white; display: flex; flex-direction: column; justify-content: space-evenly;">
                <img src="/assets/images/logo-grey.png" alt="logo" style="width: 220px; margin: 0 auto; display: block;" />
                <h3 style="margin: 0 auto; padding: 4px 0px; color: #e8002b; font-weight: bold; border: 1px solid #929292; border-width: 1px 0px; text-align: center; margin-bottom: 0px; font-size: 20px; width: 95%; text-transform: capitalize;">
                  ${firstName}
                </h3>
                <div style="width: 90%; margin: 0 auto;">
                <div style="display: flex">
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 60%; display: inline-block; margin: 5px auto; vertical-align: top;">Member ID:</div>
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 40%; margin: 5px auto; display: inline-block; text-align: left;">${memberId}</div>
                  </div>
                   <div style="display: flex">
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 60%; display: inline-block; margin: 5px auto; vertical-align: top;">Membership Type:</div>
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 40%; margin: 5px auto; display: inline-block; text-align: left;">${membershipType}</div>
                  </div>
                   <div style="display: flex">
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 60%; display: inline-block; margin: 5px auto; margin-bottom: 0px; vertical-align: top;">Membership Date:</div>
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 40%; margin: 5px auto; display: inline-block; text-align: left; margin-bottom: 0px;">${date}</div>
                  </div>
                   <div style="display: flex">
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 60%; display: inline-block; margin: 5px auto; margin-bottom: 0px; vertical-align: top;">Outlet:</div>
                  <div style="color: #535353; font-weight: 600; font-size: 12px; width: 40%; margin: 5px auto; display: inline-block; text-align: left; margin-bottom: 0px;">${branch}</div>
                  </div>
                </div>
              </div>
            ` : `
              <img src="${back}" alt="" style="width: 490px; height: 306px;" />
            `}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
  };
  
  

  return (
    <>
      <style>
      {`
        @media print {
          body {
            margin: 0;
            padding: 0;
          }
          
          #card {
            width: 490px;
            height: 306px;
            margin: 0;
            padding: 0;
            box-shadow: none;
            background: none;
          }
          
          @page {
          size: 490px 306px;
              margin: 0;
          }
        }
      `}
    </style>
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Print Card
                {/* <Link
                  to="/viewtrainer"
                  className="btn btn-danger d-inline-block float-end btn-sm"
                >
                  <i className="bi bi-x" />
                  Done
                </Link> */}
                <button
                  className="btn btn-danger d-inline-block float-end btn-sm"
                  // onClick={() => window.print()}
                  onClick={() => {
                    setFront(true);
                    setTimeout(() => {
                      printSlip();
                    }, 300);
                  }}
                >
                  Print Front Side
                </button>
                <button
                  className="btn btn-danger d-inline-block float-end btn-sm me-2"
                  // onClick={() => window.print()}
                  onClick={() => {
                    setFront(false);
                    
                    if (!front) {
                      printSlip();
                    }
                  }}
                >
                  Print Back Side
                </button>
              </h5>
            </div>
            <div className="card-body">
              <div className="container">
                {/* <div
                  id="card"
                  style={{
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                    width: "490px",
                    height: "309px",
                    margin: "0 auto",
                    // marginTop: "110px",
                  }}
                > */}
                <div
                  id="card"
                  style={{
                    width: "490px",
                    height: "306px",
                    backgroundColor: "#FFFFF",
                    margin: "0 auto",
                    display: "block",
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.12)",
                    WebkitPrintColorAdjust: "exact",
                    WebkitFilter: "opacity(1)",
                  }}
                >
                  {front ? (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "0",
                          top: "0",
                          bottom: "0",
                          backgroundColor: "#e8002b",
                          width: "40%",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {image === "" ? (
                            <div
                              style={{
                                position: "absolute",
                                width: "70%",
                                left: "0",
                                right: "0",
                                top: "0",
                                bottom: "0",
                                margin: "auto",
                                backgroundColor: "#929292",
                                height: "45%",
                                border: "1px solid #cacaca",
                                backgroundImage:
                                  'url("/assets/images/placeholder2.png")',
                                backgroundSize: "cover",
                                backgroundPosition: "top",
                                borderRadius: "50%",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                position: "absolute",
                                width: "70%",
                                left: "0",
                                right: "0",
                                top: "0",
                                bottom: "0",
                                margin: "auto",
                                backgroundColor: "#929292",
                                height: "45%",
                                border: "1px solid #cacaca",
                                backgroundImage: "url('" + image + "')",
                                backgroundSize: "cover",
                                backgroundPosition: "top",
                              }}
                            ></div>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          bottom: "0",
                          width: "60%",
                          height: "100%",
                          padding: "0px 20px",
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <img
                          src="/assets/images/logo-grey.png"
                          alt="logo"
                          style={{
                            width: "220px",
                            margin: "0 auto",
                            display: "block",
                          }}
                        />
                        <h3
                          style={{
                            margin: "0 auto",
                            padding: "4px 0px",
                            color: "#e8002b",
                            fontWeight: "bold",
                            border: "1px solid #929292",
                            borderWidth: "1px 0px",
                            textAlign: "center",
                            // marginTop: "35px",
                            // marginBottom: "15px",
                            marginBottom: "0px",
                            fontSize: "20px",
                            width: "95%",
                            textTransform: "capitalize",
                          }}
                        >
                          {firstName}
                        </h3>
                        <div
                          style={{
                            width: "90%",
                            margin: "0 auto",
                          }}
                        >
                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "60%",
                              display: "inline-block",
                              margin: "5px auto",
                              verticalAlign: "top",
                            }}
                          >
                            Member ID:
                          </div>
                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "40%",
                              margin: "5px auto",
                              display: "inline-block",
                              textAlign: "left",
                            }}
                          >
                            {memberId}
                          </div>
                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "60%",
                              display: "inline-block",
                              margin: "5px auto",
                              verticalAlign: "top",
                            }}
                          >
                            Membership Type:
                          </div>
                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "40%",
                              margin: "5px auto",
                              display: "inline-block",
                              textAlign: "left",
                            }}
                          >
                            {membershipType}
                          </div>

                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "60%",
                              display: "inline-block",
                              margin: "5px auto",
                              verticalAlign: "top",
                            }}
                          >
                            Membership Date:
                          </div>
                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "40%",
                              margin: "5px auto",
                              display: "inline-block",
                              textAlign: "left",
                            }}
                          >
                            {date}
                          </div>
                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "60%",
                              display: "inline-block",
                              margin: "5px auto",
                              marginBottom: "0px",
                              verticalAlign: "top",
                            }}
                          >
                            Outlet:
                          </div>
                          <div
                            style={{
                              color: "#535353",
                              fontWeight: "600",
                              fontSize: "12px",
                              width: "40%",
                              margin: "5px auto",
                              display: "inline-block",
                              textAlign: "left",
                              marginBottom: "0px",
                            }}
                          >
                            {branch}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <img
                      src={back}
                      alt=""
                      style={{
                        width: "490px",
                        height: "306px",
                      }}
                      onLoad={printSlip}
                    />
                  )}
                </div>
                {/* </div> */}
              </div>
              {/* <button  className="btn btn-danger d-inline-block btn-sm" onClick={() => window.print()}>Print Card</button> */}
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
    </>
  );
}

import React, { useContext, useState, useEffect, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { addGfxClass, assignToClass } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "react-js-pagination";

import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import jsPDF from "jspdf";
import $ from "jquery";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import moment from "moment";

const AddGfxClasses = () => {
  const { state } = useContext(NoteState);
  const [loading, setLoading] = useState(false);
  const [trainerName, setTrainerName] = useState([]);
  const [myBranches, setMyBranches] = useState([]);
  const [nameError, setNameError] = useState("");
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { branch_id } = state;

  const date = new Date();

  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");

  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  // update
  const [selectedBranches, setSelectedBranches] = useState("");

  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedEndTime, setSelectedEndTime] = useState(0);

  const [session, setsession] = useState("");

  const [bookSpace, setBookSpace] = useState("");

  const [packageId, setPackageId] = useState("");
  const [daysError, setDaysError] = useState(false);

  var CurrencyFormat = require("react-currency-format");

  var clickEvent = $.Event("click");

  const closeButtonRef = useRef(null);
  const getPkgs = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getGfxPackageInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data.data;
        data.map((values) => {
          setSelectedBranches(values.branch_id);
          setSelectedTrainer(values.user_id);
          setSelectedName(values.package_name);
          setSelectedTime(values.start_time);
          setSelectedEndTime(values.end_time);
          setsession(values.session_count);
          setBookSpace(values.booking_capacity);
          setPackageId(values.id);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getGfxPackages(
        branch_id,

        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branches_name: values.branches_name,
            branch_id: values.branch_id,
            package_name: values.package_name,
            status: values.status,

            category: values.category,
            user_first_name: values.user_first_name,
            user_last_name: values.user_last_name,
            user_id: values.user_id,
            session_count: values.session_count,
            booking_capacity: values.booking_capacity,
            time_slot: values.time_slot,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const gymTrainer = async (bId) => {
    setTimeSlotTrainer([]);
    setDays([]);
    try {
      const responce = await UserServices.TrainerName(bId, 1);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      setTrainerName([{ first_name: "No Trainer Found", id: "" }]);

      console.log(err);
    }
  };
  const nameVerify = async (e) => {
    var check_name = e.target.value;
    // var branch_Id = category;
    try {
      const response = await UserServices.checkAddPackageName(
        check_name,
        branch_id,
        "15"
      );
      if (response.status === 200) {
        setNameError("");
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setNameError("Name already exists");
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);

    branches();
    gymTrainer(values.branch_id);
  }, []);
  const makeInActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.inactivePackage(package_Id);
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactivePackage = (package_Id) => {
    makeInActive(package_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deletePackages(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  const makeActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.activePackage(package_Id);
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activePackage = (package_Id) => {
    makeActive(package_Id);
  };

  const convertTime = (input) => {
    const [hours, minutes] = input.split(":").map(Number);
    let formattedHours = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";

    formattedHours =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };
  const addGxClass = async (values, action) => {
    if (nameError === "") {
      var selectedDays = $(
        ".add-time-slot-days .form-check input:checkbox:checked"
      )
        .map(function () {
          return this.value;
        })
        .get()
        .join(",");

      if (!selectedDays.length) {
        setDaysError(true);
      } else {
        setDaysError(false);
        try {
          setLoading(true);

          const response = await UserServices.addGfxClass(values, selectedDays);

          if (response.status === 201) {
            setLoading(false);

            toast.success("Added Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            action.resetForm();
            $(".add-time-slot-days .form-check input:checkbox:checked").prop(
              "checked",
              false
            );
            setDays([]);
            setTimeSlotTrainer([]);
            getPkg(currentPage, limit);
          }
        } catch (err) {
          setLoading(false);
          toast.error("Unproccessable content!", { position: "top-center" });
        }
      }
    }
  };

  const freeTimeSlot = async (id) => {
    try {
      setLoading(true);
      const responce = await UserServices.freeTime(id, "0");
      if (responce.status === 204) {
        toast.error("Done Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const [timeSlotTrainer, setTimeSlotTrainer] = useState([]);
  const [days, setDays] = useState([]);

  const getTimeForTrainer = async (trId) => {
    setLoading(true);
    setDays([]);
    try {
      const responce = await UserServices.trainerTimeList(trId);

      const res = responce.data.data;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          time: values.start_time + " To " + values.end_time,
        });
        return null;
      });
      setTimeSlotTrainer([{ time: "Select Time Slot", id: "" }, ...results]);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setTimeSlotTrainer([{ time: "No Time Slot Found", id: "" }]);

      setLoading(false);
    }
  };

  const checkDays = async (tim_id, start) => {
    try {
      $(".add-time-slot-days .form-check input:checkbox:checked").prop(
        "checked",
        false
      );
      const responce = await UserServices.dayCheckForSlot(tim_id, start);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value,
        });
        return null;
      });
      setDays([...results]);
    } catch (err) {
      console.log(err);
      setDays([]);
    }
  };

  const initialValues = {
    branch_id: branch_id,
    user_id: "",
    package_name: "",
    time_id: "",
    session_count: "",
    booking_space: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addGfxClass,
      onSubmit: (values, action) => {
        addGxClass(values, action);
      },
    });

  const [timeSlot, setTimeSlot] = useState([]);
  var clickEvent = $.Event("click");

  const getTimeSolts = async (trId, brId, orId) => {
    setLoading(true);
    try {
      const responce = await UserServices.trainerTimeList(trId);

      const res = responce.data.data;
      const results = [];

      res.map((values) => {
        results.push({
          id: values.id,
          time: values.start_time + " To " + values.end_time,
        });
        return null;
      });
      setTimeSlot([{ time: "Select Time Slot", id: "" }, ...results]);
      addFieldValueTime("branch_id", brId);
      addFieldValueTime("user_id", trId);
      addFieldValueTime("order_id", orId);
      addFieldValueTime("time_id", "");

      $("#membershipTimeTime").trigger(clickEvent);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setTimeSlot([{ time: "No Time Slot Found", id: "" }]);
      addFieldValueTime("branch_id", "");
      addFieldValueTime("user_id", "");
      addFieldValueTime("order_id", "");
      addFieldValueTime("time_id", "");
      toast.error("No Time Slot Found", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("GX-Slots.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="All GX Slots"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_id")}
                className={getClassNamesFor("branch_id")}
              >
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Slot Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer")}
                className={getClassNamesFor("trainer")}
              >
                Trainer
              </th>

              <th
                scope="col"
                onClick={() => requestSort("session_count")}
                className={getClassNamesFor("session_count")}
              >
                Session Count
              </th>

              <th
                scope="col"
                onClick={() => requestSort("booking_capacity")}
                className={getClassNamesFor("booking_capacity")}
              >
                Booking Space
              </th>

              <th scope="col" className="no_sort">
                Time Slot
              </th>
              <th scope="col" className="no_sort">
                Days
              </th>
              <th scope="col" className="status">
                Status
              </th>

              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branches_name}</td>
                  <td>{el.package_name}</td>
                  <td>
                    {el.user_first_name} {el.user_last_name}
                  </td>
                  <td>{el.session_count}</td>

                  <td>{el.booking_capacity}</td>

                  <td>
                    {el.time_slot.length ? (
                      el.time_slot[0].start_time +
                      " To " +
                      el.time_slot[0].end_time
                    ) : (
                      <>
                        <span className="text-danger ">Pending</span>
                      </>
                    )}
                  </td>
                  <td>
                    {el.time_slot.length ? (
                      el.time_slot[0].booking_days.length ? (
                        el.time_slot[0].booking_days.map((day, index) => (
                          <span key={index}>
                            {day.day}
                            {index !==
                              el.time_slot[0].booking_days.length - 1 && " ,"}
                          </span>
                        ))
                      ) : (
                        // (
                        //   (day) => day.day + " ,"
                        // )
                        <span className="text-danger ">Pending</span>
                      )
                    ) : (
                      <>
                        <span className="text-danger ">Pending</span>
                      </>
                    )}
                  </td>
                  <td>
                    {el.status === "1" ? (
                      <span
                        className="d-inline-flex"
                        style={{
                          width: `70px`,
                        }}
                      >
                        <span className="badge bg-success w-100">Active</span>
                      </span>
                    ) : (
                      <span
                        className="d-inline-flex"
                        style={{
                          width: `70px`,
                        }}
                      >
                        <span className="badge bg-danger  w-100">Inactive</span>
                      </span>
                    )}
                  </td>

                  <td>
                    <button
                      className="btn  d-inline-block btn-sm custom-action-button"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="assets/images/dots.png"
                        className="action-icon"
                        alt=""
                      />
                    </button>
                    <ul className="dropdown-menu custom-dropdown">
                      {/* <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            getPkgs(el.id);
                          }}
                        >
                          Update Slot
                        </button>
                      </li> */}
                      {el.time_slot.length ? (
                        <>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/assign-days/${el.time_slot[0].id}/${el.time_slot[0].time_slot_assignment_id}/${dateSelected}/GX`}
                            >
                              Assign Days
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => freeTimeSlot(el.time_slot[0].id)}
                            >
                              Free Time
                            </button>
                          </li>
                        </>
                      ) : (
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              getTimeSolts(el.user_id, el.branch_id, el.id);
                            }}
                          >
                            Assign Slot
                          </button>
                        </li>
                      )}

                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => inactivePackage(el.id)}
                        >
                          Inactive
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => delFine(el.id)}
                        >
                          Delete
                        </button>
                      </li>
                    </ul>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const UpdatePackage = async (addPkgValues) => {
    if (nameError === "") {
      setLoading(true);
      const sTime = await convertTime(addPkgValues.start_time);
      const eTime = await convertTime(addPkgValues.end_time);
      try {
        const response = await UserServices.UpdateGfxClass(
          addPkgValues,
          packageId,
          sTime,
          eTime
        );
        if (response.status === 204) {
          setLoading(false);
          toast.success("Updated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
          getPkg(currentPage, limit);
          // Trigger the close button click event
          closeButtonRef.current.click();
        }
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };
  const addPkginitialValues = {
    branch_id: selectedBranches,
    user_id: selectedTrainer,
    booking_space: bookSpace,
    package_name: selectedName,

    start_time: selectedTime,
    end_time: selectedEndTime,
    session_count: session,
  };
  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: addPkginitialValues,
    validationSchema: addGfxClass,
    onSubmit: (addPkgValues) => {
      UpdatePackage(addPkgValues);
    },
  });

  const closeButtonRefTime = useRef(null);

  const timeSlotsAssign = async (addPkgValues) => {
    try {
      setLoading(true);
      const response = await UserServices.assignSlotToPkg(addPkgValues);

      if (response.status === 201) {
        setLoading(false);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
        closeButtonRefTime.current.click();
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something wnet wrong..", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const addPkginitialValuesForTime = {
    branch_id: "",
    user_id: "",
    time_id: "",
    order_id: "",
  };
  const {
    values: addPkgValuesTime,
    errors: addPkgErrorsTime,
    touched: addPkgTouchedTime,
    setFieldValue: addFieldValueTime,
    handleBlur: addPkgHandleBlurTime,
    handleChange: addPkgHandleChangeTime,
    handleSubmit: addPkgHandleSubmitTime,
  } = useFormik({
    enableReinitialize: true,
    initialValues: addPkginitialValuesForTime,
    validationSchema: assignToClass,
    onSubmit: (addPkgValues) => {
      timeSlotsAssign(addPkgValues);
    },
  });

  return (
    <>
      <div className="wrapper">
        <ToastContainer />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Add GX Slot</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name <span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          value={values.branch_id || ""}
                          disabled={branch_id ? true : false}
                          onChange={(e) => {
                            handleChange(e);
                            gymTrainer(e.target.value);
                            values.user_id = "";
                            values.time_id = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      Trainer Allocate <span className="text-danger">*</span>
                      {trainerName ? (
                        <select
                          className="form-select"
                          name="user_id"
                          value={values.user_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            getTimeForTrainer(e.target.value);
                            values.time_id = "";
                          }}
                          onBlur={handleBlur}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.user_id && touched.user_id ? (
                        <p className="text-danger">{errors.user_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Slot Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enetr Slot Name"
                        name="package_name"
                        value={values.package_name}
                        onChange={(e) => {
                          handleChange(e);
                          nameVerify(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {nameError ? (
                        <div className="errorMsg text-danger">{nameError}</div>
                      ) : null}
                      {errors.package_name && touched.package_name ? (
                        <p className="text-danger">{errors.package_name}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      Available Time Slots<span className="text-danger">*</span>
                      {timeSlotTrainer ? (
                        <select
                          className="form-select"
                          name="time_id"
                          value={values.time_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            checkDays(e.target.value, dateSelected);
                          }}
                          onBlur={handleBlur}
                        >
                          {timeSlotTrainer.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.time}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.time_id && touched.time_id ? (
                        <p className="text-danger">{errors.time_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Booking Space <span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Booking Space"
                        name="booking_space"
                        value={values.booking_space}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.booking_space && touched.booking_space ? (
                        <p className="text-danger">{errors.booking_space}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Number of Session <span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Session Count"
                        name="session_count"
                        value={values.session_count}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.session_count && touched.session_count ? (
                        <p className="text-danger">{errors.session_count}</p>
                      ) : null}
                    </div>
                  </div>
                  {days.length ? (
                    <div className="col-12 col-lg-12 ">
                      <div className="form-group custom-group add-time-slot-days">
                        Days
                        <span className="text-danger">*</span>
                        {days ? (
                          <div className="row">
                            {days.map((el, index) => {
                              return (
                                <div className="col-lg-2 mt-2" key={index}>
                                  <div className="form-check">
                                    <label>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={el.id}
                                      />
                                      {el.id}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                        {/* {daysError ? (
                        <p className="text-danger">Days are required</p>
                      ) : (
                        ""
                      )} */}
                      </div>
                    </div>
                  ) : null}
                  {daysError ? (
                    <p className="text-danger">Days are required</p>
                  ) : (
                    ""
                  )}

                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="clearfix"></div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">All GX Slots</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-1 col-lg-1 my-2">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary  file-download ms-auto me-3 col-md-1 col-lg-1 my-2"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={addPkgHandleSubmit}>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={addPkgValues.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                      <p className="text-danger">{addPkgErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    Trainer Allocate <span className="text-danger">*</span>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={addPkgValues.user_id || ""}
                        onChange={(e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrors.user_id && addPkgTouched.user_id ? (
                      <p className="text-danger">{addPkgErrors.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Class Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Class Name"
                      name="package_name"
                      value={addPkgValues.package_name}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                        nameVerify(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    />
                    {nameError ? (
                      <div className="errorMsg text-danger">{nameError}</div>
                    ) : null}
                    {addPkgErrors.package_name && addPkgTouched.package_name ? (
                      <p className="text-danger">{addPkgErrors.package_name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Start Time:</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Start Time"
                      name="start_time"
                      value={addPkgValues.start_time}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.start_time && addPkgTouched.start_time ? (
                      <p className="text-danger">{addPkgErrors.start_time}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">End Time:</label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="End Time"
                      name="end_time"
                      value={addPkgValues.end_time}
                      onChange={addPkgHandleChange}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.end_time && addPkgTouched.end_time ? (
                      <p className="text-danger">{addPkgErrors.end_time}</p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Booking Space <span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder="Booking Space"
                      name="booking_space"
                      value={addPkgValues.booking_space}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.booking_space &&
                    addPkgTouched.booking_space ? (
                      <p className="text-danger">
                        {addPkgErrors.booking_space}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Number of Session<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder="Session Count"
                      name="session_count"
                      value={addPkgValues.session_count}
                      onChange={(e) => {
                        addPkgHandleChange(e);
                      }}
                      onBlur={addPkgHandleBlur}
                    />
                    {addPkgErrors.session_count &&
                    addPkgTouched.session_count ? (
                      <p className="text-danger">
                        {addPkgErrors.session_count}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membershipTimeTime"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropTime"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdropTime"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabelTime"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Assign Time Slot
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRefTime}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={addPkgHandleSubmitTime}>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={addPkgValuesTime.branch_id || ""}
                        disabled={true}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrorsTime.branch_id &&
                    addPkgTouchedTime.branch_id ? (
                      <p className="text-danger">
                        {addPkgErrorsTime.branch_id}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    Available Trainers<span className="text-danger">*</span>
                    {trainerName ? (
                      <select
                        className="form-select"
                        name="user_id"
                        value={addPkgValuesTime.user_id || ""}
                        disabled={true}
                      >
                        {trainerName.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.first_name} {el.last_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrorsTime.user_id && addPkgTouchedTime.user_id ? (
                      <p className="text-danger">{addPkgErrorsTime.user_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    Available Time Slots<span className="text-danger">*</span>
                    {timeSlot ? (
                      <select
                        className="form-select"
                        name="time_id"
                        value={addPkgValuesTime.time_id || ""}
                        onChange={(e) => {
                          addPkgHandleChangeTime(e);
                        }}
                        onBlur={addPkgHandleBlurTime}
                      >
                        {timeSlot.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.time}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addPkgErrorsTime.time_id && addPkgTouchedTime.time_id ? (
                      <p className="text-danger">{addPkgErrorsTime.time_id}</p>
                    ) : null}
                  </div>
                </div>

                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Assign
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default AddGfxClasses;

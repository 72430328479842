import React, { useState, useEffect, useContext, useRef } from "react";
import UserServices from "../../UserServices/UserServices";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import { NoteState } from "../../context/notes/NoteState";
import { useFormik } from "formik";
import { balanceSheet } from "../schemas";
import moment from "moment";
import MenuButton from "../MenuButton/MenuButton";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { Swal } from "sweetalert2/dist/sweetalert2";
import $ from "jquery";
import { addOfficeCashFlow } from "../schemas";

export default function GetBankLedger() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myData, setMyData] = useState([]);
  const [mySData, setMySData] = useState("");
  const [myTData, setMyTData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLink, setExportLink] = useState("");
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const sMonth = date.getMonth();
  const monthString = (parseInt(sMonth) + 1).toString();
  let strtDate = moment().startOf("month").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(strtDate);
  const [endDate, setEndDate] = useState(dateSelected);
  const [DAteSeted, setDAteSeted] = useState(false);
  // update
  const [bank, setBank] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedResource, setSelectedResource] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState("");
  const [packageId, setPackageId] = useState("");
  const [myBranches, setMyBranches] = useState([]);

  var clickEvent = $.Event("click");
  var CurrencyFormat = require("react-currency-format");

  const closeButtonRef = useRef(null);
  const getPkgs = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getBankCashFlowInfo(id);
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((values) => {
          setSelectedBranches(values.branch_id);
          setSelectedAmount(values.amount);
          setSelectedResource(values.resource);
          setSelectedDescription(values.description);
          setSelectedType(values.type);
          setSelectedDate(values.date);
          setPackageId(values.id);
          setSelectedBankAccount(values.bank_account_id);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getPkg = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        const responce = await UserServices.getBankCashFlow(branch_id, values);
        let myDataarr;
        let mySDataarr;
        let myTDataarr;

        if (responce.status === 200) {
          setExportLink(
            process.env.REACT_APP_API_Link +
              "/finance/bank-ledger/export?branch_id=" +
              branch_id +
              "&start_date=" +
              values.sdate +
              "&end_date=" +
              values.edate +
              "&status=1"
          );
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            return results.push({
              id: values.id,
              date: values.date,
              description: values.description,
              resource: values.resource,
              type: values.type,
              amount: values.amount,
            });
          });
          mySDataarr = responce.data.opening_balance.balance;
          myTDataarr = responce.data.opening_balance.date;

          setMyData([...results]);
          setMySData(mySDataarr);
          setMyTData(myTDataarr);

          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setExportLink();
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const bankDetails = async () => {
    try {
      const responce = await UserServices.bankList(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          bank_name: value.bank_name,
          account_no: value.account_no,
        });
        return null;
      });
      setBank([{ bank_name: "Select Bank", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setBank([{ bank_name: "No Bank Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(values);
    branches();
    bankDetails();
  }, []);
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteBankCashFlow(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(values);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };

  let searchTimer;

  const searchRecord = async (e) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(async () => {
      var search_name = e.target.value;
      setLoading(true);
      try {
        const response = await UserServices.bankCashSearch(
          search_name,
          branch_id
        );
        let myDataarr;

        if (response.status === 200) {
          myDataarr = response.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              date: values.date,
              description: values.description,
              resource: values.resource,
              type: values.type,
              amount: values.amount,
            });
            return null;
          });
          setMyData([...results]);
          setCurrentPage(response.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 1000);
  };
  const setDates = async (param) => {
    setDAteSeted(true);
    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);
        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };
  let totalBal = 0;
  const Table = ({ data, rowsPerPage }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    let previousBalance = mySData?mySData:"0";

    const generatePdf = (table) => {
      const doc = new jsPDF("p", "mm", "letter");

      // Loop through all the tables in the section and add them to the PDF

      // Add a new page for each table (skip first table as it is already on the first page)
      const captionText = table.getAttribute("data-caption");
      // Add the table caption as regular text above the table
      doc.text(captionText, 10, 10);
      const tableOptions = {
        headStyles: { fillColor: [232, 0, 43], textColor: 255 },
        styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
      };
      doc.autoTable({
        html: table,
        ...tableOptions,
      });

      doc.save("Bank_Cash_Ledger.pdf");
    };

    return (
      <>
        <div className="card">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-md-6">
                <h5 className="mb-0 font-20 mt-4">Bank Cash Ledger</h5>
              </div>
              <div className=" col-md-4 ms-auto text-end mt-4">
                <button
                  className="btn btn-primary mx-2 file-download"
                  onClick={() => {
                    generatePdf(document.getElementById("report-POS"));
                  }}
                >
                  <i className="bi bi-file-earmark-pdf"></i> PDF
                </button>
                <a
                  href={exportLink}
                  rel="noreferrer"
                  // target="_blank"
                  className="btn btn-primary mx-2 file-download"
                >
                  <i className="bi bi-download" /> Excel
                </a>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="accordion-body">
              <div className="table-responsive ">
                <table
                  className="table table-striped table-borderless table-style-1"
                  id="report-POS"
                  data-caption="Bank Cash Ledger"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("date")}
                        className={getClassNamesFor("date")}
                      >
                        Date
                      </th>
                      <th scope="col" className="no_sort">
                        Description
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("resource")}
                        className={getClassNamesFor("resource")}
                      >
                        Resource
                      </th>
                      <th scope="col" className="no_sort">
                        Debit
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("Credit")}
                        className={getClassNamesFor("Credit")}
                      >
                        Credit
                      </th>
                      <th scope="col" className="no_sort">
                        Balance
                      </th>

                      <th scope="col" className="no_sort">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{moment(myTData).format("DD-MM-YYYY")}</td>
                      <td>Opening balance</td>
                      <td></td>
                      <td></td>
                      <td><CurrencyFormat
                          value={mySData?mySData:"0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          thousandSpacing={"3"}
                        /></td>
                      <td>
                        <CurrencyFormat
                          value={mySData?mySData:"0"}
                          displayType={"text"}
                          thousandSeparator={true}
                          thousandSpacing={"3"}
                        />
                      </td>
                      <td>
                        {" "}
                        <button
                          disabled={true}
                          className="updt-btn inactive-btn"
                        >
                          <i className="bi bi-repeat text-success"></i>{" "}
                          <span className="text-success ms-1 updt-text">
                            Edit
                          </span>
                        </button>
                        <button
                          type="button"
                          disabled={true}
                          className="ms-2 inactive-btn"
                        >
                          <i className="bi bi-trash3 text-danger"></i>{" "}
                          <span className="text-danger inative-text ms-1">
                            Delete
                          </span>
                        </button>
                      </td>
                    </tr>
                    {items.length
                      ? items.map((el, index) => (
                          <tr key={index}>
                            <td>
                              {currentPage === 1
                                ? index + 2
                                : currentPage - 1 + index + 2}
                            </td>
                            <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                            <td>{el.description}</td>
                            <td>{el.resource}</td>
                            <td>
                              {el.type === "Debit" ? (
                                <CurrencyFormat
                                  value={el.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  // prefix={"Rs "}
                                  // suffix={"/-"}
                                  thousandSpacing={"3"}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              {el.type === "Credit" ? (
                                <CurrencyFormat
                                  value={el.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  // prefix={"Rs "}
                                  // suffix={"/-"}
                                  thousandSpacing={"3"}
                                />
                              ) : (
                                ""
                              )}
                            </td>

                            <td>
                              {el.type === "Credit" ? (
                                <>
                                  <span className="d-none">
                                    {
                                      (previousBalance =
                                        previousBalance + el.amount)
                                    }
                                  </span>
                                  <CurrencyFormat
                                    value={previousBalance}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    // prefix={"Rs "}
                                    // suffix={"/-"}
                                    thousandSpacing={"3"}
                                  />
                                </>
                              ) : el.type === "Debit" ? (
                                <>
                                  <span className="d-none">
                                    {
                                      (previousBalance =
                                        previousBalance - el.amount)
                                    }
                                  </span>
                                  <CurrencyFormat
                                    value={previousBalance}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    // prefix={"Rs "}
                                    // suffix={"/-"}
                                    thousandSpacing={"3"}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <button
                                onClick={() => {
                                  getPkgs(el.id);
                                }}
                                className="updt-btn inactive-btn"
                              >
                                <i className="bi bi-repeat text-success"></i>{" "}
                                <span className="text-success ms-1 updt-text">
                                  Edit
                                </span>
                              </button>
                              <button
                                type="button"
                                onClick={() => delFine(el.id)}
                                className="ms-2 inactive-btn"
                              >
                                <i className="bi bi-trash3 text-danger"></i>{" "}
                                <span className="text-danger inative-text ms-1">
                                  Delete
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))
                      : null
                        // <tr>
                        //   <td
                        //     colSpan="14"
                        //     className="text-center text-capitalize"
                        //   >
                        //     No record found
                        //   </td>
                        // </tr>
                    }

                    <span className="d-none">
                      {(totalBal = previousBalance)}
                    </span>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="ms-auto d-flex">
            <div className="mt-1">
              <h5>Total Balance:</h5>
            </div>
            <div className="ms-2  fs-5">
              <CurrencyFormat
                value={totalBal}
                displayType={"text"}
                thousandSeparator={true}
                thousandSpacing={"3"}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: balanceSheet,
      onSubmit: (values) => {
        getPkg(values);
      },
    });

  const UpdateCashFlow = async (updateValues, action) => {
    setLoading(true);
    try {
      const response = await UserServices.bankCashUpdate(
        updateValues,
        packageId
      );
      if (response.status === 204) {
        setLoading(false);
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        getPkg(values);
        // Trigger the close button click event
        closeButtonRef.current.click();
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const updateinitialValues = {
    branch_id: selectedBranches,
    amount: selectedAmount,
    resource: selectedResource,
    description: selectedDescription,
    type: selectedType,
    date: selectedDate,
    bank_account_id: selectedBankAccount,
  };

  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleBlur: updateHandleBlur,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: updateinitialValues,
    validationSchema: addOfficeCashFlow,
    onSubmit: (updateValues, action) => {
      UpdateCashFlow(updateValues, action);
    },
  });
  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <div className="row">
                  <div className="col-9">
                    <h5 className="mb-0 font-20">Dates</h5>
                  </div>
                  <div className="col-md-3 d-flex justify-content-end">
                    <Link
                      to="/add-bank-cash"
                      className="btn btn-primary d-inline-block btn-sm"
                    >
                      Add Bank Cash
                    </Link>
                    <Link
                      to={-1}
                      className="btn btn-primary d-inline-block float-end btn-sm ms-2"
                    >
                      Go Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">Start date</label>
                      <input
                        type="date"
                        className={`form-control ${DAteSeted ? "glow" : ""}`}
                        placeholder="Enter Start date"
                        name="sdate"
                        value={values.sdate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.sdate && touched.sdate ? (
                        <p className="text-danger">{errors.sdate}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">End date</label>
                      <input
                        type="date"
                        className={`form-control ${DAteSeted ? "glow" : ""}`}
                        placeholder="Enter End date"
                        name="edate"
                        value={values.edate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.edate && touched.edate ? (
                        <p className="text-danger">{errors.edate}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12">
                    <h5>Quick Dates</h5>
                  </div>
                  <div className="row quick multi-button custom-multi-button">
                    <div className="col-md-4">
                      <label className="form-label d-block">Last</label>
                      <input
                        type="button"
                        check-val="lastYear"
                        onClick={() => {
                          setDates("lastYear");
                        }}
                        className="generate_report"
                        defaultValue="Year"
                      />
                      <input
                        type="button"
                        check-val="lastQuarter"
                        onClick={() => {
                          setDates("lastQuarter");
                        }}
                        className="generate_report"
                        defaultValue="Quarter"
                      />
                      <input
                        type="button"
                        defaultValue="Month"
                        check-val="lastMonth"
                        onClick={() => {
                          setDates("lastMonth");
                        }}
                        className="generate_report"
                      />
                      <input
                        type="button"
                        defaultValue="Yesterday"
                        check-val="lastDay"
                        className="generate_report"
                        onClick={() => {
                          setDates("lastDay");
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label d-block">To-Date</label>
                      <input
                        type="button"
                        defaultValue="Year"
                        check-val="toDateYear"
                        className="generate_report"
                        onClick={() => {
                          setDates("toDateYear");
                        }}
                      />
                      <input
                        type="button"
                        defaultValue="Quarter"
                        check-val="toDateQuarter"
                        className="generate_report"
                        onClick={() => {
                          setDates("toDateQuarter");
                        }}
                      />
                      <input
                        type="button"
                        defaultValue="Month"
                        check-val="toDateMonth"
                        className="generate_report"
                        onClick={() => {
                          setDates("toDateMonth");
                        }}
                      />
                      <input
                        type="button"
                        defaultValue="Today"
                        check-val="toDateToday"
                        className="generate_report"
                        onClick={() => {
                          setDates("toDateToday");
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label d-block">Previous</label>
                      <input
                        type="button"
                        defaultValue="365 Days"
                        check-val="previous365"
                        className="generate_report"
                        onClick={() => {
                          setDates("previous365");
                        }}
                      />
                      <input
                        type="button"
                        defaultValue="90 Days"
                        check-val="previous90"
                        className="generate_report"
                        onClick={() => {
                          setDates("previous90");
                        }}
                      />
                      <input
                        type="button"
                        defaultValue="30 Days"
                        check-val="previous30"
                        className="generate_report"
                        onClick={() => {
                          setDates("previous30");
                        }}
                      />
                      <input
                        type="button"
                        defaultValue="9 Days"
                        check-val="previous9"
                        className="generate_report"
                        onClick={() => {
                          setDates("previous9");
                        }}
                      />
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-md-4 col-lg-5"></div>
                  <div className="col-12 col-md-4 col-lg-2">
                    <button
                      disabled={DAteSeted ? true : false}
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 w-100"
                    >
                      Go
                    </button>
                  </div>
                  <div className="col-md-4 col-lg-5"></div>
                </form>
                <div className="row">
                  <div className="col-md-3 ">
                    <div className="form-group custom-group">
                      <label className="form-label">Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => {
                          searchRecord(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table data={myData} />
          </div>
        </main>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Bank Ledger
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="row g-3"
                onSubmit={(e) => {
                  updateHandleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={updateValues.branch_id || ""}
                        onChange={(e) => {
                          updateHandleChange(e);
                        }}
                        onBlur={updateHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {updateErrors.branch_id && updateTouched.branch_id ? (
                      <p className="text-danger">{updateErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Amount<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter Price"
                      name="amount"
                      min={0}
                      value={updateValues.amount}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.amount && updateTouched.amount ? (
                      <p className="text-danger">{updateErrors.amount}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Type<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="type"
                      value={updateValues.type || ""}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    >
                      <option value="">Select Type</option>
                      <option value="Credit">Credit</option>
                      <option value="Debit">Debit</option>
                    </select>
                    {updateErrors.type && updateTouched.type ? (
                      <p className="text-danger">{updateErrors.type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Resource<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      name="resource"
                      value={updateValues.resource || ""}
                      onChange={(e) => {
                        updateHandleChange(e);
                      }}
                      onBlur={updateHandleBlur}
                    >
                      <option value="-1">Select Resource</option>
                      <option value="Bank Account">Bank Account</option>
                      <option value="Office Counter">Office Counter</option>
                      <option value="Others">Others</option>
                      <option value="Personal">Personal</option>
                      <option value="Sales Counter">Sales Counter</option>
                    </select>
                    {updateErrors.resource && updateTouched.resource ? (
                      <p className="text-danger">{updateErrors.resource}</p>
                    ) : null}
                  </div>
                </div>
                {updateValues.resource === "Bank Account" ? (
                  <div className="col-12 col-md-6">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Bank Details<span className="text-danger">*</span>
                      </label>
                      {bank ? (
                        <select
                          className="form-select"
                          name="bank_account_id"
                          value={updateValues.bank_account_id || ""}
                          onChange={(e) => {
                            updateHandleChange(e);
                          }}
                          onBlur={updateHandleBlur}
                        >
                          {bank.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.bank_name} {el.account_no}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {updateErrors.bank_account_id &&
                      updateTouched.bank_account_id ? (
                        <p className="text-danger">
                          {updateErrors.bank_account_id}
                        </p>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="11-03-2022"
                      name="date"
                      value={updateValues.date}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.date && updateTouched.date ? (
                      <p className="text-danger">{updateErrors.date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Description<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      className="form-control"
                      placeholder="Enter Price"
                      name="description"
                      min={0}
                      value={updateValues.description}
                      onChange={updateHandleChange}
                      onBlur={updateHandleBlur}
                    />
                    {updateErrors.description && updateTouched.description ? (
                      <p className="text-danger">{updateErrors.description}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}

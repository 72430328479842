/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { clientReport } from "../schemas";
import Swal from "sweetalert2";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import CurrencyFormat from "react-currency-format";
import Pagination from "react-js-pagination";
import "jspdf-autotable";
import $ from "jquery";
import jsPDF from "jspdf";
import MenuButton from "../MenuButton/MenuButton";

export default function DepositsHistory() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [DAteSeted, setDAteSeted] = useState(false);
  const [radioOption, setRadioOption] = useState("Detail");
  const [show, setShow] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [exportLink, setExportLink] = useState("");
  const [summaryExport, setSummaryExport] = useState("");
  const [clientName, setClientName] = useState([]);

  const setDates = async (param) => {
    setDAteSeted(true);

    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);

        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };
  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        setMyData([]);
        setExportLink(
          process.env.REACT_APP_API_Link +
            "/orders/export-transaction?branch_id=" +
            branch_id +
            "&start_date=" +
            values.sdate +
            "&end_date=" +
            values.edate +
            "&category=13"
        );
        const responce = await UserServices.getDepositHistory(
          branch_id,
          values
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];
          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            value.data.map((value) => {
              results.push({
                name: value.client_name,
                note: value.note,
                client_id: value.client_id,
                branch_name: value.branch_name,
                total_received: parseInt(value.total_received),
                payment_method: value.payment_history,
                items: value.items,
                net_price: value.net_price,
                price: value.price,
                date: value.date,
                sold_by: value.sold_by,
                order_id: value.id,
                tax: value.tax,
                discount: value.discount,
              });
              return null;
            });
          });
          setMyData([...results]);
          setShow(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setExportLink();
        if (err.response.status === 401) {
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setMyData([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const summaryReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        setSummaryData([]);
        setSummaryExport(
          process.env.REACT_APP_API_Link +
            "/export-transaction-summary?branch_id=" +
            branch_id +
            "&start_date=" +
            values.sdate +
            "&end_date=" +
            values.edate +
            "&category=13"
        );
        const responce = await UserServices.getDepositSummaryReport(
          branch_id,
          values
        );
        let myDataarr;
        if (responce.status === 200) {
          myDataarr = responce.data.data;
          const res = myDataarr;
          const results = [];

          res.map((value) => {
            results.push({
              order_count: value.order_count,
              order_date: value.order_date,
              total_discount: value.total_discount,
              total_net_price: value.total_net_price,
              total_price: value.total_price,
              total_tax: value.total_tax,
            });
            return null;
          });

          setSummaryData([...results]);
          setShow(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setSummaryExport();
        if (err.response.status === 401) {
          setSummaryData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setSummaryData([]);
          setLoading(false);
        } else {
          setSummaryData([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const depositClientNames = async () => {
    try {
      const responce = await UserServices.getCafeClientDeposit(branch_id);
      const res = responce.data.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          client_id: values.client_id,
          client_name: values.client_name,
          available_balance: values.available_balance,
        });
        return null;
      });
      setClientName([
        { client_name: "Select Name", client_id: "" },
        ...results,
      ]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    depositClientNames();
  }, []);
  const initialValues = {
    sdate: startDate,
    edate: endDate,
    client_id:"",
  };
  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");
    const captionText = table.getAttribute("data-caption");
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("deposit_history.pdf");
  };
  const [limit, setLimit] = useState("25");

  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, limit);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0 text-uppercase">Filtered Result</h5>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3 text-end">
              <a
                href={exportLink}
                rel="noreferrer"
                // target="_blank"
                className="btn btn-primary mx-2 file-download float-end mb-3"
              >
                <i className="bi bi-download"></i> Excel
              </a>
              <button
                className="btn btn-primary mx-2 file-download float-end mb-3"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div className="card">
            <div className="card-body">
              <div className="col-md-1 ms-auto">
                <select
                  className="form-select mb-3"
                  onChange={(e) => {
                    setLimit(e.target.value);
                  }}
                  value={limit}
                >
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="1500">1500</option>
                  <option value={myData.length}>All</option>
                </select>
              </div>
              <div className="table-responsive ">
                <table
                  className="table  table-borderless table-style-1 table-striped"
                  data-caption="Deposits History"
                  id="report-POS"
                >
                  <thead>
                    <tr>
                      <th scope="col" className="no_sort">
                        Sr#
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("name")}
                        className={getClassNamesFor("name")}
                      >
                        Client
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("order_id")}
                        className={getClassNamesFor("order_id")}
                      >
                        Order ID
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("date")}
                        className={getClassNamesFor("date ")}
                      >
                        Deposits Date
                      </th>

                      <th
                        scope="col"
                        onClick={() => requestSort("price")}
                        className={getClassNamesFor("price")}
                      >
                        Amount
                      </th>

                      <th scope="col" className="no_sort">
                        Payment Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <>
                          <tr key={index}>
                            <td className="d-flex">
                              {page === 1
                                ? index + 1
                                : (page - 1) * limit + index + 1}
                            </td>
                            <td>
                              {el.note && el.note !== "N/A"
                                ? el.note.split(",")[0]
                                : el.name}
                            </td>
                            <td>{el.order_id}</td>
                            <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                            <td>
                              <CurrencyFormat
                                value={el.price}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                            <td>
                              {el.payment_method.map((pyel, index) => (
                                <span key={index}>
                                  {el.payment_method[index - 1]
                                    ?.payment_type !== pyel.payment_type ? (
                                    <>
                                      {el.payment_method.length > 1 && index > 0
                                        ? ", "
                                        : ""}
                                      {pyel.payment_type}{" "}
                                    </>
                                  ) : null}
                                </span>
                              ))}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center text-capitalize">
                          No record found
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="4"></td>
                      <td colSpan="1" className="expenses-num">
                        Total Amount :{" "}
                        <CurrencyFormat
                          value={myData.reduce((a, v) => (a = a + v.price), 0)}
                          displayType={"text"}
                          thousandSpacing={"3"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                {data.length && data.length > limit ? (
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={data.length}
                    onChange={(e) => {
                      setPage(e);
                    }}
                    pageRangeDisplayed={8}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First Page"
                    lastPageText="Last Page"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="grand-total-cart bg-white card">
          <div>
            <div className="cart-entry">
              Total Deposits{" "}
              <CurrencyFormat
                value={myData.reduce((a, v) => (a = a + v.price), 0)}
                displayType={"text"}
                thousandSpacing={"3"}
                thousandSeparator={true}
                prefix={"Rs "}
                suffix={"/-"}
                renderText={(value) => <span>{value}</span>}
              />
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const SummaryTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <>
        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0 text-uppercase">Filtered Result</h5>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3 text-end">
              <a
                href={summaryExport}
                rel="noreferrer"
                className="btn btn-primary mx-2 file-download float-end mb-3"
              >
                <i className="bi bi-download"></i> Excel
              </a>
              <button
                className="btn btn-primary mx-2 file-download float-end mb-3"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive ">
                <table
                  className="table  table-borderless table-style-1"
                  data-caption="Deposits History"
                  id="report-POS"
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        onClick={() => requestSort("order_count")}
                        className={getClassNamesFor("order_count")}
                      >
                        Deposits Count
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("order_date")}
                        className={getClassNamesFor("order_date")}
                      >
                        Deposits Date
                      </th>
                      <th
                        scope="col"
                        onClick={() => requestSort("total_price")}
                        className={getClassNamesFor("total_price")}
                      >
                        Total Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length ? (
                      items.map((el, index) => (
                        <>
                          <tr key={index}>
                            <td>{el.order_count} Deposits</td>
                            <td>
                              {moment(el.order_date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <CurrencyFormat
                                value={el.total_price}
                                displayType={"text"}
                                thousandSpacing={"3"}
                                thousandSeparator={true}
                                prefix={"Rs "}
                                suffix={"/-"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center text-capitalize">
                          No record found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        if (radioOption === "Summary") {
          summaryReport(values);
        } else {
          generateReport(values);
        }
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Deposits History</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <h5>Quick Dates</h5>
                </div>
                <div className="row quick multi-button custom-multi-button">
                  <div className="col-md-4">
                    <label className="form-label d-block">Last</label>
                    <input
                      type="button"
                      check-val="lastYear"
                      onClick={() => {
                        setDates("lastYear");
                      }}
                      className="generate_report"
                      defaultValue="Year"
                    />
                    <input
                      type="button"
                      check-val="lastQuarter"
                      onClick={() => {
                        setDates("lastQuarter");
                      }}
                      className="generate_report"
                      defaultValue="Quarter"
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="lastMonth"
                      onClick={() => {
                        setDates("lastMonth");
                      }}
                      className="generate_report"
                    />
                    <input
                      type="button"
                      defaultValue="Yesterday"
                      check-val="lastDay"
                      className="generate_report"
                      onClick={() => {
                        setDates("lastDay");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">To-Date</label>
                    <input
                      type="button"
                      defaultValue="Year"
                      check-val="toDateYear"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateYear");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Quarter"
                      check-val="toDateQuarter"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateQuarter");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="toDateMonth"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateMonth");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Today"
                      check-val="toDateToday"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateToday");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">Previous</label>
                    <input
                      type="button"
                      defaultValue="365 Days"
                      check-val="previous365"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous365");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="90 Days"
                      check-val="previous90"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous90");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="30 Days"
                      check-val="previous30"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous30");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="9 Days"
                      check-val="previous9"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous9");
                      }}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12">
                  <h5>Options</h5>
                </div>
                <div className="col-md-6 col-lg-3 ">
                  <div className="custom-raio">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="f-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Summary"
                          onChange={() => {
                            setRadioOption("Summary");
                          }}
                          checked={radioOption === "Summary"}
                        />
                        <label htmlFor="f-option[17]">Summary</label>
                        <div className="check" />
                      </li>
                      <li>
                        <input
                          type="radio"
                          id="s-option[17]"
                          name="option[17]"
                          className="option[17]"
                          value="Detail"
                          onChange={() => {
                            setRadioOption("Detail");
                          }}
                          checked={radioOption === "Detail"}
                        />
                        <label htmlFor="s-option[17]">Detail</label>
                        <div className="check" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12"></div>
                <div className="col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Select Client</label>
                    {clientName ? (
                      <select
                        className="form-select"
                        name="client_id"
                        value={values.client_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {clientName.map((el, index) => {
                          return (
                            <option key={index} value={el.client_id}>
                              {el.client_name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-4 col-lg-5"></div>
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5"></div>
              </form>
            </div>
          </div>
          {radioOption === "Detail" && show ? <Table data={myData} /> : null}
          {radioOption === "Summary" && show ? (
            <SummaryTable data={summaryData} />
          ) : null}
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import { addAssets } from "../schemas";
import { useContext } from "react";
import { NoteState } from "../../context/notes/NoteState";
import { useState } from "react";
import UserServices from "../../UserServices/UserServices";
import { useEffect } from "react";
import { useMask } from "react-mask-field";
import { toast } from "react-toastify";
import moment from "moment";
import { viewFines } from "../schemas";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import jsPDF from "jspdf";

export default function Assets() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [myBranches, setMyBranches] = useState([]);
  const [catgries, setCatgries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subCatgries, setSubCatgries] = useState([]);

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  const [startDate, setStartDate] = useState(defaultValue);
  // table
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const month = date.getMonth();
  const monthString = (parseInt(month) + 1).toString();
  let sDate = moment().startOf("month").format("YYYY-MM-DD");

  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.assetsGet(
        branch_id,
        newLimit,
        pageNo,
        values
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            acquisition_date: values.acquisition_date,
            purchase_cost: values.purchase_cost,
            name: values.name,
            category_name: values.category_name,
            sub_category_name: values.sub_category_name,
            current_value: values.current_value,
            description: values.description,
            quantity: values.quantity,
            total_cost: values.total_cost,
            vendor_name: values.vendor_name,
            vendor_contact: values.vendor_contact,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.deleteAssets(id);
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delAssets = (id) => {
    deleteRecord(id);
  };

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("Assets.pdf");
  };
  const changeCategory = (values) => {
    const getCategoryTypeData = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getAsetFiltration(
          "1",
          limit,
          1,
          branch_id,
          values
        );
        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];

          res.map((values) => {
            results.push({
              id: values.id,
              acquisition_date: values.acquisition_date,
              purchase_cost: values.purchase_cost,
              name: values.name,
              category_name: values.category_name,
              sub_category_name: values.sub_category_name,
              current_value: values.current_value,
              description: values.description,
              quantity: values.quantity,
              total_cost: values.total_cost,
              vendor_name: values.vendor_name,
              vendor_contact: values.vendor_contact,
            });
          });

          setMyData([...results]);
          setTotalResults(responce.data.totalRecord);
          setTotalPages(responce.data.totalPages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getCategoryTypeData();
  };
  const getSubCategories = async (e) => {
    var sb_catgry = e.target.value;
    try {
      const responce = await UserServices.SubCategoriesDropdoenName(
        sb_catgry,
        branch_id
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setSubCatgries([{ name: "Select Sub Category", id: "" }, ...results]);
    } catch (err) {
      setSubCatgries([{ name: "No record found", id: "" }]);
      console.log(err);
    }
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="Assets"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("category_name")}
                className={getClassNamesFor("category_name")}
              >
                Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("sub_category_name")}
                className={getClassNamesFor("sub_category_name")}
              >
                Sub-Category
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("purchase_cost")}
                className={getClassNamesFor("purchase_cost")}
              >
                Purchase Cost
              </th>
              <th
                scope="col"
                onClick={() => requestSort("quantity")}
                className={getClassNamesFor("quantity")}
              >
                Quantity
              </th>
              <th
                scope="col"
                onClick={() => requestSort("current_value")}
                className={getClassNamesFor("current_value")}
              >
                Current Value
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Description
              </th>
              <th
                scope="col"
                onClick={() => requestSort("acquisition_date")}
                className={getClassNamesFor("acquisition_date")}
              >
                Acquisition Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("vendor_name")}
                className={getClassNamesFor("vendor_name")}
              >
                Vendor Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("vendor_contact")}
                className={getClassNamesFor("vendor_contact")}
              >
                Vendor Contact
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.category_name}</td>
                  <td>{el.sub_category_name}</td>
                  <td>{el.name}</td>
                  <td>
                    <CurrencyFormat
                      value={el.purchase_cost}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3s"}
                    />
                  </td>
                  <td>{el.quantity}</td>
                  <td>
                    <CurrencyFormat
                      value={el.current_value}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      thousandSpacing={"3s"}
                    />
                  </td>
                  <td>{el.description}</td>
                  <td>{moment(el.acquisition_date).format("DD-MM-YYYY")}</td>
                  <td>{el.vendor_name}</td>
                  <td>{el.vendor_contact}</td>
                  <td>
                    <Link to={"/update-assets/" + el.id} className="updt-btn">
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </Link>
                    <button
                      type="button"
                      onClick={() => delAssets(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const categories = async () => {
    try {
      const responce = await UserServices.CategoriesDropdoenName(
        branch_id,
        "Asset"
      );
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setCatgries([{ name: "Select Category", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setCatgries([{ name: "No Category Found", id: "" }]);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);
    branches();
    categories();
  }, []);

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const addOfficeAsset = async (addValues, action) => {
    try {
      setLoading(true);
      const response = await UserServices.assetsAdd(addValues);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
      } else if (err.response.status === 500) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      } else if (err.response.status === 400) {
        // toast.error("Unproccessable content!", { position: "top-center" });
      }
    }
  };
  const addinitialValues = {
    branch_id: branch_id,
    category_id: "",
    name: "",
    purchase_cost: "",
    current_value: "",
    acquisition_date: startDate,
    description: "",
    quantity: "",
    total_cost: "",
    vendor_name: "",
    vendor_contact: "",
    sub_category_id: "",
  };
  const initialValues = {
    startdate: sDate,
    enddate: defaultValue,
    category_id: "",
    sub_category_id: "",
  };

  const {
    values: addValues,
    errors: addErrors,
    touched: addTouched,
    handleBlur: addHandleBlur,
    handleChange: addHandleChange,
    handleSubmit: addHandleSubmit,
  } = useFormik({
    initialValues: addinitialValues,
    validationSchema: addAssets,
    onSubmit: (addValues, action) => {
      addOfficeAsset(addValues, action);
    },
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: viewFines,
      onSubmit: (values) => {
        changeCategory(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Assets Management</h5>
            </div>
            <div className="card-body">
              <p>
                <i
                  className="bi bi-exclamation-lg text-danger"
                  aria-hidden="true"
                />
                The Fields With <strong className="text-danger">*</strong>
                Must Required Or Fill.
              </p>
              <form
                className="row g-3"
                onSubmit={(e) => {
                  addHandleSubmit(e);
                }}
              >
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        disabled={branch_id ? true : false}
                        value={addValues.branch_id || ""}
                        onChange={(e) => {
                          addHandleChange(e);
                        }}
                        onBlur={addHandleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addErrors.branch_id && addTouched.branch_id ? (
                      <p className="text-danger">{addErrors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Category<span className="text-danger">*</span>
                    </label>
                    {catgries ? (
                      <select
                        className="form-select"
                        name="category_id"
                        value={addValues.category_id || ""}
                        onChange={(e) => {
                          addHandleChange(e);
                          getSubCategories(e);
                          addValues.sub_category_id = "";
                        }}
                        onBlur={addHandleBlur}
                      >
                        {catgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {addErrors.category_id && addTouched.category_id ? (
                      <p className="text-danger">{addErrors.category_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Sub-Category<span className="text-danger">*</span>
                    </label>
                    {subCatgries ? (
                      <select
                        className="form-select"
                        name="sub_category_id"
                        value={addValues.sub_category_id || ""}
                        onChange={(e) => {
                          addHandleChange(e);
                        }}
                        onBlur={addHandleBlur}
                      >
                        {subCatgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        name="sub_category_id"
                        value={addValues.sub_category_id}
                        onChange={addHandleChange}
                        onBlur={addHandleBlur}
                      >
                        <option>Select Sub Category</option>
                      </select>
                    )}
                    {addErrors.sub_category_id && addTouched.sub_category_id ? (
                      <p className="text-danger">{addErrors.sub_category_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="name"
                      value={addValues.name}
                      onChange={addHandleChange}
                      onBlur={addHandleBlur}
                    />
                    {addErrors.name && addTouched.name ? (
                      <p className="text-danger">{addErrors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Purchase Cost<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter Purchase Cost"
                      name="purchase_cost"
                      min={0}
                      value={addValues.purchase_cost}
                      onChange={addHandleChange}
                      onBlur={addHandleBlur}
                    />
                    {addErrors.purchase_cost && addTouched.purchase_cost ? (
                      <p className="text-danger">{addErrors.purchase_cost}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Quantity"
                      name="quantity"
                      min={0}
                      value={addValues.quantity}
                      onChange={addHandleChange}
                      onBlur={addHandleBlur}
                    />
                    {addErrors.quantity && addTouched.quantity ? (
                      <p className="text-danger">{addErrors.quantity}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Total Cost<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Total Cost"
                      name="total_cost"
                      disabled={true}
                      value={addValues.quantity * addValues.purchase_cost}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Current Value<span className="text-danger">*</span>
                    </label>
                    <input
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className="form-control"
                      placeholder="Enter Purchase Cost"
                      name="current_value"
                      min={0}
                      value={addValues.current_value}
                      onChange={addHandleChange}
                      onBlur={addHandleBlur}
                    />
                    {addErrors.current_value && addTouched.current_value ? (
                      <p className="text-danger">{addErrors.current_value}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Acquisition Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="acquisition_date"
                      value={addValues.acquisition_date}
                      onChange={addHandleChange}
                      onBlur={addHandleBlur}
                    />
                    {addErrors.acquisition_date &&
                    addTouched.acquisition_date ? (
                      <p className="text-danger">
                        {addErrors.acquisition_date}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Vendor Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="vendor_name"
                      value={addValues.vendor_name}
                      onChange={addHandleChange}
                      onBlur={addHandleBlur}
                    />
                    {addErrors.vendor_name && addTouched.vendor_name ? (
                      <p className="text-danger">{addErrors.vendor_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Vendor Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="(e.g 92xxxxxxxxxx)"
                      name="vendor_contact"
                      value={addValues.vendor_contact}
                      onChange={(e) => {
                        addHandleChange(e);
                      }}
                      onBlur={addHandleBlur}
                      ref={phone_number}
                    />
                    {addErrors.vendor_contact && addTouched.vendor_contact ? (
                      <p className="text-danger">{addErrors.vendor_contact}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group custom-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Description"
                      name="description"
                      value={addValues.description}
                      onChange={addHandleChange}
                      onBlur={addHandleBlur}
                    />
                    {addErrors.description && addTouched.description ? (
                      <p className="text-danger">{addErrors.description}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">View Assets</h5>
            </div>
            <div className="card-body">
              <div className="row d-flex">
                <form className="row mb-3" onSubmit={handleSubmit}>
                  <div className="col-md-2 col-lg-2 ms-3">
                    <label className="form-label">
                      Filter By Category<span className="text-danger">*</span>
                    </label>
                    {catgries ? (
                      <select
                        className="form-select"
                        name="category_id"
                        value={values.category_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          getSubCategories(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {catgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.category_id && touched.category_id ? (
                      <p className="text-danger">{errors.category_id}</p>
                    ) : null}
                  </div>

                  <div className="col-md-2 ms-3">
                    <label className="form-label">
                      Filter By Subcategory
                      <span className="text-danger">*</span>
                    </label>
                    {subCatgries ? (
                      <select
                        className="form-select"
                        name="sub_category_id"
                        value={values.sub_category_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        {subCatgries.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.sub_category_id && touched.sub_category_id ? (
                      <p className="text-danger">{errors.sub_category_id}</p>
                    ) : null}
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">From</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="date"
                      name="startdate"
                      value={values.startdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.startdate && touched.startdate ? (
                      <p className="text-danger">{errors.startdate}</p>
                    ) : null}
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">To</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="date"
                        name="enddate"
                        value={values.enddate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onChange={() => changeCategory(values)}
                      >
                        Generate
                      </button>
                    </div>

                    {errors.enddate && touched.enddate ? (
                      <p className="text-danger">{errors.enddate}</p>
                    ) : null}
                  </div>
                  <div className="col-md-1 col-lg-1 ms-auto mt-4">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-1 mt-4">
                    <button
                      className="btn btn-primary mx-2 file-download"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </form>
              </div>
              <div className="accordion-body">
                <div className="table-responsive ">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

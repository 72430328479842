/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import moment from "moment";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { clientReport } from "../schemas";
import Swal from "sweetalert2";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import CurrencyFormat from "react-currency-format";
import "jspdf-autotable";
import $ from "jquery";
import jsPDF from "jspdf";
import MenuButton from "../MenuButton/MenuButton";

export default function NewCafeReport() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [DAteSeted, setDAteSeted] = useState(false);
  const [radioOption, setRadioOption] = useState("Detail");
  const [show, setShow] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dateSelected);
  const [endDate, setEndDate] = useState(dateSelected);
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [exportLink, setExportLink] = useState("");
  // const [summaryExport, setSummaryExport] = useState("");

  const setDates = async (param) => {
    setDAteSeted(true);

    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);

        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };
  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        setMyData([]);
        //   setExportLink(
        //     process.env.REACT_APP_API_Link +
        //       "/orders/export-transaction?branch_id=" +
        //       branch_id +
        //       "&start_date=" +
        //       values.sdate +
        //       "&end_date=" +
        //       values.edate +
        //       "&category=10"
        //   );
        const responce = await UserServices.getNewCafeReport(branch_id, values);
        let myDataarr;

        if (responce.status === 200) {
          console.log(responce);
          myDataarr = responce.data;
          const res = myDataarr;
          const results = [];
          // eslint-disable-next-line array-callback-return
          res.map((value) => {
            //   value.data.map((value) => {
            results.push({
              name: value.client_name,
              client_id: value.client_id,
              branch_name: value.branch_name,
              total_received: parseInt(value.total_received),
              payment_method: value.payment_history,
              items: value.items,
              net_price: value.net_price,
              price: value.price,
              date: value.date,
              sold_by: value.sold_by,
              order_id: value.id,
              tax: value.tax,
              discount: value.discount,
              note: value.note,
              category: value.category,
            });
            return null;
            //   });
          });
          setMyData([...results]);
          setShow(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setShow(true);
        setExportLink();
        if (err.response.status === 401) {
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setMyData([]);
          setLoading(false);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };
  const initialValues = {
    sdate: startDate,
    edate: endDate,
    searchVal: "",
  };
  const showInstalDetails = (id) => {
    $("#" + id).fadeToggle();
  };
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10;
    let pageWidth = doc.internal.pageSize.width;
    let pageHeight = doc.internal.pageSize.height;
    tables.forEach((table, index) => {
      const captionText = table.getAttribute("data-caption");
      let tableHeight = doc.autoTable.previous.finalY - currentY;
      if (currentY + tableHeight + 20 > pageHeight) {
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20;
      doc.setDrawColor(0);
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY);
      currentY = tableBottomY + 10;
    });
    doc.save("cafe_sales_new.pdf");
  };
  const [limit, setLimit] = useState("25");

  const Table = ({ data, category }) => {
    const filteredData = data.filter((item) => item.category === category);
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, limit);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    let headingText = "Filtered Result";
    if (category === "13") {
      headingText = "Modes of Payment: Deposit";
    } else if (category === "10") {
      headingText = "Modes of Payment: Cash/ Credit Card/ Online";
    }
    return (
      <>
        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-6">
              <h5 className="mb-0 text-uppercase">{headingText}</h5>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3 text-end">
              {/* <a
                href={exportLink}
                rel="noreferrer"
                // target="_blank"
                className="btn btn-primary mx-2 file-download float-end mb-3"
              >
                <i className="bi bi-download"></i> Excel
              </a> */}
              <button
                className="btn btn-primary mx-2 file-download float-end mb-3"
                onClick={() => {
                  generatePdf(document.getElementById("report-POS"));
                }}
              >
                <i className="bi bi-file-earmark-pdf"></i> PDF
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table
                className="table table-borderless table-style-1 table-striped"
                data-caption="Cafe Sales"
                id="report-POS"
              >
                <thead>
                  <tr>
                    <th scope="col" className="no_sort">
                      Sr#
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("name")}
                      className={getClassNamesFor("name")}
                    >
                      Client
                    </th>

                    <th
                      scope="col"
                      onClick={() => requestSort("order_id")}
                      className={getClassNamesFor("order_id")}
                    >
                      Order ID
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("date")}
                      className={getClassNamesFor("date ")}
                    >
                      Sale Date
                    </th>
                    {/* <th
                      scope="col"
                      onClick={() => requestSort("category")}
                      className={getClassNamesFor("category ")}
                    >
                      Category
                    </th> */}

                    <th
                      scope="col"
                      onClick={() => requestSort("price")}
                      className={getClassNamesFor("price")}
                    >
                      Price
                    </th>
                    <th scope="col" className="no_sort">
                      Discount
                    </th>
                    <th
                      scope="col"
                      onClick={() => requestSort("tax")}
                      className={getClassNamesFor("tax")}
                    >
                      GST
                    </th>
                    <th scope="col" className="no_sort">
                      Pending
                    </th>
                    <th scope="col" className="no_sort">
                      Net Price
                    </th>
                    <th scope="col" className="no_sort">
                      Payment Type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length ? (
                    filteredData.map((el, index) => (
                      <>
                        <tr key={index}>
                          <td className="d-flex">
                            {page === 1
                              ? index + 1
                              : (page - 1) * limit + index + 1}{" "}
                            {el.items.length ? (
                              <div
                                className="ms-2"
                                onClick={() => {
                                  showInstalDetails(el.order_id);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <i className="bi bi-chevron-down fw-bold"></i>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {el.note && el.note !== "N/A"
                              ? el.note.split(",")[0]
                              : el.name}
                          </td>
                          <td>{el.order_id}</td>
                          <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                          {/* <td>{el.category}</td> */}
                          <td>
                            <CurrencyFormat
                              value={el.price}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.discount}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.tax}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={"0"}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            <CurrencyFormat
                              value={el.payment_method.reduce(
                                (a, v) => (a = a + v.received),
                                0
                              )}
                              displayType={"text"}
                              thousandSpacing={"3"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                          <td>
                            {el.payment_method.map((pyel, index) => (
                              <span key={index}>
                                {el.payment_method[index - 1]?.payment_type !==
                                pyel.payment_type ? (
                                  <>
                                    {el.payment_method.length > 1 && index > 0
                                      ? ", "
                                      : ""}
                                    {pyel.payment_type}{" "}
                                  </>
                                ) : null}
                              </span>
                            ))}
                          </td>
                        </tr>
                        {el.items.length ? (
                          <tr
                            className="instalmnt-info table table-striped"
                            id={el.order_id}
                          >
                            <td colSpan="10">
                              <tr>
                                <th
                                  style={{
                                    width: "5%",
                                    background: "rgb(252 239 239);",
                                    color: "#000",
                                  }}
                                ></th>
                                <th
                                  style={{
                                    width: "45%",
                                    background: "rgb(252 239 239);",
                                    color: "#000",
                                  }}
                                >
                                  Item Name
                                </th>
                                <th
                                  style={{
                                    width: "30%",
                                    background: "rgb(252 239 239);",
                                    color: "#000",
                                  }}
                                >
                                  Price
                                </th>
                                <th
                                  style={{
                                    width: "30%",
                                    background: "rgb(252 239 239);",
                                    color: "#000",
                                  }}
                                >
                                  GST
                                </th>
                                <th
                                  style={{
                                    background: "rgb(252 239 239);",
                                    color: "#000",
                                    width: "30%",
                                  }}
                                >
                                  Net Price
                                </th>
                              </tr>
                              {el.items.map((inl, index) => (
                                <tr key={index}>
                                  <td style={{ width: "5%" }}></td>
                                  <td>{inl.package_name}</td>

                                  <td>
                                    <CurrencyFormat
                                      value={inl.price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"Rs "}
                                      suffix={"/-"}
                                      thousandSpacing={"3"}
                                    />
                                  </td>
                                  <td>
                                    <CurrencyFormat
                                      value={inl.tax}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"Rs "}
                                      suffix={"/-"}
                                      thousandSpacing={"3"}
                                    />
                                  </td>
                                  <td>
                                    <CurrencyFormat
                                      value={inl.net_price}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"Rs "}
                                      suffix={"/-"}
                                      thousandSpacing={"3"}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center text-capitalize">
                        No record found
                      </td>
                    </tr>
                  )}
                  {/* ... your total rows */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: clientReport,
      onSubmit: (values) => {
        generateReport(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">Cafe Sales</h5>
            </div>
            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="link-hirarcy">
                  <span className="text-danger">Reports</span> » Cafe Sales
                </div>
                <div className="col-12">
                  <h5>Dates</h5>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">Start date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter Start date"
                      name="sdate"
                      value={values.sdate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.sdate && touched.sdate ? (
                      <p className="text-danger">{errors.sdate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">End date</label>
                    <input
                      type="date"
                      className={`form-control ${DAteSeted ? "glow" : ""}`}
                      placeholder="Enter End date"
                      name="edate"
                      value={values.edate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.edate && touched.edate ? (
                      <p className="text-danger">{errors.edate}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <h5>Quick Dates</h5>
                </div>
                <div className="row quick multi-button custom-multi-button">
                  <div className="col-md-4">
                    <label className="form-label d-block">Last</label>
                    <input
                      type="button"
                      check-val="lastYear"
                      onClick={() => {
                        setDates("lastYear");
                      }}
                      className="generate_report"
                      defaultValue="Year"
                    />
                    <input
                      type="button"
                      check-val="lastQuarter"
                      onClick={() => {
                        setDates("lastQuarter");
                      }}
                      className="generate_report"
                      defaultValue="Quarter"
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="lastMonth"
                      onClick={() => {
                        setDates("lastMonth");
                      }}
                      className="generate_report"
                    />
                    <input
                      type="button"
                      defaultValue="Yesterday"
                      check-val="lastDay"
                      className="generate_report"
                      onClick={() => {
                        setDates("lastDay");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">To-Date</label>
                    <input
                      type="button"
                      defaultValue="Year"
                      check-val="toDateYear"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateYear");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Quarter"
                      check-val="toDateQuarter"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateQuarter");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Month"
                      check-val="toDateMonth"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateMonth");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="Today"
                      check-val="toDateToday"
                      className="generate_report"
                      onClick={() => {
                        setDates("toDateToday");
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label d-block">Previous</label>
                    <input
                      type="button"
                      defaultValue="365 Days"
                      check-val="previous365"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous365");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="90 Days"
                      check-val="previous90"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous90");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="30 Days"
                      check-val="previous30"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous30");
                      }}
                    />
                    <input
                      type="button"
                      defaultValue="9 Days"
                      check-val="previous9"
                      className="generate_report"
                      onClick={() => {
                        setDates("previous9");
                      }}
                    />
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-md-4 col-lg-5"></div>
                <div className="col-12 col-md-4 col-lg-2">
                  <button
                    disabled={DAteSeted ? true : false}
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 w-100"
                  >
                    Go
                  </button>
                </div>
                <div className="col-md-4 col-lg-5"></div>
              </form>
            </div>
          </div>
          {radioOption === "Detail" && show ? (
            <>
              <div>
                <Table data={myData} category={"10"} />{" "}
                {/* Table for category 10 */}
              </div>
              <div>
                <Table data={myData} category={"13"} />{" "}
                {/* Table for category 13 */}
              </div>
              <div className="grand-total-cart bg-white card">
                <div>
                  <div className="cart-entry">
                    Total Price{" "}
                    <CurrencyFormat
                      value={myData.reduce((a, v) => (a = a + v.price), 0)}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div>
                  <div className="cart-entry">
                    Total Discount{" "}
                    <CurrencyFormat
                      value={myData.reduce((a, v) => (a = a + v.discount), 0)}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div>
                  <div className="cart-entry">
                    Total GST{" "}
                    <CurrencyFormat
                      value={myData.reduce((a, v) => (a = a + v.tax), 0)}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div>
                  <div className="cart-entry">
                    Total Net Price{" "}
                    <CurrencyFormat
                      value={myData.reduce(
                        (a, v) =>
                          (a =
                            a +
                            v.payment_method?.reduce(
                              (a, v) => (a = a + v.received),
                              0
                            )),
                        0
                      )}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div>
                  <div className="cart-entry">
                    Total Pending{" "}
                    <CurrencyFormat
                      value={"0"}
                      displayType={"text"}
                      thousandSpacing={"3"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div></div>
              </div>
            </>
          ) : null}
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import React, { useContext, useState, useEffect, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { addGxTrainer } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "react-js-pagination";

import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import jsPDF from "jspdf";
import $ from "jquery";
import moment from "moment";

const AddGfxTrainers = () => {
  const { state } = useContext(NoteState);
  const [loading, setLoading] = useState(false);
  const [trainerName, setTrainerName] = useState([]);
  const [myBranches, setMyBranches] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { branch_id } = state;

  const getPkg = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getGfxTrainee(
        branch_id,

        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            uid: values.uid,
            status: values.status,
            user_first_name: values.first_name,
            user_last_name: values.last_name,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const gymTrainer = async (bId) => {
    try {
      const responce = await UserServices.TrainerName(bId);

      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPkg(currentPage, limit);

    branches();
    gymTrainer(values.branch_id);
  }, []);

  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.delFromGxTrainer(id);
      if (responce.status === 204) {
        toast.error("Removed Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getPkg(currentPage, limit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };

  const addGxTrainers = async (values, action) => {
    try {
      setLoading(true);

      const response = await UserServices.addGfxTrainee(values);

      if (response.status === 204) {
        setLoading(false);

        toast.success("Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        getPkg(currentPage, limit);
      }
    } catch (err) {
      setLoading(false);

      toast.error("Unproccessable content!", { position: "top-center" });
    }
  };

  const initialValues = {
    branch_id: branch_id,
    user_id: "",
    is_gx_trainer: true,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addGxTrainer,
      onSubmit: (values, action) => {
        addGxTrainers(values, action);
      },
    });

  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("GX-Trainers.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="All GX Trainers"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_id")}
                className={getClassNamesFor("branch_id")}
              >
                Trainer id
              </th>

              <th
                scope="col"
                onClick={() => requestSort("trainer")}
                className={getClassNamesFor("trainer")}
              >
                Trainer Name
              </th>
              <th scope="col" className="status">
                Status
              </th>

              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.uid}</td>

                  <td>
                    {el.user_first_name} {el.user_last_name}
                  </td>

                  <td>
                    {el.status === "1" ? (
                      <span
                        className="d-inline-flex"
                        style={{
                          width: `70px`,
                        }}
                      >
                        <span className="badge bg-success w-100">Active</span>
                      </span>
                    ) : (
                      <span
                        className="d-inline-flex"
                        style={{
                          width: `70px`,
                        }}
                      >
                        <span className="badge bg-danger  w-100">Inactive</span>
                      </span>
                    )}
                  </td>

                  <td>
                    <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Remove
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getPkg(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <>
      <div className="wrapper">
        <ToastContainer />
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Add GX Trainer</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name <span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          value={values.branch_id || ""}
                          disabled={branch_id ? true : false}
                          onChange={(e) => {
                            handleChange(e);
                            gymTrainer(e.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      Avaliable Trainers <span className="text-danger">*</span>
                      {trainerName ? (
                        <select
                          className="form-select"
                          name="user_id"
                          value={values.user_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.user_id && touched.user_id ? (
                        <p className="text-danger">{errors.user_id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="clearfix" />
                  <div className="col-12 ">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <button
              className="btn btn-primary mx-2 file-download float-end mb-3"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <div className="clearfix"></div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">All GX Trainer</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-1 col-lg-1 mt-4 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getPkg("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default AddGfxTrainers;

import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import CurrencyFormat from "react-currency-format";
import { NoteState } from "../../context/notes/NoteState";
import useSortableData from "../../hooks/useSortableData";

import UserServices from "../../UserServices/UserServices";
import MenuButton from "../MenuButton/MenuButton";
import { balanceSheet } from "../schemas";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import Swal from "sweetalert2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import jsPDF from "jspdf";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BalanceSheet() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  var dateSelected = moment(defaultValue).format("YYYY-MM-DD");

  const sMonth = date.getMonth();
  const monthString = (parseInt(sMonth) + 1).toString();
  let strtDate = moment().startOf("month").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(strtDate);
  const [endDate, setEndDate] = useState(dateSelected);

  const [Sales, setSales] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [DAteSeted, setDAteSeted] = useState(false);

  const totalSalesExpenses = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      setLoading(true);
      try {
        const responce = await UserServices.monthSaleExpenses(
          branch_id,
          values
        );
        //   console.log(responce);
        const res = responce.data.data;
        const expensesArr = [];
        const sales = [];

        res.expenses.map((value) => {
          return expensesArr.push({
            category: value.category,
            total_quantity: parseInt(value.total_quantity),
            total_price: parseInt(value.total_price),
          });
        });
        res.sales.map((value) => {
          return sales.push({
            category: value.category,
            Type: value.Type,
            total_quantity: parseInt(value.total_quantity),
            total_price: parseInt(value.total_price),
          });
        });
        setSales([...sales]);
        setExpenses([...expensesArr]);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setSales([]);
        setExpenses([]);
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater then start date!",
      });
    }
  };

  const initialValues = {
    sdate: startDate,
    edate: endDate,
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: balanceSheet,
      onSubmit: (values) => {
        totalSalesExpenses(values);
      },
    });

  useEffect(() => {
    totalSalesExpenses(values);
  }, []);

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");

    // Loop through all the tables in the section and add them to the PDF

    // Add a new page for each table (skip first table as it is already on the first page)
    const captionText = table.getAttribute("data-caption");
    // Add the table caption as regular text above the table
    doc.text(  captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });

    doc.save("  Balance_sheet.pdf");
  };

  const SalesTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    let total_price = 0;

    return (
      <>
        <div className="table-responsive">
          <table
            className="table table-striped table-borderless table-style-1"
            id="report-Sales"
            data-caption="Sales Balance Sheet"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  onClick={() => requestSort("category")}
                  className={getClassNamesFor("category")}
                >
                  Particulars
                </th>
                <th scope="col" className="no_sort">
                  Percentage
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("total_quantity")}
                  className={getClassNamesFor("total_quantity")}
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  onClick={() => requestSort("total_price")}
                  className={getClassNamesFor("total_price")}
                >
                  Credit
                </th>
              </tr>
            </thead>

            <tbody>
              {items.length ? (
                <>
                  {items.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {el.category === "1"
                          ? "Gym"
                          : el.category === "2"
                          ? "Personal Training"
                          : el.category === "3"
                          ? "Guest Pass"
                          : el.category === "4"
                          ? "Small Group PT"
                          : el.category === "5"
                          ? "Nutritionist"
                          : el.category === "6"
                          ? "Membership"
                          : el.category === "7"
                          ? "Bootcamp"
                          : el.category === "8"
                          ? "Freezing"
                          : el.category === "9"
                          ? "General"
                          : el.category === "10"
                          ? "Cafe"
                          : el.category === "11"
                          ? "CFT"
                          : el.category === "12"
                          ? "LesMills"
                          : el.category === "13"
                          ? "Deposits"
                          : el.category === "14"
                          ? "Physiotherapy"
                          : el.category === "15"
                          ? "GX Studio"
                          : el.category === "16"
                          ? "Befit Session"
                          : null}

                        {el.Type === "Renew"
                          ? el.category === "6"
                            ? "-Reactivation"
                            : "-Existing"
                          : el.Type === "New"
                          ? "-New"
                          : el.Type === "Mix"
                          ? ""
                          : ""}
                      </td>
                      <td>
                        {items.reduce((a, v) => (a = a + v.total_price), 0) !==
                        0
                          ? (
                              (el.total_price /
                                items.reduce(
                                  (a, v) => (a = a + v.total_price),
                                  0
                                )) *
                              100
                            ).toFixed(2)
                          : 0}
                        %
                      </td>
                      <td className="d-none">
                        {items.reduce((a, v) => (a = a + v.total_price), 0) !==
                        0
                          ? (total_price =
                              total_price +
                              (el.total_price /
                                items.reduce(
                                  (a, v) => (a = a + v.total_price),
                                  0
                                )) *
                                100)
                          : (total_price = total_price + 0)}
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.total_quantity}
                          displayType={"text"}
                          thousandSpacing={"3s"}
                          thousandSeparator={true}
                          prefix={""}
                          suffix={""}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={el.total_price}
                          displayType={"text"}
                          thousandSpacing={"3s"}
                          thousandSeparator={true}
                          prefix={"Rs "}
                          suffix={"/-"}
                          renderText={(value) => <span>{value}</span>}
                        />
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td className="font-20">Total Revenue</td>
                    <td className="font-20">{total_price.toFixed(0)}%</td>
                    <td></td>
                    <td className="font-20">
                      <CurrencyFormat
                        value={items.reduce(
                          (a, v) => (a = a + v.total_price),
                          0
                        )}
                        displayType={"text"}
                        thousandSpacing={"3s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="14" className="text-center text-capitalize">
                    No record found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ExpenseTable = ({ data }) => {
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    let total_price = 0;
    return (
      <div className="table-responsive">
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-Expenses"
          data-caption="Expenses Balance Sheet"
        >
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("category")}
                className={getClassNamesFor("category")}
              >
                Particulars
              </th>
              <th scope="col" className="no_sort">
                Percentage
              </th>
              <th
                scope="col"
                onClick={() => requestSort("total_quantity")}
                className={getClassNamesFor("total_quantity")}
              >
                Quantity
              </th>
              <th
                scope="col"
                onClick={() => requestSort("total_price")}
                className={getClassNamesFor("total_price")}
              >
                Debit
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              <>
                {items.map((el, index) => (
                  <tr key={index}>
                    <td>{el.category}</td>
                    <td>
                      {(
                        (el.total_price /
                          items.reduce((a, v) => (a = a + v.total_price), 0)) *
                        100
                      ).toFixed(2)}
                      %
                    </td>
                    <td className="d-none">
                      {
                        (total_price =
                          total_price +
                          (el.total_price /
                            items.reduce(
                              (a, v) => (a = a + v.total_price),
                              0
                            )) *
                            100)
                      }
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.total_quantity}
                        displayType={"text"}
                        thousandSpacing={"3s"}
                        thousandSeparator={true}
                        prefix={""}
                        suffix={""}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        value={el.total_price}
                        displayType={"text"}
                        thousandSpacing={"3s"}
                        thousandSeparator={true}
                        prefix={"Rs "}
                        suffix={"/-"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="font-20">Total Expenses Paid</td>
                  <td className="font-20">{total_price.toFixed(0)}%</td>
                  <td></td>
                  <td className="font-20">
                    <CurrencyFormat
                      value={items.reduce((a, v) => (a = a + v.total_price), 0)}
                      displayType={"text"}
                      thousandSpacing={"3s"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const month = moment(values.sdate).format("MMMM YYYY");

  const labels = Sales.map((el) =>
    el.category === "1" && el.Type === "Renew"
      ? "Gym-Existing"
      : el.category === "1" && el.Type === "New"
      ? "Gym-New"
      : el.category === "1" && el.Type === "Mix"
      ? "Gym"
      : el.category === "2" && el.Type === "Renew"
      ? "Personal Training-Existing"
      : el.category === "3" && el.Type === "Renew"
      ? "Guest Pass-Existing"
      : el.category === "4" && el.Type === "Renew"
      ? "Small Group PT-Existing"
      : el.category === "5" && el.Type === "Renew"
      ? "Nutritionist-Existing"
      : el.category === "6" && el.Type === "Renew"
      ? "Member-Reactivation"
      : el.category === "7" && el.Type === "Renew"
      ? "Bootcamp-Existing"
      : el.category === "8" && el.Type === "Renew"
      ? "Freezing-Existing"
      : el.category === "9" && el.Type === "Renew"
      ? "General-Existing"
      : el.category === "10" && el.Type === "Renew"
      ? "Cafe-Existing"
      : el.category === "11" && el.Type === "Renew"
      ? "CFT-Existing"
      : el.category === "2" && el.Type === "New"
      ? "Personal Training-New"
      : el.category === "3" && el.Type === "New"
      ? "Guest Pass-New"
      : el.category === "4" && el.Type === "New"
      ? "Small Group PT-New"
      : el.category === "5" && el.Type === "New"
      ? "Nutritionist-New"
      : el.category === "6" && el.Type === "New"
      ? "Membership-New"
      : el.category === "7" && el.Type === "New"
      ? "Bootcamp-New"
      : el.category === "8" && el.Type === "New"
      ? "Freezing-New"
      : el.category === "9" && el.Type === "New"
      ? "General-New"
      : el.category === "10" && el.Type === "New"
      ? "Cafe-New"
      : el.category === "11" && el.Type === "New"
      ? "CFT-New"
      : el.category === "2" && el.Type === "Mix"
      ? "Personal Training"
      : el.category === "3" && el.Type === "Mix"
      ? "Guest Pass"
      : el.category === "4" && el.Type === "Mix"
      ? "Small Group PT"
      : el.category === "5" && el.Type === "Mix"
      ? "Nutritionist"
      : el.category === "6" && el.Type === "Mix"
      ? "Registration"
      : el.category === "7" && el.Type === "Mix"
      ? "Bootcamp"
      : el.category === "8" && el.Type === "Mix"
      ? "Freezing"
      : el.category === "9" && el.Type === "Mix"
      ? "General"
      : el.category === "10" && el.Type === "Mix"
      ? "Cafe"
      : el.category === "11" && el.Type === "Mix"
      ? "CFT"
      : el.category === "12" && el.Type === "Mix"
      ? "LesMills"
      : el.category === "13" && el.Type === "Mix"
      ? "Deposits"
      : el.category === "14" && el.Type === "Mix"
      ? "Physiotherapy"
      : el.category === "15" && el.Type === "Mix"
      ? "GX Studio"
      : el.category === "16" && el.Type === "Mix"
      ? "Befit Session"
      : "N/A"
  );

  const setDates = async (param) => {
    setDAteSeted(true);
    try {
      const response = await UserServices.getStartEndDates(param);

      if (response.status === 200) {
        setStartDate(response.data.StartDate);
        setEndDate(response.data.EndDate);
        setDAteSeted(false);
      }
    } catch (err) {
      setDAteSeted(false);
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: " Credit",
        data: Sales.map((el) => el.total_price),
        backgroundColor: Sales.map((el) =>
          el.category === "1" && el.Type === "Renew"
            ? "#5A99D2"
            : el.category === "1" && el.Type === "New"
            ? "#ed7d31"
            : el.category === "1" && el.Type === "Mix"
            ? "#ed7d31"
            : el.category === "2" && el.Type === "Renew"
            ? "#ffc000"
            : el.category === "3" && el.Type === "Renew"
            ? "#cc0000"
            : el.category === "4" && el.Type === "Renew"
            ? "#255E91"
            : el.category === "5" && el.Type === "Renew"
            ? "#4472c4"
            : el.category === "6" && el.Type === "Renew"
            ? "#adc39e"
            : el.category === "7" && el.Type === "Renew"
            ? "#424242"
            : el.category === "8" && el.Type === "Renew"
            ? "#b22da7"
            : el.category === "9" && el.Type === "Renew"
            ? "#5700ef"
            : el.category === "11" && el.Type === "Renew"
            ? "#FF5733"
            : el.category === "10" && el.Type === "Renew"
            ? "#997300"
            : el.category === "11" && el.Type === "Renew"
            ? "#FF5733"
            : el.category === "2" && el.Type === "New"
            ? "#ffe200"
            : el.category === "3" && el.Type === "New"
            ? "#cc0000"
            : el.category === "4" && el.Type === "New"
            ? "#255E91"
            : el.category === "5" && el.Type === "New"
            ? "#4472c4"
            : el.category === "6" && el.Type === "New"
            ? "#70ad47"
            : el.category === "7" && el.Type === "New"
            ? "#A5A5A5"
            : el.category === "8" && el.Type === "New"
            ? "#b22da7"
            : el.category === "9" && el.Type === "New"
            ? "#5700ef"
            : el.category === "10" && el.Type === "New"
            ? "#997300"
            : el.category === "11" && el.Type === "New"
            ? "#FF5733"
            : el.category === "2" && el.Type === "Mix"
            ? "#ffc000"
            : el.category === "3" && el.Type === "Mix"
            ? "#cc0000"
            : el.category === "4" && el.Type === "Mix"
            ? "#255E91"
            : el.category === "5" && el.Type === "Mix"
            ? "#4472c4"
            : el.category === "6" && el.Type === "Mix"
            ? "#70ad47"
            : el.category === "7" && el.Type === "Mix"
            ? "#A5A5A5"
            : el.category === "8" && el.Type === "Mix"
            ? "#b22da7"
            : el.category === "9" && el.Type === "Mix"
            ? "#5700ef"
            : el.category === "10" && el.Type === "Mix"
            ? "#997300"
            : el.category === "11" && el.Type === "Mix"
            ? "#164059"
            : el.category === "12" && el.Type === "Mix"
            ? "#47000d"
            : el.category === "13" && el.Type === "Mix"
            ? "#4707"
            : el.category === "14" && el.Type === "Mix"
            ? "#00e8aa"
            : el.category === "15" && el.Type === "Mix"
            ? "#c98459"
            : el.category === "16" && el.Type === "Mix"
            ? "#e2006e"
            : "#000"
        ),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,

    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        color: "black",
        font: {
          size: 10,
        },
        formatter: (value, context) => {
          const dataset = context.chart.data.datasets[context.datasetIndex];
          const total = dataset.data.reduce(
            (sum, dataPoint) => sum + dataPoint,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2) + "%";
          return percentage;
        },
        anchor: "end",
        align: "start",
        offset: 20,
      },
      legend: {
        display: true,
        position: "left",
        labels: {
          font: {
            size: 16, // Adjust the font size here
          },
        },
      },
      title: {
        display: true,
        text: "Sales Breakup",
        font: {
          size: 18,
        },
        padding: {
          top: 0,
          bottom: 20,
        },
      },
    },
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="card">
                <div className="card-body">
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-12">
                      <h5>Dates</h5>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Start date</label>
                        <input
                          type="date"
                          className={`form-control ${DAteSeted ? "glow" : ""}`}
                          placeholder="Enter Start date"
                          name="sdate"
                          value={values.sdate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {errors.sdate && touched.sdate ? (
                          <p className="text-danger">{errors.sdate}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group custom-group">
                        <label className="form-label">End date</label>
                        <input
                          type="date"
                          className={`form-control ${DAteSeted ? "glow" : ""}`}
                          placeholder="Enter End date"
                          name="edate"
                          value={values.edate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {errors.edate && touched.edate ? (
                          <p className="text-danger">{errors.edate}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <h5>Quick Dates</h5>
                    </div>
                    <div className="row quick multi-button custom-multi-button">
                      <div className="col-md-4">
                        <label className="form-label d-block">Last</label>
                        <input
                          type="button"
                          check-val="lastYear"
                          onClick={() => {
                            setDates("lastYear");
                          }}
                          className="generate_report"
                          defaultValue="Year"
                        />
                        <input
                          type="button"
                          check-val="lastQuarter"
                          onClick={() => {
                            setDates("lastQuarter");
                          }}
                          className="generate_report"
                          defaultValue="Quarter"
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="lastMonth"
                          onClick={() => {
                            setDates("lastMonth");
                          }}
                          className="generate_report"
                        />
                        <input
                          type="button"
                          defaultValue="Yesterday"
                          check-val="lastDay"
                          className="generate_report"
                          onClick={() => {
                            setDates("lastDay");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">To-Date</label>
                        <input
                          type="button"
                          defaultValue="Year"
                          check-val="toDateYear"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateYear");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Quarter"
                          check-val="toDateQuarter"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateQuarter");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Month"
                          check-val="toDateMonth"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateMonth");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="Today"
                          check-val="toDateToday"
                          className="generate_report"
                          onClick={() => {
                            setDates("toDateToday");
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label d-block">Previous</label>
                        <input
                          type="button"
                          defaultValue="365 Days"
                          check-val="previous365"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous365");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="90 Days"
                          check-val="previous90"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous90");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="30 Days"
                          check-val="previous30"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous30");
                          }}
                        />
                        <input
                          type="button"
                          defaultValue="9 Days"
                          check-val="previous9"
                          className="generate_report"
                          onClick={() => {
                            setDates("previous9");
                          }}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-4 col-lg-5"></div>
                    <div className="col-12 col-md-4 col-lg-2">
                      <button
                        disabled={DAteSeted ? true : false}
                        type="submit"
                        className="btn btn-primary px-5 rounded-0 w-100"
                      >
                        Go
                      </button>
                    </div>
                    <div className="col-md-4 col-lg-5"></div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-2">
              <h5 className="mb-0 text-uppercase">Income Statement</h5>

              <hr />
              {/* <h4 className="text-capitalize date-heading">
                From The Month Of {month}
              </h4> */}
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">
                    Sales
                    <button
                      className="btn btn-primary mx-2 file-download float-end"
                      onClick={() => {
                        generatePdf(document.getElementById("report-Sales"));
                      }}
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </h5>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <div className="col-md-7">
                      <SalesTable data={Sales} />
                    </div>
                    <div className="col-md-5" style={{ minHeight: "300px" }}>
                      {Sales.length ? (
                        <Pie
                          options={options}
                          data={data}
                          plugins={[ChartDataLabels]}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card">
                <div className="card-header py-3">
                  <h5 className="mb-0 font-20">
                    Expenses Breakup{" "}
                    <button
                      className="btn btn-primary mx-2 file-download float-end"
                      onClick={() => {
                        generatePdf(document.getElementById("report-Expenses"));
                      }}
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <ExpenseTable data={expenses} />
                    </div>
                    {/* <div className="col-md-6">
                    <h5 className="d-flex justify-content-center" style={{color: "#666666"}}>Expense Breakup</h5>
                    <div className="chart-container">
                    <Bar
                  type="bar"
                  data={{
                    labels: expenses.map((el) => el.category),
                    datasets: [
                      {
                        label: "Expense",
                        backgroundColor: "#ed7d31",
                        data: expenses.map((item) =>
                          parseInt(item.total_price)
                        ),
                        barThickness: 30,
                        borderRadius: 3,
                      },
                    ],
                  }}
                  options={{
                    indexAxis: "x",
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        grid: {
                          borderDash: [3, 3],
                          drawBorder: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      
                      title: {
                        display: true,
                        // text: "Expense Breakup",
                        font: {
                          size: 16,
                        },
                      },
                      
                    },
                  }}
                  labels="months"
                />
                <Bar
  type="bar"
  data={{
    labels: expenses.map((el) => el.category),
    datasets: [
      {
        label: "Expense",
        backgroundColor: "#ed7d31",
        data: expenses.map((item) => parseInt(item.total_price)),
        barThickness: 30,
        borderRadius: 3,
        barPercentage: 0.8,    // Reduce barPercentage to create space between bars
        categoryPercentage: 0.9,
      },
    ],
  }}
  options={{
    indexAxis: "y", // Set indexAxis to "y" for horizontal chart
    scales: {
      x: {
        grid: {
          borderDash: [3, 3],
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: "Expense Breakup",
        font: {
          size: 16,
        },
      },
    },
  }}
  labels="months"
/>

                </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="grand-total-cart bg-white card  mt-md-0">
                <div>
                  <div className="cart-entry">
                    Total Revenue{" "}
                    <CurrencyFormat
                      value={Sales.reduce((a, v) => (a = a + v.total_price), 0)}
                      displayType={"text"}
                      thousandSpacing={"3s"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                  <div className="cart-entry">
                    Total Expenses Paid{" "}
                    <CurrencyFormat
                      value={expenses.reduce(
                        (a, v) => (a = a + v.total_price),
                        0
                      )}
                      displayType={"text"}
                      thousandSpacing={"3s"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                  <div className="cart-entry">
                    Net Profit & Loss
                    <CurrencyFormat
                      value={
                        Sales.reduce((a, v) => (a = a + v.total_price), 0) -
                        expenses.reduce((a, v) => (a = a + v.total_price), 0)
                      }
                      displayType={"text"}
                      thousandSpacing={"3s"}
                      thousandSeparator={true}
                      prefix={"Rs "}
                      suffix={"/-"}
                      renderText={(value) => <span>{value}</span>}
                    />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

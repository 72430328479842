/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import MenuButton from "../MenuButton/MenuButton";
import { useContext, useEffect, useState } from "react";
import UserServices from "../../UserServices/UserServices";
import Pagination from "react-js-pagination";
import useSortableData from "../../hooks/useSortableData";
import useTable from "../../hooks/useTable";
import { NoteState } from "../../context/notes/NoteState";
import jsPDF from "jspdf";
import { useFormik } from "formik";
import moment from "moment";
import { Tooltip } from "react-tooltip";

const ViewClientsPkgs = () => {
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myBranches, setMyBranches] = useState([]);
  const [name, setName] = useState([]);
  const [trainerName, setTrainerName] = useState([]);
  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;

  const getUsers = async (page, limit, values) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;
    let newLimit = limit;
    setLimit(newLimit);
    let e = document.getElementById("answerInput");
    var input = e,
      list = input.getAttribute("list"),
      options = document.querySelectorAll("#" + list + " option"),
      hiddenInput = document.getElementById(
        input.getAttribute("id") + "-hidden"
      ),
      inputValue = input.value;
    // hiddenInput.value = inputValue;
    for (var i = 0; i < options.length; i++) {
      var option = options[i];
      if (option.innerText === inputValue) {
        hiddenInput.value = await option.getAttribute("data-value");
        break;
      } else {
        hiddenInput.value = "";
      }
    }
    let client_id = await document.getElementById("answerInput-hidden").value;
    try {
      const responce = await UserServices.getClientsPackages(
        newLimit,
        pageNo,
        values,
        client_id
      );
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];
        await res.map((value) => {
          results.push({
            client_id: value.client_id,
            order_id: value.order_id,
            client_name: value.client_name,
            trainer_nam: value.trainer_nam,
            trainer_id: value.trainer_id,
            end_date: value.end_date,
            package_name: value.package_name,
            start_date: value.start_date,
            status: value.status,
            trStatus: value.trStatus,
            session_count: value.session_count,
            quantity: value.quantity,
          });
          return null;
        });
        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const gymTrainer = async (br_Id) => {
    // var br_Id = e.target.value;
    try {
      const responce = await UserServices.TrainerName(br_Id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name,
        });
        return null;
      });
      setTrainerName([{ first_name: "Select Trainer", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
      setTrainerName([]);
    }
  };

  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsName(branch_id);
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getUsers(currentPage, limit, values);
    branches();
    gymTrainer(branch_id);
    nameClients();
  }, []);

  //..........................................................................................................

  const generatePdf = (table) => {
    const doc = new jsPDF("p", "mm", "letter");
    const captionText = table.getAttribute("data-caption");
    doc.text(captionText, 10, 10);
    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 }, 
    };
    doc.autoTable({
      html: table,
      ...tableOptions,
    });
    doc.save("New-PT-Clients.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          id="report-POS"
          data-caption="All Active Clients"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("client_name")}
                className={getClassNamesFor("client_name")}
              >
                Client Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("package_name")}
                className={getClassNamesFor("package_name")}
              >
                Package Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trainer_nam")}
                className={getClassNamesFor("trainer_nam")}
              >
                Trainer Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("session_count")}
                className={getClassNamesFor("session_count")}
              >
                PT Package
              </th>
              <th
                scope="col"
                onClick={() => requestSort("start_date")}
                className={getClassNamesFor("start_date")}
              >
                Start Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("end_date")}
                className={getClassNamesFor("end_date")}
              >
                End Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("trStatus")}
                className={getClassNamesFor("trStatus")}
              >
                Time Slot
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    <Link
                      to={
                        role !== "9"
                          ? "/client-home/" + el.client_id
                          : "/trainer-client/" + el.client_id
                      }
                    >
                      {el.client_name}
                    </Link>
                  </td>
                  <td>{el.package_name}</td>
                  <td>{el.trainer_nam}</td>
                  <td>{el.session_count * el.quantity}</td>
                  <td>{moment(el.start_date).format("DD-MM-YYYY")}</td>
                  <td>{moment(el.end_date).format("DD-MM-YYYY")}</td>

                  <td>
                    {el.trStatus === "Pending" ? (
                      <>
                        <Link
                          to={`/assign-time/${el.client_id}/${el.trainer_id}`}
                          data-tooltip-id={el.client_id + el.trainer_id}
                          data-tooltip-content="Assign Time Slot"
                          className="text-danger"
                        >
                          {el.trStatus}
                        </Link>
                        <Tooltip
                          id={el.client_id + el.trainer_id}
                          place="top"
                          className="note-tooltip"
                          clickable
                        />
                      </>
                    ) : (
                      <span className="text-success">Assigned</span>
                    )}

                    {/* <Link
                      className="updt-btn inactive-btn"
                      to={`/assign-time/${el.client_id}/${el.trainer_id}`}
                    >
                      <i className="bi bi-check2-circle text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        {el.trStatus}
                      </span>
                    </Link> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getUsers(e, limit, values);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const initialValues = {
    branch_id: branch_id,
    trainer_id: role === "9" ? UId : "",
    trainer_reservation: "Pending",
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: "",
    onSubmit: async (values, action) => {
      getUsers("1", limit, values);
    },
  });

  return (
    <>
      <div className="wrapper">
        {" "}
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">New PT Clients</h5>
              </div>
              <div className="card-body">
                <form
                  className="row g-3 trainee-add mb-2"
                  onSubmit={handleSubmit}
                >
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Branch Name</label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={values.branch_id || ""}
                          onChange={(e) => {
                            handleChange(e);
                            gymTrainer(e.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {errors.branch_id && touched.branch_id ? (
                        <p className="text-danger">{errors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Client Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Client Name"
                        name="client_name"
                        list="member_id"
                        id="answerInput"
                      />
                    </div>
                    <input
                      type="hidden"
                      name="answer"
                      id="answerInput-hidden"
                    />
                    <datalist id="member_id">
                      {name.map((el, index) => {
                        return (
                          <option
                            key={index}
                            data-value={el.id}
                            value={el.name}
                          >
                            {el.name}
                          </option>
                        );
                      })}
                    </datalist>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Trainer</label>

                      {trainerName ? (
                        <select
                          className="form-select"
                          name="trainer_id"
                          value={values.trainer_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          disabled={role === "9" && UId ? true : false}
                        >
                          {trainerName.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.first_name} {el.last_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}

                      {errors.trainer_id && touched.trainer_id ? (
                        <p className="text-danger">{errors.trainer_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group custom-group">
                      <label className="form-label">Time Reservation</label>

                      <select
                        className="form-select"
                        name="trainer_reservation"
                        value={values.trainer_reservation}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="">All</option>
                        <option value="Pending">New</option>
                        <option value="Reserved">Reserved</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-1">
                    <button
                      type="submit"
                      className="btn btn-primary mx-md-2 file-download mt-md-4 w-100"
                    >
                      Search
                    </button>
                  </div>
                  <div className="col-md-1 col-lg-1 ms-auto">
                    <div className="input-group mt-md-4">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getUsers("1", e.target.value, values)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className=" col-md-1 text-end">
                    <button
                      className="btn btn-primary file-download mt-md-4 w-100"
                      onClick={() => {
                        generatePdf(document.getElementById("report-POS"));
                      }}
                      type="button"
                    >
                      <i className="bi bi-file-earmark-pdf"></i> PDF
                    </button>
                  </div>
                </form>
                <div className="table-responsive">
                  <Table data={myData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
};

export default ViewClientsPkgs;

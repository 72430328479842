import React, { useContext } from "react";
import { useState } from "react";
import MenuButton from "../../MenuButton/MenuButton";
import useSortableData from "../../../hooks/useSortableData";
import jsPDF from "jspdf";
import UserServices from "../../../UserServices/UserServices";
import { useEffect } from "react";
import { NoteState } from "../../../context/notes/NoteState";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";

export default function ViewNutritionAssessments() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [data, setMyData] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [myBranches, setMyBranches] = useState([]);
  const [selectedBranch, setselectedBranch] = useState(
    branch_id ? branch_id : ""
  );

  const [name, setName] = useState([]);

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };

  const nameClients = async () => {
    try {
      const responce = await UserServices.getClientsName(branch_id);

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.first_name + " " + value.last_name,
        });
        return null;
      });
      setName([...results]);
    } catch (err) {
      console.log(err);
      setName([]);
    }
  };

  const getRecord = async (lim, page, bId) => {
    try {
      let e = document.getElementById("answerInput");
      var input = e,
        list = input.getAttribute("list"),
        options = document.querySelectorAll("#" + list + " option"),
        hiddenInput = document.getElementById(
          input.getAttribute("id") + "-hidden"
        ),
        inputValue = input.value;

      // hiddenInput.value = inputValue;

      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (option.innerText === inputValue) {
          hiddenInput.value = await option.getAttribute("data-value");
          break;
        } else {
          hiddenInput.value = "";
        }
      }

      let client_id = await document.getElementById("answerInput-hidden").value;

      setLoading(true);
      const response = await UserServices.getNutritionAssessments(
        bId,
        client_id,
        lim,
        page
      );

      let myDataarr = response?.data?.data?.data;
      const results = [];

      myDataarr.map((value) => {
        return results.push({
          activity_level: value.activity_level,
          allergic_foods: value.allergic_foods,
          anemia: value.anemia,
          any_other_issue: value.any_other_issue,
          comments: value.comments,
          daily_water_intake: value.daily_water_intake,
          diabetes: value.diabetes,
          disliked_foods: value.disliked_foods,
          favorite_foods: value.favorite_foods,
          h_pylori: value.h_pylori,
          hypertension_cvd: value.hypertension_cvd,
          ibs: value.ibs,
          muscle_pain: value.muscle_pain,
          polycystic_ovarian_syndrome: value.polycystic_ovarian_syndrome,
          status: value.status,
          take_stress: value.take_stress,
          client: value?.client_detail?.client_name,
          client_id: value?.client_id,
          branch: value?.branch_detail?.name,
          id: value.id,
        });
      });
      setMyData([...results]);
      setTotalResults(response?.data?.totalRecord);
      setTotalPages(response?.data?.totalPages);
      setCurrentPage(response?.data?.data?.current_page);
      setLoading(false);
    } catch (err) {
      console.log(err);

      setMyData([]);
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage("1");
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecord(limit, currentPage, branch_id);

    branches();

    nameClients();
  }, []);
  //   const generatePdf = (table) => {
  //     const doc = new jsPDF("p", "mm", "letter");

  //     // Loop through all the tables in the section and add them to the PDF

  //     // Add a new page for each table (skip first table as it is already on the first page)
  //     const captionText = table.getAttribute("data-caption");
  //     // Add the table caption as regular text above the table
  //     doc.text(captionText, 10, 10);
  //     const tableOptions = {
  //       headStyles: { fillColor: [232, 0, 43], textColor: 255 },
  //       styles: { fontSize: 5 }, // Adjust the font size here to fit the table content
  //     };
  //     doc.autoTable({
  //       html: table,
  //       ...tableOptions,
  //     });

  //     doc.save("expenses.pdf");
  //   };

  const delExpense = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this record ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed1c24",
      cancelButtonColor: "#303030",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responce = await UserServices.deleteNutritionAssessments(id);
          if (responce.status === 204) {
            Swal.fire({
              title: "Deleted !",
              text: "Record deleted successfully.",
              icon: "success",
              confirmButtonColor: "#ed1c24",
            });
            getRecord(limit, currentPage, branch_id);
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  const Table = ({ data }) => {
    return (
      <div className="table-responsive">
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Nutrition Assessments"
          id="report-POS"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                General Info
              </th>
              <th scope="col" className="no_sort">
                Statements
              </th>
              <th scope="col" className="no_sort">
                Stress Level
              </th>
              <th scope="col" className="no_sort">
                Activity Level
              </th>
              <th scope="col" className="no_sort">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {data.length ? (
              <>
                {data.map((el, index) => (
                  <tr key={index}>
                    <td className="align-middle">
                      {currentPage === 1
                        ? index + 1
                        : (currentPage - 1) * limit + index + 1}
                    </td>
                    <td className="align-middle">
                      <table className="inner-table">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Branch: </strong>
                            </td>
                            <td>
                              {" "}
                              <span className="control-inner">{el.branch}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Client Name: </strong>
                            </td>
                            <td>
                              {" "}
                              <span className="control-inner">
                                <Link to={"/trainer-client/" + el.client_id}>
                                  {el.client}
                                </Link>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Daily Water Intake: </strong>
                            </td>
                            <td>
                              <span className="control-inner">
                                {el.daily_water_intake}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Food you don’t prefer: </strong>
                            </td>
                            <td>
                              <span className="control-inner">
                                {el.disliked_foods}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Food Allergies if any: </strong>
                            </td>
                            <td>
                              <span className="control-inner">
                                {el.allergic_foods}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Foods you prefer: </strong>
                            </td>
                            <td>
                              {" "}
                              <span className="control-inner">
                                {el.favorite_foods}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="align-middle">
                      <span>
                        <table className="inner-table">
                          <tbody>
                            <tr>
                              <td>
                                <strong>Diabetes type 1 or 2: </strong>
                              </td>
                              <td className="ps-2">{el.diabetes}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Hypertension or CVD: </strong>
                              </td>
                              <td className="ps-2">{el.hypertension_cvd}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Polycystic ovarian syndrome: </strong>
                              </td>
                              <td className="ps-2">
                                {el.polycystic_ovarian_syndrome}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Anemia: </strong>
                              </td>
                              <td className="ps-2">{el.anemia}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>IBS: </strong>
                              </td>
                              <td className="ps-2">{el.ibs}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>H. Pylori: </strong>
                              </td>
                              <td className="ps-2">{el.h_pylori}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>
                                  Joint mobility issue or muscle pain:
                                </strong>
                              </td>
                              <td className="ps-2">{el.muscle_pain}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>
                                  Any other issue (DISEASE OR SURGERY):
                                </strong>
                              </td>
                              <td className="ps-2">{el.any_other_issue}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Comments: </strong>
                              </td>
                              <td className="ps-2">{el.comments}</td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                    </td>
                    <td className="align-middle">{el.activity_level}</td>
                    <td className="align-middle">{el.take_stress}</td>
                    <td className="align-middle">
                      <Link
                        to={"/edit-nutrition-assessments/" + el.id}
                        className="updt-btn inactive-btn"
                      >
                        <i className="bi bi-repeat text-success"></i>{" "}
                        <span className="text-success ms-1 updt-text">
                          Edit
                        </span>
                      </Link>
                      <button
                        type="button"
                        className="ms-2 inactive-btn"
                        onClick={() => delExpense(el.id)}
                      >
                        <i className="bi bi-trash3 text-danger"></i>{" "}
                        <span className="text-danger inative-text ms-1">
                          Delete
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getRecord(limit, e, branch_id);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Nutrition Assessments{" "}
                <Link
                  to="/nutrition-assessments"
                  className="btn btn-primary d-inline-block float-end btn-sm mt-2 mt-md-0"
                >
                  Add Nutrition Assessments
                </Link>
              </h5>
            </div>
            <div className="card-body">
              <div className="row mb-4 g-3">
                <div className="col-lg-4">
                  <label className="form-label">
                    Branch Name<span className="text-danger">*</span>
                  </label>
                  {myBranches ? (
                    <select
                      className="form-select"
                      name="branch_id"
                      disabled={branch_id ? true : false}
                      value={selectedBranch}
                      onChange={(e) => {
                        setselectedBranch(e.target.value);
                      }}
                    >
                      {myBranches.map((el, index) => {
                        return (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                </div>

                <div className="col-12 col-lg-4">
                  <label className="form-label">
                    Client Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Client Name"
                    name="client_name"
                    list="member_id"
                    id="answerInput"
                  />

                  <input type="hidden" name="answer" id="answerInput-hidden" />
                  <datalist id="member_id">
                    {name.map((el, index) => {
                      return (
                        <option key={index} data-value={el.id} value={el.name}>
                          {el.name}
                        </option>
                      );
                    })}
                  </datalist>
                </div>
                <div className="col-lg-1 pt-md-1">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 file-download mt-md-4"
                    onClick={() => {
                      getRecord(limit, currentPage, selectedBranch);
                    }}
                  >
                    Search
                  </button>
                </div>
                <div className="col-lg-1 pt-md-1 ms-auto">
                  <select
                    className="form-select mt-md-4"
                    value={limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      getRecord(e.target.value, currentPage, selectedBranch);
                    }}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </select>
                </div>
                {/* <div className="col-lg-1 mt-1">
                  <button
                    className="btn btn-primary  file-download mt-4"
                    onClick={() => {
                      generatePdf(document.getElementById("report-POS"));
                    }}
                  >
                    <i className="bi bi-file-earmark-pdf"></i> PDF
                  </button>
                </div> */}
              </div>
              <Table data={data} />
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

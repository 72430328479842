import Dashboard from "./components/Dashboard-content/Dashboard";
import "react-tooltip/dist/react-tooltip.css";
import ViewClients from "./components/ViewClients/ViewClients";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import AddClients from "./components/AddClients/AddClients";
import ClientsReports from "./components/ClientsReports/ClientsReports";
import ViewTrainer from "./components/ViewStaffMember/ViewTrainer";
import AddStaffMembers from "./components/AddStaffMembers/AddStaffMembers";
import ManageStaffFines from "./components/ManageStaffFines/ManageStaffFines";
import ManageStaffAdvances from "./components/ManageStaffAdvances/ManageStaffAdvances";
import ManageStaffSalary from "./components/ManageStaffSalary/ManageStaffSalary";
import ViewGymPackages from "./components/GymPackages/ViewGymPackages";
import AddTrainerPackages from "./components/AddTrainerPackages/AddTrainerPackages";
import AssingTrainer from "./components/SellPackage/AssingTrainer";
import ManageExpenses from "./components/AddExpence/ManageExpenses";
import ManageProducts from "./components/ManageProducts/ManageProducts";
import AddnewProduct from "./components/AddNewProducts/AddnewProduct";
import ReportsAll from "./components/ReportsAll/ReportsAll";
import SendSms from "./components/SendSms/SendSms";
import ClientAssesment from "./components/ClientAssesment/ClientAssesment";
import ClientHome from "./components/ClientHome/ClientHome";
import StaffHome from "./components/StaffHome/StaffHome";
import ManageBranches from "./components/ManageBranches/ManageBranches";
import ClientPurchases from "./components/ClientPurchases/ClientPurchases";
import StaffAttendance from "./components/StaffAttendance/StaffAttendance";
import ManageTowels from "./components/ManageTowels/ManageTowels";
import SalesHistoryreports from "./components/SalesHistoryReports/SalesHistoryreports";
import AddNewGroupSession from "./components/AddNewGroupSession/AddNewGroupSession";
import ViewCardsHistory from "./components/ViewCardsHsitory/ViewCardsHistory";
import AddGroupTime from "./components/AddGroupTime/AddGroupTime";
import AddNutritionPackage from "./components/AddNutritionPackage/AddNutritionPackage";
import SearchClient from "./components/SearchClient/SearchClient";
import Capture from "./components/CaptureImage/Capture";
import NewCard from "./components/NewCard/NewCard";
import StaffNewCard from "./components/NewCard/StaffNewCard";
import Login from "./components/Logindemo/Login";
import VostroCard from "./components/VostroCard/VostroCard";
import { useContext, useEffect } from "react";
import { NoteState } from "./context/notes/NoteState";
import jwtDecode from "jwt-decode";
import UpdateBranches from "./components/ManageBranches/UpdateBranches";
import AddMembershipPackage from "./components/MembershipType/AddMembershipPackage";
import ViewClientAssesment from "./components/ClientAssesment/ViewClientAssesment";
import PostAssessment from "./components/ClientAssesment/PostAssessment";
import ManageStaffLones from "./components/StaffLones/ManageStaffLones";
import VisitorCard from "./components/NewCard/VisitorCard";
import CartForPayment from "./components/SellPackage/CartForPayment";
import RecievePayments from "./components/ClientPurchases/RecievePayments";
import ViewAllCards from "./components/ViewCardsHsitory/ViewAllCards";
import AccountDetails from "./components/ClientPurchases/AccountDetails";
import UpdateDates from "./components/ClientPurchases/UpdateDates";
import ClientAttendence from "./components/AttendanceReports/ClientAttendence";
// import SalesReport from "./components/SalesHistoryReports/SalesReport";
import Slip from "./components/Includes/Invoice/Slip";
import ResourceManager from "./components/ResourceManager/ResourceManager";
import UpdateResource from "./components/ResourceManager/UpdateResource";
import SessionAttendance from "./components/SessionAttendance/SessionAttendance";
import SingleClientAttendance from "./components/AttendanceReports/SingleClientAttendance";
import SingleStaffAttendance from "./components/StaffAttendance/SingleStaffAttendance";
import UpdateTrainerAttendance from "./components/SessionAttendance/UpdateTrainerAttendance";
import CardBack from "./components/VostroCard/CardBack";
import StaffCard from "./components/StaffCard/StaffCard";
import PrintVisitorCard from "./components/StaffCard/PrintVisitorCard";
import Renewal from "./components/Renewal/Renewal";
import NotFound from "./components/Includes/NotFound";
import ViewFreezing from "./components/FreezingFee/ViewFreezing";
import ExcelImport from "./components/ExcelImport/ExcelImport";
import UserRole from "./components/UserRole/UserRole";
import UpdateRole from "./components/UserRole/UpdateRole";
import UnAuthorized from "./components/Includes/UnAuthorized";
import Discounts from "./components/Discounts/Discounts";
import GenralPackage from "./components/MembershipType/GenralPackage";
import AddCategories from "./components/Finance/AddCategories";
import AddSubCategories from "./components/Finance/AddSubCategories";
import Cafe from "./components/MembershipType/Cafe";
import OfficeCash from "./components/Finance/OfficeCash";
import LiabilitiesManagement from "./components/Finance/LiabilitiesManagement";
import Assets from "./components/Finance/Assets";
import UpdateAssets from "./components/Finance/UpdateAssets";
import FinanceDashboard from "./components/Finance/FinanceDashboard";
import BalanceSheet from "./components/Finance/BalanceSheet";
import DailySales from "./components/Finance/DailySales";
import DailyOfficeClosing from "./components/Finance/DailyOfficeClosing";
import TaxSettings from "./components/TaxSettings/TaxSettings";
import BankDetails from "./components/Finance/BankDetails";
import ViewExpenses from "./components/AddExpence/ViewExpenses";
import GetOfficeCash from "./components/Finance/GetOfficeCash";
import GetLiabilities from "./components/Finance/GetLiabilities";
import PayLiabilities from "./components/Finance/PayLiabilities";
import SalesReportNew from "./components/SalesHistoryReports/SalesReportNew";
import CafeDashboard from "./components/Cafe-dashboard/CafeDashboard";
import CafeCheckOut from "./components/Cafe-dashboard/CafeCheckOut";
import BankLedger from "./components/Finance/BankLedger";
import GetBankLedger from "./components/Finance/GetBankLedger";
import CafeCart from "./components/Cafe-dashboard/CafeCart";
import PayLoans from "./components/StaffLones/PayLoans";
import SalesReportBootcamp from "./components/SalesHistoryReports/SalesReportBootcamp";
import AddCafe from "./components/Cafe-dashboard/AddCafe";
import UpdateCafeProduct from "./components/Cafe-dashboard/UpdateCafeProduct";
import CafeSlip from "./components/Cafe-dashboard/CafeSlip";
import CafeSales from "./components/Cafe-dashboard/CafeSales";
import CFT from "./components/MembershipType/CFT";
import Sidebar from "./components/Includes/Sidebar/Sidebar";
import Navbar from "./components/Includes/Navbar/Navbar";
import PettyCash from "./components/Finance/PettyCash";
import GetPettyCash from "./components/Finance/GetPettyCash";
import KneeLedger from "./components/Finance/KneeLedger";
import BranchLedger from "./components/Finance/BranchLedger";
import GetBranchLedger from "./components/Finance/GetBranchLedger";
import KeeneLedger from "./components/Finance/KeeneLedger";
import Approvals from "./components/Approvals/Approvals";
import FitnessPlan from "./components/Fitness/FitnessPlan";
import PersonalTrainer from "./components/Fitness/PersonalTrainer";
import SlotManage from "./components/Fitness/SlotManage";
import CafeCategory from "./components/Cafe-dashboard/CafeCategory";
import LesMills from "./components/LesMills/LesMills";
import TransactionReportNew from "./components/SalesHistoryReports/TransactionReportNew";
import AssignTimeSlots from "./components/Fitness/AssignTimeSlots";
import CafeSalesReport from "./components/SalesHistoryReports/CafeSalesReport";
// import CafeInvoice from "./components/Cafe-dashboard/CafeInvoice";
import CafeDeposit from "./components/MembershipType/CafeDeposit";
import DepositClientBalance from "./components/MembershipType/DepositClientBalance";
import Deposit from "./components/MembershipType/Deposit";
import DepositsHistory from "./components/MembershipType/DepositsHistory";
import CafeDepositCheckOut from "./components/MembershipType/CafeDepositCheckOut";
import ViewDepositBalance from "./components/MembershipType/ViewDepositBalance";
import AddPhysiotherapy from "./components/MembershipType/AddPhysiotherapy";
import CafeDepositSlip from "./components/Includes/Invoice/CafeDepositSlip";
import NewCafeReport from "./components/SalesHistoryReports/NewCafeReport";
import AddCafeCtegory from "./components/MembershipType/AddCafeCtegory";
import ManagmentIncoive from "./components/Cafe-dashboard/ManagmentIncoive";
import ManagementPendings from "./components/SalesHistoryReports/ManagementPendings";
import RecievePendings from "./components/SalesHistoryReports/RecievePendings";
import AddModule from "./components/Permissions/AddModule";
import LiabilitiesLedger from "./components/Finance/LiabilitiesLedger";
import AddLiabilitiesLedger from "./components/Finance/AddLiabilitiesLedger";
import AddMealPlan from "./components/Fitness/MealPlans/AddMealPlan";
import NutritionAssessments from "./components/Fitness/Assessment/NutritionAssessments";
import ViewNutritionAssessments from "./components/Fitness/Assessment/ViewNutritionAssessments";
import EditNutritionAssessments from "./components/Fitness/Assessment/EditNutritionAssessments";
import EditMealPlan from "./components/Fitness/MealPlans/EditMealPlan";
import ViewMealPlans from "./components/Fitness/MealPlans/ViewMealPlans";
import AddMealPlanBK from "./components/Fitness/MealPlans/AddMealPlanBK";
import ViewMealPlansDetail from "./components/Fitness/MealPlans/ViewMealPlansDetail";
import TrainerClient from "./components/TrainerClient/TrainerClient";

import TrainerDairy from "./components/Fitness/TrainerDairy";
import AssignSlotToClient from "./components/Fitness/AssignSlotToClient";
import ViewClientsPkgs from "./components/Fitness/ViewClientsPkgs";
import AssignTimeToClient from "./components/Fitness/AssignTimeToClient";
import ViewClientPreAssesmentList from "./components/ClientAssesment/ViewClientPreAssesmentList";
import ViewClientPostAssesmentList from "./components/ClientAssesment/ViewClientPostAssesmentList";
import NewPtBookings from "./components/Fitness/NewPtBookings";
import TrainerAppointments from "./components/Fitness/TrainerAppointments";
import AssignDaysToSlots from "./components/Fitness/AssignDaysToSlots";
import PTSessionAttendance from "./components/Fitness/PTSessionAttendance";
import PTReport from "./components/Fitness/PTReport";
import PTSessionAttendanceReport from "./components/Fitness/PTSessionAttendanceReport";
import AddPostAssmnt from "./components/ClientAssesment/AddPostAssmnt";
import AddPreAssmnt from "./components/ClientAssesment/AddPreAssmnt";
import ViewClientAssesmentInfo from "./components/ClientAssesment/ViewClientAssesmentInfo";
import ClientInduction from "./components/ClientAssesment/ClientInduction";
import ClientAssmentCompre from "./components/ClientAssesment/ClientAssmentCompre";
import FitnessTracker from "./components/Fitness/FitnessTracker";
import ManageExercises from "./components/Fitness/Exercises/ManageExercises";
import PlanListing from "./components/Fitness/FitnessPlans/PlanListing";
import DetailPlan from "./components/Fitness/FitnessPlans/DetailPlan";
import AddFitnessPlan from "./components/Fitness/FitnessPlans/AddFitnessPlan";
import UpdateFitnessPlan from "./components/Fitness/FitnessPlans/UpdateFitnessPlan";
import SwitchTimeForClient from "./components/Fitness/SwitchTimeForClient";
import NewAddFitnessPlan from "./components/Fitness/NewFitnessPlan/NewAddFitnessPlan";
import CreatePlan from "./components/Fitness/NewFitnessPlan/CreatePlan";
import AddGfxPackage from "./components/AddGfxPackage/AddGfxPackage";
import GxClasses from "./components/Fitness/GxClasses";
import GxClassesAttendence from "./components/Fitness/GxClassesAttendence";
import GxAttendenceReport from "./components/Fitness/GxAttendenceReport";
import GXAppointments from "./components/Fitness/GXAppointments";
import AddBefitAssessments from "./components/AddBefitAssessments/AddBefitAssessments";
import ViewClientsBefits from "./components/Fitness/ViewClientsBefits";
import AddGfxClasses from "./components/AddGfxClasses/AddGfxClasses";
import AddGfxTrainers from "./components/AddGfxTrainers/AddGfxTrainers";
import NewGXBookings from "./components/Fitness/NewGXBookings";
import AddClassesInGxSlots from "./components/AddClassesInGxSlots/AddClassesInGxSlots";
import NewBefitBookings from "./components/Fitness/NewBefitBookings";
import BefitAppointments from "./components/Fitness/BefitAppointments";
import BefitSessionAttendance from "./components/Fitness/BefitSessionAttendance";
import BefitSessionAttendanceReport from "./components/Fitness/BefitSessionAttendanceReport";
import UpdateBefitAttendance from "./components/Fitness/UpdateBefitAttendance";
import ViewClientsSPT from "./components/Fitness/ViewClientsSPT";
import SPTAppointments from "./components/Fitness/SPTAppointments";
import NewSPTBookings from "./components/Fitness/NewSPTBookings";
import SPTAttendence from "./components/Fitness/SPTAttendence";
import SPTAttendenceReport from "./components/Fitness/SPTAttendenceReport";
import StaffProfile from "./components/StaffHome/StaffProfile";
import Employeeattendance from "./components/StaffAttendance/Employeeattendance";
import NeewSPTBookings from "./components/Fitness/NeewSPTBookings";
import StaffDeutyHours from "./components/StaffAttendance/StaffDeutyHours";
import StaffPromotion from "./components/ManageStaffSalary/StaffPromotion";
import StaffSalary from "./components/ManageStaffSalary/StaffSalary";
import StaffCommision from "./components/StaffCommision/StaffCommision";
import LeaveQuota from "./components/ManageStaffSalary/LeaveQuota";
import LeaveApplication from "./components/StaffAttendance/LeaveApplication";
import SalarySlip from "./components/ManageStaffSalary/SalarySlip";
import LetterManagement from "./components/LettersManagement/LetterManagement";
import CertificateOfEmployment from "./components/LettersManagement/CertificateOfEmployement";
import AppointmentLetter from "./components/LettersManagement/AppointmentLetter";
import ConfirmationLetter from "./components/LettersManagement/ConformationLetter";
import WarningLetter from "./components/LettersManagement/WarningLetter";
import OfferLetter from "./components/LettersManagement/OfferLetter";
import BankAccountLetter from "./components/LettersManagement/BankAccountLetter";
import ExitInterviewForm from "./components/LettersManagement/ExitInterviewForm";
import EmployeClearanceForm from "./components/LettersManagement/EmployeClearanceForm";
// import InvoiceCafee from "./components/Cafe-dashboard/InvoiceCafee";

function App() {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { accessToken, role } = state;
  const locate = useLocation();
  const navigate = useNavigate();
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // if (!branch_id) {
    //   ctxDispatch({
    //     type: "LOGOUT",
    //   });

    //   navigate("/");
    // } else
    if (accessToken) {
      var decoded = jwtDecode(accessToken);
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        ctxDispatch({
          type: "LOGOUT",
        });

        navigate("/");
      } else if (locate.pathname === "/") {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, [locate.pathname]);

  return (
    <>
      {locate.pathname !== "/" && role !== "7" && accessToken ? (
        <>
          <Sidebar /> <Navbar />
        </>
      ) : null}
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <Dashboard />
            ) : role === "7" ? (
              <Navigate to="/cafe-dashboard" />
            ) : role === "12" ? (
              <Navigate to="/employee-attendance" />
            ): role === "9" || role === "11" ? (
              <Navigate to="/trainer-diary" />
            ) : role === "10" ? (
              <Navigate to="/view-nutrition-assessments" />
            ) : (
              <Navigate to="/finance-dashboard" />
            )
          }
        />
        <Route
          path="/view-clients"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <ViewClients />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-clients"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddClients />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/clients-reports"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <ClientsReports />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-staff"
          element={
            role === "3" || role === "1" || role === "4" || role === "12" ? (
              <ViewTrainer />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-staff"
          element={
            role === "3" || role === "1" || role === "4" || role === "12" ? (
              <AddStaffMembers />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-staff-promotion"
          element={
            role === "3" || role === "1" || role === "4" || role === "12" ? (
              <StaffPromotion />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/letter-managemnt"
          element={
            role === "3" || role === "1" || role === "12" ? (
              <LetterManagement />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/certificate-of-employment/:id"
          element={
            role === "3" || role === "1" ? (
              <CertificateOfEmployment />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
         <Route
          path="/appointment-letter/:id"
          element={
            role === "3" || role === "1" ? (
              <AppointmentLetter />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
        path="/confirmation-letter/:id"
        element={
          role === "3" || role === "1" ? (
            <ConfirmationLetter />
          ) : (
            <Navigate to="/dashboard" />
          )
        }
      />
       <Route
        path="/warning-letter/:id"
        element={
          role === "3" || role === "1" ? (
            <WarningLetter />
          ) : (
            <Navigate to="/dashboard" />
          )
        }
      />
       <Route
        path="/exit-interview-form/:id"
        element={
          role === "3" || role === "1" ? (
            <ExitInterviewForm />
          ) : (
            <Navigate to="/dashboard" />
          )
        }
      />
        <Route
        path="/employee-clearance-form/:id"
        element={
          role === "3" || role === "1" ? (
            <EmployeClearanceForm />
          ) : (
            <Navigate to="/dashboard" />
          )
        }
      />
        <Route
          path="/offer-letter/:id"
          element={
            role === "3" || role === "1" ? (
              <OfferLetter />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/account-opening-letter/:id"
          element={
            role === "3" || role === "1" ? (
              <BankAccountLetter />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/leave-quota"
          element={
            role === "3" || role === "1" || role === "4" || role === "12" ? (
              <LeaveQuota />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/staff-fines"
          element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <ManageStaffFines />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/staff-advances"
          element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <ManageStaffAdvances />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/staff-salary"
          element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <StaffSalary />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/staff-commission"
          element={
            role === "3" || role === "1" || role === "4" || role === "12"|| role === "11"  ? (
              <StaffCommision />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/staff-attendance"
          element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <StaffAttendance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/staff-duty-hours"
          element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <StaffDeutyHours />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/leave-application"
          element={
            role === "3" ||
            role === "1" ||
            role === "4" ||
            role === "9" ||
            role === "11" || role === "12" ? (
              <LeaveApplication />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/employee-attendance"
          element={
            role === "3" || role === "1" || role === "4" || role === "12" ? (
              <Employeeattendance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route exact path="/get_pending/:id" element={<RecievePayments />} />
        <Route
          path="/gym-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <ViewGymPackages />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/trainer-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddTrainerPackages />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-freezing"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <ViewFreezing />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/sell-package/:id/:type"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AssingTrainer />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/deposit/:id/:type"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <Deposit />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/check_out/:id/:type"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <CartForPayment />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe_check_out/:id/:type"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <CafeDepositCheckOut />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-expenses"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <ManageExpenses />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-expenses"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <ViewExpenses />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route exact path="/manage-products" element={<ManageProducts />} />
        <Route exact path="/add-new-product" element={<AddnewProduct />} />
        <Route exact path="/report" element={<ReportsAll />} />
        <Route exact path="/send-sms" element={<SendSms />} />
        <Route exact path="/preAssessment/:id" element={<ClientAssesment />} />
        <Route
          exact
          path="/preAssessment"
          element={<ViewClientPreAssesmentList />}
        />
        <Route
          exact
          path="/client-induction/:id"
          element={<ClientAssmentCompre />}
        />
        <Route
          exact
          path="/client-assessment/:id"
          element={<ClientInduction />}
        />
        <Route
          exact
          path="/viewAssessment/:id"
          element={<ViewClientAssesment />}
        />
        <Route
          exact
          path="/viewAssessmentInfo/:id"
          element={<ViewClientAssesmentInfo />}
        />
        <Route
          exact
          path="/postAssessment"
          element={<ViewClientPostAssesmentList />}
        />
        {/*//? ..... */}
        <Route exact path="/add-pre-assessment" element={<AddPreAssmnt />} />
        <Route exact path="/add-post-assessment" element={<AddPostAssmnt />} />
        {/*//? ..... */}
        <Route exact path="/postAssessment/:id" element={<PostAssessment />} />
        <Route exact path="/client-home/:id" element={<ClientHome />} />
        <Route
          exact
          path="/client-purchases/:id"
          element={<ClientPurchases />}
        />
        <Route exact path="/account-details/:id" element={<AccountDetails />} />
        <Route
          path="/invoice/:type/:id"
          element={
            role === "3" ||
            role === "1" ||
            role === "4" ||
            role === "9" ||
            role === "10" ? (
              <Slip />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/deposit_invoice/:type/:id"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <CafeDepositSlip />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-invoice/:type/:id"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <CafeSlip />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-slip/:type/:id"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <ManagmentIncoive />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route exact path="/update-dates/:id" element={<UpdateDates />} />
        {/* <Route exact path="/staff-profile/:id" element={<StaffHome />} /> */}
        <Route exact path="/staff-profile/:id" element={<StaffProfile />} />
        <Route exact path="/manage-branches" element={<ManageBranches />} />
        <Route exact path="/client-purchases" element={<ClientPurchases />} />
        <Route
          path="/manage-towels"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <ManageTowels />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route exact path="/manage-sales" element={<Renewal />} />
        <Route
          path="/sales-by-services"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <SalesHistoryreports />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-sales"
          element={
            role === "3" || role === "1" || "7" ? (
              <CafeSales />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/sales-by-bootcamp"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <SalesReportBootcamp />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-sales-report"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <CafeSalesReport />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/new-cafe-report"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <NewCafeReport />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/management-pendings"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <ManagementPendings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/receive-pendings"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <RecievePendings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/sales-report"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <SalesReportNew />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/transactions-report"
          element={
            role === "3" || role === "1" || role === "4" || role === "5" ? (
              <TransactionReportNew />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        {/* <Route
          exact
          path="/transactions-report"
          element={<TransactionReport />}
        /> */}
        <Route
          path="/attendance-report"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <ClientAttendence />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-bootcamp-calsses"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddNewGroupSession />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/assign-cards"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <ViewCardsHistory />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-group"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddGroupTime />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-nutrition-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddNutritionPackage />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-gx-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddGfxPackage />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/search-client"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <SearchClient />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route exact path="/webcam" element={<Capture />} />
        <Route
          path="/new-card/:id/:type"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <NewCard />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-cards"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <ViewAllCards />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/assign_staff_card/:id/:type"
          element={<StaffNewCard />}
        />
        <Route exact path="/visitor-card/:id/:type" element={<VisitorCard />} />
        <Route
          path="/membership-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddMembershipPackage />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="physiotherapy-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddPhysiotherapy />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/lesmills"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <LesMills />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cft-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <CFT />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route exact path="/staff-loans" element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <ManageStaffLones />
            ) : (
              <Navigate to="/dashboard" />
            )
          } />
        <Route exact path="/update-branches/:id" element={<UpdateBranches />} />
        <Route exact path="/resource-manager" element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <ResourceManager />
            ) : (
              <Navigate to="/dashboard" />
            )
          }/>
        <Route exact path="/update-resource/:id" element={<UpdateResource />} />
        <Route exact path="/pt-attendance" element={
            role === "3" || role === "1" || role === "4" || role === "12"  ? (
              <SessionAttendance />
            ) : (
              <Navigate to="/dashboard" />
            )
          } />
        <Route
          exact
          path="/client-attendance/:id"
          element={<SingleClientAttendance />}
        />
        <Route
          exact
          path="/staff-attendance-report/:id"
          element={<SingleStaffAttendance />}
        />
        <Route
          exact
          path="/update-pt-attendance/:id"
          element={<UpdateTrainerAttendance />}
        />
        <Route exact path="/print-client-card/:id" element={<VostroCard />} />
        <Route exact path="/print-staff-card/:id" element={<StaffCard />} />
        <Route exact path="/salary-slip/:id" element={<SalarySlip />} />
        <Route exact path="/update-role/:id" element={<UpdateRole />} />
        <Route exact path="/card-back" element={<CardBack />} />
        <Route
          exact
          path="/print-visitor-card"
          element={<PrintVisitorCard />}
        />
        <Route exact path="/excel-import" element={<ExcelImport />} />
        <Route exact path="/user-roles" element={<UserRole />} />
        <Route exact path="/manage-discounts" element={<Discounts />} />
        <Route exact path="*" element={<NotFound />} />
        <Route exact path="/unauthorized" element={<UnAuthorized />} />
        <Route
          path="/genral-package"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <GenralPackage />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/manage-categories"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <AddCategories />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/sub-categories"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <AddSubCategories />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/balance-sheet"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <BalanceSheet />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-dashboard"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <CafeDashboard />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-category"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <CafeCategory />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-cart"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <CafeCart />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe_checkout"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <CafeCheckOut />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/cafe-deposit"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <CafeDeposit />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/deposit-client-balance"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <DepositClientBalance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/clients-available-balance"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <ViewDepositBalance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/deposit-history"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <DepositsHistory />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-cafe-category"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <AddCafeCtegory />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route exact path="/cafe" element={<Cafe />} />
        <Route
          path="/add-cafe-product"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <AddCafe />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/update-cafe-product/:id"
          element={
            role === "3" || role === "1" || role === "7" ? (
              <UpdateCafeProduct />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-office-cash"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <OfficeCash />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-cash-ledger"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <GetOfficeCash />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-keene"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <KeeneLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/keene-ledger"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <KneeLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/branch-cash-ledger"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <GetBranchLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-bank-cash"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <BankLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-petty-cash"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <PettyCash />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-branch-cash"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <BranchLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/petty-cash-ledger"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <GetPettyCash />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/bank-ledger"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <GetBankLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/liabilities-management"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <LiabilitiesManagement />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-liabilities"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <GetLiabilities />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/liabilities-ledger"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <LiabilitiesLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-liabilities-ledger"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <AddLiabilitiesLedger />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/pay-liabilities/:id"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <PayLiabilities />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/pay-loan/:id"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <PayLoans />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/manage-assets"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <Assets />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/update-assets/:id"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <UpdateAssets />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/finance-dashboard"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <FinanceDashboard />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/daily-sales-counter"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <DailySales />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/daily-office-closing"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <DailyOfficeClosing />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/settings"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <TaxSettings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/bank-details"
          element={
            role === "3" || role === "1" || role === "5" ? (
              <BankDetails />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/approval"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <Approvals />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        {/* <Route
          path="/fitness-plan"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <FitnessPlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        /> */}
        <Route
          path="/time-slot"
          element={
            role === "3" || role === "1" || role === "11" ? (
              <SlotManage />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/assign-time-slot"
          element={
            role === "3" || role === "1" || role === "11" ? (
              <AssignTimeSlots />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-module"
          element={
            role === "3" || role === "1" || role === "4" ? (
              <AddModule />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/personal-trainer"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <PersonalTrainer />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/pt-sales-report"
          element={
            role === "3" || role === "1" || role === "11" ? (
              <PTReport />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/trainer-diary"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <TrainerDairy />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/assign-slot/:trainer_id/:time_id/:assigned_id"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <AssignSlotToClient />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/session-attendance"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <PTSessionAttendance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/session-attendance-report"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <PTSessionAttendanceReport />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/update-session-attendance/:id"
          element={
            role === "3" || role === "1" || role === "11" ? (
              <UpdateTrainerAttendance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-meal-plan"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <AddMealPlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-meal-plan-old"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <AddMealPlanBK />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-meal-plan"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <ViewMealPlans />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/detail-meal-plan/:id"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <ViewMealPlansDetail />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/edit-meal-plan/:id"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <EditMealPlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/nutrition-assessments"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <NutritionAssessments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/view-nutrition-assessments"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <ViewNutritionAssessments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/edit-nutrition-assessments/:id"
          element={
            role === "3" || role === "1" || role === "10" || role === "11" ? (
              <EditNutritionAssessments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/trainer-client/:id"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <TrainerClient />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/new-pt-packages"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <ViewClientsPkgs />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/assign-time/:client_id/:trainer_id"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <AssignTimeToClient />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/switch-time"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <SwitchTimeForClient />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/new-pt-bookings"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <NewPtBookings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/assign-days/:id/:tim_id/:start/:type"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <AssignDaysToSlots />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/trainer-appointments"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <TrainerAppointments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/fitness-tracker"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <FitnessTracker />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/manage-exercises"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <ManageExercises />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/fitness-plan"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <PlanListing />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/fitness-plan-detail/:id"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <DetailPlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-fitness-plan"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <CreatePlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-fitness-plan/:plan_id"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <NewAddFitnessPlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-fitness-plan-old"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <AddFitnessPlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/update-fitness-plan/:id"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <UpdateFitnessPlan />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/gx-classes"
          element={
            role === "3" ||
            role === "1" ||
            role === "11" ||
            role === "9" ||
            role === "10" ? (
              <GxClasses />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/gx-classes-attendance"
          element={
            role === "3" ||
            role === "1" ||
            role === "11" ||
            role === "9" ||
            role === "10" ? (
              <GxClassesAttendence />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/gx-attendance-report"
          element={
            role === "3" ||
            role === "1" ||
            role === "9" ||
            role === "11" ||
            role === "10" ? (
              <GxAttendenceReport />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-gx-slots"
          element={
            role === "3" ||
            role === "1" ||
            role === "4" ||
            role === "9" ||
            role === "11" ||
            role === "10" ? (
              <AddGfxClasses />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/add-gx-classes"
          element={
            role === "3" ||
            role === "1" ||
            role === "4" ||
            role === "9" ||
            role === "10" ||
            role === "11" ? (
              <AddClassesInGxSlots />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/gx-appointments"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <GXAppointments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/gx-trainers"
          element={
            role === "3" ||
            role === "1" ||
            role === "9" ||
            role === "11" ||
            role === "10" ? (
              <AddGfxTrainers />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/befit-assessments/:id/:client_id"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <AddBefitAssessments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/befit-list"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <ViewClientsBefits />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />

        <Route
          exact
          path="/new-befit-bookings"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <NewBefitBookings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/befit-appointments"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <BefitAppointments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/befit-attendance"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <BefitSessionAttendance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/update-befit-attendance/:id"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <UpdateBefitAttendance />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/befit-attendance-report"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <BefitSessionAttendanceReport />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/new-gx-bookings"
          element={
            role === "3" ||
            role === "1" ||
            role === "9" ||
            role === "11" ||
            role === "10" ? (
              <NewGXBookings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />

        <Route
          exact
          path="/spt-list"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <ViewClientsSPT />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />

        {/* <Route
          exact
          path="/new-spt-bookings"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <NewSPTBookings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        /> */}
        <Route
          exact
          path="/new-spt-bookings"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <NeewSPTBookings />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/spt-appointments"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <SPTAppointments />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/spt-attendance"
          element={
            role === "3" || role === "1" || role === "11" || role === "9" ? (
              <SPTAttendence />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          exact
          path="/spt-attendance-report"
          element={
            role === "3" || role === "1" || role === "9" || role === "11" ? (
              <SPTAttendenceReport />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
      </Routes>
    </>
  );
}

export default App;

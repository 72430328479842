import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import logo from "../../icons/gray.png";
import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { useParams } from "react-router";
import CurrencyFormat from "react-currency-format";

export default function SalarySlip() {
  const [loading, setLoading] = useState(false);
  const { state } = useContext(NoteState);
  const { branch_id, role, UId } = state;
  const { id } = useParams();
  const [department, setDepartment] = useState("");
  const [name, setName] = useState("");
  const [branch, setBranch] = useState("");
  const [uid, setUid] = useState("");
  const [designation, setDesignation] = useState("");
  const [ptCommission, setPTCommission] = useState("");
  const [gxCommission, setGXCommission] = useState("");
  const [reward, setReward] = useState("");
  const [sptCommission, setSPTCommission] = useState("");
  const [cafe, setCafe] = useState("");
  const [medical, setMedical] = useState("");
  const [salary, setSalary] = useState("");
  const [detections, setDetections] = useState("");
  const [commission, setCommission] = useState("");
  const [advance, setAdvance] = useState("");
  const [fine, setFine] = useState("");
  const [loans, setLoans] = useState("");
  const [joining, setJoining] = useState("");

  // Get the current date
  const currentDate = new Date();

  currentDate.setDate(1);

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  const formattedMonth = month < 10 ? `0${month}` : month;

  const downloadImage = async (blob, fileName) => {
    const div = window.document.createElement("div");
    div.style = "display:none; text-align:center;";
    const myImage = window.document.createElement("img");
    myImage.src = blob;
    myImage.style = "display:block; margin:0 auto;";
    var printMe = await div.appendChild(myImage);
    setLoading(false);
    var print_area = window.open();
    if (print_area && print_area.document) {
      print_area.document.write(div.innerHTML);
      print_area.document.close();
      print_area.focus();
      print_area.print();
      print_area.close();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to open print window",
      });
    }
  };
  const printSlip = async (el, imageFileName) => {
    setLoading(true);

    // Increase the resolution of the canvas by setting a higher scale option
    const canvas = await html2canvas(el, { scale: 3 });

    // Convert to a higher resolution JPEG image format with increased quality
    const image = canvas.toDataURL("image/jpeg", 0.95); // You can adjust the quality (0.0 to 1.0) as needed

    downloadImage(image, imageFileName);
  };

  const getSalary = async () => {
    setLoading(true);
    try {
      const responce = await UserServices.getLatestStaffSalarySlip(
        branch_id,
        formattedMonth,
        year,
        id
      );
      if (responce.status === 200) {
        let data = responce.data.data;
        data.map((value) => {
          setName(value.name);
          setDepartment(value.department);
          setBranch(value.branch);
          setUid(value.uid);
          setDesignation(value.designation);
          setPTCommission(parseInt(value.commission?.pt_commission));
          setGXCommission(parseInt(value.commission?.gx_commission));
          setReward(value.reward);
          setSPTCommission(parseInt(value.commission?.small_pt_commission));
          setCommission(parseInt(value.commission?.commission));
          setCafe(value.cafe);
          setMedical(value.medical);
          setSalary(value.salary);
          setDetections(value.detections);
          setAdvance(value.advance);
          setFine(value.fine);
          setLoans(value.loan);
          setJoining(value.joining);
          return null;
        });

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.message === "Network Error") {
      } else if (err.response.status === 404) {
        setLoading(false);
        // navigate("/dashboard");
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getSalary();
  }, []);

  let totalNetSalary = 0;
  let totalSalary = 0;

  const calculateFirstMonthSalary = (joiningDate, totalSalary) => {
    const joiningDateObj = new Date(joiningDate);

    if (
      joiningDateObj.getFullYear() === parseInt(year) &&
      joiningDateObj.getMonth() + 1 === parseInt(formattedMonth)
    ) {
      const remainingDays =
        new Date(
          joiningDateObj.getFullYear(),
          joiningDateObj.getMonth() + 1,
          0
        ).getDate() -
        joiningDateObj.getDate() +
        1;
      const dailySalary =
        totalSalary /
        new Date(
          joiningDateObj.getFullYear(),
          joiningDateObj.getMonth() + 1,
          0
        ).getDate();

      const firstMonthSalary = dailySalary * remainingDays;

      return firstMonthSalary.toFixed(0);
    } else if (parseInt(year) > joiningDateObj.getFullYear()) {
      return totalSalary.toFixed(0);
    } else if (
      joiningDateObj.getFullYear() === parseInt(year) &&
      parseInt(formattedMonth) >= joiningDateObj.getMonth() + 1
    ) {
      return totalSalary;
    } else {
      return 0;
    }
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-20">
                Print Invoice
                <button
                  className="btn btn-danger d-inline-block ms-2  me-2 btn-sm"
                  onClick={() => {
                    printSlip(document.getElementById("invoice-POS"), "slip");
                  }}
                >
                  <i className="bi bi-printer-fill"></i>
                </button>
              </h5>
            </div>
            <div className="card-body">
              <div
                className="slip-body"
                id="invoice-POS"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 1in -0.25in",
                  width: "350mm",
                  background: "rgb(255, 255, 255)",
                  margin: " 0px auto",
                }}
              >
                <div
                  style={{
                    height: "220px",
                    clipPath: "polygon(0 0, 100% 0%, 100% 79%, 0 50%)",
                    background: "#394053",
                  }}
                >
                  <div
                    className="slip-head d-flex"
                    style={{
                      padding: "20px 61px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img src={logo} alt="logo" />
                    </div>
                    <div className="text-white ms-auto">
                      <h2>SALARY SLIP</h2>
                    </div>
                  </div>
                </div>
                <div className="first-table">
                  <table style={{ margin: " 0px auto" }}>
                    <tr>
                      <th
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Employee Name
                      </th>
                      <td
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        {name}
                      </td>
                      <th
                        style={{
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Employee ID
                      </th>
                      <td
                        style={{
                          borderLeft: "1px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        {uid}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Department
                      </th>
                      <td
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        {department}
                      </td>
                      <th
                        style={{
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Position
                      </th>
                      <td
                        style={{
                          borderLeft: "1px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        {designation}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Branch
                      </th>
                      <td
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        {branch}
                      </td>
                      <th
                        style={{
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Transfer Mode
                      </th>
                      <td
                        style={{
                          borderLeft: "1px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Period
                      </th>
                      <td
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        -
                      </td>
                      <th
                        style={{
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Gross Salary
                      </th>
                      <td
                        style={{
                          borderLeft: "1px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        -
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Medical Allowance
                      </th>
                      <td
                        style={{
                          borderRight: "2px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        -
                      </td>
                      <th
                        style={{
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        Annual Inc.(if any)
                      </th>
                      <td
                        style={{
                          borderLeft: "1px solid #dddddd",
                          borderBottom: "2px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          width: "300px",
                        }}
                      >
                        N/A
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="second-table" style={{ marginTop: "100px" }}>
                  <table style={{ margin: " 0px 61px" }} className="d-flex">
                    <div>
                      <tr>
                        <th
                          style={{
                            borderRight: "2px solid #dddddd",
                            color: "#fff",
                            background: "#e8002b",
                            textAlign: "left",
                            padding: "8px",
                            width: "350px",
                          }}
                        >
                          BREAK-UP
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "350px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>PT Commission</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              padding: "8px",
                              borderLeft: "1px solid #dddddd",
                            }}
                          >
                            <CurrencyFormat
                              value={ptCommission}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "350px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>GX Commission</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={gxCommission}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "350px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Over Time</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            -
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "350px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Other</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            -
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "350px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Reward</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={reward}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "350px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>SPT Commission</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={sptCommission}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr style={{ background: "#394053", color: "#ffff" }}>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "350px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Total</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={
                                ptCommission +
                                gxCommission +
                                reward +
                                sptCommission
                              }
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                    </div>
                    <div>
                      <tr>
                        <th
                          style={{
                            borderRight: "2px solid #dddddd",
                            color: "#fff",
                            background: "#e8002b",
                            textAlign: "left",
                            padding: "8px",
                            width: "425px",
                          }}
                        >
                          DEDUCTION
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Cafe</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              padding: "8px",
                              borderLeft: "1px solid #dddddd",
                            }}
                          >
                            <CurrencyFormat
                              value={cafe}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Others</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              padding: "8px",
                              borderLeft: "1px solid #dddddd",
                            }}
                          >
                            -
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>PT Charge</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            -
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Medical</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={medical}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Facility Usgae</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            -
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Tax Withheld</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            -
                          </div>
                        </th>
                      </tr>
                      <tr style={{ background: "#394053", color: "#ffff" }}>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Total</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={cafe + medical}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                    </div>
                    <div>
                      <tr>
                        <th
                          style={{
                            borderRight: "2px solid #dddddd",
                            color: "#fff",
                            background: "#e8002b",
                            textAlign: "left",
                            padding: "8px",
                            width: "425px",
                          }}
                        >
                          SUMMARY
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Total salary</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              padding: "8px",
                              borderLeft: "1px solid #dddddd",
                            }}
                          >
                            <CurrencyFormat
                              value={salary}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Other</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            -
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Deductions</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={detections}
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            minHeight: "41px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}> </div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          ></div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                            minHeight: "41px",
                          }}
                        >
                          <div style={{ padding: "8px" }}> </div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          ></div>
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            borderLeft: "0",
                            width: "425px",
                            display: "flex",
                            minHeight: "41px",
                          }}
                        >
                          <div style={{ padding: "8px" }}> </div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              borderLeft: "1px solid #dddddd",
                              padding: "8px",
                            }}
                          ></div>
                        </th>
                      </tr>
                      <tr style={{ background: "#394053", color: "#ffff" }}>
                        <th
                          style={{
                            border: "1px solid #dddddd",
                            width: "425px",
                            display: "flex",
                          }}
                        >
                          <div style={{ padding: "8px" }}>Total</div>
                          <div
                            className="ms-auto"
                            style={{
                              minWidth: " 100px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: " center",
                              padding: "8px",
                            }}
                          >
                            <CurrencyFormat
                              value={
                                parseInt(
                                  calculateFirstMonthSalary(joining, salary)
                                ) +
                                commission +
                                reward -
                                advance -
                                fine -
                                cafe -
                                detections -
                                loans -
                                medical
                              }
                              displayType={"text"}
                              thousandSpacing={"2s"}
                              thousandSeparator={true}
                              prefix={"Rs "}
                              suffix={"/-"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </div>
                        </th>
                      </tr>
                    </div>
                  </table>
                </div>
                <div className="third-section" style={{ marginTop: "100px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        background: "#edf2f5",
                        padding: "10px",
                        fontWeight: "700",
                        borderRadius: "5px",
                      }}
                    >
                      *This is a system generated slip and does't require a sign
                      and stemp.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "100px",
                    height: "50px",
                    background: "#394053",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="text-white"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    wwww.vostroworld.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

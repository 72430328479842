import React, { useState, useEffect, useContext } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useNavigate, useParams } from "react-router";
import { NoteState } from "../../context/notes/NoteState";
import { useMask } from "react-mask-field/dist";
import UserServices from "../../UserServices/UserServices";
import { useFormik } from "formik";
import { staffhome } from "../schemas";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import Webcam from "react-webcam";
import $ from "jquery";
import { MdEdit } from "react-icons/md";
import StaffProfileFine from "./StaffProfileFine";
import StaffProfileReward from "./StaffProfileReward";
import SatffProfileSalary from "./SatffProfileSalary";
import defaultImage from "../../../src/icons/default.jpg";
import StaffProfilInfo from "./StaffProfilInfo";
import StaffProfileLeaves from "./StaffProfileLeaves";
import { Link } from "react-router-dom";

export default function StaffProfile() {
  const { state, dispatch: ctxDispatch } = useContext(NoteState);
  const { branch_id } = state;

  const navigate = useNavigate();
  const [phoneError, setPhoneError] = useState("");
  const [picture, setPicture] = useState("");
  const [cnicError, setCnicError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pkgUp, setPkgUp] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [emergencyContactNo, setEmergencyContactNo] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [emailError, setEmailError] = useState("");

  const [gender, setGender] = useState("");
  const [employeStatus, setEmployeStatus] = useState("");
  const [date, setDate] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [myDob, setMyDob] = useState("");
  const [employmentEnd, setEmploymentEnd] = useState("");

  const [salary, setSalary] = useState("");
  const [medical, setMedical] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [cnic, setCnic] = useState("");
  const [commission, setCommission] = useState("");
  const [probation, setProbation] = useState("");
  const [uid, setUid] = useState("0");
  const [branchId, setBranchId] = useState("");
  const [prfoImg, setPrfoImg] = useState("");
  const [file, setFile] = useState("");
  const [staffCatagry, setStaffCatagry] = useState([]);
  const [trainingCat, setTrainingCat] = useState([]);
  const [myDepartment, setMyDepartment] = useState([]);
  const [myDesignation, setMyDesignation] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [myData, setMyData] = useState([]);

  const { id } = useParams();
  const formData = new FormData();

  const [showFineComponent, setShowFineComponent] = useState(false);
  const [showRewardComponent, setShowRewardComponent] = useState(false);
  const [showSalaryComponent, setShowSalaryComponent] = useState(false);
  const [showInfoComponent, setShowInfoComponent] = useState(false);
  const [showLeaveComponent, setShowLeaveComponent] = useState(false);
  const [activeButton, setActiveButton] = useState("generalInfo");

  const handleInfoButtonClick = () => {
    setShowInfoComponent(true);
    setShowFineComponent(false);
    setShowRewardComponent(false);
    setShowSalaryComponent(false);
    setShowLeaveComponent(false);
    setActiveButton("info");
  };
  const handleFineButtonClick = () => {
    setShowFineComponent(true);
    setShowRewardComponent(false);
    setShowSalaryComponent(false);
    setShowInfoComponent(false);
    setShowLeaveComponent(false);
    setActiveButton("fines");
  };

  const handleRewardButtonClick = () => {
    setShowFineComponent(false);
    setShowRewardComponent(true);
    setShowSalaryComponent(false);
    setShowLeaveComponent(false);
    setShowInfoComponent(false);
    setActiveButton("rewards");
  };

  const handleSalaryButtonClick = () => {
    setShowFineComponent(false);
    setShowRewardComponent(false);
    setShowLeaveComponent(false);
    setShowInfoComponent(false);
    setShowSalaryComponent(true);
    setActiveButton("salary");
  };
  const handleLeaveButtonClick = () => {
    setShowFineComponent(false);
    setShowRewardComponent(false);
    setShowLeaveComponent(true);
    setShowInfoComponent(false);
    setShowSalaryComponent(false);
    setActiveButton("leave");
  };
  const handleGeneralInfoButtonClick = () => {
    setShowFineComponent(false);
    setShowRewardComponent(false);
    setShowInfoComponent(false);
    setShowLeaveComponent(false);
    setShowSalaryComponent(false);
    setActiveButton("generalInfo");
  };

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getStaffProfileInfo(id);
        if (responce.status === 200) {
          let data = responce.data.data;
          data.map((value) => {
            setFname(value.first_name);
            setLname(value.last_name);
            setFatherName(value.father_name);
            setPhone(value.phone);
            setEmergencyContactNo(value.emergency_contact_no);
            setBloodGroup(value.blood_group);
            setemail(value.email);
            setDate(value.joining);
            setEmploymentEnd(value.employment_end_date);
            setAppointmentDate(value.appointment_date);
            setMyDob(value.dob);
            setSalary(value.salary);
            setMedical(value.monthly_medical);
            setAddress(value.address);
            setTrainingCat(value.training_categories);
            setSelectedDepartment(value.department_id);
            setSelectedDesignation(value.designation_id);
            setCommission(value.commission);
            setProbation(value.probation_duration);
            setCity(value.city);
            setGender(value.gender);
            setEmployeStatus(value.employment_status);
            setStartTime(value.start_time);
            setEndTime(value.end_time);
            setCnic(value.cnic);
            setUid(value.uid);
            setBranchId(value.branch_id);
            setPrfoImg(value.image);
            return null;
          });

          department(data[0].branch_id);
          designation(data[0].branch_id);

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err.message === "Network Error") {
        } else if (err.response.status === 404) {
          setLoading(false);
          // navigate("/dashboard");
        } else {
          setLoading(false);
        }
      }
    };

    const department = async () => {
      try {
        const responce = await UserServices.departmentName();
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyDepartment([{ name: "Select Department", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
        setMyDepartment([]);
      }
    };

    const designation = async () => {
      try {
        const responce = await UserServices.designationName();
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });
        setMyDesignation([{ name: "Select Designation", id: "" }, ...results]);
      } catch (err) {
        console.log(err);
        setMyDesignation([]);
      }
    };
    const generateReport = async () => {
      setLoading(true);
      try {
        const responce = await UserServices.getFilterActiveEmployeHours(
          branch_id,
          id,
          "999999",
          "1"
        );

        let myDataarr;

        if (responce.status === 200) {
          myDataarr = responce.data.data.data;
          const res = myDataarr;
          const results = [];
          const daysOrder = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ];
          const sortDutyHoursByDay = (dutyHours) => {
            return dutyHours.sort((a, b) => {
              return (
                daysOrder.indexOf(a.day.trim()) -
                daysOrder.indexOf(b.day.trim())
              );
            });
          };
          res.map((values) => {
            results.push({
              id: values.id,
              branch_name: values.branch_name,
              day: values.day,
              name: values.name,
              uid: values.uid,
              duty_hours: sortDutyHoursByDay(values.duty_hours),
            });
            return null;
          });

          setMyData([...results]);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 401) {
          setMyData([]);
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Request time Out!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "LogIn Again!",
          });
        } else if (err.response.status === 404) {
          setMyData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    generateReport();
    getUsers();
  }, []);

  const phone_number = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const emergency_contact_no = useMask({
    mask: "+92 (___)-_______",
    replacement: { _: /\d/ },
  });
  const cnic_format = useMask({
    mask: "_____-_______-_-",
    replacement: { _: /\d/ },
  });
  const videoConstraints = {
    width: 450,
    height: 400,
    facingMode: "user",
  };
  const verifyPhone = async (e) => {
    var check_Phone = e.target.value;
    const phNum = check_Phone
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");
    try {
      const response = await UserServices.checkStaffPhone(phNum, id);
      if (response.status === 200) {
        setPhoneError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setPhoneError("Phone number already exists");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };
  const verifyCnic = async (e) => {
    var check_Cnic = e.target.value;
    try {
      const response = await UserServices.checkStaffCnic(check_Cnic, id);
      if (response.status === 200) {
        setCnicError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setCnicError("CNIC already exists");
      } else if (err.response.status === 401) {
        ctxDispatch({
          type: "LOGOUT",
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time out!",
        });
        navigate("/");
      }
    }
  };
  const UpdateProfile = async (values, catType) => {
    if (file !== "") {
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("image_upload_from", "gallery");
    } else if (picture !== "") {
      formData.append("file", picture);
      formData.append("image_upload_from", "webcam");
    }

    const phNum = values.phone
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");
      const ephNum = values.emergency_contact_no
      .replace(/-/g, "")
      .replace(/[\])}[{(]/g, "")
      .replace(/ /g, "");
    formData.append("first_name", values.name);
    formData.append("last_name", values.last_name);
    formData.append("father_name", values.father_name);
    formData.append("cnic", values.cnic);
    formData.append("email", values.email);
    formData.append("phone", phNum);
    formData.append("emergency_contact_no", ephNum);
    formData.append("address", values.address);
    formData.append("city", values.city);

    formData.append("gender", values.gender);
    formData.append("employment_status", values.employment_status);
    formData.append("joining", values.joining);
    formData.append("employment_end_date", values.employment_end_date);
    formData.append("appointment_date", values.appointment_date);
    formData.append("dob", values.dob);
    formData.append("salary", values.staffSalary);
    formData.append("designation_id", values.designation);
    formData.append("department_id", values.select_department);
    formData.append("commission", values.commission);
    formData.append("monthly_medical", values.medical);
    formData.append("probation_duration", values.probation_duration);
    formData.append("type", catType);
    formData.append("start_time", values.start);
    formData.append("end_time", values.end);
    formData.append("blood_group", values.blood_group);

    setLoading(true);

    try {
      const response = await UserServices.UpdateStaffProfile(formData, id);
      if (response.status === 204) {
        setLoading(false);

        toast.success("Profile information updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setEditMode(false);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error("Some data is missing", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
  };
  const webcamRef = React.useRef(null);
  const capture = () => {
    setPicture("");
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    var image = document.getElementById("output");
    image.src = pictureSrc;
  };
  const formatTime12Hour = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = (hours % 12 || 12).toString().padStart(2, "0");
    return `${hours12}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };
  const Table = ({ data }) => {
    return (
      <>
        <div className="col-6 mt-3">
          <ul className="d-flex justify-content-between">
            {data.length ? (
              data.map((el, index) => (
                <li key={index}>
                  <span className="">{el.category_info?.name}</span>
                </li>
              ))
            ) : (
              <li>
                <span className="text-center text-capitalize">
                  No Package Avaliable
                </span>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  };
  const STable = ({ data }) => {
    return (
      <>
        {data.length ? (
          data.map((el, index) => (
            <>
              {el.duty_hours.length ? (
                <div className="d-flex">
                  {el.duty_hours.map((inl, index) => (
                    <div key={index} className="ms-3 duty-hours">
                      <div>
                        <h6>{inl.day}</h6>
                        <div className="mt-2">
                          {formatTime12Hour(inl.start_time)} <strong>To</strong>{" "}
                          {formatTime12Hour(inl.end_time)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span className="text-center text-capitalize">
                  No Record Available
                </span>
              )}
            </>
          ))
        ) : (
          <div>
            <div colSpan="14" className="text-center text-capitalize">
              No record found
            </div>
          </div>
        )}
        {/* {data.length ? (
          data.map((el, index) => (
            <>
              {el.duty_hours.length ? (
                <div className="d-flex">
                  {el.duty_hours.map((inl, index) =>
                    index === 0 ? (
                      <div key={index} className="ms-3 duty-hours">
                        <div>
                          <h6>{inl.day}</h6>
                          <div className="mt-2">
                            {formatTime12Hour(inl.start_time)}{" "}
                            <strong>To</strong> {formatTime12Hour(inl.end_time)}
                          </div>
                        </div>
                      </div>
                    )
                     : index + 1 === el.duty_hours.length ? (
                      <div key={index} className="ms-3 duty-hours">
                        <div>
                          <h6>{inl.day}</h6>
                          <div className="mt-2">
                            {formatTime12Hour(inl.start_time)}{" "}
                            <strong>To</strong> {formatTime12Hour(inl.end_time)}
                          </div>
                        </div>
                      </div>
                    ) : el.duty_hours[index - 1].start_time ===
                        inl.start_time &&
                      el.duty_hours[index + 1].start_time === inl.start_time &&
                      el.duty_hours[index - 1].end_time === inl.end_time &&
                      el.duty_hours[index + 1].end_time === inl.end_time ? (
                      ""
                    ) : (
                      <div key={index} className="ms-3 duty-hours">
                        <div>
                          <h6>{inl.day}</h6>
                          <div className="mt-2">
                            {formatTime12Hour(inl.start_time)}{" "}
                            <strong>To</strong> {formatTime12Hour(inl.end_time)}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <span className="text-center text-capitalize">
                  No Record Available
                </span>
              )}
            </>
          ))
        ) : (
          <div>
            <div colSpan="14" className="text-center text-capitalize">
              No record found
            </div>
          </div>
        )} */}
      </>
    );
  };

  const initialValues = {
    name: fname,
    last_name: lname,
    father_name: fatherName,
    cnic: cnic,
    email: email,
    phone: phone,
    emergency_contact_no: emergencyContactNo,
    blood_group: bloodGroup,
    address: address,
    city: city,
    gender: gender,
    employment_status: employeStatus,
    joining: date,
    employment_end_date: employmentEnd,
    appointment_date: appointmentDate,
    dob: myDob,
    staffSalary: salary,
    commission: commission,
    probation_duration: probation,
    medical: medical,
    select_department: selectedDepartment,
    designation: selectedDesignation,
    start: startTime ? startTime : "",
    end: endTime ? endTime : "",
  };

  const staffCatagryGet = async (e) => {
    if (e.target.value === "12" || e.target.value === "13") {
      try {
        const responce = await UserServices.staffcatagries(branch_id);
        const res = responce.data.data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            name: value.name,
          });
          return null;
        });

        setStaffCatagry([...results]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const showPkg = async () => {
    try {
      const responce = await UserServices.staffcatagries(branch_id);
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });

      setStaffCatagry([...results]);
      setPkgUp(true);
    } catch (err) {
      setPkgUp(false);

      console.log(err);
    }
  };

  const emailVerify = async (e) => {
    var email = e.currentTarget.value;
    try {
      const response = await UserServices.checkEmailwId(email, id);
      if (response.status === 200) {
        setEmailError("");
      }
    } catch (err) {
      if (err.response.status === 409) {
        setEmailError("email already exists");
      }
    }
  };
  const handleEditClick = () => {
    setEditMode(true);
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: staffhome,
      onSubmit: (values) => {
        var selectedCat = $("input:checkbox:checked")
          .map(function () {
            return this.value;
          })
          .get()
          .join(",");
        UpdateProfile(values, selectedCat);
      },
    });
  return (
    <div className="wrapper">
      <ToastContainer />
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3 d-flex">
              <div className="d-flex align-items-center">
                <div className="font-20 text-capitalize">Staff Name:</div>
                <div className="ms-2 new-staff-name">
                  {values.name + " " + values.last_name}
                </div>
              </div>
              <div className="d-flex ms-auto align-items-center">
                <div className="font-20 text-capitalize ms-4">Staff ID:</div>
                <div className="ms-2 new-staff-name">{uid}</div>
                {!editMode && (
                  <div className="profile-edit ms-3" onClick={handleEditClick}>
                    <MdEdit />
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="row mt-2">
                <div className="col-md-12 col-sm-6 d-flex justify-content-around">
                  <button
                    className={`ms-2 new-profile-btn ${
                      activeButton === "generalInfo" ? "prof-btn-active" : ""
                    }`}
                    onClick={handleGeneralInfoButtonClick}
                  >
                    <i className="bi bi-person-fill fs-5 "></i> General Info
                  </button>
                  <button
                    className={`ms-2 new-profile-btn ${
                      activeButton === "info" ? "prof-btn-active" : ""
                    }`}
                    onClick={handleInfoButtonClick}
                  >
                    <i className="bi bi-box-arrow-right fs-5"></i> Additional
                    Info
                  </button>
                  <button
                    className={`ms-2 new-profile-btn ${
                      activeButton === "salary" ? "prof-btn-active" : ""
                    }`}
                    onClick={handleSalaryButtonClick}
                  >
                    <i className="bi bi-file-earmark-text-fill fs-5"></i> Salary
                    Records
                  </button>
                  <button
                    className={`ms-2 new-profile-btn ${
                      activeButton === "rewards" ? "prof-btn-active" : ""
                    }`}
                    onClick={handleRewardButtonClick}
                  >
                    <i className="bi bi-gift-fill fs-5"></i> Rewards
                  </button>
                  <button
                    className={`ms-2 new-profile-btn ${
                      activeButton === "fines" ? "prof-btn-active" : ""
                    }`}
                    onClick={handleFineButtonClick}
                  >
                    <i className="bi bi-currency-exchange fs-5"></i> Fines
                  </button>
                  <button className="ms-2 new-profile-btn">
                    <i className="bi bi-person-fill-check fs-5"></i>{" "}
                    Disciplinary Action
                  </button>
                  <button
                    className={`ms-2 new-profile-btn ${
                      activeButton === "leave" ? "prof-btn-active" : ""
                    }`}
                    onClick={handleLeaveButtonClick}
                  >
                    <i className="bi bi-person-vcard fs-5"></i> Leave Quota
                  </button>
                </div>
              </div>
              {showFineComponent ? (
                <StaffProfileFine />
              ) : showRewardComponent ? (
                <StaffProfileReward />
              ) : showSalaryComponent ? (
                <SatffProfileSalary />
              ) : showInfoComponent ? (
                <StaffProfilInfo />
              ) : showLeaveComponent ? (
                <StaffProfileLeaves />
              ) : (
                <form className="row g-3 mt-2" onSubmit={handleSubmit}>
                  <div className="col-3 ">
                    <div className="profcard">
                      <div className="cover-photo">
                        <div className="prof-avatar">
                          <div className="prof-avatar-edit">
                            <input
                              type="file"
                              id="imageUpload"
                              onClick={(e) => {
                                e.target.value = null;
                                setFile("");
                                setPicture("");
                              }}
                              onChange={handleUpload}
                              accept=".png, .jpg, .jpeg"
                            />

                            <button
                              className="btn btn-primary "
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              disabled={!editMode}
                            >
                              <i className="bi bi-camera-fill" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                >
                                  Capture with webcam
                                </button>
                              </li>
                              <li>
                                <label
                                  className="dropdown-item"
                                  htmlFor="imageUpload"
                                >
                                  Upload Image
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div className="prof-avatar-preview">
                            <div
                              id="imagePreview"
                              style={
                                file === "" && picture === ""
                                  ? prfoImg === ""
                                    ? {
                                        backgroundImage: `url(${defaultImage})`,
                                        overflow: "hidden",
                                      }
                                    : {
                                        backgroundImage: `url(${defaultImage})`,
                                        overflow: "hidden",
                                        backgroundImage:
                                          'url("' + prfoImg + '")',
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                      }
                                  : {
                                      backgroundImage: "none",
                                      overflow: "hidden",
                                    }
                              }
                            >
                              <img
                                alt=""
                                id="output"
                                style={
                                  file === "" && picture === ""
                                    ? {
                                        display: "none",
                                      }
                                    : {
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="about">
                        {values.name + " " + values.last_name} <br />{" "}
                        <div className="sub-about">{uid}</div>
                      </p>
                      <hr />
                      <div className="progress-section">
                        <div className="d-flex justify-content-between sect-1">
                          <div>Profile Completion</div>
                          <div>50%</div>
                        </div>
                        <div className="prof-progress">
                          <div
                            className="progress"
                            role="progressbar"
                            aria-label="Danger example"
                            aria-valuenow={100}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ height: "6px" }}
                          >
                            <div
                              className="progress-bar bg-danger"
                              style={{ width: "50%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      {/* <hr/> */}
                    </div>
                  </div>
                  <div className="col-9">
                    <h5 className="mb-0 text-danger">Staff Name</h5>
                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Enter First Name"
                            name="name"
                            value={values.name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          />
                        </div>
                        {errors.name && touched.name ? (
                          <p className="text-danger">{errors.name}</p>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Enter Last Name"
                            name="last_name"
                            value={values.last_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          />
                        </div>
                        {errors.last_name && touched.last_name ? (
                          <p className="text-danger">{errors.last_name}</p>
                        ) : null}
                      </div>
                      <div className="col-6 mt-3">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Father's Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Enter Last Name"
                            name="father_name"
                            value={values.father_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          />
                        </div>
                      </div>
                      <div className="col-6 mt-3">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            DOB <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control "
                            placeholder="Enter DOB"
                            name="dob"
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          />
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-3 text-danger">Staff Contact Detail</h5>
                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                            value={values.email || ""}
                            onChange={(e) => {
                              handleChange(e);
                              emailVerify(e);
                            }}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          />
                        </div>
                        {emailError ? (
                          <div className="errorMsg text-danger">
                            {emailError}
                          </div>
                        ) : null}
                        {errors.email && touched.email ? (
                          <p className="text-danger">{errors.email}</p>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="3000000000"
                            name="phone"
                            value={values.phone}
                            onChange={(e) => {
                              handleChange(e);
                              verifyPhone(e);
                            }}
                            onBlur={handleBlur}
                            disabled={!editMode}
                            ref={phone_number}
                          />
                          {phoneError !== "" ? (
                            <p className="text-danger">{phoneError}</p>
                          ) : null}

                          {errors.phone && touched.phone ? (
                            <p className="text-danger">{errors.phone}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-6 mt-3">
                    <div className="form-group custom-group">
                      <label className="form-label">
                      Emergency Contact No
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="(e.g 92xxxxxxxxxx"
                        name="emergency_contact_no"
                        value={values.emergency_contact_no}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        disabled={!editMode}
                        ref={emergency_contact_no}
                      />
                    </div>
                  </div>
                  <div className="col-6 mt-3">
                    <div className="form-group custom-group">
                      <label className="form-label">Blood Group</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Enter Blood Group"
                        name="blood_group"
                        value={values.blood_group}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!editMode}
                      />
                    </div>
                  </div>
                      <div className="col-6 mt-3">
                        <div className="form-group custom-group">
                          <label className="form-label">
                            CNIC <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=" xxxxx-xxxxxxx-x"
                            name="cnic"
                            value={values.cnic}
                            onChange={(e) => {
                              handleChange(e);
                              verifyCnic(e);
                            }}
                            onBlur={handleBlur}
                            disabled={!editMode}
                            ref={cnic_format}
                          />
                          {cnicError !== "" ? (
                            <p className="text-danger">{cnicError}</p>
                          ) : null}
                          {errors.cnic && touched.cnic ? (
                            <p className="text-danger">{errors.cnic}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-3 text-danger">Staff Address Detail</h5>
                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="form-group custom-group">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Enter Address"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          />
                        </div>
                        {errors.address && touched.address ? (
                          <p className="text-danger">{errors.address}</p>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <div className="form-group custom-group">
                          <label className="form-label">City</label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Enter City"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          />
                        </div>
                        {errors.city && touched.city ? (
                          <p className="text-danger">{errors.city}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <h5 className="mt-3 text-danger">Staff Duty Time</h5>
                    <Link to="/staff-duty-hours" className="mt-3 ms-auto">
                      <i className="bi bi-plus-lg"></i>Add Duty Hours
                    </Link>
                  </div>
                  <div className="row mt-2">
                    <STable data={myData} />
                  </div>
                  <h5 className="mt-3 text-danger">Employee Status</h5>
                  <div className="row mt-2">
                    <div className="col-4">
                      <div className="form-group custom-group">
                        <label className="form-label">Employee Status</label>
                        <select
                          className="form-select"
                          name="employment_status"
                          multiple={false}
                          value={values.employment_status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!editMode}
                        >
                          <option value="">Select employment_status</option>
                          <option value="Employed">Employed</option>
                          <option value="Terminated">Terminated</option>
                          <option value="Resigned">Resigned</option>
                        </select>
                      </div>
                    </div>
                    {values.employment_status === "Terminated" ||
                    values.employment_status === "Resigned" ? (
                      <>
                        <div className="col-4">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Termination/Resignation Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control "
                              name="employment_end_date"
                              value={values.employment_end_date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editMode}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <h5 className="mt-3 text-danger">Staff Other Details</h5>
                  <div className="row mt-2">
                    <div className="col-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="gender"
                          multiple={false}
                          value={values.gender}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!editMode}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      {errors.gender && touched.gender ? (
                        <p className="text-danger">{errors.gender}</p>
                      ) : null}
                    </div>
                    <div className="col-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Department <span className="text-danger">*</span>
                        </label>
                        {myDepartment.length ? (
                          <select
                            className="form-select"
                            name="select_department"
                            multiple={false}
                            value={values.select_department}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          >
                            {myDepartment.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            autoComplete="off"
                            name="select_department"
                            value={values.select_department}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Department</option>
                          </select>
                        )}
                        {errors.select_department &&
                        touched.select_department ? (
                          <p className="text-danger">
                            {errors.select_department}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Designation <span className="text-danger">*</span>
                        </label>
                        {myDesignation.length ? (
                          <select
                            className="form-select"
                            autoComplete="off"
                            name="designation"
                            value={values.designation}
                            multiple={false}
                            onChange={(e) => {
                              handleChange(e);
                              staffCatagryGet(e);
                            }}
                            onBlur={handleBlur}
                            disabled={!editMode}
                          >
                            {myDesignation.map((el, index) => {
                              return (
                                <option key={index} value={el.id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <select
                            className="form-select"
                            name="designation"
                            value={values.designation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">Select Designation</option>
                          </select>
                        )}
                        {errors.designation && touched.designation ? (
                          <p className="text-danger">{errors.designation}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-4 mt-3">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Salary <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control "
                          placeholder="Enter Gender"
                          name="staffSalary"
                          value={values.staffSalary}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!editMode}
                        />
                      </div>
                      {errors.staffSalary && touched.staffSalary ? (
                        <p className="text-danger">{errors.staffSalary}</p>
                      ) : null}
                    </div>
                    {values.designation === "1" ||
                    values.designation === "2" ||
                    values.designation === "76" ||
                    values.designation === 1 ||
                    values.designation === 2 ||
                    values.designation === 76 ? (
                      <>
                        <div className="col-4 mt-3">
                          <div className="form-group custom-group">
                            <label className="form-label">Commission</label>
                            <input
                              type="number"
                              className="form-control "
                              placeholder="Commission"
                              name="commission"
                              value={values.commission}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editMode}
                            />
                          </div>
                          {errors.commission && touched.commission ? (
                            <p className="text-danger">{errors.commission}</p>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                    <div className="col-4 mt-3">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Joining Date <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control "
                          placeholder="Enter joining"
                          name="joining"
                          value={values.joining}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!editMode}
                        />
                      </div>
                      {errors.joining && touched.joining ? (
                        <p className="text-danger">{errors.joining}</p>
                      ) : null}
                    </div>
                    <div className="col-4 mt-3">
                      <div className="form-group custom-group">
                        <label className="form-label">Appointment Date</label>
                        <input
                          type="date"
                          className="form-control "
                          placeholder="Enter Appointment Date"
                          name="appointment_date"
                          value={values.appointment_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!editMode}
                        />
                      </div>
                    </div>
                    <div className="col-4 mt-3">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Probation Period{" "}
                          <span className="text-black-50">
                            (Enter the Probation Period in form of months)
                          </span>
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          placeholder="Please enter the Probation Period in form of months"
                          name="probation_duration"
                          value={values.probation_duration}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          disabled={!editMode}
                        />
                      </div>
                    </div>
                    {values.designation === "1" ||
                    values.designation === "2" ||
                    values.designation === "76" ||
                    values.designation === 1 ||
                    values.designation === 2 ||
                    values.designation === 76 ? (
                      <>
                        <div className="col-4 mt-3">
                          <div className="custom-group">
                            <div className="custom-group">
                              <label className="form-label-1 fw-bold text-danger">
                                Package Category
                              </label>
                              {editMode && !pkgUp && (
                                <button
                                  className="btn btn-link p-0 ms-2 font-13 align-bottom custom-update-btn"
                                  type="button"
                                  onClick={showPkg}
                                >
                                  Update
                                </button>
                              )}

                              {/* {pkgUp ? null : (
                              <button
                                className="btn btn-link p-0 ms-2 font-13 align-bottom custom-update-btn"
                                type="button"
                                onClick={showPkg}
                              >
                                Update
                              </button>
                            )} */}
                            </div>
                          </div>

                          {pkgUp ? (
                            staffCatagry.length ? (
                              <div className="custom-raio">
                                <ul className="cat-type">
                                  {staffCatagry.map((el, index) => (
                                    <li key={index}>
                                      <input
                                        type="checkbox"
                                        id={el.id}
                                        value={el.id}
                                        className="option[17]"
                                      />
                                      <label htmlFor={el.id}>{el.name}</label>
                                      <div className="check" />
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : null
                          ) : (
                            <Table data={trainingCat} />
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                  <h5 className="mt-3 text-danger">Other Benefits</h5>
                  <div className="row mt-2">
                    <div className="col-4">
                      <div className="form-group custom-group">
                        <label className="form-label">
                          Medical
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          placeholder="Please enter medical"
                          name="medical"
                          value={values.medical}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          disabled={!editMode}
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="col-4 mt-3">
                      {editMode && (
                        <button
                          type="submit"
                          className="btn btn-primary px-5 rounded-0 mt-3"
                        >
                          Update Profile
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </main>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Capture Image
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {picture === "" ? (
                  <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    width={450}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className="webcam"
                  />
                ) : (
                  <img src={picture} alt="" />
                )}
              </div>
              <div>
                {picture !== "" ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPicture("");
                      }}
                      className="btn btn-danger custom-butn3 mt-2"
                    >
                      Retake
                    </button>
                    <button
                      className="btn btn-danger custom-butn3 mt-2 ms-2"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      capture();
                    }}
                    className="btn btn-danger custom-butn3 "
                  >
                    Capture
                  </button>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

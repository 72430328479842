import React, { useEffect, useState } from "react";
import logo from "../../icons/latest-logo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import MenuButton from "../MenuButton/MenuButton";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import UserServices from "../../UserServices/UserServices";

export default function EmployeClearanceForm() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedUid, setSelectedUid] = useState("");
  const [selectedJoining, setSelectedJoining] = useState("");
  const navigate = useNavigate();
  const currentDate = moment();
  const downloadImage = async (blob, fileName) => {
    const div = window.document.createElement("div");
    div.style = "display:none; text-align:center;";
    const myImage = window.document.createElement("img");
    myImage.src = blob;
    myImage.style = "display:block; margin:0 auto;";
    var printMe = await div.appendChild(myImage);
    setLoading(false);
    var print_area = window.open();
    if (print_area && print_area.document) {
      print_area.document.write(div.innerHTML);
      print_area.document.close();
      print_area.focus();
      print_area.print();
      print_area.close();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to open print window",
      });
    }
  };
  const printSlip = async (el, imageFileName) => {
    setLoading(true);
    const canvas = await html2canvas(el, { scale: 3 });
    const image = canvas.toDataURL("image/jpeg", 0.95);

    downloadImage(image, imageFileName);
  };
  const generatePdf = async (el) => {
    setLoading(true);
    const canvas = await html2canvas(el, {
      scale: 5,
      allowTaint: true,
      useCORS: true,
    });
    const image = canvas.toDataURL("image/png", 1.0);
    const invoiceWidth = 150; // in mm
    const invoiceHeight = 297; // in mm
    const pdf = new jsPDF("p", "mm", [invoiceWidth, invoiceHeight]);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const scaleX = pdfWidth / canvasWidth;
    const scaleY = pdfHeight / canvasHeight;
    const scale = Math.min(scaleX, scaleY);
    const canvasScaledWidth = canvasWidth * scale;
    const canvasScaledHeight = canvasHeight * scale;
    const pdfX = (pdfWidth - canvasScaledWidth) / 2;
    const pdfY = (pdfHeight - canvasScaledHeight) / 2;
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(
      imgData,
      "PNG",
      pdfX,
      pdfY,
      canvasScaledWidth,
      canvasScaledHeight
    );
    const pdfData = pdf.output("blob");
    const pdfURL = URL.createObjectURL(pdfData);
    setLoading(false);
    window.open(pdfURL, "_blank");
  };
  const getRecordss = async (id) => {
    setLoading(true);
    try {
      const response = await UserServices.getCertificatesLettersInfo(id);
      if (response.status === 200) {
        let data = response.data.data;
        // eslint-disable-next-line array-callback-return
        data.map((values) => {
          setSelectedUser(values.employee.name);
          setSelectedPosition(values.employee.position);
          setSelectedDepartment(values.employee.depart);
          setSelectedGender(values.employee.gender);
          setSelectedUid(values.employee.uid);
          setSelectedJoining(values.employee.joining);
          setSelectedAddress(values.branch_address);
        });
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getRecordss(id);
  }, []);
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3 d-flex justify-content-between">
              <h5 className="mb-0 font-20">Exit Interview Form</h5>
              <div className="d-flex">
                <button
                  className="btn certificate-button me-2"
                  onClick={() => navigate(-1)}
                >
                  Go Back <i className="bi bi-arrow-left ms-1"></i>
                </button>
                <button
                  className="btn certificate-button me-2"
                  onClick={() => {
                    printSlip(
                      document.getElementById("employment-certificate"),
                      "slip"
                    );
                  }}
                >
                  Print <i className="bi bi-printer-fill ms-1"></i>
                </button>

                <button
                  className="btn certificate-button"
                  onClick={() => {
                    generatePdf(
                      document.getElementById("employment-certificate")
                    );
                  }}
                >
                  PDF <i className="bi bi-file-earmark-pdf-fill ms-1"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div
                id="employment-certificate"
                className="printable-area"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 1in -0.25in",
                  width: "210mm",
                  height: "297mm",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgb(255, 255, 255)",
                  margin: " 0px auto",
                  border: "10px groove #ed1c24",
                }}
              >
                <div style={{ width: "98%", height: "98%" }}>
                  <div className="letter-head d-flex justify-content-center">
                    <img src={logo} alt="logo" height={50} />
                  </div>
                  <div className="letter-head d-flex justify-content-center mt-3">
                    <h3
                      style={{
                        color: "#ed1c24",
                      }}
                    >
                      EMPLOYEE CLEARANCE FORM
                    </h3>
                  </div>
                  <div className="form-data mt-3 d-flex justify-content-between">
                    <div
                      className="d-flex"
                      style={{
                        minWidth: "220px",
                        padding: "5px",
                        minWidth: "50%",
                      }}
                    >
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Name:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedUser}
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{
                        minWidth: "220px",
                        padding: "5px",
                        minWidth: "50%",
                      }}
                    >
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Designation:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedPosition}
                      </div>
                    </div>
                  </div>
                  <div className="form-data d-flex justify-content-between">
                    <div
                      className="d-flex"
                      style={{
                        minWidth: "220px",
                        padding: "5px",
                        minWidth: "50%",
                      }}
                    >
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Employee No:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedUid}
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{
                        minWidth: "220px",
                        padding: "5px",
                        minWidth: "50%",
                      }}
                    >
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Department:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {selectedDepartment}
                      </div>
                    </div>
                  </div>
                  <div className="form-data d-flex justify-content-between">
                    <div
                      className="d-flex"
                      style={{
                        minWidth: "220px",
                        padding: "5px",
                        minWidth: "50%",
                      }}
                    >
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        No. of days present during the month:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {" "}
                        N/A
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{
                        minWidth: "220px",
                        padding: "5px",
                        minWidth: "50%",
                      }}
                    >
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        Last Working Day:
                      </div>
                      <div className="ms-2" style={{ fontSize: "15px" }}>
                        {" "}
                        N/A
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="form-body">
                    <span className="d-flex">
                      <div
                        className="mt-2"
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          background: "#000",
                        }}
                      ></div>
                      <p className="ms-2">Type of Separation:</p>
                    </span>
                    <div className="check-boxes d-flex justify-content-between">
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Resignation
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Retirement
                        </label>
                      </div>
                      <div className="form-check" style={{ minWidth: "170px" }}>
                        <label>
                          <input className="form-check-input" type="checkbox" />
                          Other
                        </label>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        fontWeight: "700",
                        background: "#ff000014",
                      }}
                    >
                      <span>HR</span>
                      <span>Remarks/ Signature</span>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        a. Resignation Letter
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        b. Clearance Form
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        c. Identity Card
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        d. Uniform
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        e. Exit Interview
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        fontWeight: "700",
                        background: "#ff000014",
                      }}
                    >
                      <span>IMMEDIATE SUPERIOR</span>
                      <span></span>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        a. Locker Keys
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        b. Co. Files/ Registers/ Manual/Equipment
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        padding: "5px",
                        fontWeight: "700",
                        background: "#ff000014",
                      }}
                    >
                      <span>IMMEDIATE SUPERIOR</span>
                      <span></span>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        a. Loan/ Advance
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="remarks-section d-flex justify-content-between"
                      style={{
                        border: "1px solid #ddd",
                        borderTop: "0",
                      }}
                    >
                      <div style={{ width: "50%", padding: "5px" }}>
                        b. Any other Liabilities
                      </div>
                      <div
                        style={{
                          width: "50%",
                          borderLeft: "1px solid #dddd",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="d-flex justify-content-between mt-5"
                      style={{ fontWeight: "600" }}
                    >
                      <div
                        className=""
                        style={{
                          borderTop: "1px solid #000",
                          maxWidth: "148px",
                        }}
                      >
                        Employee Signature:
                      </div>
                      <div>
                        Last Date: {moment(currentDate).format("DD-MM-YYYY")}
                      </div>
                    </div>
                    <div className="end mt-5">
                      <h5>Head of Department / H.R.</h5>
                      <p>
                        We hereby confirm that we do not have any objections to
                        clearance of dues of{" "}
                        <span>
                          {selectedGender === "Male" && " Mr."}
                          {selectedGender === "Female" && " Ms."}
                          {selectedGender === "Others" && " Mr. / Ms."}{" "}
                        </span>{" "}
                        <span
                          style={{
                            fontWeight: 700,
                            color: "#ed1c24",
                          }}
                        >
                          {selectedUser}
                        </span>{" "}
                        We also confirm that all property of the Company
                        entrusted to him/her during the tenure of service has
                        been recovered and there is nothing against the
                        individual as on date.
                      </p>
                      <div
                        className="mt-5"
                        style={{
                          borderTop: "1px solid #000",
                          maxWidth: "153px",
                          fontWeight: "600",
                        }}
                      >
                        Authorized Signatory
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

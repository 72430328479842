import React, { useEffect, useState, useContext, useRef } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import { timeSlot } from "../schemas";
import { NoteState } from "../../context/notes/NoteState";
import Pagination from "react-js-pagination";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import $ from "jquery";
import Swal from "sweetalert2";

export default function SlotManage() {
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const [limit, setLimit] = useState("25");
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [myData, setMyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // for inactive table
  const [inactiveLimit, setInactiveLimit] = useState("25");
  const [inactiveTotalResults, setInactiveTotalResults] = useState("0");
  const [inactiveTotalPages, setInactiveTotalPages] = useState("1");
  const [inactiveData, setInactiveData] = useState([]);
  const [inactiveCurrentPage, setInactiveCurrentPage] = useState(1);
  // update
  const [selectedBranches, setSelectedBranches] = useState("");
  const [Price, setPrice] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageId, setPackageId] = useState("");

  var clickEvent = $.Event("click");

  const closeButtonRef = useRef(null);
  const convertTimeTo24HourFormat = (input) => {
    const [time, period] = input.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    const isPM = period === "PM";
    const formattedHours = isPM ? hours + 12 : hours;
    return `${formattedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };
  const getSingleTime = async (id) => {
    setLoading(true);
    try {
      const responce = await UserServices.getTimeSlotInfo(id);

      if (responce.status === 200) {
        let data = responce.data.data;
        // eslint-disable-next-line array-callback-return
        data.map(async (values) => {
          setSelectedBranches(values.branch_id);
          const endTime = await convertTimeTo24HourFormat(values.end_time);
          const startTime = await convertTimeTo24HourFormat(values.start_time);
          setPrice(endTime);
          setPackageName(startTime);
          setPackageId(values.id);
        });
        $("#membership").trigger(clickEvent);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getTimeSlot = async (page, limit) => {
    setLoading(true);
    setCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setLimit(newLimit);
    try {
      const responce = await UserServices.getactiveTimeSlot(
        branch_id,
        "1",
        newLimit,
        pageNo
      );
      let myDataarr;
      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_name: values.branch_name,
            end_time: values.end_time,
            start_time: values.start_time,
          });
          return null;
        });

        setMyData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.totalRecord);
        setTotalPages(responce.data.totalPages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setTotalResults("0");
        setTotalPages("1");
        setMyData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const getInactiveTimeSlot = async (page, limit) => {
    setLoading(true);
    setInactiveCurrentPage(page);
    let pageNo = page;

    let newLimit = limit;
    setInactiveLimit(newLimit);
    try {
      const responce = await UserServices.getactiveTimeSlot(
        branch_id,
        "0",
        newLimit,
        pageNo
      );
      let myDataarr;

      if (responce.status === 200) {
        myDataarr = responce.data.data.data;
        const res = myDataarr;
        const results = [];

        res.map((values) => {
          results.push({
            id: values.id,
            branch_name: values.branch_name,
            end_time: values.end_time,
            start_time: values.start_time,
          });
          return null;
        });

        setInactiveData([...results]);
        setInactiveLimit(responce.data.data.per_page);
        setInactiveTotalResults(responce.data.totalRecord);
        setInactiveTotalPages(responce.data.totalPages);
        setInactiveCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        setInactiveTotalResults("0");
        setInactiveTotalPages("1");
        setInactiveData([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getTimeSlot(currentPage, limit);
    getInactiveTimeSlot(inactiveCurrentPage, inactiveLimit);
    branches();
  }, []);
  const makeInActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.changeTimeSlot(package_Id, "0");
      if (responce.status === 204) {
        setLoading(false);
        toast.error("Inactivated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getTimeSlot(currentPage, limit);
        getInactiveTimeSlot(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const inactivePackage = (package_Id) => {
    makeInActive(package_Id);
  };
  const deleteRecord = async (id) => {
    try {
      const responce = await UserServices.changeTimeSlot(id, "2");
      if (responce.status === 204) {
        toast.error("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getInactiveTimeSlot(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delFine = (id) => {
    deleteRecord(id);
  };
  const makeActive = async (package_Id) => {
    try {
      setLoading(true);
      const responce = await UserServices.changeTimeSlot(package_Id, "1");
      if (responce.status === 204) {
        setLoading(false);
        toast.success("Activated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getTimeSlot(currentPage, limit);
        getInactiveTimeSlot(inactiveCurrentPage, inactiveLimit);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const activePackage = (package_Id) => {
    makeActive(package_Id);
  };
  const addPkginitialValues = {
    branch_id: branch_id,
    start_date: "",
    end_date: "",
  };
  const convertTime = (input) => {
    const [hours, minutes] = input.split(":").map(Number);
    let formattedHours = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";

    formattedHours =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };
  const addTimeSlot = async (addPkgValues, action) => {
    const sTime = await convertTime(addPkgValues.start_date);
    const eTime = await convertTime(addPkgValues.end_date);
    if (sTime === eTime) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start and End time should not be the same!",
      });
    } else {
      try {
        setLoading(true);
        const response = await UserServices.addTimeSlot(
          addPkgValues.branch_id,
          sTime,
          eTime
        );

        if (response.status === 201) {
          setLoading(false);
          toast.success("Added Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          action.resetForm();
          getTimeSlot(currentPage, limit);
        }
      } catch (err) {
        setLoading(false);
        toast.error("Unprocessable content!", { position: "top-right" });
        if (err.response.status === 422) {
          // Handle other error cases if needed
        } else if (err.response.status === 500) {
          // Handle other error cases if needed
        } else if (err.response.status === 400) {
          // Handle other error cases if needed
        }
      }
    }
  };
  const checkTime = async (addPkgValues, action) => {
    const sTime = await convertTime(addPkgValues.start_date);
    const eTime = await convertTime(addPkgValues.end_date);
    try {
      setLoading(true);
      const response = await UserServices.checkExistingTime(
        addPkgValues.branch_id,
        sTime,
        eTime
      );
      if (response.status === 200) {
        setLoading(false);
        addTimeSlot(addPkgValues, action);
      }
    } catch (err) {
      setLoading(false);
      // toast.error("Unprocessable content!", { position: "top-right" });
      if (err.response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Record Already Exists!",
        });
      } else if (err.response.status === 500) {
        // Handle other error cases if needed
      } else if (err.response.status === 400) {
        // Handle other error cases if needed
      }
    }
  };

  const {
    values: addPkgValues,
    errors: addPkgErrors,
    touched: addPkgTouched,
    handleBlur: addPkgHandleBlur,
    handleChange: addPkgHandleChange,
    handleSubmit: addPkgHandleSubmit,
  } = useFormik({
    initialValues: addPkginitialValues,
    validationSchema: timeSlot,
    onSubmit: (addPkgValues, action) => {
      checkTime(addPkgValues, action);
    },
  });
  const generatePdf = () => {
    const doc = new jsPDF("p", "mm", "letter");
    const tables = document.querySelectorAll("table");

    const tableOptions = {
      headStyles: { fillColor: [232, 0, 43], textColor: 255 },
      styles: { fontSize: 5 },
    };
    let currentY = 10; // Initialize Y position for the text
    let pageWidth = doc.internal.pageSize.width; // Get the page width
    let pageHeight = doc.internal.pageSize.height; // Get the page height
    tables.forEach((table, index) => {
      // if (index !== 0) {
      //   doc.addPage();
      // }

      const captionText = table.getAttribute("data-caption");

      // Calculate the height of the current table
      let tableHeight = doc.autoTable.previous.finalY - currentY;

      // Check if the table fits on the current page
      if (currentY + tableHeight + 20 > pageHeight) {
        // If it doesn't fit, start a new page
        doc.addPage();
        currentY = 10;
      }

      doc.text(captionText, 10, currentY);
      currentY += 5;

      doc.autoTable({
        html: table,
        startY: currentY,
        ...tableOptions,
      });
      // Draw a line at the bottom of the table to separate it
      const tableBottomY = doc.autoTable.previous.finalY + 5;
      const lineWidth = pageWidth - 20; // Full page width (left margin: 10, right margin: 10)
      doc.setDrawColor(0); // Set line color to black
      doc.line(10, tableBottomY, 10 + lineWidth, tableBottomY); // Draw a line at the bottom of the table
      currentY = tableBottomY + 10; // Update the Y position for the next table
    });
    doc.save("time-slots.pdf");
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Active Time Slot"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("start_time")}
                className={getClassNamesFor("start_time")}
              >
                Start Time
              </th>
              <th
                scope="col"
                onClick={() => requestSort("end_time")}
                className={getClassNamesFor("end_time")}
              >
                End time
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>
                  <td>{el.start_time}</td> <td>{el.end_time}</td>
                  <td>
                    <button
                      onClick={() => {
                        getSingleTime(el.id);
                      }}
                      className="updt-btn inactive-btn"
                    >
                      <i className="bi bi-repeat text-success"></i>{" "}
                      <span className="text-success ms-1 updt-text">
                        Update
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => inactivePackage(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-x-circle text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Inactive
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              getTimeSlot(e, limit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const InactiveTable = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1"
          data-caption="Inactive Time Slot"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Branch Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("start_time")}
                className={getClassNamesFor("start_time")}
              >
                Start Time
              </th>
              <th
                scope="col"
                onClick={() => requestSort("end_time")}
                className={getClassNamesFor("end_time")}
              >
                End time
              </th>
              <th scope="col" className="no_sort">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {inactiveCurrentPage === 1
                      ? index + 1
                      : (inactiveCurrentPage - 1) * inactiveLimit + index + 1}
                  </td>
                  <td>{el.branch_name}</td>
                  <td>{el.start_time}</td>

                  <td>{el.end_time}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => activePackage(el.id)}
                      className="active-btn"
                    >
                      <i className="bi bi-check-circle text-success"></i>{" "}
                      <span className="text-success inative-text ms-1">
                        Active
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => delFine(el.id)}
                      className="ms-2 inactive-btn"
                    >
                      <i className="bi bi-trash3 text-danger"></i>{" "}
                      <span className="text-danger inative-text ms-1">
                        Delete
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {inactiveTotalResults > inactiveLimit && inactiveTotalPages > 1 ? (
          <Pagination
            activePage={inactiveCurrentPage}
            itemsCountPerPage={parseInt(inactiveLimit)}
            totalItemsCount={inactiveTotalResults}
            onChange={(e) => {
              getInactiveTimeSlot(e, inactiveLimit);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const UpdatePackage = async (values, action) => {
    const sTime = await convertTime(values.start_date);
    const eTime = await convertTime(values.end_date);
    if (sTime === eTime) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start and End time should not be the same!",
      });
    } else {
      try {
        setLoading(true);
        const response = await UserServices.updateTimeSlot(
          values.branch_id,
          sTime,
          eTime,
          packageId
        );
        if (response.status === 204) {
          setLoading(false);
          toast.success("Updated Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
          getTimeSlot(currentPage, limit);
          // Trigger the close button click event
          closeButtonRef.current.click();
        }
      } catch (err) {
        if (err.response.status === 422) {
          toast.error("Some data is missing", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };

  const checkTimeUpdate = async (addPkgValues, action) => {
    const sTime = await convertTime(addPkgValues.start_date);
    const eTime = await convertTime(addPkgValues.end_date);
    try {
      setLoading(true);
      const response = await UserServices.checkExistingTimeUpdate(
        addPkgValues.branch_id,
        sTime,
        eTime,
        packageId
      );
      if (response.status === 200) {
        setLoading(false);
        UpdatePackage(addPkgValues, action);
      }
    } catch (err) {
      setLoading(false);
      // toast.error("Unprocessable content!", { position: "top-right" });
      if (err.response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Record Already Exists!",
        });
      } else if (err.response.status === 500) {
        // Handle other error cases if needed
      } else if (err.response.status === 400) {
        // Handle other error cases if needed
      }
    }
  };

  const initialValues = {
    branch_id: selectedBranches,
    start_date: packageName,
    end_date: Price,
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: timeSlot,
      onSubmit: (values, action) => {
        checkTimeUpdate(values, action);
      },
    });
  return (
    <>
      <div className="wrapper">
        <main className="page-content customer-contnent">
          <div className="site-main-design">
            <MenuButton />
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Add Time Slots</h5>
              </div>
              <div className="card-body">
                <p>
                  <i
                    className="bi bi-exclamation-lg text-danger"
                    aria-hidden="true"
                  />
                  The Fields With <strong className="text-danger">*</strong>
                  Must Required Or Fill.
                </p>
                <form
                  className="row g-3"
                  onSubmit={(e) => {
                    addPkgHandleSubmit(e);
                  }}
                >
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Branch Name<span className="text-danger">*</span>
                      </label>
                      {myBranches ? (
                        <select
                          className="form-select"
                          name="branch_id"
                          disabled={branch_id ? true : false}
                          value={addPkgValues.branch_id || ""}
                          onChange={(e) => {
                            addPkgHandleChange(e);

                            addPkgValues.start_date = "";
                          }}
                          onBlur={addPkgHandleBlur}
                        >
                          {myBranches.map((el, index) => {
                            return (
                              <option key={index} value={el.id}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : null}
                      {addPkgErrors.branch_id && addPkgTouched.branch_id ? (
                        <p className="text-danger">{addPkgErrors.branch_id}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        Start Time<span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        placeholder="Start Time"
                        name="start_date"
                        autoComplete="off"
                        value={addPkgValues.start_date}
                        onChange={async (e) => {
                          addPkgHandleChange(e);
                        }}
                        onBlur={addPkgHandleBlur}
                      />

                      {addPkgErrors.start_date && addPkgTouched.start_date ? (
                        <p className="text-danger">{addPkgErrors.start_date}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group custom-group">
                      <label className="form-label">
                        End Time <span className="text-danger">*</span>
                      </label>
                      <input
                        onWheel={(e) => e.target.blur()}
                        type="time"
                        className="form-control"
                        placeholder="End Time"
                        name="end_date"
                        min={0}
                        value={addPkgValues.end_date}
                        onChange={addPkgHandleChange}
                        onBlur={addPkgHandleBlur}
                      />
                      {addPkgErrors.end_date && addPkgTouched.end_date ? (
                        <p className="text-danger">{addPkgErrors.end_date}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="clearfix" />
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 rounded-0 mb-1"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <button
              className="btn btn-primary mx-2 file-download float-end mb-3"
              onClick={() => {
                generatePdf(document.getElementById("report-POS"));
              }}
            >
              <i className="bi bi-file-earmark-pdf"></i> PDF
            </button>
            <div className="clearfix"></div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Active Time Slots</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 col-lg-2 ms-2 mb-3"></div>
                  <div className="col-md-1 col-lg-1 mb-2 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => getTimeSlot("1", e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <Table data={myData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header py-3">
                <h5 className="mb-0 font-20">Inactive Time Slot</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-lg-6 ms-3"></div>
                  {/* {totalResults > limit ? ( */}
                  <div className="col-md-1 col-lg-1 mb-2 ms-auto me-3">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={inactiveLimit}
                        onChange={(e) =>
                          getInactiveTimeSlot("1", e.target.value)
                        }
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="accordion-body">
                  <div className="table-responsive ">
                    <InactiveTable
                      data={inactiveData}
                      rowsPerPage={inactiveLimit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* ......Hidden Button....... */}
      <button
        className="btn btn-primary w-100 d-none"
        type="button"
        id="membership"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      ></button>
      {/* Modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Update Time Slot
              </h1>
              <button
                type="button"
                className="btn-close me-3  mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              >
                <i className="bi bi-x-lg text-dark fs-4"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    {myBranches ? (
                      <select
                        className="form-select"
                        name="branch_id"
                        value={values.branch_id || ""}
                        disabled={branch_id ? true : false}
                        onChange={(e) => {
                          handleChange(e);

                          setSelectedBranches(e.target.value);
                          values.start_date = "";
                        }}
                        onBlur={handleBlur}
                      >
                        {myBranches.map((el, index) => {
                          return (
                            <option key={index} value={el.id}>
                              {el.name}
                            </option>
                          );
                        })}
                      </select>
                    ) : null}
                    {errors.branch_id && touched.branch_id ? (
                      <p className="text-danger">{errors.branch_id}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      Start Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Enter Name"
                      name="start_date"
                      value={values.start_date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.start_date && touched.start_date ? (
                      <p className="text-danger">{errors.start_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group custom-group">
                    <label className="form-label">
                      End Time<span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      placeholder="Enter Price"
                      name="end_date"
                      value={values.end_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.end_date && touched.end_date ? (
                      <p className="text-danger">{errors.end_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary px-5 rounded-0 mb-1"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
